import { ShortTextFieldMetadata, ShortTextFieldType, shortTextFieldMetadataSchema } from './types';
import { DialogContent } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createTypedFormEntry } from '../../../fields/FormEntry';
import FormTextField from '../../../fields/FormTextField';
import FormTextArea from '../../../fields/FormTextArea';
import { FormToggleSwitchField } from '../../../fields/FormToggleSwitchField';
import { FieldEditDialog } from '../../DynamicFormBuilder';
import { fieldDef } from './index';
import { BuilderDialogProps, BuilderPluginValues, BuilderPlugins, FormWithPlugins } from '../types';
import { addPluginsToDefaultData, addPluginsToSchema } from '../utils';

type ShortTextFieldBuilderDialogProps<T extends BuilderPlugins> = BuilderDialogProps<ShortTextFieldType, T>;

const DEFAULT_VALUES: ShortTextFieldMetadata = {
  label: '',
  required: false,
  description: undefined,
};

const IW_COLOR = INTELLIGENT_WORKFLOWS_BRAND_COLOR;

const FieldMetadataEntry = createTypedFormEntry<ShortTextFieldMetadata>();

export const ShortTextFieldBuilderDialog = <T extends BuilderPlugins>(props: ShortTextFieldBuilderDialogProps<T>) => {
  const { onClose, onSubmit, defaultValues, plugins } = props;

  const { t } = useTranslation();

  const form = useForm<FormWithPlugins<ShortTextFieldMetadata>>({
    defaultValues: addPluginsToDefaultData(defaultValues ?? DEFAULT_VALUES, plugins),
    resolver: zodResolver(addPluginsToSchema(shortTextFieldMetadataSchema, plugins)),
  });

  const submitHandler: SubmitHandler<FormWithPlugins<ShortTextFieldMetadata>> = (values) => {
    if (onSubmit) {
      const { plugins: outputs, ...metadata } = values;
      onSubmit(metadata, outputs as BuilderPluginValues<T>);
    }
  };

  return (
    <FieldEditDialog form={form} schema={shortTextFieldMetadataSchema} onSubmit={form.handleSubmit(submitHandler)}>
      <FieldEditDialog.Header mode={defaultValues ? 'edit' : 'create'} titleSuffix={t(fieldDef.typeNameI18nKey)}>
        <FieldMetadataEntry
          name="required"
          component={FormToggleSwitchField}
          componentProps={{ label: t('DYNAMIC_FORM_BUILDER.REQUIRED_FIELD'), color: IW_COLOR }}
        />
      </FieldEditDialog.Header>
      <DialogContent>
        <FieldMetadataEntry
          name="label"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.FIELD_LABEL')}
          component={FormTextField}
          componentProps={{ color: IW_COLOR, inputProps: { maxLength: 40 } }}
        />
        <FieldMetadataEntry
          name="description"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.HELPTEXT')}
          component={FormTextArea}
          componentProps={{ color: IW_COLOR, size: 'M', variant: 'outline' }}
        />
        {plugins?.map(({ Component }, index) => (
          <Component key={index} name={`plugins.${index}`} fieldType="SHORT_TEXT" />
        ))}
      </DialogContent>
      <FieldEditDialog.Footer isFormValid={form.formState.isValid} onClose={onClose} />
    </FieldEditDialog>
  );
};
