import dagre from '@dagrejs/dagre';
import { cloneDeep } from 'lodash';

import type { EdgeId, NodeId, Path, Variable } from '../types';
import type { ProcessDefinition } from '../../../definitionsTypes';
import jmespath from '@metrichor/jmespath';

const START_NODE_ID_FALLBACK = 'start';

export const getProcessDefinitionStartNodeId = (processDefinition: ProcessDefinition) => {
  const startNode = processDefinition.process.start[0];

  if (!startNode) {
    return START_NODE_ID_FALLBACK;
  }

  return startNode.start.id;
};

export const findAllPathsBetweenNodes = ({
  graph,
  fromNodeId,
  toNodeId,
}: {
  graph: dagre.graphlib.Graph;
  fromNodeId: NodeId;
  toNodeId: NodeId;
}): Path[] => {
  const paths: Path[] = [];
  const visited = new Set<NodeId>();

  const doDepthFirstSearch = (currentNode: NodeId, nodePath: NodeId[], edgePath: EdgeId[]) => {
    nodePath.push(currentNode);
    visited.add(currentNode);

    if (currentNode === toNodeId) {
      paths.push({ nodes: [...nodePath], edges: [...edgePath] });
    } else {
      const neighbors = (graph.successors(currentNode) || []) as unknown as NodeId[];
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          const edgeId = graph.edge(currentNode, neighbor).id;
          edgePath.push(edgeId);
          doDepthFirstSearch(neighbor, nodePath, edgePath);
          edgePath.pop();
        }
      }
    }

    nodePath.pop();
    visited.delete(currentNode);
  };

  doDepthFirstSearch(fromNodeId, [], []);
  return paths;
};

export const toJMESPathExpression = <T extends unknown>(obj: T): string => {
  if (obj === null) {
    return 'null';
  }

  if (Array.isArray(obj)) {
    return `[${obj.map(toJMESPathExpression).join(',')}]`;
  }

  if (typeof obj === 'object') {
    const entries = Object.entries(obj).map(([key, value]) => `${key}:${toJMESPathExpression(value)}`);
    return `{${entries.join(',')}}`;
  }

  return `\`${String(obj)}\``;
};

export const getObjectFromJMESPathExpression = <T extends unknown>(expression?: string): T | null => {
  try {
    return jmespath.search('{}', expression ? expression : '[]') as T;
  } catch {
    return null;
  }
};

export const sortVariablesAlphabetically = (variables: Variable[]): Variable[] => {
  const clonedVariables = cloneDeep(variables);

  function sortVariables(childVariables: Variable[]): Variable[] {
    childVariables.sort((a, b) => a.label.localeCompare(b.label));

    for (const childVariable of childVariables) {
      if (childVariable.variables.length > 0) {
        childVariable.variables = sortVariables(childVariable.variables);
      }
    }

    return childVariables;
  }

  return sortVariables(clonedVariables);
};

export const replaceFirstSegmentOfVariableId = (oldId: string, newId: string): string => {
  // The variable ID can start with a UDF call, in which case we need to handle it differently
  const udfStart = '(udf(`urn:verticeone';

  if (oldId.startsWith(udfStart)) {
    // The UDF segment ends with '))'
    const endIndex = oldId.indexOf('))');
    if (endIndex !== -1) {
      const remainder = oldId.substring(endIndex + 2);
      return newId + remainder;
    }
  }

  const dotIndex = oldId.indexOf('.');
  if (dotIndex === -1) {
    return newId;
  }

  return newId + oldId.substring(dotIndex);
};
