import { FC, useState } from 'react';

import type { Variable } from '../../types';
import { VariableChip } from '../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../VariableSelector/VariablesDialog';
import { useWorkflowVariablesGroups } from './useWorkflowVariablesGroups';

const formatVariablePath = (path?: string[]) => {
  if (!path) {
    return '';
  }

  return path.join(' > ');
};

type WorkflowVariableSelectorProps = {
  udfVariables: Variable[];
  allRequestVariables: Variable[];
  isDisabled?: boolean;
  selectedVariable?: Variable;
  onSelectedVariableChange: (property: Variable) => void;
  isVariableTypeDisplayed?: boolean;
  typeFilter?: string;
  positionFromTheRight?: number;
  addVariableDefaults?: Pick<Variable, 'origin' | 'type'>;
  onAddLocalVariable?: (variable: Variable) => void;
  selectedVariableHint?: string;
  testId?: string;
  error?: boolean;
};

export const WorkflowVariableSelector: FC<WorkflowVariableSelectorProps> = ({
  udfVariables,
  allRequestVariables,
  isDisabled,
  selectedVariable,
  onSelectedVariableChange,
  isVariableTypeDisplayed = true,
  typeFilter,
  positionFromTheRight,
  addVariableDefaults,
  onAddLocalVariable,
  selectedVariableHint,
  testId,
  error,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const variablesGroups = useWorkflowVariablesGroups({
    udfVariables,
    allRequestVariables,
  });

  const handleVariableClick = (variable: Variable) => {
    onSelectedVariableChange(variable);
    setIsDialogOpen(false);
  };

  return (
    <>
      <VariableChip
        label={selectedVariable?.label}
        typeLabel={isVariableTypeDisplayed ? selectedVariable?.type.labels?.[0] : undefined}
        onClick={() => setIsDialogOpen(true)}
        withIcon
        isDisabled={isDisabled}
        hint={selectedVariableHint}
        testId={testId}
        isError={error}
        tooltipContent={formatVariablePath(selectedVariable?.path)}
      />
      <VariablesDialog
        selectedVariableId={selectedVariable?.id}
        variablesGroups={variablesGroups}
        typeFilter={typeFilter}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
        positionFromTheRight={positionFromTheRight}
        addVariableDefaults={addVariableDefaults}
        onAddLocalVariable={onAddLocalVariable}
      />
    </>
  );
};
