import React, { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import styles from './IntegrationsCardContent.module.scss';

type IntegrationsCardContentProps = {
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  description?: ReactNode | string;
  statusBadge?: ReactNode;
};

export const IntegrationsCardContent = ({
  title,
  subtitle,
  description,
  statusBadge,
}: IntegrationsCardContentProps) => {
  const titleComponent =
    typeof title === 'string' ? (
      <Text variant="heading" size="S" color="text1">
        {title}
      </Text>
    ) : (
      title
    );

  const subtitleComponent =
    typeof subtitle === 'string' ? (
      <Text variant="heading" size="S" color="text1">
        {subtitle}
      </Text>
    ) : (
      subtitle
    );

  const descriptionComponent =
    typeof description === 'string' ? (
      <Box
        sx={{
          height: `calc(16.8px * 3)`, // 3 lines of body-regular S text
        }}
      >
        <Text
          variant="body-regular"
          size="S"
          color="text2"
          sx={{
            overflow: 'hidden',
            lineClamp: 3,
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {description}
        </Text>
      </Box>
    ) : (
      description
    );

  return (
    <Stack direction="column" gap="16px">
      <Stack direction="column">
        <Stack className={styles['title-section']} direction="row" gap="8px" sx={{}}>
          <Box
            sx={{
              flexShrink: 1,
              minWidth: 0,
              '& > *': {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
          >
            {titleComponent}
          </Box>
          <Box sx={{ flex: '1 0 auto' }}>{statusBadge}</Box>
        </Stack>
        {subtitleComponent}
      </Stack>
      {descriptionComponent}
    </Stack>
  );
};
