const REGEXP_ALPHA_NEXT_TO_DIGIT = /\d[a-zA-Z]|[a-zA-Z]\d/;
export const isValidNameFormat = (text: string) => !REGEXP_ALPHA_NEXT_TO_DIGIT.test(text);
export const fixInvalidNameFormat = (text: string) =>
  text.replaceAll(new RegExp(REGEXP_ALPHA_NEXT_TO_DIGIT, 'g'), (m) => m.split('').join(' '));

const VALID_CHARACTERS_REGEXP = /^[a-zA-Z0-9\s]*$/;
export const hasValidNameCharacters = (text: string) => VALID_CHARACTERS_REGEXP.test(text);
const INVALID_CHARACTERS_REGEXP = /[^a-zA-Z0-9\s]/g;
export const fixInvalidCharacters = (text: string) => text.replaceAll(INVALID_CHARACTERS_REGEXP, '');

const WORD_STARTING_WITH_CAPITAL_REGEXP = /^[A-Z][a-z]*$/;
const NUMBERS_REGEXP = /^\d+$/;
export const hasValidUppercaseWord = (text: string) => {
  return text.split(' ').every((word) => NUMBERS_REGEXP.test(word) || WORD_STARTING_WITH_CAPITAL_REGEXP.test(word));
};

export const fixInvalidUppercaseWords = (text: string) => {
  return text
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const normalizeWhiteSpace = (name: string) => name.trim().replaceAll(/\s+/g, ' ');

export const fixName = (name: string) => {
  if (!hasValidUppercaseWord(name)) {
    name = fixInvalidUppercaseWords(name);
  }
  if (!hasValidNameCharacters(name)) {
    name = fixInvalidCharacters(name);
  }
  if (!isValidNameFormat(name)) {
    name = fixInvalidNameFormat(name);
  }
  return name;
};
