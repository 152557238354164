import { Divider, Stack, styled } from '@mui/material';
import {
  GridCellParams,
  gridClasses,
  GridColDef,
  gridDateComparator,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NoDataGridWrapper from '@vertice/core/src/components/NoDataGridWrapper';
import { UsageContext } from '@vertice/core/src/modules/applications/Applications/UsageContext';
import { smartTextContainsFilterOperator } from '@verticeone/design-system';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import DataGrid from '@vertice/components/src/DataGrid';
import SearchInput from '@vertice/core/src/components/SearchInput';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Card, CardContent } from '@vertice/components';
import { isTruthy } from '@verticeone/utils/validation';
import { LastUsedCell } from '../../../components/LastUsedCell';
import { User } from '../../hooks/useUsers';
import { EMPTY_FILTER } from '../../utils';
import { Bin, Bins } from '../ActivityFrequency/utils';
import ActivityFrequencyCell from './ActivityFrequencyCell';
import { FilterButton } from './FilterButton';
import FullNameCell from './FullNameCell';
import { NoRows } from './NoRows';

type UsersGridProps = {
  isLoading: boolean;
  data: Readonly<Array<User>>;
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  background: theme.palette.core.color1,
}));

const StyledDataGrid = styled(DataGrid<User>)(({ theme }) => ({
  background: theme.palette.core.bg,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  overflow: 'auto',
  [`.${gridClasses.main}`]: {
    borderRadius: 0,
  },
}));

const Header = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 6),
}));

export const isUserInactiveFilterOperator: GridFilterOperator = {
  label: 'is Inactive',
  value: 'isUserInactive',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return () => true;
    }

    // eslint-disable-next-line arrow-body-style
    return (params: GridCellParams): boolean => {
      return !params.value || params.value < filterItem.value;
    };
  },
};

export const isUserActiveFilterOperator: GridFilterOperator = {
  label: 'is Active',
  value: 'isUserActive',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return () => true;
    }

    // eslint-disable-next-line arrow-body-style
    return (params: GridCellParams): boolean => {
      return !!params.value && params.value >= filterItem.value;
    };
  },
};

const useGetColumns = (): Array<GridColDef<User>> => {
  const { t } = useTranslation();

  return [
    {
      field: 'name',
      headerName: t('APPLICATIONS.USAGE.NAME.HEADER'),
      flex: 2,
      filterOperators: [smartTextContainsFilterOperator],
      renderCell: (params) => <FullNameCell {...params} />,
    },
    {
      field: 'lastAccess',
      headerName: t('APPLICATIONS.USAGE.LAST_ACTIVITY.HEADER'),
      flex: 1,
      sortComparator: gridDateComparator,
      valueGetter: (params) => params.row.lastAccess,
      filterOperators: [isUserActiveFilterOperator, isUserInactiveFilterOperator],
      renderCell: (params) => <LastUsedCell lastUsed={params?.row?.lastAccess} />,
    },
    {
      field: 'activityFrequency',
      headerName: t('APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.HEADER'),
      flex: 1,
      sortComparator: (a, b) => Bins.indexOf(a) - Bins.indexOf(b),
      valueGetter: (params) => params.row.activityFrequency,
      renderCell: (params) => <ActivityFrequencyCell activityFrequency={params.row.activityFrequency} />,
    },
  ];
};

export const Grid = ({ data, isLoading }: UsersGridProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const columns = useGetColumns();
  const [search, setSearch] = useState('');

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const { userActivityFilter, setUserActivityFilter } = useContext(UsageContext);

  const fourWeeksAgo = useMemo(() => dayjs().subtract(28, 'day').startOf('day').toISOString(), []);

  const activeCount = data?.filter((user) => user.lastAccess && user.lastAccess >= fourWeeksAgo).length;
  const inactiveCount = data?.filter((user) => !user.lastAccess || user.lastAccess < fourWeeksAgo).length;
  const dailyCount = data?.filter((user) => user.activityFrequency === Bin.DAILY).length;
  const twiceAWeekCount = data?.filter((user) => user.activityFrequency === Bin.TWICE_A_WEEK).length;
  const weeklyCount = data?.filter((user) => user.activityFrequency === Bin.WEEKLY).length;
  const biweeklyCount = data?.filter((user) => user.activityFrequency === Bin.BIWEEKLY).length;
  const monthlyCount = data?.filter((user) => user.activityFrequency === Bin.MONTHLY).length;
  const occasionallyCount = data?.filter((user) => user.activityFrequency === Bin.OCCASIONALLY).length;

  useEffect(() => {
    setFilterModel({
      items: [
        {
          id: 1,
          field: 'name',
          value: search,
          operator: 'smartTextContains',
        },
        userActivityFilter.active && {
          id: 2,
          field: 'lastAccess',
          value: fourWeeksAgo,
          operator: 'isUserActive',
        },
        userActivityFilter.inactive && {
          id: 3,
          field: 'lastAccess',
          value: fourWeeksAgo,
          operator: 'isUserInactive',
        },
        userActivityFilter.daily && {
          id: 4,
          field: 'activityFrequency',
          value: Bin.DAILY,
          operator: 'equals',
        },
        userActivityFilter.twiceAWeek && {
          id: 5,
          field: 'activityFrequency',
          value: Bin.TWICE_A_WEEK,
          operator: 'equals',
        },
        userActivityFilter.weekly && {
          id: 6,
          field: 'activityFrequency',
          value: Bin.WEEKLY,
          operator: 'equals',
        },
        userActivityFilter.biweekly && {
          id: 7,
          field: 'activityFrequency',
          value: Bin.BIWEEKLY,
          operator: 'equals',
        },
        userActivityFilter.monthly && {
          id: 8,
          field: 'activityFrequency',
          value: Bin.MONTHLY,
          operator: 'equals',
        },
        userActivityFilter.occasionally && {
          id: 9,
          field: 'activityFrequency',
          value: Bin.OCCASIONALLY,
          operator: 'equals',
        },
      ].filter(isTruthy),
    });
  }, [search, userActivityFilter, fourWeeksAgo]);

  return (
    <Card>
      <StyledCardContent>
        <Header>
          <Stack direction="row" flexWrap="wrap" gap={2}>
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.ALL')}
              count={activeCount + inactiveCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, all: true });
              }}
              active={userActivityFilter.all}
            />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.ACTIVE')}
              count={activeCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, active: true });
              }}
              active={userActivityFilter.active}
            />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.INACTIVE')}
              count={inactiveCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, inactive: true });
              }}
              active={userActivityFilter.inactive}
            />
            <Divider orientation="vertical" flexItem />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.DAILY')}
              count={dailyCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, daily: true });
              }}
              active={userActivityFilter.daily}
            />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.TWICE_A_WEEK')}
              count={twiceAWeekCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, twiceAWeek: true });
              }}
              active={userActivityFilter.twiceAWeek}
            />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.WEEKLY')}
              count={weeklyCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, weekly: true });
              }}
              active={userActivityFilter.weekly}
            />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.BIWEEKLY')}
              count={biweeklyCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, biweekly: true });
              }}
              active={userActivityFilter.biweekly}
            />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.MONTHLY')}
              count={monthlyCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, monthly: true });
              }}
              active={userActivityFilter.monthly}
            />
            <FilterButton
              label={t('APPLICATIONS.USAGE.USERS_GRID.FILTERS.OCCASIONALLY')}
              count={occasionallyCount}
              onClick={() => {
                setUserActivityFilter({ ...EMPTY_FILTER, occasionally: true });
              }}
              active={userActivityFilter.occasionally}
            />
          </Stack>
          <SearchInput
            disabled={!data?.length}
            placeholder={t('APPLICATIONS.USAGE.SEARCH_USERS')}
            value={search}
            onSearch={setSearch}
            sx={{ width: '20vw' }}
          />
        </Header>
        <Stack width="calc(100% + 2px)" height={!data.length ? 'auto' : '65vh'} margin="0 -1px -1px -1px">
          <NoDataGridWrapper hasData={!!data?.length}>
            <StyledDataGrid
              autoHeight={false}
              rowHeight={60}
              apiRef={apiRef}
              columns={columns}
              rows={data}
              filterModel={filterModel}
              loading={isLoading}
              disableColumnFilter
              disableRowSelectionOnClick
              disableColumnMenu
              hideFooterRowCount
              hideFooter
              slots={{
                noRowsOverlay: NoRows,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
            />
          </NoDataGridWrapper>
        </Stack>
      </StyledCardContent>
    </Card>
  );
};
