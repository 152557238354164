import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorDrawerGroup } from '../../../EditorDrawer';
import { IssueTypePicker } from '../JiraCommon/IssueTypePicker';
import { VariableMapper } from '../JiraCommon/VariableMapper/VariableMapper';
import { VariableMapperLoader } from '../JiraCommon/VariableMapperLoader';
// import { Attachments } from './Attachments';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../utils';
import { JiraCreateTaskFormData } from './formSchema';
import { JiraCreateFormProps } from './JiraCreateForm';

type JiraCreateSetupProps = Pick<JiraCreateFormProps, 'issueTypePickerProps' | 'variableMapperProps'> & {
  defaultIssueTypeId: string;
  isLoadingVariableMapper: boolean;
};

export const JiraCreateSetup: FC<JiraCreateSetupProps> = ({
  defaultIssueTypeId,
  issueTypePickerProps,
  isLoadingVariableMapper,
  variableMapperProps,
}) => {
  const { t } = useTranslation();

  return (
    <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.JIRA_ISSUE_SETUP')}>
      <IssueTypePicker defaultIssueTypeId={defaultIssueTypeId} {...issueTypePickerProps} />
      {isLoadingVariableMapper ? (
        <VariableMapperLoader />
      ) : (
        <VariableMapper<JiraCreateTaskFormData>
          formFieldName="variables"
          requiredToFieldsCount={REQUIRED_JIRA_CREATE_FIELDS.length}
          otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.JIRA')}
          {...variableMapperProps}
        />
      )}
      {/* TODO: Add attachments feature once it's fully supported on WF side */}
      {/* <Attachments /> */}
    </EditorDrawerGroup>
  );
};
