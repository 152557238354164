import { FieldDef } from '../types';
import {
  getCheckboxJsonSchema,
  getCheckboxJsonSchemaFromMetadata,
  getCheckboxPresenterDefault,
  getCheckboxPresenterSchema,
} from './utils';
import { CheckboxFieldType } from './types';
import { CheckboxFieldBuilderCard } from './CheckboxFieldBuilderCard';
import { CheckboxFieldPresenter } from './CheckboxFieldPresenter';
import { CheckboxFieldBuilderDialog } from './CheckboxFieldBuilderDialog';

export type { CheckboxFieldType, CheckboxFieldMetadata } from './types';
export { checkboxFieldSchema, checkboxFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<CheckboxFieldType> = {
  type: 'CHECKBOX',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.CHECKBOX',
  builderCard: CheckboxFieldBuilderCard,
  builderDialog: CheckboxFieldBuilderDialog,
  presenter: CheckboxFieldPresenter,
  getPresenterDefault: getCheckboxPresenterDefault,
  getPresenterSchema: getCheckboxPresenterSchema,
  getJsonSchemaFromMetadata: getCheckboxJsonSchemaFromMetadata,
  getJsonSchema: getCheckboxJsonSchema,
};
