import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Node } from '@xyflow/react';
import { Stack, useTheme } from '@mui/material';
import { CheckOutlined, InfoOutlined, PriorityHighOutlined } from '@mui/icons-material';
import { ValidationItem } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { IconWrapper, Text } from '@verticeone/design-system';
import { EditorDrawer, EditorDrawerHeader } from '../EditorDrawer';
import { useWorkflowValidationContext } from './WorkflowValidationContext';
import { ValidationSection, ValidationSectionSkeleton } from './ValidationSection';
import { PriorityHighestOutlined } from './PriorityHighestOutlined';

type ValidationDrawerProps = {
  isOpen: boolean;
  handleResolve: (node?: Node) => void;
};

type SectionType = {
  type: 'error' | 'warning' | 'info';
  title: string;
  items: ValidationItem[] | null;
  tooltip?: string;
};

export const ICON_MAP = {
  error: PriorityHighestOutlined,
  warning: PriorityHighOutlined,
  info: InfoOutlined,
};

const ValidationDrawerLayout = ({
  children,
  isOpen,
  onClose,
}: PropsWithChildren<{ isOpen: boolean; onClose: () => void }>) => {
  const { t } = useTranslation();
  return (
    <EditorDrawer open={isOpen}>
      <EditorDrawerHeader
        title={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.TITLE')}
        subtitle={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.SUBTITLE')}
        gap={2}
        onClose={onClose}
      />
      {children}
    </EditorDrawer>
  );
};

export const ValidationDrawer = ({ isOpen, handleResolve }: ValidationDrawerProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { infos, warnings, errors, setIsValidationDrawerOpen, isValidatingWorkflow, isWorkflowValid } =
    useWorkflowValidationContext();

  const sections: SectionType[] = useMemo(
    () => [
      {
        type: 'error',
        title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.ERRORS'),
        tooltip: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.ERRORS_TOOLTIP'),
        items: errors,
      },
      {
        type: 'warning',
        title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.WARNINGS'),
        tooltip: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.WARNINGS_TOOLTIP'),
        items: warnings,
      },
      {
        type: 'info',
        title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.INFOS'),
        items: infos,
      },
    ],
    [errors, warnings, infos, t]
  );

  const layoutProps = {
    isOpen,
    onClose: () => setIsValidationDrawerOpen(false),
  };

  if (isValidatingWorkflow) {
    return (
      <ValidationDrawerLayout {...layoutProps}>
        <ValidationContentSkeleton />
      </ValidationDrawerLayout>
    );
  }

  if (isWorkflowValid) {
    return (
      <ValidationDrawerLayout {...layoutProps}>
        <Stack height="100%" overflow="auto" maxWidth="100%" justifyContent="center" alignItems="center">
          <Stack gap={6} direction="column" alignItems="center">
            <IconWrapper size="XL" icon={CheckOutlined} htmlColor={palette.success.main} />
            <Stack gap={2} direction="column" alignItems="center">
              <Text variant="body-bold" size="S" color="text1">
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.NO_ISSUES_FOUND')}
              </Text>
              <Text variant="body-regular" size="S" color="text3">
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.WORKFLOW_VALID')}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </ValidationDrawerLayout>
    );
  }

  return (
    <ValidationDrawerLayout {...layoutProps}>
      <Stack height="100%" overflow="auto" maxWidth="100%">
        {sections.map((section) =>
          section.items !== null && section.items.length > 0 ? (
            <ValidationSection
              key={section.type}
              Icon={ICON_MAP[section.type]}
              tooltip={section.tooltip}
              handleResolve={handleResolve}
              items={section.items}
              type={section.type}
              title={section.title}
            />
          ) : null
        )}
      </Stack>
    </ValidationDrawerLayout>
  );
};

export const ValidationContentSkeleton = () => {
  return (
    <Stack height="100%" overflow="auto" maxWidth="100%">
      {Array.from({ length: 4 }).map((_, index) => (
        <ValidationSectionSkeleton key={index} />
      ))}
    </Stack>
  );
};
