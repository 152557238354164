import { ZodObject, ZodRawShape, z } from 'zod';
import { LongTextFieldMetadata, LongTextFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';

export function getLongTextPresenterSchema(field: LongTextFieldType): ZodObject<ZodRawShape> {
  let schema = z.string().max(10000);

  return z.object({
    [field.name]: field.metadata.required ? schema.min(1) : schema.optional(),
  });
}

export function getLongTextPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<LongTextFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: '' };
}

export function getLongTextJsonSchemaFromMetadata(metadata: LongTextFieldMetadata): JsonSchema {
  return { type: metadata.required ? ['string'] : ['string', 'null'], title: metadata.label };
}

export function getLongTextJsonSchema(field: LongTextFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getLongTextJsonSchemaFromMetadata(field.metadata) },
  };
}
