import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Text, useFormatDateRange } from '@verticeone/design-system';
import LegendItem from '@vertice/core/src/components/charts/components/Legend/LegendItem';
import CompletedRequestsGraph from './CompletedRequestsGraph';
import { CompleteRequestsProvider, type Range, useCompleteRequestsContext } from './CompleteRequestsContext';
import dayjs from 'dayjs';

export const generateMonthsInRange = (range: Range): string[] => {
  const start = dayjs(range.startDate);
  const end = dayjs(range.endDate);
  const months = [];

  let current = start;

  while (current.isBefore(end) || current.isSame(end, 'month')) {
    months.push(current.startOf('month').format('YYYY-MM-DDTHH:mm:ssZ'));
    current = current.add(1, 'month');
  }

  return months;
};

const deterministicRandom = (seed: string) => {
  let h = 0;
  for (let i = 0; i < seed.length; i++) {
    h = (Math.imul(31, h) + seed.charCodeAt(i)) | 0;
  }
  return () => {
    h = (Math.imul(31, h) + 0x6d2b79f5) | 0;
    return (h >>> 0) / 0xffffffff;
  };
};

export const generateRandomData = (length: number, seed: string): number[] => {
  const rng = deterministicRandom(seed);
  return Array.from({ length }, () => Math.floor(rng() * 10) + 1);
};

const CompletedRequestsCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.COMPLETED_REQUESTS' });
  const { range } = useCompleteRequestsContext();
  const months = generateMonthsInRange(range);
  const onTimeData = generateRandomData(months.length, `${range.startDate}-${range.endDate}-ontime`);
  const lateData = generateRandomData(months.length, `${range.startDate}-${range.endDate}-late`);
  const totalLate = lateData.reduce((acc, curr) => acc + curr, 0);
  const totalOnTime = onTimeData.reduce((acc, curr) => acc + curr, 0);

  const data = {
    total: totalLate + totalOnTime,
    totalOnTime,
    totalLate,
    chart: {
      months: generateMonthsInRange(range),
      values: [
        {
          id: 'onTime',
          data: onTimeData,
        },
        {
          id: 'late',
          data: lateData,
        },
      ],
      usedCategories: ['onTime', 'late'],
    },
  };
  const formatDateRange = useFormatDateRange();

  return (
    <Card testId="my-tasks-card">
      <Stack p={2} gap={4}>
        <Grid container rowSpacing={2} columnSpacing={8} alignItems="stretch">
          <Grid item sm={4}>
            <Stack p={5} gap={4} justifyContent="space-between" height="100%">
              <Stack gap={1}>
                <Text
                  variant="body-bold"
                  size="M"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {t('TITLE')}
                </Text>
                <Text variant="body-regular" color="text4" size="XS">
                  {formatDateRange(range.startDate, range.endDate)}
                </Text>
              </Stack>
              <Stack gap={1}>
                <Text
                  variant="heading"
                  size="XL"
                  sx={{
                    fontWeight: 400,
                  }}
                >
                  {data.total}
                </Text>
                <Text variant="body-regular" size="S" color="text1">
                  {t('TOTAL_COMPLETED_REQUESTS')}
                </Text>
              </Stack>
              <Stack gap={6}>
                <Stack gap={2} direction="row">
                  <Stack gap={1} flex={1}>
                    <Text
                      variant="heading"
                      size="M"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {data.totalOnTime}
                    </Text>
                    <LegendItem label="Completed on-time" id="completed-on-time" color="#90E94B" />
                  </Stack>
                  <Stack gap={1} flex={1}>
                    <Text
                      variant="heading"
                      size="M"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {data.totalLate}
                    </Text>
                    <LegendItem label="Completed late" id="completed-late" color="#0B9C9F" />
                  </Stack>
                </Stack>
                <Stack direction="row" sx={{ gap: '2px' }} alignItems="center">
                  <div
                    style={{
                      width: (totalOnTime / data.total) * 100 + '%',
                      height: 12,
                      borderRadius: 4,
                      background: 'linear-gradient(to right, #90E94B, #33D970)',
                    }}
                  />
                  <div
                    style={{
                      width: (totalLate / data.total) * 100 + '%',
                      height: 12,
                      borderRadius: 4,
                      backgroundColor: '#0B9C9F',
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={8}>
            <CompletedRequestsGraph data={data.chart} />
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

const CompletedRequestsCardWithContext = () => {
  const endDate = dayjs().startOf('month').format('YYYY-MM-DD');
  const startDate = dayjs().subtract(6, 'months').startOf('month').format('YYYY-MM-DD');

  return (
    <CompleteRequestsProvider
      defaultRange={{
        startDate,
        endDate,
      }}
    >
      <CompletedRequestsCard />
    </CompleteRequestsProvider>
  );
};

export default CompletedRequestsCardWithContext;
