import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type StepContainerProps = PropsWithChildren;

export const StepContainer: FC<StepContainerProps> = ({ children }) => {
  return (
    <Stack gap={3} maxWidth={1280}>
      {children}
    </Stack>
  );
};
