import React from 'react';
import { Button, ButtonProps } from '@verticeone/design-system';
import { Control, FieldPath, FieldValues, useFormState } from 'react-hook-form';

type TestConnectionButtonProps<T extends FieldValues = FieldValues> = {
  validateFields: Array<FieldPath<T>>;
  control: Control<T>;
} & ButtonProps;

const TestConnectionButton = <T extends FieldValues>({
  control,
  validateFields,
  ...buttonProps
}: TestConnectionButtonProps<T>) => {
  const fieldValues = useFormState({ control, name: validateFields });

  return (
    <Button variant="outline" size="S" color="primary" disabled={!fieldValues.isValid} {...buttonProps}>
      {'Test Connection'}
    </Button>
  );
};

export default TestConnectionButton;
