import { FC, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Select } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { fieldDefs } from '../../fields/fields';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { AddFieldButton } from './AddFieldButton';
import { FieldType } from '@vertice/slices/src/openapi/codegen/servicesAPI';

type SelectNewFieldProps = {
  onAdd: (fieldType: FieldType) => void;
};

type FieldTypeItem = {
  label: string;
  type: FieldType;
};

export const SelectNewField: FC<SelectNewFieldProps> = (props) => {
  const { onAdd } = props;

  const [activeSelection, setActiveSelection] = useState<boolean>(false);
  const { t } = useTranslation();

  const fieldOptions = useMemo(
    () =>
      fieldDefs.map((field) => ({
        type: field.type,
        label: t(field.typeNameI18nKey),
      })),
    [t]
  );

  const handleSelect = (value: FieldTypeItem | null) => {
    if (!value) return;
    onAdd(value.type);
    setActiveSelection(false);
  };

  if (!activeSelection) {
    return <AddFieldButton onClick={() => setActiveSelection(true)} label="Add field" />;
  }

  return (
    <Box minWidth={256}>
      <Select<FieldTypeItem, false>
        variant="outlined"
        size="S"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        isClearable={false}
        options={fieldOptions}
        getOptionLabel={({ label }) => label}
        getOptionValue={({ type }) => type}
        placeholder={t('DYNAMIC_FORM_BUILDER.SELECT_FIELD_TYPE')}
        menuPosition="fixed"
        onChange={handleSelect}
        testId={'field-type-select'}
      />
    </Box>
  );
};
