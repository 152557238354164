import { CircularProgress, Stack } from '@mui/material';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { useSaveWorkflow } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useSaveWorkflow';
import {
  ValidationChip,
  useWorkflowValidationContext,
} from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema';
import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { PageHeader } from '@verticeone/design-system';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type ActionsProps = {
  definitions?: Definitions;
  workflowVersionData?: WorkflowVersion;
  hasChanges: boolean;
  workflowId: string;
  backPath?: string;
  refetch: () => void;
};

export const Actions = ({
  definitions,
  workflowVersionData,
  workflowId,
  backPath,
  refetch,
  hasChanges,
}: ActionsProps) => {
  const { saveWorkflow, isSavingWorkflow } = useSaveWorkflow();
  const { isWorkflowValid, errors, warnings, infos, isValidatingWorkflow, setIsValidationDrawerOpen, validate } =
    useWorkflowValidationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    const param = new URLSearchParams({ exit: 'true' }).toString();

    // Since state setters are async, we will use a search param as deciding factor to navigate
    navigate(`?${param}`);
    if (backPath) {
      navigate(backPath);
    }
  };

  const handleSaveWorkflow = (shouldGoBack = false) => {
    if (definitions) {
      saveWorkflow(
        {
          workflowId: workflowId,
          definitions: definitions,
          workflowVersion: workflowVersionData?.versionId,
          serviceRef: workflowVersionData?.serviceRef,
          customVersionName: workflowVersionData?.versionName,
        },
        async () => {
          await refetch();
          enqueueSnackbar({ variant: 'success', message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_SUCCESS') });
        },
        () => {
          enqueueSnackbar({
            variant: 'error',
            message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE'),
            description: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE_DESCRIPTION'),
          });
        }
      );
    }
  };

  return (
    <>
      <ValidationChip
        onClick={() => {
          if (definitions) {
            void validate(definitions);
          }
          setIsValidationDrawerOpen(true);
        }}
        isLoading={isValidatingWorkflow}
        isValid={isWorkflowValid}
        errors={errors?.length}
        warnings={warnings?.length}
        infos={infos?.length}
      />
      <PageHeader.SecondaryAction
        onClick={() => {
          handleClose();
        }}
      >
        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.CLOSE')}
      </PageHeader.SecondaryAction>
      <PageHeader.PrimaryAction
        disabled={!hasChanges || isSavingWorkflow}
        onClick={() => {
          handleSaveWorkflow();
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          {isSavingWorkflow && <CircularProgress size="XS" color="neutral" />}
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.SAVE')}
        </Stack>
      </PageHeader.PrimaryAction>
    </>
  );
};
