import { Stack, useTheme } from '@mui/material';

import { parse } from '../../../../hooks/workflows/urn';
import { IconWrapper, Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { OpenInNewTwoTone } from '@mui/icons-material';
import { useState } from 'react';
import { TaskDrawer } from '../../task/TaskDrawer/TaskDrawer';
import { useRequestContext } from './RequestContext';
import { useRequestTasks } from './useRequestTasks';
import { TaskRow } from '../../task/dataSource';

export const CommentSourceRefButton = ({ sourceRef }: { sourceRef?: string }) => {
  const [taskDrawerTask, setTaskDrawerTask] = useState<TaskRow | null>(null);
  const isTask = sourceRef ? parse(sourceRef).resourceName === 'task' : false;
  const taskId = sourceRef ? parse(sourceRef).resourceId : null;
  const { requestId } = useRequestContext();
  const { allTasks } = useRequestTasks(requestId);

  const { palette } = useTheme();
  const { t } = useTranslation();

  if (!isTask) return null;

  const taskRow = allTasks.find((task) => task.id === taskId);

  const handleClick = () => {
    if (taskRow) {
      setTaskDrawerTask(taskRow);
    }
  };

  return (
    <>
      <Stack
        justifyContent="start"
        gap={1}
        direction="row"
        sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
        onClick={handleClick}
      >
        <Text size="XS" color="neutral1" variant="button-bold">
          {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.FROM', { taskName: taskRow?.name })}
        </Text>
        <IconWrapper icon={OpenInNewTwoTone} size="XS" htmlColor={palette.neutral.color1} />
      </Stack>
      {taskDrawerTask && <TaskDrawer onClose={() => setTaskDrawerTask(null)} taskRow={taskDrawerTask} />}
    </>
  );
};
