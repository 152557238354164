import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import { addMinutes } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { Dialog, DialogContent, DialogHeader, DialogActions, DialogText } from '@verticeone/design-system';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { Accordion, AccordionSummary, AccordionDetails } from '@verticeone/design-system';

import { useModalData } from './useModalData';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { Purchase, useBuySavingsPlanMutation } from '../../../../../dataSources/useBuySavingsPlanMutation';
import useTermFormatter from '@vertice/dashboard/src/modules/cloud/hooks/useTermFormater';
import dayjs from 'dayjs';
import { OfferingData } from '../../../../../types';
import { useTableContext } from '../TableContext';

type BuyRIModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

type RowProps = {
  label: string;
  value: string | number;
};

const Row = ({ label, value }: RowProps) => (
  <Stack direction="row" justifyContent="space-between">
    <Text variant="body-regular">{label}</Text>
    <Text variant="body-bold">{value}</Text>
  </Stack>
);

type AccordionSummaryTitleProps = {
  detail: OfferingData;
};

const AccordionSummaryTitle = ({ detail }: AccordionSummaryTitleProps) => {
  const formatTerm = useTermFormatter();

  return <Text variant="body-bold">{`${detail.planType} ${formatTerm(detail.term)} ${detail.paymentOption}`}</Text>;
};

const Summary = () => {
  const { isShoppingTable } = useTableContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.BUY_MODAL' });
  const formatCurrency = useFormatCurrency();
  const formatTerm = useTermFormatter();
  const formatDefaultCurrency = useCallback(
    (value: number) => formatCurrency(value, { currency: AWS_DEFAULT_CURRENCY }),
    [formatCurrency]
  );
  const data = useModalData();

  // Calculate the total costs
  const totalSpCost = data.details.reduce((total, detail) => total + detail.totalCost, 0);
  const totalUpfrontCost = data.details.reduce((total, detail) => total + detail.upfrontCost, 0);
  const totalExpectedSavings = data.details.reduce((total, detail) => total + detail.expectedSavings, 0);

  return (
    <Stack sx={{ overflow: 'auto' }}>
      <DialogContent>
        <Row label={t('ITEMS.PRODUCT')} value={`${data.product}`} />
        <Stack sx={{ margin: '0 -14px' }} gap={2}>
          {data.details.map((detail) => (
            <Accordion size="S" variant="outlined" key={detail.offeringId}>
              <AccordionSummary title={<AccordionSummaryTitle detail={detail} />} />
              <AccordionDetails>
                <Stack gap={2}>
                  <Row label={t('ITEMS.TERM')} value={formatTerm(detail.term)} />
                  <Row
                    label={t('ITEMS.START_DATE')}
                    value={
                      detail.executeImmediately === 1
                        ? t('VALUES.IMMEDIATELY')
                        : dayjs(detail.startDate).format('MMM DD, YYYY')
                    }
                  />
                  <Row label={t('ITEMS.PAYMENT_OPTION')} value={detail.paymentOption} />
                  <Row
                    label={t('ITEMS.HOURLY_COMMITMENT')}
                    value={formatCurrency(detail.hourlyCommitment, {
                      currency: detail.currencyCode,
                      maximumFractionDigits: 2,
                    })}
                  />
                  <Row label={t('ITEMS.UPFRONT_COST')} value={formatDefaultCurrency(detail.upfrontCost)} />
                  <Row label={t('ITEMS.MONTHLY_COST')} value={formatDefaultCurrency(detail.monthlyCost)} />
                  {!isShoppingTable && (
                    <Row label={t('ITEMS.EXPECTED_SAVINGS')} value={formatDefaultCurrency(detail.expectedSavings)} />
                  )}
                  <Row label={t('ITEMS.TOTAL_COST')} value={formatDefaultCurrency(detail.totalCost)} />
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        <Row label={t('ITEMS.TOTAL_COST')} value={formatDefaultCurrency(totalSpCost)} />
        <Row label={t('ITEMS.TOTAL_UPFRONT_COST')} value={formatDefaultCurrency(totalUpfrontCost)} />
        {!isShoppingTable && (
          <Row label={t('ITEMS.TOTAL_EXPECTED_SAVINGS')} value={formatDefaultCurrency(totalExpectedSavings)} />
        )}
      </DialogContent>
    </Stack>
  );
};

const BuySPModal = ({ open, setOpen }: BuyRIModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.BUY_MODAL' });
  const data = useModalData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate: buyReservation } = useBuySavingsPlanMutation();

  const handleConfirm = () => {
    setIsSubmitting(true);

    buyReservation(
      data.details.map(
        (detail) =>
          ({
            savingsPlanOfferingId: detail.offeringId,
            savingsPlanType: detail.planType,
            commitment: detail.hourlyCommitment,
            upfrontPaymentAmount: detail.paymentOption === 'Partial Upfront' ? detail.upfrontCost : undefined,
            purchaseExecutionTime: (detail.executeImmediately
              ? addMinutes(new Date(), +30)
              : new Date(detail.startDate)
            ).toISOString(),
          } as Purchase)
      ),
      {
        onSettled: () => {
          setIsSubmitting(false);
          setOpen(false);
        },
        onSuccess: () => {
          enqueueSnackbar(t('SNACKBAR.SUCCESS.TITLE'), {
            variant: 'success',
            description: t('SNACKBAR.SUCCESS.MESSAGE'),
          });
          setIsSubmitting(false);
          setOpen(false);
        },
        onError: (errorMessage) => {
          if (errorMessage) {
            enqueueSnackbar(t('SNACKBAR.ERROR.TITLE'), {
              variant: 'error',
              description: `${t('SNACKBAR.ERROR.MESSAGE')} ${errorMessage}`,
            });
          } else {
            enqueueSnackbar(t('SNACKBAR.ERROR.TITLE'), {
              variant: 'error',
              description: t('SNACKBAR.ERROR.DESCRIPTION'),
            });
          }
          setIsSubmitting(false);
          setOpen(false);
        },
      }
    );
  };

  return (
    <Dialog open={open} size="M">
      <DialogHeader>{t('TITLE')}</DialogHeader>
      <Stack paddingX={6} paddingTop={6}>
        <DialogText variant="body-regular">
          <Trans
            i18nKey="CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.BUY_MODAL.DESCRIPTION"
            components={{ b: <Text variant="body-bold" /> }}
          />
        </DialogText>
      </Stack>
      <Summary />
      <DialogActions>
        <Button testId="dismiss" variant="outline" color="tertiary" onClick={() => setOpen(false)}>
          {t('FOOTER.DISMISS')}
        </Button>
        <Button testId="confirm" variant="solid" color="tertiary" onClick={handleConfirm} isLoading={isSubmitting}>
          {t('FOOTER.CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuySPModal;
