import { TaskNodeState, WorkflowTaskNodeType } from './types';
import { TaskConfiguration } from '../../definitionsTypes';
import { parseResourceRef } from '../../../../hooks/workflows/refUtils';

export const resolveTaskNodeType = (
  taskState?: TaskNodeState,
  configurations?: TaskConfiguration[]
): WorkflowTaskNodeType => {
  if (taskState) {
    return resolveTaskNodeTypeFromTaskState(taskState);
  }

  return resolveTaskNodeTypeFromTaskConfigurations(configurations);
};

const resolveTaskNodeTypeFromTaskState = (taskState?: TaskNodeState): WorkflowTaskNodeType => {
  if (taskState && !taskState?.resourceUrn) {
    return 'USER';
  }
  return resolveServiceTaskType(taskState?.resourceUrn as string);
};

const resolveTaskNodeTypeFromTaskConfigurations = (configurations?: TaskConfiguration[]): WorkflowTaskNodeType => {
  const serviceConfiguration = configurations?.find((config) => config['kind'] === 'Vertice:Service');
  if (!serviceConfiguration || !serviceConfiguration?.resourceUrn) {
    // fallback to user
    return 'USER';
  }

  return resolveServiceTaskType(serviceConfiguration?.resourceUrn as string);
};

export const resolveServiceTaskType = (resourceRef: string): WorkflowTaskNodeType => {
  const serviceAccountId = parseResourceRef(resourceRef).accountId;
  if (serviceAccountId) {
    return 'SERVICE';
  }
  return 'VERTICE_SERVICE';
};
