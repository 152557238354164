import type { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import { ResourceUrn, Variable, VariableOrigin, xDatasourceParams } from '../WorkflowEditor/types';
import {
  JsonSchema,
  XTypeServiceCatalogResource,
  XTypeServiceCatalogResourceDefinition,
  ServiceServiceCatalogResource,
  FunctionServiceCatalogResource,
  FormServiceCatalogResource,
  XTypeItems,
  XTypeOption,
  JsonSchemaType,
} from './types';
import uniqueBy from 'lodash/uniqBy';
import { getVariableTypeLabels } from '../utils/getVariableTypeLabels';

export const isXTypeOptionItems = (items: XTypeItems): items is XTypeOption[] => {
  return items.every((item) => typeof item.id === 'string');
};

export const isFormServiceCatalogResource = (
  serviceCatalogResource: ServiceCatalogResource
): serviceCatalogResource is FormServiceCatalogResource =>
  serviceCatalogResource.kind === 'Vertice/ServiceCatalog/Form/FormDefinition';

export const isFunctionServiceCatalogResource = (
  serviceCatalogResource: ServiceCatalogResource
): serviceCatalogResource is FunctionServiceCatalogResource =>
  serviceCatalogResource.kind === 'Vertice/ServiceCatalog/Function/FunctionDefinition';

export const isServiceServiceCatalogResource = (
  serviceCatalogResource: ServiceCatalogResource
): serviceCatalogResource is ServiceServiceCatalogResource =>
  serviceCatalogResource.kind === 'Vertice/ServiceCatalog/Service/ServiceDefinition';

export const isXTypeServiceCatalogResource = (
  serviceCatalogResource: ServiceCatalogResource
): serviceCatalogResource is XTypeServiceCatalogResource =>
  serviceCatalogResource.kind === 'Vertice/ServiceCatalog/XType/XTypeDefinition';

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const formatVariableLabel = (variableName: string): string => {
  // Handle snake_case variables
  if (variableName.includes('_')) {
    const parts = variableName.split('_');
    return parts.map(capitalizeFirstLetter).join(' ');
  }

  // Handle camelCase variables
  const parts = variableName.split(/(?=[A-Z])/);
  return parts.map(capitalizeFirstLetter).join(' ');
};

const isUdfPropertyId = (propertyId: string) => propertyId.startsWith('udf');
const isUdfVariableId = (propertyId: string) => propertyId.startsWith('udf');

export const getPropertyId = (propertyName: string, parentPropertyId?: string): string => {
  if (!parentPropertyId) {
    return propertyName;
  }

  if (isUdfPropertyId(parentPropertyId)) {
    return `(${parentPropertyId}).${propertyName}`;
  }

  return `${parentPropertyId}.${propertyName}`;
};

export const PROPERTY_XTYPE_MAP = {
  requestId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/request\/id\/v\d+$/,
    isVisible: false,
  },
  departmentId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/department\/id\/v\d+$/,
    isVisible: true,
  },
  vendor: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/v\d+$/,
    isVisible: true,
  },
  vendorId: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/id\/v\d+$/,
    isVisible: false,
  },
  product: {
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/v\d+$/,
    isVisible: true,
  },
  productId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/id\/v\d+$/,
    isVisible: false,
  },
  contractId: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/contract\/id\/v\d+$/,
    isVisible: false,
  },
  userId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/user\/id\/v\d+$/,
    isVisible: true,
  },
  accountId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/account\/id\/v\d+$/,
    isVisible: false,
  },
  money: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/v\d+$/,
    isVisible: true,
  },
  moneyAmount: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/amount\/v\d+$/,
    isVisible: true,
  },
  moneyCurrency: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/currency\/v\d+$/,
    isVisible: false,
  },
} as const;

const removeJsonSchemasOfNullType = (jsonSchemas: JsonSchema[]) => {
  return jsonSchemas.filter((jsonSchema) => jsonSchema.type !== 'null');
};

const getUdfInputFromInputJsonSchema = (service: ServiceCatalogResource): string => {
  const inputJsonSchema = service.definition?.FunctionProvider?.Interface?.Input?.JsonSchema;

  if (!inputJsonSchema) {
    return '';
  }

  if (inputJsonSchema.type === 'object' && inputJsonSchema.properties) {
    let udfInput = '{';

    Object.keys(inputJsonSchema.properties).forEach((key, index, array) => {
      if (index > 0 && index < array.length) {
        udfInput += ', ';
      }
      udfInput += `${key}: ${key}`;
    });

    return udfInput + '}';
  }

  return '';
};

const UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP: Record<ResourceUrn, string> = {
  'urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation':
    'udf(`urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation`, {approvedBudget: approvedBudget, budgetCurrency: contractCurrency})',
};

const removeVersionFromUrn = (urn: ResourceUrn) => urn.replace(/\/v\d+$/, '');

/*
 * UDFs function calls are not part of the service definition, so we need to manually map them.
 * If the UDF is not in the map, we need to generate the function call based on the input schema.
 * This is temporary until we have a better way to get the UDF function call.
 * It should be built by the user using the UI, but this is not part of this iteration.
 */
const getUdfPropertyId = (service: ServiceCatalogResource) => {
  const serviceUrn = service.urn;

  const serviceUrnWithoutVersion = removeVersionFromUrn(serviceUrn);

  if (serviceUrnWithoutVersion in UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP) {
    return UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP[serviceUrnWithoutVersion];
  }

  const inputJsonSchema = getUdfInputFromInputJsonSchema(service);

  if (!inputJsonSchema) {
    return '';
  }

  return `udf(\`${serviceUrnWithoutVersion}\`, ${inputJsonSchema})`;
};

/*
 * List of UDFs that should not be shown in the UI.
 * This is temporary until we have a generic way to hide UDFs
 * which is to be decided how are we going to do it.
 */
const HIDDEN_UDFS = [
  'urn:verticeone:vertice::services:udf/saas/account/contract/owners',
  'urn:verticeone:vertice::services:udf/core/exchange-currency',
];

export const getVariableFromFunctionDefinition = ({
  udfServiceCatalogResource,
  xTypeServiceCatalogResources,
  serviceCatalog,
}: {
  udfServiceCatalogResource: ServiceCatalogResource;
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[];
  serviceCatalog: 'account' | 'global';
}): Variable => {
  const origin: VariableOrigin = {
    id: udfServiceCatalogResource.urn,
    kind: `vertice-${serviceCatalog}-udf`,
    label: udfServiceCatalogResource.name,
  };
  const defaultVariable: Variable = {
    id: '',
    label: '',
    type: {
      baseType: [],
      xType: '',
      format: '',
      labels: [],
    },
    origin: origin,
    isVisible: false,
    values: [],
    operators: [],
    variables: [],
    required: false,
    isSelectable: false,
    isDeprecated: udfServiceCatalogResource.status === 'ARCHIVED',
    path: [],
  };

  if (HIDDEN_UDFS.includes(removeVersionFromUrn(udfServiceCatalogResource.urn))) {
    return defaultVariable;
  }

  const jsonSchema = udfServiceCatalogResource.definition?.FunctionProvider?.Interface?.Output?.JsonSchema;

  if (!jsonSchema) {
    return defaultVariable;
  }

  const variableId = getUdfPropertyId(udfServiceCatalogResource);

  if (!variableId) {
    return defaultVariable;
  }

  const udfVariableXType = jsonSchema['x-type'];
  const xTypeServiceCatalogResource = xTypeServiceCatalogResources.find(
    (xTypeResource) => xTypeResource.urn === udfVariableXType
  );

  const isVariableVisible = getIsVariableVisible({
    variableKey: udfServiceCatalogResource.name,
    xTypeServiceCatalogResource,
    xEnabled: jsonSchema['x-enabled'],
    variableType: getVariablePrimitiveTypes(jsonSchema),
  });

  if (!isVariableVisible) {
    return defaultVariable;
  }

  const udfVariable = convertJsonSchemaToVariable({
    jsonSchema,
    origin,
    variableLabelOverride: udfServiceCatalogResource.name,
    variableKey: variableId,
    xTypeServiceCatalogResources,
    variableIsDeprecatedOverride: udfServiceCatalogResource.status === 'ARCHIVED',
  });

  const variableType = getVariablePrimitiveTypes(jsonSchema);
  if ((variableType.includes('object') || variableType.includes('array')) && udfVariable.variables.length === 0) {
    return defaultVariable;
  }

  return {
    ...defaultVariable,
    variables: [udfVariable],
  };
};

const getVariablePrimitiveTypes = (jsonSchema: JsonSchema): JsonSchemaType[] => {
  if (jsonSchema.type) {
    return Array.isArray(jsonSchema.type) ? jsonSchema.type : [jsonSchema.type];
  }

  return [];
};

export const getVariableValues = ({
  jsonSchema,
  xTypeDefinition,
  datasourceParams,
}: {
  jsonSchema: JsonSchema;
  xTypeDefinition?: XTypeServiceCatalogResourceDefinition;
  datasourceParams?: xDatasourceParams;
}) => {
  if (xTypeDefinition?.Datasource?.Items && isXTypeOptionItems(xTypeDefinition.Datasource.Items)) {
    return xTypeDefinition.Datasource.Items.filter((item) => {
      if (item['x-enabled'] !== undefined) {
        return item['x-enabled'];
      }

      return true;
    }).map((item) => ({
      value: item.id,
      name: item.id,
      label: item.title,
    }));
  }

  if (xTypeDefinition?.Datasource?.Provided) {
    return datasourceParams ?? ('ProvidedAsyncByXTypeDataSource' as const);
  }

  if (jsonSchema.enum) {
    return jsonSchema.enum
      .filter((value) => value !== null)
      .map((value) => ({
        value: value?.toString() ?? '',
        name: value?.toString() ?? '',
        label: value?.toString() ?? '',
      }));
  }

  return [];
};

export const getIsVariableVisible = ({
  variableKey,
  xTypeServiceCatalogResource,
  xEnabled,
  variableType,
  childVariables,
}: {
  xTypeServiceCatalogResource?: XTypeServiceCatalogResource;
  variableKey: string;
  xEnabled?: boolean;
  variableType?: JsonSchemaType[];
  childVariables?: Variable[];
}) => {
  // Release 1: We don't want to display variables that return an array
  // ref: https://vertice.atlassian.net/browse/RED-1879
  if (variableType?.includes('array')) {
    return false;
  }

  if (xEnabled !== undefined) {
    return xEnabled;
  }

  if (childVariables?.length) {
    // if the variable consists of variables, the visibility is determined based on the children variables
    return childVariables.some((variable) => variable.isVisible);
  }

  if (xTypeServiceCatalogResource) {
    const { definition, urn } = xTypeServiceCatalogResource;

    if (definition?.Operators.Allowed.length === 0) {
      return false;
    }

    const xTypeDefinitionFE = Object.entries(PROPERTY_XTYPE_MAP).find(([_, { regex }]) => regex.test(urn))?.[1];

    if (xTypeDefinitionFE) {
      return xTypeDefinitionFE.isVisible;
    }
  }

  return variableKey !== 'id';
};

export const getVariableId = (variableKey: string, parentVariableId?: string) => {
  if (!parentVariableId) {
    return variableKey;
  }

  const finalVariableKey = variableKey === '[0]' ? variableKey : `.${variableKey}`;

  if (isUdfVariableId(parentVariableId)) {
    return `(${parentVariableId})${finalVariableKey}`;
  }

  return `${parentVariableId}${finalVariableKey}`;
};

const getIsVariableSelectable = (
  variableType: JsonSchema['type'],
  xTypeServiceCatalogResource?: XTypeServiceCatalogResource
) => {
  if (variableType === 'array') {
    return false;
  }

  if (variableType === 'object') {
    if (!xTypeServiceCatalogResource) {
      return false;
    }

    return PROPERTY_XTYPE_MAP.money.regex.test(xTypeServiceCatalogResource.urn);
  }

  return true;
};

const getVariableIdOverride = ({
  variables,
  xTypeServiceCatalogResource,
}: {
  xTypeServiceCatalogResource?: XTypeServiceCatalogResource;
  variables: Variable[];
}) => {
  if (!xTypeServiceCatalogResource) {
    return undefined;
  }

  if (PROPERTY_XTYPE_MAP.money.regex.test(xTypeServiceCatalogResource.urn)) {
    const amountVariable = variables.find(
      (variable) => variable.type.xType && PROPERTY_XTYPE_MAP.moneyAmount.regex.test(variable.type.xType)
    );

    if (!amountVariable) {
      return undefined;
    }

    return amountVariable.id;
  }

  return undefined;
};

export const convertJsonSchemaToVariable = ({
  jsonSchema,
  origin,
  variableKey,
  parentVariableId,
  variableLabelOverride,
  variableIsDeprecatedOverride,
  xTypeServiceCatalogResources,
  parentVariableRequired,
  parentVariableIsSelectable,
  parentVariablePath = [],
}: {
  jsonSchema: JsonSchema;
  origin: VariableOrigin;
  parentVariableId?: string;
  parentVariableRequired?: string[];
  variableKey: string;
  variableLabelOverride?: string;
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[];
  parentVariableIsSelectable?: boolean;
  parentVariablePath?: string[];
  variableIsDeprecatedOverride?: boolean;
}): Variable => {
  const variableId = getVariableId(variableKey, parentVariableId);
  const variableLabel = variableLabelOverride ?? jsonSchema.title ?? formatVariableLabel(variableKey);
  const isVariableDeprecated = variableIsDeprecatedOverride ?? undefined;
  const variablePath = variableLabel ? [...parentVariablePath, variableLabel] : parentVariablePath;

  // Handle allOf, anyOf, oneOf
  const combinationJsonSchemas = jsonSchema.allOf ?? jsonSchema.anyOf ?? jsonSchema.oneOf;
  if (combinationJsonSchemas) {
    const combinationJsonSchemasWithoutNullType = removeJsonSchemasOfNullType(combinationJsonSchemas);
    if (combinationJsonSchemasWithoutNullType.length === 1) {
      return convertJsonSchemaToVariable({
        jsonSchema: combinationJsonSchemasWithoutNullType[0],
        origin,
        variableKey,
        parentVariableId,
        xTypeServiceCatalogResources,
        variableLabelOverride,
        parentVariableRequired,
        parentVariablePath,
        variableIsDeprecatedOverride,
      });
    }

    const possibleVariables = combinationJsonSchemasWithoutNullType.map((childJsonSchema) => {
      return convertJsonSchemaToVariable({
        jsonSchema: childJsonSchema,
        origin,
        variableKey,
        parentVariableId,
        xTypeServiceCatalogResources,
        parentVariableRequired,
        variableLabelOverride,
        parentVariablePath,
        variableIsDeprecatedOverride,
      });
    });

    /* For now, we only handle object types as we can afford to merge properties of objects for allOf, anyOf, oneOf combinations.
     * The drawback is that we lose the distinction between the different types of objects.
     */
    if (possibleVariables.every((possibleVariable) => possibleVariable.type?.baseType?.includes('object'))) {
      const uniqueChildVariables = uniqueBy(
        possibleVariables.map((possibleVariable) => possibleVariable.variables).flat(),
        'id'
      );

      return {
        id: variableId,
        label: variableLabel,
        isDeprecated: isVariableDeprecated,
        type: {
          labels: ['Object'],
          baseType: ['object'],
        },
        isSelectable: false,
        origin,
        isVisible: true,
        values: [],
        operators: [],
        required: false,
        variables: uniqueChildVariables,
        path: variablePath,
      };
    }
  }

  const variables: Variable[] = [];

  const variableXType = jsonSchema['x-type'];
  const datasourceParams = jsonSchema['x-datasource-params'];
  const xTypeServiceCatalogResource = xTypeServiceCatalogResources.find(
    (xTypeResource) => xTypeResource.urn === variableXType
  );
  const xTypeDefinition = xTypeServiceCatalogResource?.definition;
  const finalJsonSchema = xTypeDefinition?.Schema.JsonSchema ?? jsonSchema;
  const isVariableSelectable = parentVariableIsSelectable
    ? false
    : getIsVariableSelectable(finalJsonSchema.type, xTypeServiceCatalogResource);

  // Handle object type
  if (finalJsonSchema.type === 'object' && finalJsonSchema.properties) {
    for (const [key, childJsonSchema] of Object.entries(finalJsonSchema.properties)) {
      variables.push(
        convertJsonSchemaToVariable({
          jsonSchema: childJsonSchema,
          origin,
          variableKey: key,
          parentVariableId: variableId,
          xTypeServiceCatalogResources,
          parentVariableRequired: finalJsonSchema.required,
          parentVariableIsSelectable: isVariableSelectable ? true : undefined,
          parentVariablePath: variablePath,
        })
      );
    }
  }

  // Handle array type
  if (finalJsonSchema.type === 'array' && finalJsonSchema.items) {
    variables.push(
      convertJsonSchemaToVariable({
        jsonSchema: finalJsonSchema.items,
        origin,
        variableKey: '[0]',
        parentVariableId: variableId,
        variableLabelOverride: `${variableLabel} - Item`,
        xTypeServiceCatalogResources,
        parentVariableRequired: finalJsonSchema.required,
        parentVariableIsSelectable: isVariableSelectable ? true : undefined,
        parentVariablePath: variablePath,
      })
    );
  }

  const variableType = getVariablePrimitiveTypes(finalJsonSchema);

  return {
    id: variableId,
    idOverride: getVariableIdOverride({ variables, xTypeServiceCatalogResource }),
    label: variableLabel,
    type: {
      baseType: variableType,
      xType: variableXType,
      format: finalJsonSchema.format,
      labels: getVariableTypeLabels({
        type: variableType,
        format: finalJsonSchema.format,
        enum: finalJsonSchema.enum,
        xTypeResource: xTypeServiceCatalogResource,
      }),
    },
    origin,
    isVisible:
      parentVariableIsSelectable ??
      getIsVariableVisible({
        variableKey,
        xTypeServiceCatalogResource,
        xEnabled: jsonSchema['x-enabled'],
        variableType,
        childVariables: variables,
      }),
    values: getVariableValues({ jsonSchema: finalJsonSchema, xTypeDefinition, datasourceParams }),
    operators: xTypeDefinition?.Operators.Allowed || [],
    required: parentVariableRequired?.includes(variableKey) || false,
    variables: variables.filter((variable) => variable.isVisible),
    isSelectable: isVariableSelectable,
    isDeprecated: isVariableDeprecated,
    path: variablePath,
  };
};
