import { RendererEdge, RendererNode, RendererTaskNode } from './types';
import { useCallback, useEffect, useState } from 'react';
import { ReactFlowInstance } from '@xyflow/react';
import { TaskStatus } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { isEqual } from 'lodash';

const isNodeTaskNode = (node: RendererNode): node is RendererTaskNode => node.data.kind === 'task';
const shouldNodeFitIntoView = ({ data }: RendererTaskNode) =>
  data.state?.status && Array<TaskStatus>('ACTIVE', 'FAILED').includes(data.state.status);

const MIN_TASK_NODES_TO_FIT_ACTIVE_TASKS = 3;

export const useFitActiveTasksIntoView = ({ nodes, skip }: { nodes: RendererNode[]; skip: boolean }) => {
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<RendererNode, RendererEdge>>();
  const [zoomedNodes, setZoomedNodes] = useState<RendererTaskNode['id'][]>([]);

  const fitActiveNodesIntoView = useCallback(
    async (taskNodesToFitView: RendererTaskNode[]) => {
      const taskNodesToFitViewCount = taskNodesToFitView.length;

      if (reactFlowInstance && taskNodesToFitViewCount > 0 && !skip) {
        return await reactFlowInstance.fitView({
          nodes: taskNodesToFitView.map((node) => ({ id: node.id })),
          duration: 1000,
          maxZoom: 1,
          padding: taskNodesToFitViewCount > 1 ? 0.1 : 3,
        });
      }

      return false;
    },
    [reactFlowInstance, skip]
  );

  useEffect(() => {
    const taskNodes = nodes.filter(isNodeTaskNode);
    const zoomableNodes = taskNodes.filter(shouldNodeFitIntoView);

    // use data.id to compare nodes, because the nodes are recreated on every render with uniq sufix
    const nextNodes = zoomableNodes.map(({ data }) => data.id);
    if (taskNodes.length <= MIN_TASK_NODES_TO_FIT_ACTIVE_TASKS || isEqual(zoomedNodes, nextNodes)) {
      return;
    }

    const timeoutId = setTimeout(async () => {
      if (await fitActiveNodesIntoView(zoomableNodes)) {
        setZoomedNodes(nextNodes);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [nodes, zoomedNodes, fitActiveNodesIntoView]);

  return {
    setReactFlowInstance,
  };
};
