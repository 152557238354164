import { useNavigate } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  useDeactivateAccountIntegrationMutation,
  useRemoveJiraWebhookConfigMutation,
  useRevokeAccountIntegrationMutation,
} from '@vertice/slices';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { isValidIntegrationResponse } from '../../utils';
import { JIRA_PROVIDER_ID } from '../Jira/constants';

type DeactivationParams = {
  providerId: string;
  shouldRedirect?: boolean;
};

export const useDeactivateIntegration = (params: DeactivationParams) => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [deactivateAccountIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeAccountIntegration, { isLoading: isIntegrationRevokeInProgress }] =
    useRevokeAccountIntegrationMutation();
  const [removeWebhookMutation, { isLoading: isRemoveWebhookInProgress }] = useRemoveJiraWebhookConfigMutation();

  const deactivateIntegration = async () => {
    if (params.providerId === JIRA_PROVIDER_ID) {
      await removeWebhookMutation({ accountId });
    }

    const response = await deactivateAccountIntegration({
      accountId,
      integrationId: params.providerId,
    });

    if (isValidIntegrationResponse(response)) {
      await revokeAccountIntegration({ accountId, integrationId: params.providerId });
      if (params.shouldRedirect) {
        navigate(generatePath(routes.PREFERENCES.INTEGRATIONS.ABSOLUTE_PATH));
      }
    }
  };

  return {
    deactivateIntegration,
    isIntegrationDeactivationInProgress,
    isIntegrationRevokeInProgress,

    // add || isXXXInProgress if more extra calls can happen (for any provider)
    isAnyExtraCallInProgress: isRemoveWebhookInProgress,
  };
};
