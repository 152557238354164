import { Stack, styled } from '@mui/material';
import { SVGProps } from 'react';

const Badge = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: '-25%',
  right: '-25%',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.color4,
  fontSize: '8px',
  lineHeight: '8px',
  borderRadius: '50%',
  width: 12,
  height: 12,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CommentsIconWithBadge = ({
  width = 16,
  height = 16,
  unreadCount,
  ...props
}: SVGProps<SVGSVGElement> & { unreadCount?: number }) => {
  const parsedUnread = unreadCount ? (unreadCount > 9 ? '9+' : unreadCount) : undefined;

  return (
    <Stack position="relative">
      <BaseCommentsIcon width={width} height={height} {...props} />
      {parsedUnread && <Badge>{parsedUnread}</Badge>}
    </Stack>
  );
};

export const BaseCommentsIcon = ({ width, height, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.7834 14.45L12.2501 12.9H2.41676C2.07232 12.9 1.76676 12.7667 1.5001 12.5C1.23343 12.2333 1.1001 11.9278 1.1001 11.5833V3.08333C1.1001 2.72778 1.23343 2.41667 1.5001 2.15C1.76676 1.88333 2.07232 1.75 2.41676 1.75H13.5834C13.9501 1.75 14.264 1.88333 14.5251 2.15C14.7862 2.41667 14.9168 2.72778 14.9168 3.08333V13.9833C14.9168 14.2722 14.7807 14.475 14.5084 14.5917C14.2362 14.7083 13.9945 14.6611 13.7834 14.45ZM2.41676 3.08333V11.5833H12.5834L13.5834 12.5833V3.08333H2.41676ZM2.41676 3.08333V12.5833V11.5833V3.08333Z"
        fill="currentColor"
      />
    </svg>
  );
};
