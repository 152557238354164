import React, { useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, ToggleSwitch } from '@verticeone/design-system';
import useLoggedUserAccountRoles from '../../../../hooks/useLoggedUserAccountRoles';
import { useMyTasksOnlyParam } from '../dataSource/queryParams';

const MyTasksOnlySwitch = () => {
  const { t } = useTranslation();
  const showMyTasksOnlyToggleId = useId();
  const [myTasksOnlyValue, toggle] = useMyTasksOnlyParam();
  const { isUserPowerUser, isUserAdmin, isLoading: isLoadingUserRoles } = useLoggedUserAccountRoles();
  const isAdminOrPowerUser = isUserAdmin || isUserPowerUser;
  const myTasksToggleDisabled = !isAdminOrPowerUser || isLoadingUserRoles;

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <ToggleSwitch
        id={showMyTasksOnlyToggleId}
        checked={myTasksOnlyValue}
        color="secondary"
        onChange={toggle}
        disabled={myTasksToggleDisabled}
        testId="MyTasksOnly"
      />
      <label htmlFor={showMyTasksOnlyToggleId} style={{ whiteSpace: 'nowrap' }}>
        <Text variant="button-bold" size="S" color={myTasksToggleDisabled ? 'inactive1' : 'text1'}>
          {t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.MY_TASKS_ONLY`)}
        </Text>
      </label>
    </Stack>
  );
};

export default MyTasksOnlySwitch;
