import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { hasApplicationData } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import { Grid } from '../components/UsersGrid/Grid';
import { UsersTile } from '../components/UsersTile';
import { useApplication } from '../hooks/useApplication';
import { useUsers } from '../hooks/useUsers';

export const Users = () => {
  const { applicationId } = useParams();
  const { application, isFetching } = useApplication(applicationId);
  const { data, isFetching: isUsersFetching } = useUsers(
    {
      applicationId,
    },
    { skip: !application }
  );
  const appHasData = hasApplicationData(application);

  return (
    <Stack>
      {appHasData && <UsersTile isLoading={isFetching} application={application} />}
      <Grid data={data} isLoading={isUsersFetching} />
    </Stack>
  );
};
