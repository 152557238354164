import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, ifExpression } from '@verticeone/design-system';
import { type FieldArrayWithId, useFormContext, useFormState } from 'react-hook-form';
import FormTextArea from '@vertice/core/src/modules/forms/fields/FormTextArea';

import { EditGatewayFormData } from '../schema';
import type { Variable } from '../../../types';
import type { Gateway } from '../../../../../definitionsTypes';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { QueryBuilder } from '../../../QueryBuilder/QueryBuilder';
import { EdgeNameInput } from './EdgeNameInput';

type EdgeFieldContainerProps = {
  $withRedBorder?: boolean;
  $focused?: boolean;
};

const EdgeFieldContainer = styled(Stack)<EdgeFieldContainerProps>(({ theme, $withRedBorder = false, $focused }) => ({
  backgroundColor: theme.palette.input.color1,
  padding: theme.spacing(4),
  borderRadius: 12,
  outline: $withRedBorder ? `2px solid ${theme.palette.error.color3}` : 'none',
  ...ifExpression($focused, {
    boxShadow: `0 0 0px 4px ${theme.palette.secondary.color3}`,
    outline: `1px solid ${theme.palette.secondary.color1}`,
  }),
}));

type EdgeFieldProps = {
  edge: FieldArrayWithId<EditGatewayFormData, 'edges'>;
  index: number;
  requestVariables: Variable[];
  udfVariables: Variable[];
  gatewayType: Gateway['gatewayType'];
  onSelectionChanged: (edgeId: string) => void;
  selectedEdgeId: string | undefined;
};

export const EdgeField: FC<EdgeFieldProps> = ({
  edge,
  index,
  requestVariables,
  udfVariables,
  gatewayType,
  onSelectionChanged,
  selectedEdgeId,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY' });
  const { setValue, watch } = useFormContext<EditGatewayFormData>();
  const formState = useFormState<EditGatewayFormData>();
  const hasConditionError = !!formState.errors.edges?.[index]?.condition;

  const isEdgeFromExclusiveGateway = gatewayType === 'Exclusive';
  const isEdgeFromJoinGateway = gatewayType === 'Join';
  const isEdgeFromForkGateway = gatewayType === 'Fork';

  const isDefaultEdge = edge.conditionType === 'DefaultFlow';
  const isJsonLogicCondition = edge.conditionType === 'JsonLogic';

  const isExpressionEditorDisplayed = !!requestVariables.length && !!udfVariables.length;

  const edgeId = watch(`edges.${index}.id`);
  const isFocused = edgeId === selectedEdgeId;

  return (
    <EdgeFieldContainer
      $withRedBorder={hasConditionError}
      $focused={isFocused}
      gap={isDefaultEdge && isEdgeFromExclusiveGateway ? 5 : 6}
      key={edge.id}
      onFocusCapture={() => onSelectionChanged(edgeId)}
      onClick={() => onSelectionChanged(edgeId)}
    >
      <EdgeNameInput edge={edge} index={index} gatewayType={gatewayType} />
      {!isEdgeFromJoinGateway && (
        <>
          {isDefaultEdge && isEdgeFromExclusiveGateway ? (
            <Text variant="body-regular" color="text3" size="S">
              {t('EDGES.DEFAULT_DESCRIPTION')}
            </Text>
          ) : (
            isExpressionEditorDisplayed && (
              <>
                {isDefaultEdge || isJsonLogicCondition ? (
                  <QueryBuilder
                    udfVariables={udfVariables}
                    requestVariables={requestVariables}
                    onConditionChange={(newCondition, newConditionType) => {
                      setValue(`edges.${index}.condition`, newCondition, { shouldDirty: true });
                      if (newConditionType) {
                        setValue(`edges.${index}.conditionType`, newConditionType, { shouldDirty: true });
                      }
                    }}
                    initialCondition={isDefaultEdge ? undefined : edge.condition}
                    isConditionRemovalEnabled={isEdgeFromForkGateway}
                  />
                ) : (
                  <FormTextArea<EditGatewayFormData>
                    id={`edge-condition-text-area-${index}`}
                    size="S"
                    label={edge.conditionType}
                    variant="outline"
                    color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                    name={`edges.${index}.condition`}
                  />
                )}
              </>
            )
          )}
        </>
      )}
    </EdgeFieldContainer>
  );
};
