import React, { useId } from 'react';
import { StatsBody, StatsContainer, Title, Value } from '@vertice/core/src/components/StatsBar';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import SolidStatsTile from '@vertice/core/src/components/StatsBar/StatsTile/SolidStatsTile';
import { useOverviewStatsData } from '../useOverviewStatsData';

const CompletedSpendStatsContainer = () => {
  const id = useId();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.OVERVIEW.STATS' });
  const formatCurrency = useFormatCurrency();
  const { data, isLoading } = useOverviewStatsData();

  return (
    <StatsContainer flex={1}>
      <SolidStatsTile color="success">
        <StatsBody
          isLoading={isLoading}
          title={<Title id={id}>{t('COMPLETED_SPEND')}</Title>}
          value={
            <Value aria-labelledby={id}>
              {formatCurrency(data.completedSpend, {
                maximumFractionDigits: 0,
                currency: 'USD',
              })}
            </Value>
          }
        />
      </SolidStatsTile>
    </StatsContainer>
  );
};

export default CompletedSpendStatsContainer;
