import { FC } from 'react';
import { IconButton, Tooltip } from '@verticeone/design-system';
import { AddOutlined, DeleteOutlined, SubdirectoryArrowRightOutlined } from '@mui/icons-material';
import { ActionProps, findPath, isRuleGroupType, remove, getParentPath } from 'react-querybuilder';
import { isQueryValid } from '../utils';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTooltipContent } from '../../components/StyledTooltipContent';

export const AddRuleAction: FC<ActionProps> = ({ handleOnClick, ruleOrGroup, disabled }) => {
  const { t } = useTranslation();
  if (!isRuleGroupType(ruleOrGroup)) {
    return;
  }
  const isValidQuery = isQueryValid(ruleOrGroup);
  const isEmptyQuery = ruleOrGroup.rules.length === 0;

  if (!isValidQuery && !isEmptyQuery) {
    return;
  }

  return (
    <Tooltip
      title={
        <StyledTooltipContent>
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.ACTIONS.ADD_CONDITION')}
        </StyledTooltipContent>
      }
      size="S"
    >
      <IconButton
        testId="add-rule"
        icon={AddOutlined}
        size="S"
        variant="outline"
        color="neutral"
        onClick={handleOnClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};

// allow group within a group
const MAX_GROUP_DEPTH = 2;

export const AddGroupAction: FC<ActionProps> = ({ handleOnClick, schema, path, level, disabled }) => {
  const { t } = useTranslation();
  const query = schema.getQuery();
  const ruleQuery = query ? findPath(path, query) : null;
  const isValidQuery = ruleQuery && isQueryValid(ruleQuery);
  const isEmptyQuery = ruleQuery && isRuleGroupType(ruleQuery) && ruleQuery.rules.length === 0;

  if ((!isValidQuery && !isEmptyQuery) || level >= MAX_GROUP_DEPTH) {
    return null;
  }

  return (
    <Tooltip
      title={
        <StyledTooltipContent>
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.ACTIONS.ADD_GROUP')}
        </StyledTooltipContent>
      }
      size="S"
    >
      <IconButton
        testId="add-group"
        icon={SubdirectoryArrowRightOutlined}
        size="S"
        variant="outline"
        color="neutral"
        onClick={handleOnClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export const RemoveGroupAction: FC<ActionProps> = ({ handleOnClick, ruleOrGroup, disabled }) => {
  const { t } = useTranslation();
  // show the remove group button only if the group is empty
  if (isRuleGroupType(ruleOrGroup) && ruleOrGroup.rules.length !== 0) {
    return null;
  }

  return (
    // show the remove group button to the right
    <Stack flexGrow={1} alignItems="end">
      <Tooltip
        title={
          <StyledTooltipContent>
            {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.ACTIONS.REMOVE_GROUP')}
          </StyledTooltipContent>
        }
        size="S"
      >
        <IconButton
          testId="remove-group"
          icon={DeleteOutlined}
          size="S"
          variant="plain"
          color="neutral"
          onClick={handleOnClick}
          disabled={disabled}
        />
      </Tooltip>
    </Stack>
  );
};

export const RemoveRuleAction: FC<ActionProps> = ({ handleOnClick, schema, path, level, disabled }) => {
  const { t } = useTranslation();
  const query = schema.getQuery();
  const parentQuery = query ? findPath(getParentPath(path), query) : null;

  const handleButtonClick = () => {
    // remove whole group when the last item of it gets removed
    if (query && isRuleGroupType(parentQuery) && parentQuery.rules.length === 1 && level > 1) {
      const wholeGroupRemoved = remove(query, getParentPath(path));
      schema?.dispatchQuery(wholeGroupRemoved);
      return;
    }

    handleOnClick();
  };

  return (
    <Tooltip
      title={
        <StyledTooltipContent>
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.ACTIONS.REMOVE_CONDITION')}
        </StyledTooltipContent>
      }
      size="S"
    >
      <IconButton
        testId="remove-rule"
        icon={DeleteOutlined}
        size="S"
        variant="plain"
        color="neutral"
        onClick={handleButtonClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};
