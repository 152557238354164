import { useEffect } from 'react';
import { Definitions } from '../../../definitionsTypes';
import { useWorkflowValidationContext } from './WorkflowValidationContext';

export const Validator = ({ definitions }: { definitions?: Definitions }) => {
  const { validate } = useWorkflowValidationContext();

  useEffect(() => {
    if (!definitions) return;

    void validate(definitions);
  }, [validate, definitions]);

  return null;
};
