import { FC, FormEventHandler, ReactNode, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogHeader } from '@verticeone/design-system';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { Divider, Stack } from '@mui/material';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { ZodSchema } from 'zod';
import { FormSchemaProvider } from '../../../schema/FormSchemaContext';

const IW_COLOR = INTELLIGENT_WORKFLOWS_BRAND_COLOR;

type FieldEditDialogProps<T extends FieldValues> = {
  children: ReactNode | ReactNode[];
  form: UseFormReturn<T>;
  schema: ZodSchema<any>;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

const FieldEditDialogRoot = <T extends FieldValues>(props: FieldEditDialogProps<T>) => {
  const { children, form, onSubmit, schema } = props;

  const handleSubmitWithoutPropagation: FormEventHandler<HTMLFormElement> = (event) => {
    event.stopPropagation();
    onSubmit(event);
  };

  return (
    <FormSchemaProvider value={schema}>
      <FormProvider {...form}>
        <Dialog open={true} size="M" width={660}>
          <form onSubmit={handleSubmitWithoutPropagation}>{children}</form>
        </Dialog>
      </FormProvider>
    </FormSchemaProvider>
  );
};

type FieldEditDialogHeaderProps = {
  mode: 'edit' | 'create';
  titleSuffix?: string;
  children: ReactNode | ReactNode[];
};

const FieldEditDialogHeader: FC<FieldEditDialogHeaderProps> = (props) => {
  const { children, mode, titleSuffix } = props;
  const { t } = useTranslation();

  const dialogTitlePrefix = useMemo(
    () =>
      match(mode)
        .with('create', () => t('DYNAMIC_FORM_BUILDER.DIALOG.TITLE_ADD_FIELD'))
        .with('edit', () => t('DYNAMIC_FORM_BUILDER.DIALOG.TITLE_UPDATE_FIELD'))
        .otherwise(() => ''),
    [t, mode]
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogHeader>{`${dialogTitlePrefix}: ${titleSuffix}`}</DialogHeader>
        {children}
      </Stack>
      <Divider />
    </>
  );
};

type FieldEditDialogFooterProps = {
  onClose?: () => void;
  isFormValid: boolean;
};

const FieldEditDialogFooter: FC<FieldEditDialogFooterProps> = (props) => {
  const { onClose, isFormValid } = props;
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <DialogActions>
        <Button variant="outline" onClick={onClose} color={IW_COLOR}>
          {t('DIALOG.BUTTONS.CLOSE')}
        </Button>
        <Button type="submit" variant="solid" disabled={!isFormValid} color={IW_COLOR}>
          {t('DIALOG.BUTTONS.SAVE')}
        </Button>
      </DialogActions>
    </>
  );
};

export const FieldEditDialog = Object.assign(FieldEditDialogRoot, {
  Header: FieldEditDialogHeader,
  Footer: FieldEditDialogFooter,
});
