import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@verticeone/design-system';
import type { CombinatorSelectorProps } from 'react-querybuilder';

type CombinatorSelectorButtonProps = PropsWithChildren & {
  isSelected: boolean;
  onClick: () => void;
  testId?: string;
  disabled?: boolean;
};

const CombinatorSelectorButton: FC<CombinatorSelectorButtonProps> = ({
  isSelected,
  onClick,
  children,
  testId,
  disabled,
}) => {
  return (
    <Button
      size="S"
      variant={isSelected ? 'ghost' : 'outline'}
      color={isSelected ? 'secondary' : 'neutral'}
      onClick={onClick}
      testId={testId}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export const CombinatorSelector: FC<CombinatorSelectorProps> = ({ value, handleOnChange, disabled }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.COMBINATORS',
  });
  const isAndSelected = value === 'and';

  return (
    <ButtonGroup activeButtonKeys={isAndSelected ? 0 : 1}>
      <CombinatorSelectorButton
        testId="combinator-selector-and"
        isSelected={isAndSelected}
        onClick={() => handleOnChange('and')}
        disabled={disabled}
      >
        {t('AND')}
      </CombinatorSelectorButton>
      <CombinatorSelectorButton
        testId="combinator-selector-or"
        isSelected={!isAndSelected}
        onClick={() => handleOnChange('or')}
        disabled={disabled}
      >
        {t('OR')}
      </CombinatorSelectorButton>
    </ButtonGroup>
  );
};
