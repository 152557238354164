import {
  RequestContextData,
  RequestContextProvider,
} from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/RequestContext';
import { RequestDetailPage as RequestDetailPageContent } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/RequestDetailPage';
import { useParams } from 'react-router-dom';
import { useRequestDetails } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/useRequestDetails';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { widgetDef as vendorDiligenceWidgetDef } from './widgets/vendorDiligence/widgetDef';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export const RequestDetailPage = () => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();
  const params = useParams() as { requestId: string };

  const { accountId } = useAccountContext();
  const { request, parentRequest, isLoading, workflowVersion, isExecutedByWorkflow, isRequestAndWorkflowMismatch } =
    useRequestDetails(params.requestId, accountId);

  const parentRequestRef = parentRequest ? parseRequestRef(parentRequest.ref) : undefined;
  const parentRequestPath = parentRequestRef
    ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, { requestId: parentRequestRef.requestId })
    : undefined;

  const requestContext: RequestContextData = {
    requestId: params.requestId,
    request,
    parentRequest,
    parentRequestPath,
    isLoading,
    workflowVersion,
    isExecutedByWorkflow,
    additionalWidgets: [vendorDiligenceWidgetDef],
    applicationIdentifier: 'CFA',
    isRequestAndWorkflowMismatch,
  };

  return (
    <RequestContextProvider value={requestContext}>
      <RequestDetailPageContent />
    </RequestContextProvider>
  );
};
