import { z } from 'zod';
import { t } from 'i18next';

export const radioGroupFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
  values: z
    .string()
    .array()
    .min(1, { message: t('DYNAMIC_FORM_BUILDER.DIALOG.ADD_AT_LEAST_ONE_VALUE') }),
});

export const radioGroupFieldSchema = z.object({
  name: z.string(),
  type: z.literal('RADIO_GROUP'),
  metadata: radioGroupFieldMetadataSchema,
});

export type RadioGroupFieldType = z.infer<typeof radioGroupFieldSchema>;

export type RadioGroupFieldMetadata = z.infer<typeof radioGroupFieldMetadataSchema>;
