import { assertExhausted } from '@verticeone/utils/logic';
import ApiKeyForm, { type ApiKeyFormData } from './ApiKeyForm';
import type { AuthType } from '../../../AuthType';

type UnifiedAuthTypeData = ApiKeyFormData; // | OauthFormData;

type UseFormProps = {
  authType?: AuthType;
  data?: UnifiedAuthTypeData;
  onSubmit: (data: UnifiedAuthTypeData) => void;
};

const Form = ({ authType, onSubmit, data }: UseFormProps) => {
  switch (authType) {
    case 'apiKey':
      return <ApiKeyForm data={data as ApiKeyFormData} onSubmit={onSubmit} />;
    case undefined:
      return null;
    default:
      assertExhausted(authType);
      return null;
  }
};

export default Form;
