import React from 'react';
import { Stack } from '@mui/material';
import { EllipsisText } from '@verticeone/design-system';
import type { TaskRow } from '../../../dataSource';

type TaskNameProps = {
  taskRow: TaskRow;
};

export const TaskName = ({ taskRow }: TaskNameProps) => (
  <Stack direction="row" gap={4} alignItems="center" minWidth={0}>
    <EllipsisText variant="body-regular" size="S" title={taskRow.name}>
      {taskRow.name}
    </EllipsisText>
  </Stack>
);
