import { Stack, useTheme } from '@mui/material';
import { Children, isValidElement, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { SlackIcon } from '@vertice/assets';
import { BreadcrumbsV2, Button, IconWrapper } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/src';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';
import { Step } from '../common';
import { DisconnectSlackDialog } from './DisconnectSlackDialog';
import { useDeactivateSlackIntegration } from './hooks/useDeactivateSlackIntegration';

type IntegrationSetupProps = {
  activeStep: Step;
} & PropsWithChildren;

export const IntegrationSetup = ({ activeStep, children }: IntegrationSetupProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const routes = useRoutes();

  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);
  const handleSetIsDisconnectDialogOpened = (value: boolean) => {
    setIsDisconnectDialogOpened(value);
  };

  const { deactivateSlackIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress } =
    useDeactivateSlackIntegration(handleSetIsDisconnectDialogOpened);

  const stepContent = Children.toArray(children).find((child) =>
    isValidElement<WizardStepProps>(child) ? child.props.stepValue === activeStep : false
  );

  return (
    <Stack flex={1} bgcolor={palette.core.color1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={4}
        p={6}
        borderBottom={`1px solid ${palette.core.color3}`}
      >
        <BreadcrumbsV2
          items={[
            {
              label: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_PREFERENCES'),
              component: Link,
              to: generatePath(routes.PREFERENCES.ABSOLUTE_PATH),
            },
            {
              label: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_SLACK'),
              startAdornment: <IconWrapper icon={SlackIcon} />,
            },
          ]}
        />
        {activeStep === Step.CONNECTION && (
          <>
            <Button variant="solid" size="M" color="neutral" onClick={() => setIsDisconnectDialogOpened(true)}>
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT')}
            </Button>
            <DisconnectSlackDialog
              isOpened={isDisconnectDialogOpened}
              isLoading={isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress}
              onSubmit={deactivateSlackIntegration}
              onClose={() => setIsDisconnectDialogOpened(false)}
            />
          </>
        )}
      </Stack>
      <Stack p={6} alignItems="center">
        {stepContent}
      </Stack>
    </Stack>
  );
};
