import { FC, Fragment, PropsWithChildren, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from '@verticeone/design-system';
import { CommentsCard } from '@verticeone/design-system';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';
import { useLoggedUser } from '@verticeone/auth/src';
import { ConversationTab, ConversationTabs, UnreadMessageChip } from './ConversationTabs';
import { CommentPanel } from './CommentPanel';
import { ConversationProvider, useConversationContext, ConversationData } from './ConversationContext';

type ConversationVariant = 'accordion' | 'card' | 'none';

export type ConversationProps = {
  variant?: ConversationVariant;
  request: ConversationData;
  parentRequest?: ConversationData;
  sourceRef?: string;
  height?: number | string;
};

type ConversationLayoutWrapperProps = PropsWithChildren<{
  variant: ConversationVariant;
  parentRequest?: ConversationProps['parentRequest'];
}>;

const ConversationLayoutWrapper = ({ children, variant, parentRequest }: ConversationLayoutWrapperProps) => {
  const { t } = useTranslation();
  const { current } = useConversationContext();

  if (variant === 'accordion' && current) {
    return (
      <Accordion size="XS" variant="label" defaultExpanded>
        <AccordionSummary
          title={
            <Stack gap={2} direction="row" alignItems="center">
              {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.COMMENTS')}
              {!parentRequest && <UnreadMessageChip {...current} showNew />}
            </Stack>
          }
        />
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  }

  return <>{children}</>;
};

export const ConversationTabsContent = ({
  tab,
  accountId,
  parentRequest,
}: {
  tab: ConversationTab;
  accountId: string;
  parentRequest?: ConversationProps['parentRequest'];
}) => {
  const { current, parent, currentUsers, parentUsers } = useConversationContext();
  const { userId, isIatUser, roles } = useLoggedUser();

  const canEditComents = isIatUser || !!roles?.includes('admin');

  return (
    <>
      {(tab === 'internal' || !parentRequest) && (
        <CommentPanel
          userId={userId}
          accountId={accountId}
          canEditComents={canEditComents}
          {...current}
          {...currentUsers}
        />
      )}
      {tab === 'customer' && parentRequest && (
        <CommentPanel
          userId={userId}
          accountId={parentRequest.accountId}
          canEditComents={canEditComents}
          {...parent}
          {...parentUsers}
        />
      )}
    </>
  );
};

export const ConversationSection: FC<ConversationProps> = ({
  variant = 'accordion',
  request,
  parentRequest,
  sourceRef,
  height = 800,
}) => {
  const [tab, setTab] = useState<ConversationTab>('customer');
  const Wrapper = variant !== 'none' ? CommentsCard : Fragment;

  return (
    <UsersContextProvider>
      <ConversationProvider request={request} parentRequest={parentRequest} sourceRef={sourceRef}>
        <ConversationLayoutWrapper variant={variant} parentRequest={parentRequest}>
          <Wrapper>
            <Stack height={height}>
              {parentRequest && <ConversationTabs value={tab} onChange={setTab} />}
              <ConversationTabsContent tab={tab} accountId={request.accountId} parentRequest={parentRequest} />
            </Stack>
          </Wrapper>
        </ConversationLayoutWrapper>
      </ConversationProvider>
    </UsersContextProvider>
  );
};
