import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoData, DataGrid } from '@verticeone/design-system';
import { useTaskColumns } from './useColumns';
import type { TaskRow } from '../../dataSource';
import { useActiveRowParam, useSearchParam, usePaginationParam } from '../../dataSource/queryParams';

type TableProps = {
  rows: Array<TaskRow>;
  isLoading: boolean;
};

const Table = ({ rows, isLoading }: TableProps) => {
  const { t } = useTranslation();
  const columns = useTaskColumns();
  const [searchValue] = useSearchParam();
  const [paginationModel, updatePaginationModel] = usePaginationParam();
  const [, setActiveRowId] = useActiveRowParam();

  return (
    <DataGrid<TaskRow>
      columns={columns}
      rows={rows}
      loading={isLoading}
      loadingStyle="skeleton"
      onRowClick={(params) => setActiveRowId(params.row.id)}
      slots={{
        noRowsOverlay: () => (
          <NoData
            header={t(
              searchValue
                ? `INTELLIGENT_WORKFLOWS.TASKS_LIST.NO_TASKS_FOUND`
                : `INTELLIGENT_WORKFLOWS.TASKS_LIST.NO_TASKS_AVAILABLE`
            )}
            content={searchValue ? undefined : t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.NO_TASKS_AVAILABLE_SUBTITLE`)}
          />
        ),
      }}
      initialState={{
        pagination: {
          paginationModel,
        },
        sorting: {
          sortModel: [{ field: 'activeDays', sort: 'desc' }],
        },
      }}
      pagination
      onPaginationModelChange={updatePaginationModel}
      pageSizeOptions={[10, 25, 50, 100]}
      autoHeight
      noRowsOverlayHeight={300}
      rowHeight={67}
      sx={{
        '.MuiDataGrid-cell--withRightBorder': {
          borderRight: 'none',
        },
      }}
    />
  );
};

export default Table;
