import { useMemo } from 'react';
import { useTaskContext } from '../TaskContext';

export const useFilePathPrefix = (): string => {
  const { taskOverview } = useTaskContext();

  return useMemo(() => {
    const taskId = taskOverview?.task?.ref.split('/').pop();
    const requestId = taskOverview?.request?.ref.split('/').pop();

    // Added taskId to prefix so we can distinguish between file-uploads for different tasks
    return requestId && taskId ? `requests/${requestId}/files/task/${taskId}` : '';
  }, [taskOverview]);
};
