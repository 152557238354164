import { Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import type { ProcessDefinition, VerticeFormConfiguration } from '../../../../definitionsTypes';
import type { NodeId } from '../../types';
import { CustomFormEdit } from './CustomForm/CustomFormEdit';
import type { EditUserTaskFormData } from './schema';
import { TaskAssignee } from './TaskAssignee/TaskAssignee';
import { TaskFields } from './TaskFields';
import { TaskProperties } from './TaskProperties';
import { isApprovalFormUrn, isGenericFormUrn } from './utils';

type EditUserTaskFormProps = {
  formConfiguration?: VerticeFormConfiguration;
  onDirty: () => void;
  onAddUser: () => void;
  taskName?: string;
  allowContractOwnerAssignment?: boolean;
  nodeId: NodeId;
  processDefinition: ProcessDefinition;
  workflowServiceRef?: string;
  isAdvancedWorkflowDefinitionEditModeEnabled?: boolean;
};

export const EditUserTaskForm: FC<EditUserTaskFormProps> = ({
  formConfiguration,
  taskName,
  onDirty,
  onAddUser,
  allowContractOwnerAssignment,
  nodeId,
  processDefinition,
  workflowServiceRef,
  isAdvancedWorkflowDefinitionEditModeEnabled = false,
}) => {
  const { formState } = useFormContext<EditUserTaskFormData>();

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  const isSimpleApproval = formConfiguration && isApprovalFormUrn(formConfiguration.formUrn);
  const isGenericForm = formConfiguration && isGenericFormUrn(formConfiguration.formUrn);

  return (
    <Stack gap={8} direction={'column'} width={'100%'}>
      <TaskProperties />
      <TaskAssignee
        processDefinition={processDefinition}
        onAddUser={onAddUser}
        nodeId={nodeId}
        workflowServiceRef={workflowServiceRef}
        allowContractOwnerAssignment={allowContractOwnerAssignment}
        isConditionalAssignmentEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
      />
      {isSimpleApproval && <TaskFields taskName={taskName} />}
      {isGenericForm && <CustomFormEdit nodeId={nodeId} processDefinition={processDefinition} />}
    </Stack>
  );
};
