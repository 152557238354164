import React, { useState } from 'react';
import { Stack, styled } from '@mui/material';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { AuthType } from '../../AuthType';

type NewIntegrationCardContentProps = {
  hover: boolean;
};

const IntegrationType = styled(Stack)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  backgroundColor: theme.palette.neutral.color4,
  '&:hover': {
    backgroundColor: theme.palette.neutral.hover.color4,
  },
}));

const NewIntegrationCardContent = ({ hover }: NewIntegrationCardContentProps) => {
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const apiKey: AuthType = 'apiKey';

  if (!hover) {
    return <>Add Integration</>;
  }

  return (
    <Stack direction="row" gap={1} height="100%" width="100%">
      <IntegrationType onClick={() => navigate(routes.PREFERENCES.INTEGRATIONS.CUSTOM.CREATE, { authType: apiKey })}>
        API Key
      </IntegrationType>
      <IntegrationType>OAuth 2.0</IntegrationType>
    </Stack>
  );
};

const NewIntegrationCard = () => {
  const [hover, setHover] = useState(false);

  return (
    <Stack
      p={4}
      bgcolor="transparent"
      border="1px dashed"
      borderColor="core.color4"
      borderRadius={4}
      height="100%"
      justifyContent="center"
      alignItems="center"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <NewIntegrationCardContent hover={hover} />
    </Stack>
  );
};

export default NewIntegrationCard;
