import { GenericFormField } from '../types';
import { isEqual } from 'lodash';

/**
 * Deep comparison of generic form fields arrays.
 * This function detects whether the order or attributes of the fields
 * in two arrays are modified, ensuring a strict and deep equality check.
 *
 * @param  x - The first array of form fields to compare.
 * @param  y - The second array of form fields to compare.
 * @returns - Returns true if both arrays are deeply equal, false otherwise.
 *
 * Note:
 * - The comparison is performed using deep equality for each element.
 * - Arrays must have the same length and the same order of elements to be considered equal.
 */
export const isArrayEqual = (x: GenericFormField[], y: GenericFormField[]) => {
  if (x?.length !== y?.length) return false;

  for (let i = 0; i < x.length; i++) {
    if (!isEqual(x[i], y[i])) return false;
  }

  return true;
};
