import React, { useId } from 'react';
import { StatsBody, StatsContainer, StatsTile, Title, Value } from '@vertice/core/src/components/StatsBar';
import { useTranslation } from 'react-i18next';
import { useOverviewStatsData } from '../useOverviewStatsData';

const OpenRequestsStatsContainer = () => {
  const id = useId();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.OVERVIEW.STATS' });
  const { data, isLoading } = useOverviewStatsData();

  return (
    <StatsContainer flex={1}>
      <StatsTile>
        <StatsBody
          isLoading={isLoading}
          title={<Title id={id}>{t('OPEN_REQUESTS')}</Title>}
          value={<Value aria-labelledby={id}>{!isLoading && data.openRequests}</Value>}
        />
      </StatsTile>
    </StatsContainer>
  );
};

export default OpenRequestsStatsContainer;
