import { useMemo } from 'react';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useListTasksPaginatedQuery } from '@vertice/slices/src/api/enhancedBffeWorkflowsAPI';
import { getAssignedUsersFromTaskList, getTaskRows } from './utils';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { useResolveUsersInfo } from '../../../../hooks/useResolveUsersInfo';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { isTaskRef } from '../../../../hooks/workflows/refUtils';

export const useTasks = () => {
  const { accountId } = useAccountContext();
  const { data: tasksData, isFetching: isLoadingTasksData, refetch } = useListTasksPaginatedQuery({ accountId });
  const tasksAssignees = useMemo(() => getAssignedUsersFromTaskList(tasksData), [tasksData]);
  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useRelevantUsersById();
  const { usersById, isLoading: isLoadingUsers } = useResolveUsersInfo(tasksAssignees, accountUsersById);

  useNotificationWebSocketSubscription({
    filter: isTaskRef,
    callback: () => {
      void refetch();
    },
  });

  return {
    data: getTaskRows(tasksData?.items ?? [], usersById),
    isLoading: isLoadingUsers || isLoadingAccountUsers || isLoadingTasksData,
    refetch,
  };
};
