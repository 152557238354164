import { AnimatePresence, motion } from 'framer-motion';
import { Alert, AlertButton } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

export type FormPreviewAlertProps = { visible: boolean; onClosePreviewClick: () => void };

export const FormPreviewAlert = ({ visible, onClosePreviewClick }: FormPreviewAlertProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.FORM_PREVIEW',
  });
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          style={{ overflow: 'hidden', flexShrink: 0 }}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { height: 'auto' },
            collapsed: { height: 0 },
          }}
        >
          <Alert subtitle={t('BELOW_IS_PREVIEW')} noBorderRadius>
            <motion.div layoutId="preview-button" style={{ zIndex: 10 }}>
              <AlertButton onClick={onClosePreviewClick}>{t('CLOSE_PREVIEW')}</AlertButton>
            </motion.div>
          </Alert>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
