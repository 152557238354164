import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccountIntegrationQuery, useListCreateJiraIssueTypeFieldsQuery } from '@vertice/slices';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { IconWrapper, Loader } from '@verticeone/design-system';
import { useAccountContext } from '../../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { isProcessDefinition, isTaskIOMappingConfiguration } from '../../../../../pocWorkflowSchema';
import { useWorkflowRendererContext } from '../../../../WorkflowRenderer/WorkflowRendererContext';
import { EditorDrawerScrollContainer } from '../../../EditorDrawer';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { findVariableById } from '../../../VariableSelector/utils';
import { VariableChip } from '../../../VariableSelector/VariableChip';
import { JiraTicketLink } from '../JiraCommon/JiraTicketLink';
import { ConnectionFormType } from '../JiraCommon/types';
import { findValueInTaskObj } from '../JiraCommon/utils';
import { VariableMapperHeader } from '../JiraCommon/VariableMapper/VariableMapperHeader';
import { JIRA_FIELD_PREFIX } from '../utils';

const VariableMapperBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  backgroundColor: theme.palette.core.color1,
  borderRadius: 12,
  padding: theme.spacing(4),
}));

type JiraCreateCompleteProps = {
  taskOverview?: TaskOverview;
};

export const JiraCreateComplete: FC<JiraCreateCompleteProps> = ({ taskOverview }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { workflowDefinitions } = useWorkflowRendererContext();

  const { data: integrationData } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });

  const processDefinition = workflowDefinitions?.definitions.find(isProcessDefinition);
  const processTaskDefinition = processDefinition?.process?.tasks?.find((processTask) =>
    taskOverview?.task.taskId?.includes(processTask.task.id)
  );
  const processTaskIOMappingConfiguration =
    processTaskDefinition?.task?.configurations?.find(isTaskIOMappingConfiguration);
  const projectId = processTaskIOMappingConfiguration?.mapping.inputFields.find(
    (field) => field.name === 'projectId'
  )?.value;
  const issueTypeId = processTaskIOMappingConfiguration?.mapping.inputFields.find(
    (field) => field.name === 'issueTypeId'
  )?.value;

  const { data: issueTypeFieldsData, isLoading: isLoadingIssueTypeFields } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: projectId ?? '',
      issueTypeId: issueTypeId ?? '',
    },
    { skip: !projectId || !issueTypeId }
  );

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: processTaskDefinition?.task.id ?? '',
    processDefinition,
    workflowServiceRef: taskOverview?.request?.serviceRef,
  });
  const allVariables = [...requestVariables, ...udfVariables];

  const filteredFields = processTaskIOMappingConfiguration?.mapping.inputFields
    .filter((field) => field.name.includes(JIRA_FIELD_PREFIX) || field.name.match(/(.*)<(.*)>/g))
    ?.map((field) => ({ name: field.name, value: field.value }));

  const domainUrl = (integrationData?.parameters?.connectionForm as ConnectionFormType)?.domain;
  const ticketKey = taskOverview?.task?.result?.ticketKey;
  const ticketSummary =
    taskOverview?.task?.input?.[`${JIRA_FIELD_PREFIX}summary`] ??
    findValueInTaskObj(/summary<(.*?)>/, taskOverview?.task.input);
  const ticketUrl = domainUrl && ticketKey ? `${domainUrl}browse/${ticketKey}` : undefined;
  const ticketTitle = ticketKey && ticketSummary ? `${ticketKey}: ${ticketSummary}` : undefined;

  return (
    <EditorDrawerScrollContainer>
      <VariableMapperBox>
        <VariableMapperHeader
          worflowVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.VERTICE')}
          otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.JIRA')}
          fromWorkflow={true}
        />
        {isLoadingIssueTypeFields ? (
          <Box sx={{ height: 120 }}>
            <Loader size={24} />
          </Box>
        ) : (
          filteredFields?.map((field, index) => {
            const workflowVariable = findVariableById(allVariables, field.value);
            const jiraVariable = issueTypeFieldsData?.fields?.find((jiraField) =>
              field.name.includes(JIRA_FIELD_PREFIX)
                ? jiraField.id === field.name.replace(JIRA_FIELD_PREFIX, '')
                : jiraField.id === field.name.split('<')[0]
            );

            return (
              <Grid container sx={{ zIndex: 1 }} key={index}>
                <Grid item xs={6}>
                  <VariableChip
                    label={workflowVariable?.label}
                    typeLabel={workflowVariable?.type.labels?.[0]}
                    isDisabled={true}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <IconWrapper icon={ArrowForwardIcon} />
                </Grid>
                <Grid item xs={4}>
                  <VariableChip label={jiraVariable?.name} typeLabel={jiraVariable?.type} isDisabled={true} />
                </Grid>
              </Grid>
            );
          })
        )}
      </VariableMapperBox>
      <JiraTicketLink url={ticketUrl} title={ticketTitle} />
    </EditorDrawerScrollContainer>
  );
};
