import { FormControl, Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import React, { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert } from '@verticeone/design-system';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { usePurchaseVerticeNegotiationEligibility } from '../formHooks/usePurchaseVerticeNegotiationEligibility';
import { useFormContext } from 'react-hook-form';
import { FormData } from '../schema';
import { useTaskFormContext } from '../../shared/TaskFormContext';

export const VerticePurchaseNegotiationThresholdSection = () => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();
  const formatCurrency = useFormatCurrency();
  const { setValue } = useFormContext<FormData>();
  const negotiationEligibility = usePurchaseVerticeNegotiationEligibility();

  useEffect(() => {
    if (negotiationEligibility?.eligibleForVerticeNegotiation === undefined) {
      return;
    }

    if (negotiationEligibility.eligibleForVerticeNegotiation) {
      setValue('verticeNegotiationRequested', false);
    } else {
      setValue('internalNegotiationRequested', false);
    }
  }, [negotiationEligibility?.eligibleForVerticeNegotiation, setValue]);

  if (negotiationEligibility === undefined) {
    return null;
  }

  const { threshold: verticeNegotiationThreshold, eligibleForVerticeNegotiation } = negotiationEligibility;

  return (
    <Stack gap={3}>
      <Alert
        icon={InfoOutlinedIcon}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        title={
          <Trans
            i18nKey={`INTELLIGENT_WORKFLOWS.TASK_FORMS.PURCHASE_REQUIREMENTS.LABELS.THRESHOLD_${
              eligibleForVerticeNegotiation ? 'ABOVE' : 'BELOW'
            }_TITLE`}
            components={{ bold: <b /> }}
            values={{
              accountThreshold: verticeNegotiationThreshold
                ? formatCurrency(verticeNegotiationThreshold.value, {
                    currency: verticeNegotiationThreshold.currency,
                    maximumFractionDigits: 0,
                  })
                : '',
            }}
          />
        }
        subtitle={
          <Trans
            i18nKey={`INTELLIGENT_WORKFLOWS.TASK_FORMS.PURCHASE_REQUIREMENTS.LABELS.THRESHOLD_${
              eligibleForVerticeNegotiation ? 'ABOVE' : 'BELOW'
            }_SUBTITLE`}
            components={{ break: <br /> }}
            values={{
              accountThreshold: verticeNegotiationThreshold
                ? formatCurrency(verticeNegotiationThreshold.value, {
                    currency: verticeNegotiationThreshold.currency,
                    maximumFractionDigits: 0,
                  })
                : '',
            }}
          />
        }
        variant="ghost"
        size="S"
      />
      {eligibleForVerticeNegotiation && (
        <FormControl variant="outlined">
          <FormChecklistItemField
            name={'internalNegotiationRequested'}
            label={t(`INTELLIGENT_WORKFLOWS.TASK_FORMS.PURCHASE_REQUIREMENTS.LABELS.THRESHOLD_ABOVE_OVERRIDE_LABEL`)}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={'S'}
            disabled={readOnly}
            testId="threshold-above-override"
          />
        </FormControl>
      )}
      {!eligibleForVerticeNegotiation && (
        <FormControl variant="outlined">
          <FormChecklistItemField
            name={'verticeNegotiationRequested'}
            label={t(`INTELLIGENT_WORKFLOWS.TASK_FORMS.PURCHASE_REQUIREMENTS.LABELS.THRESHOLD_BELOW_OVERRIDE_LABEL`)}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={'S'}
            disabled={readOnly}
            testId="threshold-below-override"
          />
        </FormControl>
      )}
    </Stack>
  );
};
