import { FC } from 'react';
import { NodeProps, Node } from '@xyflow/react';
import { NodeControls } from './NodeControls';
import { WorkflowLabelNode } from '../../model/types';
import { styled } from '@mui/material';
import { ChipButton } from '@verticeone/design-system';
import { LABEL_NODE_HEIGHT } from '../modelToRendererGraph';

const StyledLabelChip = styled(ChipButton)({
  display: 'block',
  textAlign: 'center',
  height: LABEL_NODE_HEIGHT,
});

export const LabelNodeComponent: FC<NodeProps<Node<WorkflowLabelNode>>> = (props) => {
  const { data } = props;

  const isPassedEdge = data?.state?.passed;
  const isSelectedEdge = data?.state?.selected;

  return (
    <NodeControls
      {...props}
      content={
        <StyledLabelChip
          color={isPassedEdge ? 'success' : 'secondary'}
          isActive={false}
          variant={isSelectedEdge ? 'solid' : 'ghost'}
          size="M"
        >
          {data.name}
        </StyledLabelChip>
      }
    />
  );
};
