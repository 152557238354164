import { FieldDef } from '../types';
import { RadioGroupFieldBuilderCard } from './RadioGroupFieldBuilderCard';
import { RadioGroupFieldBuilderDialog } from './RadioGroupFieldBuilderDialog';
import { RadioGroupFieldPresenter } from './RadioGroupFieldPresenter';
import { RadioGroupFieldType } from './types';
import {
  getRadioGroupJsonSchema,
  getRadioGroupJsonSchemaFromMetadata,
  getRadioGroupPresenterDefault,
  getRadioGroupPresenterSchema,
} from './utils';

export type { RadioGroupFieldType, RadioGroupFieldMetadata } from './types';
export { radioGroupFieldMetadataSchema, radioGroupFieldSchema } from './types';

export const fieldDef: FieldDef<RadioGroupFieldType> = {
  type: 'RADIO_GROUP',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.RADIO_GROUP',
  builderCard: RadioGroupFieldBuilderCard,
  builderDialog: RadioGroupFieldBuilderDialog,
  presenter: RadioGroupFieldPresenter,
  getPresenterDefault: getRadioGroupPresenterDefault,
  getJsonSchemaFromMetadata: getRadioGroupJsonSchemaFromMetadata,
  getPresenterSchema: getRadioGroupPresenterSchema,
  getJsonSchema: getRadioGroupJsonSchema,
};
