import { useEffect, useMemo, useState } from 'react';
import { getFullName } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';

import { TaskAssignmentConfiguration } from '../../../../definitionsTypes';
import { EXPRESSION_ALIASES } from '../../../../task/ExpressionAliases';
import { DEFINITION_VERSION } from '../../../../definitions/constants';
import { formatUserRef, parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import { useAccountUsersById } from '../../../../../../hooks/useAccountUsersById';
import { EditUserTaskFormData } from './schema';

const getAssigneeFromAssignmentConfiguration = (assignmentConfiguration: TaskAssignmentConfiguration): string[] => {
  if (assignmentConfiguration.type === 'STATIC') {
    return [...(assignmentConfiguration.assignment?.map((a) => parseUserRef(a).userId) || [])];
  } else if (assignmentConfiguration.type === 'REQUEST_OWNER') {
    return ['REQUEST_OWNER'];
  } else if (assignmentConfiguration.type === 'EXPRESSION') {
    const result: string[] = [];

    assignmentConfiguration.assignment?.forEach((a) => {
      const matchingAlias = EXPRESSION_ALIASES.find((alias) => alias.assignmentItem === a);
      if (matchingAlias) {
        result.push(matchingAlias.code);
      }
    });

    return result;
  }

  return [];
};

// convert task assignment configurations to assignment select values
export const getDefaultAssigneesFromAssignmentConfigurations = (
  assignmentConfigurations: TaskAssignmentConfiguration[]
): string[] => {
  const result: string[] = [];

  assignmentConfigurations.forEach((assignmentConfiguration) => {
    if (!assignmentConfiguration.condition) {
      const assignees = getAssigneeFromAssignmentConfiguration(assignmentConfiguration);
      result.push(...assignees);
    }
  });

  return result;
};

export const getConditionalAssigneesFromAssignmentConfigurations = (
  assignmentConfigurations: TaskAssignmentConfiguration[]
): EditUserTaskFormData['conditionalAssignees'] => {
  const conditionalAssignees: EditUserTaskFormData['conditionalAssignees'] = [];

  assignmentConfigurations.forEach((assignmentConfiguration) => {
    if (assignmentConfiguration.condition) {
      conditionalAssignees.push({
        condition: assignmentConfiguration.condition.expression,
        assignees: getAssigneeFromAssignmentConfiguration(assignmentConfiguration),
      });
    }
  });

  return conditionalAssignees.reduce<EditUserTaskFormData['conditionalAssignees']>(
    (mergedConditionalAssignees, conditionalAssignee) => {
      const existingMergedConditionalAssignee = mergedConditionalAssignees.find(
        (mergedConditionalAssignee) => mergedConditionalAssignee.condition === conditionalAssignee.condition
      );
      if (existingMergedConditionalAssignee) {
        existingMergedConditionalAssignee.assignees.push(...conditionalAssignee.assignees);
      } else {
        mergedConditionalAssignees.push(conditionalAssignee);
      }
      return mergedConditionalAssignees;
    },
    []
  );
};

// convert assignment select values to task assignment configurations
export const getAssignmentConfigurationsFromAssignees = (assignees: string[]): TaskAssignmentConfiguration[] => {
  const staticAssignmentConfiguration: TaskAssignmentConfiguration = {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'STATIC',
    assignment: [],
  };
  const expressionAssignmentConfiguration: TaskAssignmentConfiguration = {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'EXPRESSION',
    expression_type: 'JMESPathExpression',
    assignment: [],
  };
  const requestOwnerAssignmentConfiguration: TaskAssignmentConfiguration = {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'REQUEST_OWNER',
  };
  let hasRequestOwnerAssignment = false;

  assignees.forEach((assignee) => {
    const matchingAlias = EXPRESSION_ALIASES.find((alias) => alias.code === assignee);
    if (matchingAlias) {
      expressionAssignmentConfiguration.assignment?.push(matchingAlias.assignmentItem);
    } else {
      staticAssignmentConfiguration.assignment?.push(formatUserRef(assignee));
    }
  });

  return [
    ...(hasRequestOwnerAssignment ? [requestOwnerAssignmentConfiguration] : []),
    ...(expressionAssignmentConfiguration.assignment?.length ? [expressionAssignmentConfiguration] : []),
    ...(staticAssignmentConfiguration.assignment?.length ? [staticAssignmentConfiguration] : []),
  ];
};

export const getAssignmentConfigurationsFromConditionalAssignees = (
  conditionalAssignees: EditUserTaskFormData['conditionalAssignees']
): TaskAssignmentConfiguration[] => {
  const assignmentConfigurations: TaskAssignmentConfiguration[] = [];

  if (!conditionalAssignees.length) {
    return assignmentConfigurations;
  }

  for (const conditionalAssignee of conditionalAssignees) {
    const { assignees, condition } = conditionalAssignee;
    if (!assignees.length || !condition) {
      continue;
    }

    const assignmentConfigurationsForCondition = getAssignmentConfigurationsFromAssignees(assignees);

    for (const assignmentConfigurationForCondition of assignmentConfigurationsForCondition) {
      assignmentConfigurations.push({
        ...assignmentConfigurationForCondition,
        condition: {
          expression: condition,
          expression_type: 'JsonLogic',
        },
      });
    }
  }

  return assignmentConfigurations;
};

export type Option = {
  readonly value: string;
  readonly label: string;
  readonly disabled?: boolean;
};

export type GroupOption = {
  label: string;
  options: Option[];
};

// get grouped options for the assignee select
export const useAssignmentOptions = (
  currentAssignees: string[],
  sortAssignees: boolean,
  allowContractOwnerAssignment?: boolean
): GroupOption[] => {
  const { t } = useTranslation();
  const { usersById } = useAccountUsersById();

  const options = useMemo(() => {
    const dynamicUsersOption: Option[] = EXPRESSION_ALIASES.map((alias) => ({
      value: alias.code,
      disabled: alias.disabled,
      label: t(`INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.DYNAMIC_USERS.${alias.code}`),
    }))
      .filter((option) => option.value !== 'CONTRACT_OWNER' || allowContractOwnerAssignment)
      .sort((o1, o2) => o1.label.localeCompare(o2.label));

    const userOptions: Option[] = Object.values(usersById)
      .map((user) => ({
        value: user.userId,
        label: getFullName(user),
      }))
      .sort((u1, u2) => u1.label.localeCompare(u2.label));

    return [
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.DYNAMIC_USERS_GROUP'),
        options: dynamicUsersOption,
      },
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.STATIC_USERS_GROUP'),
        options: userOptions,
      },
    ];
  }, [allowContractOwnerAssignment, t, usersById]);

  const [sortedOptions, setSortedOptions] = useState(options);

  useEffect(() => {
    if (sortAssignees) {
      setSortedOptions(sortOptions(options, currentAssignees));
    }
  }, [currentAssignees, options, sortAssignees]);

  return sortedOptions;
};

// sort assignee select groups options to move checked options to the top
export const sortOptions = (groupOptions: GroupOption[], checkedAssignees: string[]): GroupOption[] => {
  return groupOptions.slice().map((groupOption) => {
    const sortedOptions = groupOption.options.slice().sort((o1, o2) => {
      const isChecked1 = checkedAssignees.includes(o1.value);
      const isChecked2 = checkedAssignees.includes(o2.value);
      let checkValue = 0;
      if (isChecked1 !== isChecked2) {
        checkValue = isChecked1 && !isChecked2 ? -1 : 1;
      }
      const x = checkValue || o1.label.localeCompare(o2.label);
      return x;
    });
    return {
      ...groupOption,
      options: sortedOptions,
    };
  });
};
