import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { getTaskServiceConfiguration, isIOMappingConfiguration } from '../../../../../definitions/taskDefinition';
import { FieldMapping, TaskDefinition } from '../../../../../definitionsTypes';
import { getVariableTypeLabels } from '../../../../utils';
import { JsonSchemaType, XTypeServiceCatalogResource } from '../../../../WorkflowViewer/types';
import { Variable } from '../../../types';
import { findVariableById } from '../../../VariableSelector/utils';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../utils';
import { JiraIssueField, TaskMinimum, WorkflowVariables } from './types';
import { VariableMapperRowFormBase } from './VariableMapper/formSchema';
import {
  composeVariableDefaults,
  createVariable,
  uniqueNameWithNumber,
} from '../../../VariableSelector/customVariableUtils';
import { VariablesGroup } from '../../../VariableSelector/types';

export const ISSUE_TYPE_ID_NAME = 'issueTypeId';
export const PROJECT_ID_NAME = 'projectId';
export const TICKET_ID_NAME = 'ticketId';

/**
 * Get Input mapping variables  used in  Workflow to Create Jira mapping
 * Jira ids are internally prefixed, we need to strip that prefix in preparation for used in variable picker
 * @param task
 * @param taskService
 */
export const getJiraCreateInputMapping = (task: TaskDefinition, taskService: ServiceCatalogResource) => {
  // get patter BE is expecting the jira fields have - like: "^jiraFields."
  const pattern = Object.keys(taskService.definition.ServiceProvider.Interface.Input.JsonSchema.patternProperties)[0];
  // eslint-disable-next-line no-useless-escape
  const escapedPattern = pattern.replace('.', `\.`); // Escape dot we need to keep

  let projectId: string | undefined;
  let issueTypeId: string | undefined;
  let includeAttachments: boolean | undefined;
  let variables: FieldMapping[] = [];

  const ioMappingConfiguration = task.task.configurations?.find(isIOMappingConfiguration);
  const inputFields = ioMappingConfiguration?.mapping.inputFields;

  if (ioMappingConfiguration && inputFields) {
    inputFields.forEach((field) => {
      // required field we need to know
      if (field.name === PROJECT_ID_NAME) {
        projectId = field.value;
        // required field we need to know
      } else if (field.name === ISSUE_TYPE_ID_NAME) {
        issueTypeId = field.value;
      } else if (field.name === 'includeAttachments') {
        includeAttachments = field.value === 'true';
      } else {
        const regex = new RegExp(`${escapedPattern}(.*)`); // Capturing group for the rest of the string
        const match = field.name.match(regex);
        let name = '';
        // To support old format with jiraFields. prefix
        if (match) {
          name = match[1];
        } else {
          name = field.name.split('<')[0];
        }
        variables.push({ ...field, name });
      }
    });
  }
  return {
    projectId,
    issueTypeId,
    includeAttachments,
    variables,
  };
};

/**
 * Map workflow type variables into object used in variable mapper
 */
export const workflowToFormVariables = (workflowVariables: FieldMapping[], fromWorkflow: boolean) => {
  const formVariables: VariableMapperRowFormBase[] = workflowVariables.map((inputField) => ({
    from: fromWorkflow ? inputField.value : inputField.name,
    to: fromWorkflow ? inputField.name : inputField.value,
    mappingType: inputField.mappingType,
  }));

  return formVariables;
};

export const getVariableXType = (variables: Variable[], variableId: string) => {
  return variables.find((variable) => variable.id === variableId)?.type.xType;
};

const getVariableBaseType = (variables: Variable[], variableId: string) => {
  return variables.find((variable) => variable.id === variableId)?.type.baseType?.[0];
};

export const enhanceInputFieldsName = (
  wfVariables: WorkflowVariables,
  jiraVariables: Variable[],
  variable: VariableMapperRowFormBase,
  fromWorkflow = true,
  prefix = ''
) => {
  const wfVariableType = REQUIRED_JIRA_CREATE_FIELDS.includes(variable['to'])
    ? getVariableBaseType(jiraVariables, variable['to'])
    : findVariableById([...wfVariables.request, ...wfVariables.udfs], variable[fromWorkflow ? 'from' : 'to'])?.type
        .baseType?.[0];
  const jiraVariableXType = getVariableXType(jiraVariables, variable[fromWorkflow ? 'to' : 'from']);

  return fromWorkflow
    ? `${variable.to}<${wfVariableType},${jiraVariableXType}>`
    : `${prefix}${variable.from}<${jiraVariableXType},${wfVariableType}>`;
};

export type RequiredField = {
  externalId: string;
  whereRequired: 'from' | 'to';
  preCreateWfVariable: boolean;
  mappingType: FieldMapping['mappingType'];
};

type EnhanceWithDefaultParam = {
  formVariables: VariableMapperRowFormBase[];
  requiredFields: RequiredField[];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & ({} | { task: TaskMinimum; externalVariables: Variable[]; variablesGroups: VariablesGroup[] }); // these parameters are needed for workflow variable creation

/**
 * Enhance array of VariableMapperRowFormBase of required ids, if they are not already used.
 * If workflow variables should also be created, they are created and tey id is used in that VariableMapperRowFormBase object. But to be then showed by picker
 * local workflowVariables request  must be updated by those newly created variable objects.
 * @param params
 */
export const enhanceWithDefault = (params: EnhanceWithDefaultParam) => {
  const { formVariables, requiredFields } = params;
  const notUsedFields = requiredFields.filter(
    (requiredField) =>
      !formVariables.some((variable) => variable[requiredField.whereRequired] === requiredField.externalId)
  );

  const newDefaults: VariableMapperRowFormBase[] = [];
  const newWorkflowVariables: Variable[] = [];
  for (const field of notUsedFields) {
    let workflowVariableId = '';
    /* Create workflow variable and use its id */
    if (field.preCreateWfVariable && 'task' in params) {
      //
      const { task, externalVariables, variablesGroups } = params;
      const externalVariable = externalVariables.find((exVariable) => exVariable.id === field.externalId);

      if (!externalVariable) continue;

      const addVariableDefaults = composeVariableDefaults(externalVariable, task, 'vertice-task-output-mapping');
      const fieldName = externalVariable.label;
      const name = uniqueNameWithNumber(`${task.name} ${fieldName}`, variablesGroups);
      const newWorkflowVariable = createVariable(addVariableDefaults, name);
      newWorkflowVariables.push(newWorkflowVariable);
      workflowVariableId = newWorkflowVariable ? newWorkflowVariable.id : '';
    }
    newDefaults.push({
      from: field.whereRequired === 'from' ? field.externalId : workflowVariableId,
      to: field.whereRequired === 'to' ? field.externalId : workflowVariableId,
      mappingType: field.mappingType,
    });
  }

  return { formVariables: [...newDefaults, ...formVariables], newWorkflowVariables };
};

export const getCorrespondingTaskService = (task: TaskDefinition, resources: ServiceCatalogResource[]) => {
  const taskServiceConfiguration = getTaskServiceConfiguration(task);
  return resources.find((service) => service.urn === taskServiceConfiguration?.resourceUrn);
};

const transformJiraTypeToWorkflow = (
  jiraVariable: JiraIssueField,
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[]
) => {
  const xTypeServiceCatalogResource = xTypeServiceCatalogResources.find(
    (xTypeResource) => xTypeResource.urn === jiraVariable.xType
  );

  return {
    baseType: [jiraVariable.type as JsonSchemaType],
    labels: getVariableTypeLabels({
      type: jiraVariable.type as JsonSchemaType,
      xTypeResource: xTypeServiceCatalogResource,
    }),
  };
};

export const transformJiraToWorkflowLikeVariable = (
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[],
  jiraVariable?: JiraIssueField
): Variable | undefined => {
  if (!jiraVariable) return;

  const { baseType, labels } = transformJiraTypeToWorkflow(jiraVariable, xTypeServiceCatalogResources);
  const wfVariable: Variable & { name?: string; xType?: string } = {
    ...jiraVariable,
    label: jiraVariable.name,
    path: [jiraVariable.name],
    type: {
      baseType,
      labels,
      xType: jiraVariable.xType,
    },
    isVisible: true,
    isSelectable: true,
    origin: {
      // How origin should be extended? But it's only for picker, would be thrown away on processing.
      id: 'jira',
      label: 'Jira',
      kind: 'jira',
    },
    variables: [],
    required: false,
  };
  delete wfVariable['name'];
  delete wfVariable['xType'];
  return wfVariable;
};

export const transformJiraToWorkflowLikeVariables = (
  variables: JiraIssueField[],
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[]
): Variable[] => {
  return variables
    .map((jiraVariable) => transformJiraToWorkflowLikeVariable(xTypeServiceCatalogResources, jiraVariable))
    .filter((v) => !!v) as Variable[];
};

export const findValueInTaskObj = (searchKey: string | RegExp, taskObj?: object) => {
  return typeof searchKey === 'string'
    ? Object.entries(taskObj ?? {}).find(([key]) => key.includes(searchKey))?.[1]
    : Object.entries(taskObj ?? {}).find(([key]) => searchKey.test(key))?.[1];
};
