import { Stack } from '@mui/material';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { SectionWrapper } from './components/SectionWrapper';
import { DiscoveryAndUsage } from './DiscoveryAndUsage';
import { Security } from './Security';
import { CloudCostOptimization } from './AWS/AWS';
import { Accounting } from './Accounting';
import { Notifications } from './Notifications';
import { Workflows } from './Workflows';
import { CustomSection } from './Custom/widget';

export const Integrations = () => {
  const { isEnabled, getFeature } = useFeatures();
  const mergeIntegrationFeature = getFeature(FEATURES.INTEGRATION_MERGE);
  const mergeIntegrationProperties = mergeIntegrationFeature?.properties;
  const mergeAccountingIntegrationAllowed = mergeIntegrationFeature && mergeIntegrationProperties?.accounting;

  return (
    <Stack gap={8}>
      <SectionWrapper isVisible={isEnabled(FEATURES.USAGE_ANALYTICS)}>
        <DiscoveryAndUsage />
      </SectionWrapper>
      <SectionWrapper isVisible={isEnabled(FEATURES.SSO)}>
        <Security />
      </SectionWrapper>
      <SectionWrapper isVisible={isEnabled(FEATURES.AWS_COST_INSIGHT)}>
        <CloudCostOptimization />
      </SectionWrapper>
      <SectionWrapper isVisible={isEnabled(FEATURES.INTEGRATIONS) && mergeAccountingIntegrationAllowed}>
        <Accounting />
      </SectionWrapper>
      <SectionWrapper isVisible={isEnabled(FEATURES.INTEGRATION_SLACK)}>
        <Notifications />
      </SectionWrapper>
      <SectionWrapper isVisible={isEnabled(FEATURES.INTEGRATION_JIRA) || isEnabled(FEATURES.INTEGRATION_VISOTRUST)}>
        <Workflows />
      </SectionWrapper>
      <SectionWrapper
        isVisible={isEnabled(FEATURES.INTEGRATION_GENERIC_INBOUND) || isEnabled(FEATURES.INTEGRATION_GENERIC_OUTBOUND)}
      >
        <CustomSection />
      </SectionWrapper>
    </Stack>
  );
};
