import React, { createContext, useContext, useState, PropsWithChildren } from 'react';

export type Range = {
  startDate: string;
  endDate: string;
};

type CompleteRequestsContextType = {
  range: Range;
  setRange: (startDate: string, endDate: string) => void;
};

const CompleteRequestsContext = createContext<CompleteRequestsContextType | undefined>(undefined);

type CompleteRequestsProviderProps = PropsWithChildren & {
  defaultRange: {
    startDate: string;
    endDate: string;
  };
};

export const CompleteRequestsProvider = ({ children, defaultRange }: CompleteRequestsProviderProps) => {
  const [range, setRange] = useState<{
    startDate: string;
    endDate: string;
  }>(defaultRange);

  const updateRange = (startDate: string, endDate: string) => {
    setRange({ startDate, endDate });
  };

  return (
    <CompleteRequestsContext.Provider value={{ range, setRange: updateRange }}>
      {children}
    </CompleteRequestsContext.Provider>
  );
};

export const useCompleteRequestsContext = (): CompleteRequestsContextType => {
  const context = useContext(CompleteRequestsContext);
  if (!context) {
    throw new Error('useRange must be used within a CompleteRequestsProvider');
  }
  return context;
};
