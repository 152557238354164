import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, Divider, IconButton, IconWrapper, Link, Text } from '@verticeone/design-system';
import { format } from 'date-fns';
import { CancelOutlined, CheckCircleOutline, DescriptionOutlined, ArrowCircleUpOutlined } from '@mui/icons-material';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { useMyTasksData, type Task as TaskType } from './useMyTasksData';

const Task = ({ title, vendorId, vendorName, type, date, status }: TaskType) => {
  return (
    <Stack gap={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text variant="body-bold" size="M">
          {title}
        </Text>
        {status === 'todo' && (
          <Stack direction="row" gap={0}>
            <IconButton icon={CancelOutlined} onClick={() => {}} size="M" variant="plain" color="error" />
            <IconButton icon={CheckCircleOutline} onClick={() => {}} size="M" variant="plain" color="success" />
          </Stack>
        )}
        {status === 'done' && (
          <IconButton icon={DescriptionOutlined} onClick={() => {}} size="M" variant="plain" color="tertiary" />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={1} alignItems="center">
          <VendorLogo vendorId={vendorId ?? undefined} size={28} />
          <Text variant="body-bold" size="S">
            {vendorName}
          </Text>
          <Text variant="body-regular" size="S">
            {type}
          </Text>
        </Stack>
        <Text variant="body-regular" size="S">
          {format(new Date(date), 'PP')}
        </Text>
      </Stack>
    </Stack>
  );
};

const MyTasksCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.MY_TASKS' });
  const { palette } = useTheme();
  const { data } = useMyTasksData();

  return (
    <Card testId="my-tasks-card">
      <CardHeader>
        <Text variant="heading" size="S">
          {t('TITLE')}
        </Text>
        <Stack direction="row" gap={2} justifyContent="space-between">
          <Stack direction="row" gap={4} alignItems="center">
            <Text variant="display" size="S" color="error1">
              {data.count}
            </Text>
            <IconWrapper icon={ArrowCircleUpOutlined} size="XL" htmlColor={palette.error.color1} />
          </Stack>
          <Stack direction="column" gap={2} alignItems="flex-end">
            <Text variant="body-bold" color="error1">
              {data.due_in_3_days}
            </Text>
            <Text variant="body-regular">{data.due_in_10_days}</Text>
          </Stack>
        </Stack>
      </CardHeader>
      <Stack p={6} gap={4}>
        <Stack gap={2}>
          {data.tasks.map((task, index) => (
            <Stack gap={2}>
              <Task key={task.id} {...task} />
              {index < data.tasks.length - 1 && <Divider />}
            </Stack>
          ))}
        </Stack>
        <Stack alignItems="center">
          <Link href="#" onClick={() => {}} color="secondary">
            {t('SHOW_ALL')}
          </Link>
        </Stack>
      </Stack>
    </Card>
  );
};

export default MyTasksCard;
