import { useMemo } from 'react';
import { ServiceCatalogResource, useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import { ServiceCatalogResourceMap, ServiceCatalogResources, XTypeServiceCatalogResource } from './types';
import { useAccountContext } from '../../../../contexts/AccountContext';
import {
  isFunctionServiceCatalogResource,
  isServiceServiceCatalogResource,
  isXTypeServiceCatalogResource,
  convertJsonSchemaToVariable,
  getVariableFromFunctionDefinition,
  isFormServiceCatalogResource,
} from './utils';

const getServiceCatalogResourceMapFromServiceCatalogResources = ({
  serviceCatalogResources,
  xTypeServiceCatalogResources,
  serviceCatalog,
}: {
  serviceCatalogResources: ServiceCatalogResource[];
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[];
  serviceCatalog: 'account' | 'global';
}): ServiceCatalogResourceMap => {
  return serviceCatalogResources.reduce<ServiceCatalogResourceMap>((serviceCatalogResourceMap, resource) => {
    const { name, urn } = resource;

    if (isServiceServiceCatalogResource(resource)) {
      if (!resource.definition.ServiceProvider.Interface) {
        return serviceCatalogResourceMap;
      }

      const { Output, Input } = resource.definition.ServiceProvider.Interface;

      if (!Output?.JsonSchema || !Input?.JsonSchema) {
        return serviceCatalogResourceMap;
      }

      const origin = { id: urn, label: name, kind: `vertice-${serviceCatalog}-service` } as const;

      serviceCatalogResourceMap[urn] = {
        output: convertJsonSchemaToVariable({
          jsonSchema: Output.JsonSchema,
          origin,
          variableKey: '',
          xTypeServiceCatalogResources,
        }).variables,
        input: convertJsonSchemaToVariable({
          jsonSchema: Input.JsonSchema,
          origin,
          variableKey: '',
          xTypeServiceCatalogResources,
        }).variables,
      };

      return serviceCatalogResourceMap;
    }

    if (isFormServiceCatalogResource(resource)) {
      if (!resource.definition.Interface) {
        return serviceCatalogResourceMap;
      }

      const { Output, Input } = resource.definition.Interface;

      if (!Output?.JsonSchema || !Input?.JsonSchema) {
        return serviceCatalogResourceMap;
      }

      const origin = { id: urn, label: name, kind: `vertice-${serviceCatalog}-form` } as const;

      serviceCatalogResourceMap[urn] = {
        output: convertJsonSchemaToVariable({
          jsonSchema: Output.JsonSchema,
          origin,
          variableKey: '',
          xTypeServiceCatalogResources,
        }).variables,
        input: convertJsonSchemaToVariable({
          jsonSchema: Input.JsonSchema,
          origin,
          variableKey: '',
          xTypeServiceCatalogResources,
        }).variables,
      };
    }

    if (isFunctionServiceCatalogResource(resource)) {
      if (!resource.definition.FunctionProvider.Interface) {
        return serviceCatalogResourceMap;
      }

      const { Input, Output } = resource.definition.FunctionProvider.Interface;

      if (!Input?.JsonSchema || !Output?.JsonSchema) {
        return serviceCatalogResourceMap;
      }

      const origin = { id: urn, label: name, kind: `vertice-${serviceCatalog}-udf` } as const;

      serviceCatalogResourceMap[urn] = {
        output: getVariableFromFunctionDefinition({
          udfServiceCatalogResource: resource,
          xTypeServiceCatalogResources,
          serviceCatalog,
        }).variables,
        input: convertJsonSchemaToVariable({
          jsonSchema: Input.JsonSchema,
          origin,
          variableKey: '',
          xTypeServiceCatalogResources,
        }).variables,
      };
    }
    return serviceCatalogResourceMap;
  }, {});
};

export const useServiceCatalogResources = (): ServiceCatalogResources => {
  const { accountId } = useAccountContext();
  const { data: accountServiceCatalogData } = useListServiceCatalogQuery({ accountId, includeAllVersions: true });
  const { data: globalServiceCatalogData } = useListServiceCatalogQuery({
    accountId: 'GLOBAL',
    includeAllVersions: true,
  });

  return useMemo(() => {
    if (!accountServiceCatalogData?.items || !globalServiceCatalogData?.items) {
      return {
        account: {},
        global: {},
        xTypeServiceCatalogResources: [],
      };
    }

    const xTypeServiceCatalogResources = [
      ...globalServiceCatalogData.items.filter(isXTypeServiceCatalogResource),
      ...accountServiceCatalogData.items.filter(isXTypeServiceCatalogResource),
    ];

    const account = getServiceCatalogResourceMapFromServiceCatalogResources({
      serviceCatalogResources: accountServiceCatalogData.items,
      xTypeServiceCatalogResources,
      serviceCatalog: 'account',
    });

    const global = getServiceCatalogResourceMapFromServiceCatalogResources({
      serviceCatalogResources: globalServiceCatalogData.items,
      xTypeServiceCatalogResources,
      serviceCatalog: 'global',
    });

    return {
      account,
      global,
      xTypeServiceCatalogResources,
    };
  }, [accountServiceCatalogData, globalServiceCatalogData]);
};
