import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, DatePicker, Select } from '@verticeone/design-system';
import type { FullOption, ValueEditorProps } from 'react-querybuilder';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { XTypeDatasourceAsyncSelect } from './XTypeDatasourceAsyncSelect';
import { NumberField } from './NumberField';
import { MoneyField } from './MoneyField/MoneyField';
import { ConditionalTooltip } from './ConditionalTooltip';
import { validationError } from '../utils';
import { xDatasourceParams } from '../../types';

const isValidXTypeUrn = (xTypeUrn: unknown): xTypeUrn is string => {
  return typeof xTypeUrn === 'string';
};

const isValidDataSourceParams = (params: unknown): params is xDatasourceParams => {
  return typeof params === 'object' && params !== null;
};

const isValidTargetCurrency = (targetCurrency: unknown): targetCurrency is string | undefined => {
  return typeof targetCurrency === 'string' || targetCurrency === undefined;
};
const isValidMoneyValue = (value: unknown): value is { var: string } | string => {
  return (
    typeof value === 'string' ||
    (typeof value === 'object' && value !== null && 'var' in value && typeof value.var === 'string')
  );
};

export const ValueEditor: FC<ValueEditorProps> = ({
  rule,
  value,
  handleOnChange,
  type,
  inputType,
  values: options,
  fieldData,
  disabled,
  validation,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES',
  });

  const testId = 'value-editor';
  const error = validationError(validation) && !rule.value;
  const isDisabled = disabled || !rule.field;

  if (type === 'select') {
    if (inputType === 'async-select' && isValidXTypeUrn(fieldData.xType)) {
      return (
        <XTypeDatasourceAsyncSelect
          testId={testId}
          xTypeUrn={fieldData.xType}
          onChange={handleOnChange}
          value={value}
          disabled={isDisabled}
          error={error}
          dataSourceParams={
            isValidDataSourceParams(fieldData.xDatasourceParams) ? fieldData.xDatasourceParams : undefined
          }
        />
      );
    }

    if (inputType === 'checkbox') {
      const selectedOption = options?.find((option) => option.value === String(value));
      return (
        <ConditionalTooltip value={selectedOption?.label ?? ''} offset={48}>
          <Select<FullOption<string>, false>
            variant="outlined"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            value={selectedOption ?? null}
            onChange={(newValue) => handleOnChange(newValue?.value === 'true')}
            options={options}
            testId={testId}
            menuPosition="fixed"
            isDisabled={isDisabled}
            placeholder={t('CHOOSE_VALUE')}
            error={error}
          />
        </ConditionalTooltip>
      );
    }

    if (inputType === 'money' && isValidTargetCurrency(fieldData.targetCurrency) && isValidMoneyValue(value)) {
      return (
        <MoneyField
          defaultTargetCurrency={fieldData.targetCurrency}
          testId={testId}
          value={typeof value === 'string' ? value : value.var}
          onChange={handleOnChange}
          isDisabled={isDisabled}
          hasError={error}
        />
      );
    }

    const selectedOption = options?.find((option) => option.value === value);

    return (
      <ConditionalTooltip value={selectedOption?.label ?? ''} offset={48}>
        <Select<FullOption<string>, false>
          variant="outlined"
          size="S"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          value={selectedOption ?? null}
          onChange={(newValue) => handleOnChange(newValue?.value)}
          options={options}
          testId={testId}
          menuPosition="fixed"
          isDisabled={isDisabled}
          placeholder={t('CHOOSE_VALUE')}
          error={error}
        />
      </ConditionalTooltip>
    );
  }

  if (inputType === 'date') {
    return (
      <DatePicker
        size="S"
        value={value}
        onChange={(date) => handleOnChange(date)}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        testId={testId}
        disabled={isDisabled}
        error={error}
      />
    );
  }

  if (inputType === 'number') {
    return (
      <NumberField
        testId={testId}
        value={value}
        onChange={handleOnChange}
        disabled={isDisabled}
        error={error}
        placeholder={t('INPUT_VALUE')}
      />
    );
  }

  return (
    <TextField
      variant="outlined"
      size="S"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      value={value}
      onChange={(event) => handleOnChange(event.target.value)}
      type={inputType ?? 'text'}
      testId={testId}
      disabled={isDisabled}
      error={error}
      placeholder={t('INPUT_VALUE')}
    />
  );
};
