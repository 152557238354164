import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JiraIcon } from '@vertice/assets';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { IconWrapper, Loader } from '@verticeone/design-system';
import { IntegrationBreadcrumbIcon } from 'pages/Preferences/Integrations/components/IconWrappers';
import { IntegrationSetup } from 'pages/Preferences/Integrations/components/IntegrationSetup';
import { DisconnectButton } from './components/DisconnectButton';
import { StepContent } from './components/StepContent';
import { JIRA_PROVIDER_ID } from './constants';
import { JiraSetupStep } from './types';

export const JiraSetup = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: jiraIntegration, isLoading: isLoadingJiraIntegrationData } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: JIRA_PROVIDER_ID,
  });

  const [step, setStep] = useState<JiraSetupStep>(JiraSetupStep.CONNECTION);
  const handleSetStep = (newStep: JiraSetupStep) => {
    setStep(newStep);
  };

  const activeStep = useMemo(() => {
    if (step === JiraSetupStep.CONNECTION && jiraIntegration?.status === 'ACTIVE') {
      return JiraSetupStep.SUCCESS;
    }
    return step;
  }, [jiraIntegration?.status, step]);

  if (isLoadingJiraIntegrationData) {
    return (
      <Box sx={{ height: '100%' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <IntegrationSetup
      breadcrumb={{
        label: t('PREFERENCES.INTEGRATIONS.JIRA.SETUP_CARD.TITLE'),
        icon: (
          <IntegrationBreadcrumbIcon>
            <IconWrapper icon={JiraIcon} />
          </IntegrationBreadcrumbIcon>
        ),
      }}
      actionButton={<DisconnectButton activeStep={activeStep} setStep={handleSetStep} />}
      stepContent={<StepContent activeStep={activeStep} setStep={handleSetStep} />}
    />
  );
};
