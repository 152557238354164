import { isGenericFormUrn } from '../../forms/EditUserTaskForm/utils';
import { parseFormUrn } from '../../forms/EditUserTaskForm/CustomForm';

import {
  useDescribeCatalogResourceCamelQuery,
  CatalogResourceCamelCase,
} from '@vertice/slices/src/api/enhancedCatalogAPI';
import { useMemo } from 'react';

type UseGenericFormDefinitionParams = {
  formUrn: string | null;
};

export const useCustomFormDefinition = (
  params: UseGenericFormDefinitionParams
): {
  resource: CatalogResourceCamelCase | undefined;
  isLoading: boolean;
} => {
  const { formUrn } = params;

  const isGenericForm = formUrn ? isGenericFormUrn(formUrn) : false;
  const { accountId, formPath } = formUrn ? parseFormUrn(formUrn) : { accountId: null, formPath: null };

  const { data: formDefinition, isLoading } = useDescribeCatalogResourceCamelQuery(
    { accountId: accountId ?? 'GLOBAL', resourceId: encodeURIComponent(formPath!) },
    { skip: !isGenericForm && !formUrn }
  );

  return useMemo(() => ({ resource: formDefinition?.resource, isLoading }), [formDefinition?.resource, isLoading]);
};
