import { fieldDef as moneyFieldDef } from './MoneyField';
import { fieldDef as dropdownFieldDef } from './DropdownField';
import { fieldDef as checkboxFieldDef } from './CheckboxField';
import { fieldDef as dateFieldDef } from './DateField';
import { fieldDef as lineItemsFieldDef } from './LineItemsField';
import { fieldDef as shortTextFieldDef } from './ShortTextField';
import { fieldDef as longTextFieldDef } from './LongTextField';
import { fieldDef as numberFieldDef } from './NumberField';
import { fieldDef as radioGroupFieldDef } from './RadioGroupField';
import { fieldDef as fileUploadFieldDef } from './FileUploadField';
import { keyBy, Dictionary } from 'lodash';
import { FieldDef } from './types';

export const fieldDefs = [
  checkboxFieldDef,
  dateFieldDef,
  shortTextFieldDef,
  lineItemsFieldDef,
  longTextFieldDef,
  moneyFieldDef,
  numberFieldDef,
  dropdownFieldDef,
  radioGroupFieldDef,
  fileUploadFieldDef,
];

export const fieldDefsByType: Dictionary<FieldDef<any>> = keyBy(fieldDefs, ({ type }) => type);
