import { workflowsV2ApiCodegen } from '../openapi/codegen/workflowsV2Api';
import { enhancedBffeWorkflowsAPI, WORKFLOW_BFFE_TASKS } from './enhancedBffeWorkflowsAPI';

const PREFIX = 'workflowsV2';
const WORKFLOW_VERSIONS = `${PREFIX}WorkflowVersions`;
const WORKFLOW_INSTANCES = `${PREFIX}WorkflowInstances`;
const WORKFLOW_TASKS_TAG = `${PREFIX}WorkflowTasks`;

type TagType = typeof WORKFLOW_VERSIONS | typeof WORKFLOW_INSTANCES | typeof WORKFLOW_TASKS_TAG;

export const enhancedWorkflowsV2API = workflowsV2ApiCodegen.enhanceEndpoints<TagType>({
  addTagTypes: [WORKFLOW_VERSIONS],
  endpoints: {
    listWorkflows: {
      providesTags: [WORKFLOW_VERSIONS],
    },
    activateWorkflowVersion: {
      invalidatesTags: [WORKFLOW_VERSIONS],
    },
    deployWorkflow: {
      invalidatesTags: [WORKFLOW_VERSIONS],
    },
    deployWorkflowVersion: {
      invalidatesTags: [WORKFLOW_VERSIONS],
    },
    listWorkflowInstances: {
      providesTags: [WORKFLOW_INSTANCES],
    },
    startWorkflowInstance: {
      invalidatesTags: [WORKFLOW_INSTANCES],
    },
    listAccountWorkflowInstances: {
      providesTags: [WORKFLOW_INSTANCES],
    },
    listUserTasks: {
      providesTags: [WORKFLOW_TASKS_TAG],
    },
    fulfillUserTask: {
      invalidatesTags: [WORKFLOW_TASKS_TAG],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        // it takes a while to update tasks in BFFE - this should be a temporary solution before we come with
        // a more robust one, perhaps by doing everything via BFFE
        setTimeout(() => {
          dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_TASKS]));
        }, 500);
      },
    },
    getTask: {
      providesTags: [WORKFLOW_TASKS_TAG],
    },
    draftUserTask: {
      invalidatesTags: [WORKFLOW_TASKS_TAG],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_TASKS]));
        }, 500);
      },
    },
    assignUserTask: {
      invalidatesTags: [WORKFLOW_TASKS_TAG],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_TASKS]));
        }, 500);
      },
    },
    deleteWorkflowVersion: {
      invalidatesTags: [WORKFLOW_VERSIONS],
    },
    listWorkflowVersions: {
      providesTags: [WORKFLOW_VERSIONS],
    },
  },
});
