import React, { useId } from 'react';
import { StatsBody, StatsContainer, StatsTile, Title, Value } from '@vertice/core/src/components/StatsBar';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';
import { useOverviewStatsData } from '../useOverviewStatsData';

const InFlightSpendStatsContainer = () => {
  const id = useId();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.OVERVIEW.STATS' });
  const { data, isLoading } = useOverviewStatsData();

  return (
    <StatsContainer flex={1}>
      <StatsTile>
        <StatsBody
          isLoading={isLoading}
          title={<Title id={id}>{t('IN_FLIGHT_SPEND')}</Title>}
          value={
            <Value aria-labelledby={id}>
              {formatCurrency(data.inFlightSpend, {
                maximumFractionDigits: 0,
                currency: 'USD',
              })}
            </Value>
          }
        />
      </StatsTile>
    </StatsContainer>
  );
};

export default InFlightSpendStatsContainer;
