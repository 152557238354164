import { Stack } from '@mui/material';
import { useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField, TextFieldArea, TextFieldCaption } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { EditorDrawerGroup } from '../../EditorDrawer';
import { EditUserTaskFormData } from './schema';

export const TaskProperties = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK' });
  const id = useId();

  const {
    register,
    formState: { errors },
  } = useFormContext<EditUserTaskFormData>();

  return (
    <EditorDrawerGroup title={t('TITLES.TASK_PROPERTIES')}>
      <Stack gap={1}>
        <TextFieldCaption required label={t('NAME')} size="XS" htmlFor={`${id}-name`} />
        <TextField
          {...register('name')}
          id={`${id}-name`}
          variant="outlined"
          size="S"
          fullWidth
          autoComplete="off"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          error={!!errors.name}
        />
      </Stack>
      <Stack gap={1}>
        <TextFieldCaption label={t('DESCRIPTION')} size="XS" htmlFor={`${id}-description`} />
        <TextFieldArea
          {...register('description')}
          id={`${id}-description`}
          variant="outline"
          size="S"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          autoComplete="off"
          maxRows={6}
          style={{ resize: 'none' }}
        />
      </Stack>
    </EditorDrawerGroup>
  );
};
