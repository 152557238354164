import { z, ZodObject, ZodRawShape } from 'zod';
import { MoneyFieldMetadata, MoneyFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';

export const getMoneyPresenterSchema = (field: MoneyFieldType): ZodObject<ZodRawShape> => {
  const amountSchema = z.number();
  return z.object({
    [field.name]: z
      .object({
        amount: field.metadata.required ? amountSchema : amountSchema.nullable(),
        currency: z.string().min(3).max(3),
      })
      .transform((money) => (money.amount !== null ? money : null))
      .nullable(),
  });
};

export const getMoneyPresenterDefault = ({
  field,
  config,
  defaultValues,
}: GetPresenterDefaultProps<MoneyFieldType>) => {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: { amount: null, currency: config.defaultCurrency } };
};

export const getMoneyJsonSchemaFromMetadata = (metadata: MoneyFieldMetadata): JsonSchema => {
  return {
    type: ['object', 'null'],
    'x-type': 'urn:verticeone:vertice::services:schema/core/money/v1',
    title: metadata.label,
  };
};

export const getMoneyJsonSchema = (field: MoneyFieldType) => ({
  required: [field.name],
  properties: { [field.name]: getMoneyJsonSchemaFromMetadata(field.metadata) },
});
