import { useCallback } from 'react';
import { useLoggedUser } from '@verticeone/auth/src';
import { statusMap, TaskRow } from './utils';
import { FilterStatus } from './queryParams';

export const useGetFilteredTasksAssignedToUser = () => {
  const { userId: loggedUserId } = useLoggedUser();

  return useCallback(
    (onlyMyTasks = true) =>
      (task: TaskRow) => {
        if (onlyMyTasks) {
          return task.assignees.some(({ id }) => id === loggedUserId);
        }

        return true;
      },
    [loggedUserId]
  );
};

export const useGetFilteredTasksBySearch = () => {
  const fuzzySearch = (query: string, text?: string) => {
    const words = query.split(/\s+/).filter(Boolean);
    const regex = new RegExp(words.map((word) => `(?=.*${word})`).join(''), 'i');

    if (text) {
      return regex.test(text);
    }

    return true;
  };

  return useCallback(
    (searchValue: string) => (task: TaskRow) => {
      if (searchValue === '') {
        return true;
      }

      return [task.name, task.requestName, ...task.assignees.map((user) => user.fullName)].some((field) =>
        fuzzySearch(searchValue.toLowerCase(), field?.toLowerCase())
      );
    },
    []
  );
};

export const useGetFilteredTasksByFilter = () => {
  return useCallback(
    (filter: FilterStatus) => (task: TaskRow) => {
      if (filter === 'ALL') {
        return true;
      }

      return statusMap[filter].includes(task.status);
    },
    []
  );
};
