import { useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { BreadcrumbsV2, Loader, Tab, Tabs, TabsBottomLineWrapper } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/src';

import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { toConstantCase } from '@verticeone/utils/strings';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

const getTabLabelKey = (tabId: string) => `PREFERENCES.${toConstantCase(tabId)}.TAB`;

export const Preferences = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { PREFERENCES } = useRoutes();
  const { isEnabled: isFeatureEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const someIntegrationEnabled =
    !isFeaturesLoading &&
    (isFeatureEnabled(FEATURES.SSO) ||
      isFeatureEnabled(FEATURES.USAGE_ANALYTICS) ||
      isFeatureEnabled(FEATURES.INTEGRATIONS));

  const customizationFeatureEnabled = !isFeaturesLoading && isFeatureEnabled(FEATURES.CONTRACTS_CUSTOMIZATION);

  const availableTabs = useMemo(
    () =>
      [
        {
          key: PREFERENCES.COMPANY.PATH,
          path: PREFERENCES.COMPANY.ABSOLUTE_PATH,
          isVisible: isUserAdmin,
        },
        {
          key: PREFERENCES.DIRECTORY.PATH,
          path: PREFERENCES.DIRECTORY.ABSOLUTE_PATH,
          isVisible: isUserAdmin,
        },
        {
          key: PREFERENCES.INTEGRATIONS.PATH,
          path: PREFERENCES.INTEGRATIONS.ABSOLUTE_PATH,
          isVisible: isUserAdmin && someIntegrationEnabled,
        },
        {
          key: PREFERENCES.WORKFLOWS.PATH,
          path: PREFERENCES.WORKFLOWS.ABSOLUTE_PATH,
          isVisible: isUserAdmin,
        },
        {
          key: PREFERENCES.USER.PATH,
          path: PREFERENCES.USER.ABSOLUTE_PATH,
          isVisible: true,
        },
        {
          key: PREFERENCES.CUSTOMIZATION.PATH,
          path: PREFERENCES.CUSTOMIZATION.ABSOLUTE_PATH,
          isVisible: isUserAdmin && customizationFeatureEnabled,
        },
      ].filter(({ isVisible }) => isVisible),
    [PREFERENCES, customizationFeatureEnabled, isUserAdmin, someIntegrationEnabled]
  );

  const activeTab = availableTabs.find(({ path }) => matchPath(`${path}/*`, location.pathname));

  useEffect(() => {
    if (!activeTab && availableTabs.length > 0) {
      navigate(availableTabs[0].path, { replace: true });
    }
  }, [activeTab, navigate, availableTabs, PREFERENCES]);

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <BreadcrumbsV2 items={[{ label: t('PREFERENCES.TITLE') }]} />
      </Stack>
      {activeTab ? (
        <>
          {availableTabs.length > 1 && (
            <TabsBottomLineWrapper paddingX={10}>
              <Tabs variant="outlined" value={activeTab.path} onChange={(_, value) => navigate(value)}>
                {availableTabs.map(({ path, key }) => (
                  <Tab key={path} value={path} label={t(getTabLabelKey(key))} />
                ))}
              </Tabs>
            </TabsBottomLineWrapper>
          )}
          <Outlet />
        </>
      ) : (
        <Loader />
      )}
    </Stack>
  );
};
