import { ChangeEvent, FC } from 'react';
import { FieldCard } from '../../DynamicFormBuilder';
import { NumberFieldMetadata, NumberFieldType } from './types';

export type NumberFieldBuilderCardProps = {
  field: NumberFieldType;
  onRemove?: () => void;
  onEdit?: () => void;
  onChange?: (metadata: NumberFieldMetadata) => void;
};

export const NumberFieldBuilderCard: FC<NumberFieldBuilderCardProps> = (props) => {
  const { field, onRemove, onEdit, onChange } = props;

  const handleRequiredChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) onChange({ ...field.metadata, required: checked });
  };

  return (
    <FieldCard>
      <FieldCard.Label fieldType={field.type} required={field.metadata.required}>
        {field.metadata.label}
      </FieldCard.Label>
      <FieldCard.Actions>
        <FieldCard.RequiredSwitchAction checked={field.metadata.required} onChange={handleRequiredChange} />
        <FieldCard.EditAction onClick={onEdit} />
        <FieldCard.RemoveAction onClick={onRemove} />
      </FieldCard.Actions>
    </FieldCard>
  );
};
