import React, { BaseSyntheticEvent, useCallback } from 'react';
import { Stack } from '@mui/material';
import { Control, FieldPath, FieldValues, UseFormRegister, useFormState } from 'react-hook-form';
import { TextField, TextFieldCaption, Text } from '@verticeone/design-system';
import FormControl from './FormControl';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

export const PROTOCOL = 'https';

type HostnameFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  control: Control<T>;
};

const HostnameField = <T extends FieldValues>({ name, register, setValue, control }: HostnameFieldProps<T>) => {
  const { errors } = useFormState({ control, name });
  const removeProtocol = useCallback(
    (e: BaseSyntheticEvent) => {
      void setValue(
        name,
        e.target.value.startsWith(PROTOCOL)
          ? e.target.value.trim().replace(new RegExp(`^${PROTOCOL}:\\/\\/`), '')
          : e.target.value.trim()
      );
    },
    [setValue, name]
  );

  return (
    <FormControl>
      <Stack>
        <TextFieldCaption label={'Host Name'} htmlFor="host-name" size="XS" required />
        <Text variant="body-regular" size="XS" color="text3">
          {'The base URL for all requests sent to this integration'}
        </Text>
      </Stack>
      <TextField
        id="host-name"
        size="S"
        error={!!errors}
        startAdornment={
          <Stack pr={2}>
            <Stack py={1.5} borderRight="1px solid" borderColor="core.color3">
              <Text variant="body-regular" size="S" color="inactive1" height="100%" pr={3.5}>
                {PROTOCOL}://
              </Text>
            </Stack>
          </Stack>
        }
        {...register(name, { onChange: removeProtocol })}
      />
    </FormControl>
  );
};

export default HostnameField;
