import { useMemo } from 'react';
import { EditUserTaskFormData } from '../../forms/EditUserTaskForm/schema';
import { TaskDefinition } from '../../../../definitionsTypes';
import {
  getIOMappingConfigurations,
  getTaskAssignmentConfigurations,
  getTaskFormConfiguration,
} from '../../../../definitions/taskDefinition';
import {
  getConditionalAssigneesFromAssignmentConfigurations,
  getDefaultAssigneesFromAssignmentConfigurations,
} from '../../forms/EditUserTaskForm/assignmentOptions';
import { getObjectFromJMESPathExpression } from '../../hooks/utils';
import { isApprovalFormUrn } from '../../forms/EditUserTaskForm/utils';
import { CatalogResourceCamelCase, FormDefinitionCamelCase } from '@vertice/slices/src/api/enhancedCatalogAPI';
import { isFormCatalogResource } from '../../../../utils/catalogResourceUtils';
import { RESERVED_VARIABLES } from './useTaskFormSubmit';

const TASK_FORM_DEFAULT_VALUES: EditUserTaskFormData = {
  name: '',
  description: '',
  isSimpleApprovalForm: false,
  buttonLabels: undefined,
  formHeading: undefined,
  defaultAssignees: [],
  conditionalAssignees: [],
  customFormFields: undefined,
  customFormVariables: undefined,
};

export type UseTaskFormDefaultValuesProps = {
  task?: TaskDefinition;
  customForm?: CatalogResourceCamelCase;
};

export const useTaskFormDefaultValues = (props: UseTaskFormDefaultValuesProps) => {
  const { task, customForm } = props;

  const values = useMemo<EditUserTaskFormData>(() => {
    let defaultValues = TASK_FORM_DEFAULT_VALUES;

    if (!task) return defaultValues;

    defaultValues = attachTaskProperties(defaultValues, task);
    defaultValues = attachTaskAssignees(defaultValues, task);
    defaultValues = attachApprovalTaskConfig(defaultValues, task);
    defaultValues = attachCustomFormConfig(defaultValues, task, customForm?.definition);

    return defaultValues;
  }, [task, customForm]);

  return { values };
};

const attachTaskProperties = (baseDefaults: EditUserTaskFormData, task: TaskDefinition): EditUserTaskFormData => {
  return {
    ...baseDefaults,
    name: task.task.name ?? '',
    description: task.task.description ?? '',
  };
};

const attachTaskAssignees = (baseDefaults: EditUserTaskFormData, task: TaskDefinition): EditUserTaskFormData => {
  const assignmentConfigurations = getTaskAssignmentConfigurations(task);

  const defaultAssignees = getDefaultAssigneesFromAssignmentConfigurations(assignmentConfigurations);
  const conditionalAssignees = getConditionalAssigneesFromAssignmentConfigurations(assignmentConfigurations);

  return { ...baseDefaults, defaultAssignees, conditionalAssignees };
};

const attachApprovalTaskConfig = (baseDefaults: EditUserTaskFormData, task: TaskDefinition): EditUserTaskFormData => {
  const form = getTaskFormConfiguration(task);
  const isApproval = form && isApprovalFormUrn(form.formUrn);

  if (!isApproval) return baseDefaults;

  let defaultValues: EditUserTaskFormData = { ...baseDefaults, isSimpleApprovalForm: true };
  const ioMapping = getIOMappingConfigurations(task);

  if (!ioMapping) return defaultValues;

  const buttonLabelMapping = ioMapping.mapping.inputFields.find((field) => field.name === 'buttonLabels')?.value;
  const buttonLabels = getObjectFromJMESPathExpression<EditUserTaskFormData['buttonLabels']>(buttonLabelMapping);

  const formHeadingMapping = ioMapping.mapping.inputFields.find((field) => field.name === 'formHeading')?.value;
  const formHeading = getObjectFromJMESPathExpression<EditUserTaskFormData['formHeading']>(formHeadingMapping);

  return { ...defaultValues, buttonLabels: buttonLabels ?? undefined, formHeading: formHeading ?? undefined };
};

const attachCustomFormConfig = (
  baseDefaults: EditUserTaskFormData,
  task: TaskDefinition,
  form?: FormDefinitionCamelCase
): EditUserTaskFormData => {
  if (!form || !isFormCatalogResource(form)) return baseDefaults;

  let customFormVariables = {};
  const customFormFields = form?.form?.formUiModel?.layout?.fields ?? [];
  const ioMapping = getIOMappingConfigurations(task);

  if (ioMapping) {
    customFormVariables = ioMapping.mapping.outputFields
      .filter((outField) => !RESERVED_VARIABLES.includes(outField.name))
      .filter((outField) => outField.mappingType === 'JMESPathExpression')
      .reduce((prev, curr) => ({ ...prev, [curr.value]: curr.name }), {});
  }

  return { ...baseDefaults, customFormFields, customFormVariables };
};
