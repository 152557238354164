import { FieldDef } from '../types';
import { LongTextFieldBuilderCard } from './LongTextFieldBuilderCard';
import { LongTextFieldBuilderDialog } from './LongTextFieldBuilderDialog';
import { LongTextFieldPresenter } from './LongTextFieldPresenter';
import { LongTextFieldType } from './types';
import {
  getLongTextJsonSchema,
  getLongTextJsonSchemaFromMetadata,
  getLongTextPresenterDefault,
  getLongTextPresenterSchema,
} from './utils';

export type { LongTextFieldType, LongTextFieldMetadata } from './types';
export { longTextFieldMetadataSchema, longTextFieldSchema } from './types';

export const fieldDef: FieldDef<LongTextFieldType> = {
  type: 'LONG_TEXT',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.LONG_TEXT',
  builderCard: LongTextFieldBuilderCard,
  builderDialog: LongTextFieldBuilderDialog,
  presenter: LongTextFieldPresenter,
  getPresenterDefault: getLongTextPresenterDefault,
  getPresenterSchema: getLongTextPresenterSchema,
  getJsonSchemaFromMetadata: getLongTextJsonSchemaFromMetadata,
  getJsonSchema: getLongTextJsonSchema,
};
