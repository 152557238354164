import { JsonSchemaType, JsonSchema, XTypeServiceCatalogResource } from '../WorkflowViewer/types';
import { match, P } from 'ts-pattern';
import { capitalizeFirstLetter } from '../WorkflowViewer/utils';

export type GetVariableTypeLabelsConfig = {
  type?: JsonSchema['type'];
  enum?: JsonSchema['enum'];
  format?: JsonSchema['format'];
  xTypeResource?: XTypeServiceCatalogResource;
};

/**
 * Derives a list of labels for a given variable type based on its configuration.
 *
 * @param config - Configuration object that defines the type and attributes of the variable.
 * @returns An array of human-readable labels describing the variable type.
 *
 * ### Behavior:
 *
 * Generates labels in priority: xTypeResource > format > enumValues > primitive variable type
 */
export const getVariableTypeLabels = (config: GetVariableTypeLabelsConfig): string[] => {
  const primType = getVariablePrimitiveTypes({ type: config.type });
  const { xTypeResource, enum: enumValues, format } = config;

  if (xTypeResource) {
    return xTypeResource.category ? [xTypeResource.category] : [xTypeResource.definition.Name];
  }

  return (
    match({ primType, format, enumValues })
      /* if format is defined, derive type from format value */
      .with({ primType: P._, enumValues: P._, format: P.select(P.nonNullable) }, (f) => [capitalizeFirstLetter(f)])

      /* if in enum values contains only True value, reflect this as True */
      .with({ primType: P._, enumValues: [true] }, () => ['True'])

      /* if enum values contains anything else, reflect this as Dropdown */
      .with({ primType: P._, enumValues: P.array() }, () => ['Dropdown'])

      /* if primitive types are only field defined, map this to user type */
      .with({ primType: P.select(P.nonNullable) }, (t) => t.map(toPrimiteTypeLabel))

      /* any non matching value is marked as unknown  */
      .otherwise(() => ['Unknown'])
  );
};

const toPrimiteTypeLabel = (type: JsonSchemaType) => {
  return match(type)
    .with('string', () => 'Text')
    .with('boolean', () => 'True/False')
    .with(P._, (t) => capitalizeFirstLetter(t))
    .exhaustive();
};

const getVariablePrimitiveTypes = (jsonSchema: JsonSchema): JsonSchemaType[] => {
  if (!jsonSchema.type) return [];
  return Array.isArray(jsonSchema.type) ? jsonSchema.type : [jsonSchema.type];
};
