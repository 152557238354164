import React from 'react';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';

import FormSectionTitle from './components/FormSection';
import HostnameField, { PROTOCOL } from './components/HostnameField';
import IntegrationNameField from './components/IntegrationNameField';
import ApiKeyField from './components/ApiKeyField';
import TestConnectionField from './components/TestConnectionField';
import TestConnectionButton from './components/TestConnectionButton';
import FormSection from './components/FormSection';
import SaveIntegrationButton from './components/SaveIntegrationButton';
import TestConnectionResponse from './components/TestConnectionResponse';
import useTestConnection from './useTestConnection';

const hasProtocol = (url: string) => !/^[a-zA-Z][a-zA-Z0-9+.-]*:\/\//.test(url);

const schema = z.object({
  name: z.string().min(1),
  auth: z.object({
    apiKey: z.string().min(1),
    baseUrl: z.object({
      protocol: z.literal(PROTOCOL),
      hostname: z.string().min(1).refine(hasProtocol, { message: 'protocolError' }),
      path: z.string(),
    }),
  }),
});

export type Schema = z.infer<typeof schema>;

export type ApiKeyFormData = {
  name: string;
  auth: {
    apiKey: string;
    url: string;
  };
};

type FormProps = {
  data?: ApiKeyFormData;
  onSubmit: (data: ApiKeyFormData) => void;
};

const serialize = (data: Schema): ApiKeyFormData => ({
  name: data.name,
  auth: {
    apiKey: data.auth.apiKey,
    url: `${data.auth.baseUrl.protocol}://${data.auth.baseUrl.hostname}`,
  },
});

const ApiKeyForm = ({ data, onSubmit }: FormProps) => {
  const { register, formState, handleSubmit, setValue, getValues, control } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: data?.name ?? '',
      auth: {
        apiKey: data?.auth.apiKey ?? '',
        baseUrl: {
          protocol: PROTOCOL,
          hostname: data?.auth.url.replace(`${PROTOCOL}://`, '') ?? '',
          path: '',
        },
      },
    },
  });

  const [testConnection, testConnectionState] = useTestConnection({
    serialize: () => ({
      protocol: getValues('auth.baseUrl.protocol'),
      hostname: getValues('auth.baseUrl.hostname'),
      path: getValues('auth.baseUrl.path'),
      auth: {
        authType: 'apiKey',
        apiKey: getValues('auth.apiKey'),
      },
    }),
  });

  return (
    <form onSubmit={handleSubmit((submittedData) => onSubmit(serialize(submittedData)))}>
      <Stack direction="column" gap={6}>
        <IntegrationNameField name="name" register={register} control={control} />
        <ApiKeyField name="auth.apiKey" register={register} control={control} />

        <FormSection title={'Connection details'}>
          <HostnameField name="auth.baseUrl.hostname" register={register} setValue={setValue} control={control} />
          <Stack direction="row" alignItems="flex-end" gap={2}>
            <TestConnectionField
              name="auth.baseUrl.path"
              protocolPath="auth.baseUrl.protocol"
              hostnamePath="auth.baseUrl.hostname"
              register={register}
              control={control}
            />
            <TestConnectionButton
              control={control}
              validateFields={['auth.baseUrl', 'auth.apiKey']}
              onClick={testConnection}
              isLoading={testConnectionState.isLoading}
            />
          </Stack>
        </FormSection>

        <FormSectionTitle title={'test connection response'}>
          <TestConnectionResponse {...testConnectionState} />
          <SaveIntegrationButton isFormValid={formState.isValid} />
        </FormSectionTitle>
      </Stack>
    </form>
  );
};

export default ApiKeyForm;
