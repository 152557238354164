import { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';

type SectionWrapperProps = {
  isVisible?: boolean;
} & PropsWithChildren;

const StyledBox = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(10)}`,
  '&:not(:last-child)': {
    paddingBottom: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.core.color3}`,
  },
}));

export const SectionWrapper = ({ children, isVisible = true }: SectionWrapperProps) => {
  if (!isVisible) {
    return null;
  }

  return <StyledBox>{children}</StyledBox>;
};
