import { FC } from 'react';
import { type FullOperator, OperatorSelectorProps, toFlatOptionArray } from 'react-querybuilder';
import { Select } from '@verticeone/design-system';

import { ConditionalTooltip } from './ConditionalTooltip';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { validationError } from '../utils';
import { useTranslation } from 'react-i18next';

export const OperatorSelector: FC<OperatorSelectorProps> = ({
  rule,
  handleOnChange,
  options,
  value,
  disabled,
  validation,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES',
  });
  const operatorOptions = toFlatOptionArray(options);
  const selectedOperator = operatorOptions?.find((operator) => operator.value === value);

  const error = validationError(validation) && !rule.operator;
  const isDisabled = disabled || !rule.field;

  return (
    <ConditionalTooltip value={selectedOperator?.label ?? ''} offset={48}>
      <Select<FullOperator<string>, false>
        variant="outlined"
        size="S"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        value={selectedOperator ?? null}
        onChange={(newOperator) => handleOnChange(newOperator?.value)}
        options={operatorOptions}
        testId="operator-selector"
        menuPosition="fixed"
        isDisabled={isDisabled}
        placeholder={t('CHOOSE_OPERATOR')}
        error={error}
      />
    </ConditionalTooltip>
  );
};
