import { useTranslation } from 'react-i18next';

import { useRoutes } from '@verticeone/router/src';
import { EntraIcon } from '@vertice/assets';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type EntraSetupCardProps = {
  providerId: string;
};

export const EntraSetupCard = ({ providerId }: EntraSetupCardProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="Microsoft Entra ID"
      providerIcon={<EntraIcon />}
      description={t('PREFERENCES.INTEGRATIONS.ENTRA.DESCRIPTION')}
      wizardRoute={routes.PREFERENCES.INTEGRATIONS.USAGE.ENTRA.ABSOLUTE_PATH}
    />
  );
};
