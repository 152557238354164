import { Box, styled, Theme } from '@mui/material';
import { FC } from 'react';

import { Text } from '@verticeone/design-system';
import { Variable } from '../../types';

const LabelWrapper = styled(Text)<{ $theme: Theme }>(({ $theme }) => ({
  display: 'inline-flex',
  gap: $theme.spacing(0.5),
  alignItems: 'center',
  backgroundColor: $theme.palette.secondary.color4,
  borderRadius: $theme.spacing(0.5),
  padding: '2px 4px',
  color: $theme.palette.secondary.color1,
}));

const TypeWrapper = styled(Text)<{ $theme: Theme }>(({ $theme }) => ({
  backgroundColor: $theme.palette.secondary.color3,
  borderRadius: $theme.spacing(0.5),
  padding: '2px 4px',
}));

type VariableTokenProps = {
  theme: Theme;
  variable: Variable;
};

export const VariableToken: FC<VariableTokenProps> = ({ theme, variable }) => {
  return (
    <>
      <Box component="span" display="none" className="variable-id">{`{{${variable.id}}}`}</Box>
      <LabelWrapper variant="button-bold" size="S" contentEditable={false} $theme={theme} className="variable-label">
        {variable.label}
        {variable.type.labels !== undefined && (
          <TypeWrapper variant="button-bold" size="XXS" $theme={theme} className="variable-type">
            {variable.type.labels[0]}
          </TypeWrapper>
        )}
      </LabelWrapper>
    </>
  );
};
