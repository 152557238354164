import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Button, testProps } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { IconButton } from '@verticeone/design-system';

import { TaskIcon } from '../../sharedVisualStyle/taskStyle';
import { resolveTaskNodeThumbnailConfiguration } from '../../workflowSchema/model/resolveTaskNodeThumbnailConfiguration';
import type { TaskRow } from '../dataSource';
import { CommentsIconWithBadge } from './icons/CommentIcon';

type TaskDrawerHeaderProps = {
  taskRow: TaskRow;
  userId: string;
  onClose: () => void;
  toggleComments?: () => void;
  isCommentsOpen?: boolean;
  unreadCount: number | undefined;
};

export const TaskDrawerHeader = ({
  taskRow,
  userId,
  isCommentsOpen,
  unreadCount,
  onClose,
  toggleComments,
}: TaskDrawerHeaderProps) => {
  const thumbnail = useMemo(() => resolveTaskNodeThumbnailConfiguration(taskRow?.configurations!), [taskRow]);

  return (
    <Stack gap={2} padding={6} direction="row" justifyContent="space-between" {...testProps('taskDrawerHeading')}>
      <Stack direction="row" alignItems="center" gap={2} height="100%">
        <TaskIcon
          userId={userId}
          status={taskRow.status}
          type={thumbnail.type}
          id={thumbnail.id}
          size="M"
          assigneeIds={taskRow.assignees.map((a) => a.id)}
        />
        <Text variant="heading" size="M" color="text1" {...testProps('taskName')}>
          {taskRow.name}
        </Text>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        {isCommentsOpen !== undefined && toggleComments && (
          <Button
            variant="plain"
            size="XS"
            color="secondary"
            endIcon={<CommentsIconWithBadge unreadCount={unreadCount} />}
            onClick={toggleComments}
          >
            {isCommentsOpen ? 'Hide comments' : 'Show comments'}
          </Button>
        )}
        <IconButton size="M" variant="ghost" icon={CloseOutlined} onClick={onClose} />
      </Stack>
    </Stack>
  );
};
