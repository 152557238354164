import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useGetAccountIntegrationsQuery } from '@vertice/slices';

export type ApiKeyAuth = {
  type: 'API';
  baseUrl: string;
};

export type OAUTH = {
  type: 'OAUTH';
};

export type IntegrationItem = {
  id: string;
  name: string;
  enabled: boolean;
  auth: ApiKeyAuth | OAUTH;
};

type UseCustomIntegrationDataProps = {
  id?: string;
};

export const useCustomIntegrationsData = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { accountId } = useAccountContext();

  return {
    data: outbound,
    isFetching: false,
  };

  /*return useGetAccountIntegrationsQuery({
    accountId: accountId,
  }, {
    selectFromResult: (result) => ({
      ...result,
      data: result?.data?.map<IntegrationItem>((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [provider, category, authType, name] = item.id.split(':');

        return ({
          id: item.id,
          name,
          enabled: item.enabled,
          auth: {
            ...item.parameters,
            type: authType as (ApiKeyAuth | OAUTH)['type'],
          } as ApiKeyAuth | OAUTH,
        });
      }) ?? [],
    }),
  });*/
};

export const useCustomIntegrationData = ({ id }: UseCustomIntegrationDataProps) => {
  const { data, ...otherProps } = useCustomIntegrationsData();

  return {
    ...otherProps,
    data: data.find((item) => item.id === id),
  };
};

export const outbound: Array<IntegrationItem> = [
  {
    id: 'acme_connect',
    name: 'Acme Connect',
    enabled: true,
    auth: {
      baseUrl: 'https://api.acme.com',
      type: 'API',
    },
  },
  {
    id: 'gain_sight',
    name: 'GainSight',
    enabled: true,
    auth: {
      baseUrl: 'https://service.gainsight.com',
      type: 'API',
    },
  },
];

export const inbound: Array<IntegrationItem> = [
  {
    id: 'sales_force',
    name: 'SalesForce',
    enabled: true,
    auth: {
      baseUrl: 'https://api.salesforce.com',
      type: 'API',
    },
  },
];
