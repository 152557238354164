import React, { createContext, useContext, useState, ReactNode } from 'react';
import { OfferingData } from '../../../../types';

type TempDataContextType = {
  getTempData: (key: string) => OfferingData | undefined;
  setTempData: (data: OfferingData[]) => void;
};

const TempDataContext = createContext<TempDataContextType | undefined>(undefined);

export const TempDataProvider = ({ children }: { children: ReactNode }) => {
  const [tempData, setTempDataState] = useState<OfferingData[]>([]);

  const setTempData = (data: OfferingData[]) => {
    setTempDataState(data);
  };

  const getTempData = (key: string) => {
    return tempData.find((item) => item.offeringId === key);
  };

  return <TempDataContext.Provider value={{ setTempData, getTempData }}>{children}</TempDataContext.Provider>;
};

export const useTempData = (): TempDataContextType => {
  const context = useContext(TempDataContext);
  if (!context) {
    throw new Error('useTempData must be used within a TempDataProvider');
  }
  return context;
};
