import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export type Task = {
  id: string;
  title: string;
  vendorId: string;
  vendorName: string;
  type: string;
  date: string;
  status: string;
};

type MyRequestsData = {
  tasks: Task[];
  count: number;
  due_in_3_days: string;
  due_in_10_days: string;
};

export const useMyTasksData = () => {
  const tempData = {
    name: 'my_tasks',
    stats: {
      count: 12,
      due_in_3_days: '3 due in 3 days',
      due_in_10_days: '9 due in 10 days',
    },
    details: {
      columns: ['id', 'title', 'vendor_id', 'vendor_name', 'type', 'date', 'status'],
      types: ['string', 'string', 'string', 'string', 'string', 'isotime', 'string'],
      data: [
        [
          '1',
          'Security Review',
          'c8cfa123-2c7c-56d5-9086-25d94246d3ec',
          'NetSuite',
          'New Purchase',
          '2024-12-18',
          'todo',
        ],
        ['2', 'Legal Review', 'c8cfa123-2c7c-56d5-9086-25d94246d3ec', 'NetSuite', 'New Purchase', '2024-12-19', 'todo'],
        ['3', 'Commercial Review', '61a63d7c-fb29-5829-be2e-e9dadebedade', 'Adobe', 'Renewal', '2024-12-20', 'todo'],
        [
          '4',
          'Requirement Gathering',
          '6d15aedf-8393-5462-97b7-8b0c176804ed',
          'Postmark',
          'Renewal',
          '2024-12-25',
          'done',
        ],
        ['5', 'IT Review', '0079f31a-400f-5c0b-8b12-fc86c5177e46', 'HubSpot', 'Renewal', '2024-12-30', 'todo'],
      ],
    },
  };

  const data: MyRequestsData = {
    tasks: getTableData(tempData.details, {
      vendor_id: 'vendorId',
      vendor_name: 'vendorName',
      type: 'type',
      title: 'title',
      date: 'date',
      status: 'status',
    }) as Task[],
    count: tempData.stats.count,
    due_in_3_days: tempData.stats.due_in_3_days,
    due_in_10_days: tempData.stats.due_in_10_days,
  };

  return {
    data,
    isLoading: false,
  };
};
