import { Stack, SxProps } from '@mui/material';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { AppTypeContext } from '../../../../../../../contexts/AppTypeContext';

type MissingSetupProps = {
  title: string;
  description: string;
  sx?: SxProps;
};

export const MissingSetup: FC<MissingSetupProps> = ({ title, description, sx = {} }) => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { isIAT } = useContext(AppTypeContext);

  const navigateToSetup = () => {
    navigate(routes.PREFERENCES.INTEGRATIONS.WORKFLOWS.JIRA);
  };

  return (
    <Stack gap={10} alignItems="start" sx={sx}>
      <Stack gap={2}>
        <Text variant="body-bold" size="S">
          {title}
        </Text>
        <Text variant="body-regular" size="XS" color="neutral2">
          {description}
        </Text>
      </Stack>
      {!isIAT && (
        <Button variant="solid" color="neutral" onClick={navigateToSetup}>
          {t('INTELLIGENT_WORKFLOWS.INTEGRATION.GO_TO_SETUP')}
        </Button>
      )}
    </Stack>
  );
};
