import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system';
import { Dialog, DialogActions, DialogContent, DialogHeader } from '@verticeone/design-system';
import * as z from 'zod';
import { GoodFormOnSubmitCallback, useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import { FormSchemaProvider } from '@vertice/core/src/modules/forms/schema/FormSchemaContext';
import FormTextArea from '@vertice/core/src/modules/forms/fields/FormTextArea';
import { FormMoneyField } from '@vertice/core/src/modules/forms/fields/FormMoneyField';

type ContractDialogProps = {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onSuccess: (data: PricingDataRequestFormData) => void;
};

const pricingDataRequestFormDataSchema = z.object({
  reasonQuestion: z.string().min(1),
  provideDetailQuestion: z.string().min(1),
  focusAreaQuestion: z.string().min(1),
  budget: z
    .object({
      amount: z.number().nullish(),
      currency: z.string().nullish(),
    })
    .nullish(),
});

export type PricingDataRequestFormData = z.infer<typeof pricingDataRequestFormDataSchema>;
const PricingDataRequestFormEntry = createTypedFormEntry<PricingDataRequestFormData>();

// We keep this as a separate component, because it subscribes to the whole formState and we don't want the root
// useForm to subscribe to it (it would cause re-renders of the whole form)
const SubmitButton = () => {
  const { t } = useTranslation();
  const formState = useFormState<PricingDataRequestFormData>();
  const submitButtonProps = getPropsForSubmitButton(formState);
  return (
    <Button
      type="submit"
      color="primary"
      variant="solid"
      isLoading={submitButtonProps.isLoading}
      disabled={submitButtonProps.disabled}
      size="M"
    >
      {t('VENDOR.BENCHMARK_REQUEST_DIALOG.BUTTONS.REQUEST_DATA')}
    </Button>
  );
};

const PricingDataRequestDialog = ({ open, onClose, onSuccess, disabled }: ContractDialogProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'VENDOR.BENCHMARK_REQUEST_DIALOG' });
  const formMethods = useForm<PricingDataRequestFormData>({
    defaultValues: {
      reasonQuestion: '',
      provideDetailQuestion: '',
      focusAreaQuestion: '',
      budget: { amount: null, currency: 'USD' },
    },
    mode: 'all', // Validate on change, blur, submit
    resolver: zodResolver(pricingDataRequestFormDataSchema),
  });
  const goodFormMethods = useGoodFormUtils(formMethods);

  const onRequest: GoodFormOnSubmitCallback<PricingDataRequestFormData> = async (formData) => {
    onSuccess(formData);
    formMethods.reset();
  };

  useEffect(() => {
    if (!open) {
      formMethods.reset();
    }
  }, [open, formMethods]);

  return (
    <Dialog size="XL" open={open}>
      <FormProvider {...formMethods}>
        <FormSchemaProvider value={pricingDataRequestFormDataSchema}>
          <form onSubmit={goodFormMethods.handleSubmit(onRequest)}>
            <DialogHeader size="M">{t('HEADER_TITLE')}</DialogHeader>
            <DialogContent>
              <PricingDataRequestFormEntry
                name="reasonQuestion"
                label={t('FIELDS.QUESTION_1_LABEL')}
                component={FormTextArea}
                width={12}
                componentProps={{
                  size: 'S',
                  color: 'primary',
                  variant: 'solid',
                  textLimit: 10000,
                  placeholder: t('FIELDS.QUESTION_1_DESCRIPTION'),
                }}
              />
              <PricingDataRequestFormEntry
                name="provideDetailQuestion"
                label={t('FIELDS.QUESTION_2_LABEL')}
                component={FormTextArea}
                width={12}
                componentProps={{
                  size: 'S',
                  color: 'primary',
                  variant: 'solid',
                  textLimit: 10000,
                  placeholder: t('FIELDS.QUESTION_2_DESCRIPTION'),
                }}
              />
              <PricingDataRequestFormEntry
                name="focusAreaQuestion"
                label={t('FIELDS.QUESTION_3_LABEL')}
                component={FormTextArea}
                width={12}
                componentProps={{
                  size: 'S',
                  color: 'primary',
                  variant: 'solid',
                  textLimit: 10000,
                  placeholder: t('FIELDS.QUESTION_3_DESCRIPTION'),
                }}
              />
              <PricingDataRequestFormEntry
                name="budget"
                label={t('FIELDS.BUDGET_LABEL')}
                component={FormMoneyField}
                width={12}
                componentProps={{}}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outline" size="M">
                {t('BUTTONS.DISCARD')}
              </Button>
              <SubmitButton />
            </DialogActions>
          </form>
        </FormSchemaProvider>
      </FormProvider>
    </Dialog>
  );
};

export default PricingDataRequestDialog;
