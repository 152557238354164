import { Grid } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { FC } from 'react';

type VariableMapperHeaderProps = {
  worflowVariablesTitle: string;
  otherVariablesTitle: string;
  fromWorkflow: boolean;
};

export const VariableMapperHeader: FC<VariableMapperHeaderProps> = ({
  worflowVariablesTitle,
  otherVariablesTitle,
  fromWorkflow,
}) => {
  const fromTitle = fromWorkflow ? worflowVariablesTitle : otherVariablesTitle;
  const toTitle = fromWorkflow ? otherVariablesTitle : worflowVariablesTitle;

  return (
    <Grid container sx={{ zIndex: 1 }} alignItems={'center'}>
      <Grid item xs={fromWorkflow ? 6 : 4} display={'grid'}>
        <Text variant="caption" size="XS" color="text2">
          {fromTitle}
        </Text>
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}></Grid>
      <Grid item xs={fromWorkflow ? 4 : 6} display={'grid'}>
        <Text variant="caption" size="XS" color="text2">
          {toTitle}
        </Text>
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}></Grid>
    </Grid>
  );
};
