import { useTranslation } from 'react-i18next';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { IconButton, Tooltip } from '@verticeone/design-system';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { EditOutlined } from '@mui/icons-material';
import EditExecutionDateModal from '@vertice/dashboard/src/pages/Cloud/CloudRIO/components/ExecutionDateModal';
import { useState } from 'react';
import { useDisabledRow } from './utils';

const EditableStartDayCell = ({ id, field, value, row }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const { isDisabled } = useDisabledRow(row.offeringId);

  const isExecutionDateModalOpen = isEditMode;
  const effectiveDate = new Date(value);
  const executeImmediately = row.executeImmediately === 1 || false;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.RECOMMENDED_TAB.TABLE.EFFECTIVE_DAY_CELL',
  });

  const label = executeImmediately ? t('IMMEDIATELY') : dayjs(effectiveDate).format('MMM DD, YYYY');

  if (isDisabled) {
    return label;
  }

  const setEditMode = (edit: boolean) => {
    if (edit) {
      setIsEditMode(true);
      if (apiRef.current.getRowMode(id) === 'view') {
        void apiRef.current.startRowEditMode({ id });
      }
    } else {
      setIsEditMode(false);
      if (apiRef.current.getRowMode(id) === 'edit') {
        void apiRef.current.stopRowEditMode({ id });
      }
    }
  };

  const handleEditExecutionDate = () => {
    setEditMode(true);
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Text variant="body-regular">{label}</Text>
        <Tooltip size="S" content={t('CHANGE_PURCHASE_DATE')}>
          <IconButton size="M" variant="plain" onClick={handleEditExecutionDate} icon={EditOutlined} />
        </Tooltip>
      </Stack>
      <EditExecutionDateModal
        open={isExecutionDateModalOpen}
        setOpen={setEditMode}
        effectiveDate={effectiveDate}
        executeImmediately={executeImmediately}
        onChange={(data) => {
          void apiRef.current.setEditCellValue({ id, field, value: data.effectiveDate });
          void apiRef.current.setEditCellValue({
            id,
            field: 'executeImmediately',
            value: data.executeImmediately ? 1 : 0,
          });

          setEditMode(false);
        }}
      />
    </>
  );
};

export default EditableStartDayCell;
