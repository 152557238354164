import { FC } from 'react';
import { ShortTextFieldType } from './types';
import { Stack } from '@mui/material';
import FormTextField, { FormTextFieldType } from '../../../fields/FormTextField';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';

import { DynamicFormPresenterConfig } from '../../types';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';

type ShortTextFieldPresenterProps = {
  field: ShortTextFieldType;
  config: DynamicFormPresenterConfig;
};

export const ShortTextFieldPresenter: FC<ShortTextFieldPresenterProps> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormTextFieldType<FieldValues>>
        name={field.name}
        component={FormTextField}
        componentProps={{ color: INTELLIGENT_WORKFLOWS_BRAND_COLOR }}
        label={label}
        description={description}
        required={required}
        disabled={config.mode === 'readOnly' || config.mode === 'preview'}
      />
    </Stack>
  );
};
