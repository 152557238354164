import { Box, type BoxProps } from '@mui/material';

export const CardsList = ({ children, sx, ...otherProps }: BoxProps) => (
  <Box
    {...otherProps}
    sx={{
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'start',
      flexWrap: 'wrap',
      maxWidth: 1800, // so 4 cards are max for one line
      gap: 4,
      ...sx,
    }}
  >
    {children}
  </Box>
);
