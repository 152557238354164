import { useTranslation } from 'react-i18next';

import { useRoutes } from '@verticeone/router/src';
import { GoogleIcon } from '@vertice/assets';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type GoogleSetupCardProps = {
  providerId: string;
};

export const GoogleSetupCard = ({ providerId }: GoogleSetupCardProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="Google"
      providerIcon={<GoogleIcon />}
      description={t('PREFERENCES.INTEGRATIONS.GOOGLE.DESCRIPTION')}
      wizardRoute={routes.PREFERENCES.INTEGRATIONS.USAGE.GOOGLE.ABSOLUTE_PATH}
    />
  );
};
