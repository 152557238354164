import { FC, ReactNode } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { Box, Stack, styled, useTheme } from '@mui/material';
import {
  Text,
  EllipsisText,
  IconButton,
  IconButtonProps,
  FormControlLabel,
  ToggleSwitch,
  ToggleSwitchProps,
  DragHandle,
  baseDraggableStyles,
  baseDropAnimation,
  baseDragOverlayStyles,
} from '@verticeone/design-system';
import { getFieldTypeText } from '../../fields/utils';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, SettingsOutlined } from '@mui/icons-material';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { FieldType } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { CSSProperties } from 'styled-components';
import { createPortal } from 'react-dom';
import { DragOverlay } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';

type FieldCardRootProps = {
  children: ReactNode | ReactNode[];
};

const FieldCardRoot: FC<FieldCardRootProps> = (props) => {
  const { children } = props;

  return (
    <Stack gap={2} direction="row">
      {children}
    </Stack>
  );
};

type FieldCardLabelProps = {
  required?: boolean;
  children: ReactNode | ReactNode[];
  fieldType: FieldType;
};

const FieldCardLabel: FC<FieldCardLabelProps> = (props) => {
  const { children, fieldType, required } = props;
  const { t } = useTranslation();

  return (
    <Stack gap={1} minWidth={0} flexGrow={1} pr={2}>
      <Text variant="label" size="XXS" color="text3">
        {getFieldTypeText(t, fieldType)}
      </Text>
      <Stack direction="row" gap={0.5}>
        <EllipsisText variant="body-bold" size="S" color="text2">
          {children}
        </EllipsisText>
        <Text variant="body-bold" size="S" color="error2">
          {required ? '*' : ''}
        </Text>
      </Stack>
    </Stack>
  );
};

const FieldCardActions: FC<{ children: ReactNode | ReactNode[] }> = (props) => {
  const { children } = props;

  return (
    <Stack direction="row" flexShrink={0}>
      {children}
    </Stack>
  );
};

const FieldCardRemoveAction: FC<Omit<IconButtonProps, 'icon'>> = (props) => {
  const { t } = useTranslation();

  return (
    <IconButton
      {...props}
      icon={DeleteOutlined}
      size="S"
      color="neutral"
      variant="plain"
      aria-label={t('DYNAMIC_FORM_BUILDER.REMOVE_FIELD')}
    />
  );
};

const FieldCardEditAction: FC<Omit<IconButtonProps, 'icon'>> = (props) => {
  const { t } = useTranslation();

  return (
    <IconButton
      {...props}
      icon={SettingsOutlined}
      size="S"
      color="neutral"
      variant="plain"
      aria-label={t('DYNAMIC_FORM_BUILDER.UPDATE_FIELD')}
    />
  );
};

const FieldCardRequiredSwitchAction: FC<ToggleSwitchProps> = (props) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      sx={{ mr: 2 }}
      control={<ToggleSwitch size="XS" sx={{ mr: 0.5 }} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} {...props} />}
      label={
        <Text variant="button-bold" flexShrink={0} size="XS">
          {t('DYNAMIC_FORM_BUILDER.REQUIRED_FIELD')}
        </Text>
      }
      labelPlacement="end"
    />
  );
};

type FieldCardDraggableProps = {
  id: string;
  children?: ReactNode | ReactNode[];
};

const BaseFieldCard = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.input.color1,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
  paddingLeft: theme.spacing(3),
}));

export const FieldCardDraggable: FC<FieldCardDraggableProps> = (props) => {
  const { children, id } = props;

  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition };

  return (
    <BaseFieldCard sx={{ ...baseDraggableStyles({ isDragging }) }} ref={setNodeRef} style={style} id={id}>
      <Stack justifyContent="center" alignItems="center">
        <DragHandle listeners={listeners} attributes={attributes} />
      </Stack>
      <Box flexGrow={1}>{children}</Box>
    </BaseFieldCard>
  );
};

type FieldCardDragOverlayProps = {
  id: string | null;
  children?: ReactNode | ReactNode[];
};

export const FieldCardDragOverlay: FC<FieldCardDragOverlayProps> = (props) => {
  const { children, id } = props;
  const theme = useTheme();

  return createPortal(
    <DragOverlay dropAnimation={baseDropAnimation}>
      {children && id && (
        <BaseFieldCard id={id} sx={{ ...baseDragOverlayStyles({ theme }) }}>
          <Stack justifyContent="center" alignItems="center">
            <DragHandle />
          </Stack>
          <Box flexGrow={1}>{children}</Box>
        </BaseFieldCard>
      )}
    </DragOverlay>,
    document.getElementById('root')!
  );
};

export const FieldCard = Object.assign(FieldCardRoot, {
  Label: FieldCardLabel,
  Actions: FieldCardActions,
  RemoveAction: FieldCardRemoveAction,
  EditAction: FieldCardEditAction,
  RequiredSwitchAction: FieldCardRequiredSwitchAction,
  Draggable: FieldCardDraggable,
  DragOverlay: FieldCardDragOverlay,
});
