import React from 'react';
import { TextFieldCaption } from '@verticeone/design-system';
import { FormControl, Stack } from '@mui/material';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../TaskFormContext';
import { useWatch } from 'react-hook-form';
import { Alert } from '@verticeone/design-system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SIZE = 'S';

type PurchasingManagerPermissionsV2Props = {
  contactVendorDisabled?: boolean;
  withVertice: boolean;
};

export const PurchasingManagerPermissionsV2: React.FC<PurchasingManagerPermissionsV2Props> = ({
  contactVendorDisabled,
  withVertice,
}) => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();
  const contactVendorDirectly = useWatch({ name: 'contactVendorDirectly' });
  const mainVendorContact = useWatch({ name: 'mainVendorContact' });

  return (
    <Stack direction="column" gap={6}>
      {withVertice && (
        <>
          {contactVendorDisabled && (
            <Alert
              icon={InfoOutlinedIcon}
              color="error"
              title={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.NO_VENDOR_CONTACT_WARNING')}
              variant="ghost"
              size="XS"
            />
          )}

          <FormControl variant="outlined">
            <Stack gap={1}>
              <FormChecklistItemField
                name="contactVendorDirectly"
                label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PERMISSION_CONTACT_VENDOR')}
                color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                size={SIZE}
                disabled={contactVendorDisabled || readOnly}
              />
              {!contactVendorDirectly && mainVendorContact && (
                <Alert
                  icon={InfoOutlinedIcon}
                  color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                  title={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.CONTACT_VENDOR_DIRECTLY_UNCHECKED_INFO')}
                  variant="ghost"
                  size="XS"
                />
              )}
            </Stack>
          </FormControl>
        </>
      )}

      <Stack gap={0}>
        <TextFieldCaption
          label={t(
            withVertice
              ? 'INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PERMISSION_TITLE_VERTICE'
              : 'INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PERMISSION_TITLE_INTERNAL'
          )}
          size="XS"
        />
        <FormControl variant="outlined">
          <FormChecklistItemField
            name="discussMultiYearDeals"
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PERMISSION_DISCUSS_MULTI_YEAR')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={SIZE}
            disabled={readOnly}
          />
        </FormControl>
        <FormControl variant="outlined">
          <FormChecklistItemField
            name="discussCompetitors"
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PERMISSION_DISCUSS_COMPETITORS')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={SIZE}
            disabled={readOnly}
          />
        </FormControl>
        <FormControl variant="outlined">
          <FormChecklistItemField
            name="discussCaseStudies"
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.RENEWAL_REQUIREMENTS.LABELS.PERMISSION_DISCUSS_CASE_STUDIES')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={SIZE}
            disabled={readOnly}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
};
