type MyRequestsData = {
  completedSpend: number;
  inFlightSpend: number;
  openRequests: number;
  overdueRequests: number;
};

export const useOverviewStatsData = () => {
  const tempData = {
    name: 'overview_stats',
    stats: {
      completedSpend: 5200000,
      inFlightSpend: 1200000,
      openRequests: 69,
      overdueRequests: 42,
    },
  };

  const data: MyRequestsData = {
    ...tempData.stats,
  };

  return {
    data,
    isLoading: false,
  };
};
