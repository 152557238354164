import { FieldDef } from '../types';
import {
  getMoneyJsonSchema,
  getMoneyJsonSchemaFromMetadata,
  getMoneyPresenterDefault,
  getMoneyPresenterSchema,
} from './utils';
import { MoneyFieldType } from './types';
import { MoneyFieldBuilderCard } from './MoneyFieldBuilderCard';
import { MoneyFieldPresenter } from './MoneyFieldPresenter';
import { MoneyFieldBuilderDialog } from './MoneyFieldBuilderDialog';

export { type MoneyFieldType, type MoneyFieldMetadata } from './types';
export { moneyFieldSchema, moneyFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<MoneyFieldType> = {
  type: 'MONEY',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.MONEY',
  builderCard: MoneyFieldBuilderCard,
  builderDialog: MoneyFieldBuilderDialog,
  presenter: MoneyFieldPresenter,
  getPresenterDefault: getMoneyPresenterDefault,
  getPresenterSchema: getMoneyPresenterSchema,
  getJsonSchemaFromMetadata: getMoneyJsonSchemaFromMetadata,
  getJsonSchema: getMoneyJsonSchema,
};
