export type FontProperties = {
  fontName?: string;
  fontSize?: `${number}px`;
};

export const getTextWidth = (text: string, { fontName = 'Inter', fontSize = '12px' }: FontProperties) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const font = `${fontSize} ${fontName}`;

  if (!context) {
    return 0;
  }

  context.font = font;

  return context.measureText(text).width;
};
