import { FieldDef } from '../types';
import { FileUploadFieldBuilderCard } from './FileUploadFieldBuilderCard';
import { FileUploadFieldBuilderDialog } from './FileUploadFieldBuilderDialog';
import { FileUploadFieldPresenter } from './FileUploadFieldPresenter';
import { FileUploadFieldType } from './types';
import {
  getFileUploadJsonSchema,
  getFileUploadJsonSchemaFromMetadata,
  getFileUploadPresenterDefault,
  getFileUploadPresenterSchema,
} from './utils';

export type { FileUploadFieldType, FileUploadFieldMetadata } from './types';
export { fileUploadFieldMetadataSchema, fileUploadFieldSchema } from './types';

export const fieldDef: FieldDef<FileUploadFieldType> = {
  type: 'FILE_UPLOAD',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.FILE_UPLOAD.FIELD_TYPE_NAME',
  builderCard: FileUploadFieldBuilderCard,
  builderDialog: FileUploadFieldBuilderDialog,
  presenter: FileUploadFieldPresenter,
  getPresenterDefault: getFileUploadPresenterDefault,
  getPresenterSchema: getFileUploadPresenterSchema,
  getJsonSchemaFromMetadata: getFileUploadJsonSchemaFromMetadata,
  getJsonSchema: getFileUploadJsonSchema,
};
