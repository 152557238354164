import {
  FlowDefinition,
  FlowEdge,
  Process,
  ProcessDefinition,
} from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import {
  WorkflowModel,
  WorkflowStartNode,
  WorkflowEndNode,
  WorkflowTaskNode,
  WorkflowEdge,
  WorkflowGatewayNode,
  WorkflowState,
  WorkflowLabelNode,
  WorkflowNode,
  EdgeState,
} from './types';
import { resolveTaskNodeType } from './resolveTaskNodeType';
import { resolveTaskNodeThumbnailConfiguration } from './resolveTaskNodeThumbnailConfiguration';
import { findResourceUrnFromConfigurations } from './configurationUtils';
import { ServicesThumbnails } from '../WorkflowViewer/useServicesThumbnails';
import { isFlowEdgeConditionConfiguration } from '../../pocWorkflowSchema';

const convertStartNodes = (startNodes: Process['start'], state?: WorkflowState): WorkflowStartNode[] =>
  startNodes.map(({ start }) => ({
    id: start.id,
    name: start.name,
    kind: 'start',
    state: {
      parentRequest: state?.parentRequest,
    },
  }));

const convertEndNodes = (endNodes: Process['end']): WorkflowEndNode[] =>
  endNodes.map(({ end }) => ({
    id: end.id,
    name: end.name,
    kind: 'end',
  }));

const convertTaskNodes = (
  taskNodes: Process['tasks'],
  state?: WorkflowState,
  serviceThumbnails?: ServicesThumbnails
): WorkflowTaskNode[] =>
  taskNodes?.map(({ task }) => {
    const resourceUrn = findResourceUrnFromConfigurations(task.configurations);
    const resourceId = resourceUrn?.slice(resourceUrn.indexOf('/') + 1);
    const serviceThumbnail = resourceId ? serviceThumbnails?.[resourceId] : null;

    const taskThumbnail = resolveTaskNodeThumbnailConfiguration(task.configurations || []);
    const isTaskThumbnailFallback = taskThumbnail.id === '' || taskThumbnail.type === '';

    return {
      id: task.id,
      name: task.name,
      kind: 'task',
      state: state?.tasks[task.id],
      type: resolveTaskNodeType(state?.tasks[task.id], task.configurations),
      requestRoute: state?.requestRoute,
      thumbnail: isTaskThumbnailFallback && serviceThumbnail ? serviceThumbnail : taskThumbnail,
    };
  }) || [];

const convertGatewayNodes = (gatewayNodes: Process['gateways'], state?: WorkflowState): WorkflowGatewayNode[] =>
  gatewayNodes?.map(({ gateway }) => ({
    id: gateway.id,
    name: gateway.name,
    kind: 'gateway',
    state: state?.gateways[gateway.id],
    gatewayType: gateway.gatewayType,
  })) || [];

export const AVERAGE_CHAR_WIDTH = 9;

const getLabelNodeId = (id: string) => `${id}-label`;

const splitEdgeToLabelNodeAndEdges = (edge: FlowEdge, state?: EdgeState) => {
  if (!edge?.name) {
    return;
  }

  const nodeId = getLabelNodeId(edge.id);

  const node: WorkflowLabelNode = {
    id: nodeId,
    kind: 'label',
    name: edge.name,
    state,
    to: edge.to,
    from: edge.from,
    originalEdgeId: edge.id,
    width: edge.name.length * AVERAGE_CHAR_WIDTH,
  };

  const edgeEnd: WorkflowEdge = {
    id: `${edge.id}-end`,
    name: '',
    from: nodeId,
    originalEdgeId: edge.id,
    to: edge.to,
    state,
  };

  const edgeStart: WorkflowEdge = {
    id: `${edge.id}-start`,
    name: '',
    from: edge.from,
    to: nodeId,
    originalEdgeId: edge.id,
    state,
  };

  return {
    node,
    edgeStart,
    edgeEnd,
  };
};

const convertEdges = (
  process?: Process,
  workflowState?: WorkflowState
): {
  edges: WorkflowEdge[];
  labelNodes: WorkflowLabelNode[];
} => {
  const labelNodes: WorkflowLabelNode[] = [];
  const edges: WorkflowEdge[] = [];

  process?.flow?.flow.edges?.forEach(({ edge }) => {
    const nodeAndEdges = splitEdgeToLabelNodeAndEdges(edge, workflowState?.edges[edge.id]);
    const isDefault = edge.configurations?.some(
      (config) => isFlowEdgeConditionConfiguration(config) && config.condition.conditionType === 'DefaultFlow'
    );

    if (nodeAndEdges) {
      const { edgeEnd, edgeStart, node } = nodeAndEdges;
      labelNodes.push(node);
      edges.push({ ...edgeStart, isDefault });
      edges.push(edgeEnd);
    } else {
      edges.push({
        ...edge,
        state: workflowState?.edges[edge.id],
        isDefault,
      });
    }
  });

  return {
    labelNodes,
    edges,
  };
};

const isNodeAfterEdge = (node: WorkflowNode, edge: FlowEdge) => edge.from === node.id;
const isEndNodeWithEdge = (node: WorkflowNode, edge: FlowEdge) => edge.to === node.id && node.kind === 'end';
const isLabelNodeWithinEdge = (node: WorkflowNode, edge: FlowEdge) =>
  node.kind === 'label' && node.id === getLabelNodeId(edge.id);

const addNodesPassedState = (nodes: WorkflowNode[], flow?: FlowDefinition, workflowState?: WorkflowState) => {
  const passedEdges = flow?.flow.edges?.filter(({ edge }) => workflowState?.edges[edge.id]?.passed);

  return nodes.map((node) => {
    return {
      ...node,
      state: {
        ...node.state,
        passed: passedEdges?.some(
          ({ edge }) =>
            isNodeAfterEdge(node, edge) || isEndNodeWithEdge(node, edge) || isLabelNodeWithinEdge(node, edge)
        ),
      },
    };
  });
};

type ProcessDefinitionToModel = {
  processDefinition: ProcessDefinition;
  workflowState?: WorkflowState;
  servicesThumbnails?: ServicesThumbnails;
};

export const processDefinitionToModel = ({
  processDefinition,
  workflowState,
  servicesThumbnails,
}: ProcessDefinitionToModel): WorkflowModel => {
  const processDefinitionKind: ProcessDefinition['kind'] = 'ProcessEngine:ProcessDefinition';

  if (processDefinition.kind !== processDefinitionKind) {
    throw new Error(`Invalid process definition kind: ${processDefinition.kind}`);
  }

  const { labelNodes, edges } = convertEdges(processDefinition.process, workflowState);
  const nodes = addNodesPassedState(
    [
      ...convertStartNodes(processDefinition.process.start, workflowState),
      ...convertEndNodes(processDefinition.process.end),
      ...convertTaskNodes(processDefinition.process.tasks, workflowState, servicesThumbnails),
      ...convertGatewayNodes(processDefinition.process.gateways, workflowState),
      ...labelNodes,
    ],
    processDefinition.process.flow,
    workflowState
  );

  return {
    nodes,
    edges,
  };
};
