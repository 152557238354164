import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';

type FormSectionTitleProps = {
  title: string;
  children: React.ReactNode;
};

const FormSection = ({ title, children }: FormSectionTitleProps) => (
  <Stack gap={6}>
    {!!title && (
      <Text variant="label" size="XXS" color="text3" pt={6}>
        {title}
      </Text>
    )}
    {children}
  </Stack>
);

export default FormSection;
