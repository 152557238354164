import { Stack } from '@mui/material';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Divider } from '@verticeone/design-system';
import { ConversationSection } from '../../request/RequestDetailPage/sections';
import { parseRequestRef } from '../../../../hooks/workflows/refUtils';

export const TaskCommentsSection = ({
  isCommentsOpen,
  taskOverview,
  accountId,
}: {
  isCommentsOpen: boolean;
  taskOverview?: TaskOverview;
  accountId: string;
}) => {
  if (!isCommentsOpen || !accountId) return null;

  return (
    <>
      <Divider orientation="vertical" />
      <Stack bgcolor="white" minHeight="100%">
        <Stack minHeight={0} width={384} height="100%">
          <ConversationSection
            height="100%"
            variant="none"
            request={{ accountId, requestId: parseRequestRef(taskOverview!.request!.ref).requestId }}
            sourceRef={taskOverview!.task.ref}
          />
        </Stack>
      </Stack>
    </>
  );
};
