import { FieldDef } from '../types';
import { ShortTextFieldBuilderCard } from './ShortTextFieldBuilderCard';
import { ShortTextFieldBuilderDialog } from './ShortTextFieldBuilderDialog';
import { ShortTextFieldPresenter } from './ShortTextFieldPresenter';
import { ShortTextFieldType } from './types';
import {
  getShortTextJsonSchema,
  getShortTextJsonSchemaFromMetadata,
  getShortTextPresenterDefault,
  getShortTextPresenterSchema,
} from './utils';

export type { ShortTextFieldType, ShortTextFieldMetadata } from './types';
export { shortTextFieldMetadataSchema, shortTextFieldSchema } from './types';

export const fieldDef: FieldDef<ShortTextFieldType> = {
  type: 'SHORT_TEXT',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.SHORT_TEXT',
  builderCard: ShortTextFieldBuilderCard,
  builderDialog: ShortTextFieldBuilderDialog,
  presenter: ShortTextFieldPresenter,
  getPresenterDefault: getShortTextPresenterDefault,
  getPresenterSchema: getShortTextPresenterSchema,
  getJsonSchemaFromMetadata: getShortTextJsonSchemaFromMetadata,
  getJsonSchema: getShortTextJsonSchema,
};
