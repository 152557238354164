import { RadioGroupValueListItem } from './types';
import { GridRowId } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useSimpleDialogContext } from '@verticeone/design-system';
import { useCallback } from 'react';
import { useRadioGroupValuesListModel } from './useRadioGroupValuesListModel';
import { normalizeValue } from './utils';
import { ValidateRowResult } from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';

export type UseRadioGroupValuesListActionsParams = {
  valueRows: RadioGroupValueListItem[];
  setValueRows: (rows: RadioGroupValueListItem[]) => void;
};

export type UseRadioGroupValuesListActionsReturn = {
  validateRow?: (row: RadioGroupValueListItem) => ValidateRowResult<RadioGroupValueListItem>;
  onErrorsChange?: (errors: string[]) => void;
  onAddRow?: (row: RadioGroupValueListItem) => void;
  onUpdateRow?: (row: RadioGroupValueListItem) => void;
  onBeforeDeleteRow?: (row: RadioGroupValueListItem) => Promise<boolean>;
  onDeleteRow?: (id: GridRowId) => void;
};

const getNormalizedRow = (row: RadioGroupValueListItem): RadioGroupValueListItem => {
  return { ...row, value: normalizeValue(row.value) };
};

const useRadioGroupValuesListActions = ({
  valueRows,
  setValueRows,
}: UseRadioGroupValuesListActionsParams): UseRadioGroupValuesListActionsReturn => {
  const { t } = useTranslation(undefined, { keyPrefix: 'DYNAMIC_FORM_BUILDER.DIALOG.RADIO_GROUP_LIST' });
  const { addValue, updateValue, removeValue } = useRadioGroupValuesListModel({ valueRows, setValueRows });

  const { getConfirmation } = useSimpleDialogContext();

  const validateRow = useCallback(
    (rawRow: RadioGroupValueListItem): { isValid: true } | { isValid: false; message: string } => {
      const { id, value: valueName } = getNormalizedRow(rawRow);
      if (!valueName) {
        return { isValid: false, message: t('ERROR_DIALOG.MISSING_VALUE_MESSAGE') };
      }

      const valueExists = valueRows.some((rawItem: RadioGroupValueListItem) => {
        // Defensive precaution: Assume there could be existing not-normalized items.
        const item = getNormalizedRow(rawItem);
        return item.value === valueName && item.id !== id;
      });
      if (valueExists) {
        return { isValid: false, message: t('ERROR_DIALOG.EXISTING_VALUE_MESSAGE') };
      }

      return { isValid: true };
    },
    [t, valueRows]
  );

  const onAddRow = useCallback((row: RadioGroupValueListItem) => addValue(getNormalizedRow(row)), [addValue]);

  const onUpdateRow = useCallback((row: RadioGroupValueListItem) => updateValue(getNormalizedRow(row)), [updateValue]);

  const onBeforeDeleteRow = useCallback(async () => {
    const confirmation = await getConfirmation({
      title: t('REMOVE_DIALOG.TITLE'),
      description: t('REMOVE_DIALOG.MESSAGE'),
      okButton: {
        label: t('REMOVE_DIALOG.YES'),
      },
    });

    return confirmation.status === 'ok';
  }, [getConfirmation, t]);

  const onDeleteRow = useCallback((id: GridRowId) => removeValue(id as string), [removeValue]);

  const onErrorsChange = useCallback(
    (errors: string[]) => {
      if (errors.length) {
        void getConfirmation({
          title: t('ERROR_DIALOG.TITLE'),
          description: errors.join(', '),
          cancelButton: {
            hidden: true,
          },
        });
      }
    },
    [getConfirmation, t]
  );

  return {
    validateRow,
    onAddRow,
    onUpdateRow,
    onBeforeDeleteRow,
    onDeleteRow,
    onErrorsChange,
  };
};

export default useRadioGroupValuesListActions;
