import { useTranslation } from 'react-i18next';

import { useRoutes } from '@verticeone/router/src';
import { JumpCloudIcon } from '@vertice/assets';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type JumpCloudSetupCardProps = {
  providerId: string;
};

export const JumpCloudSetupCard = ({ providerId }: JumpCloudSetupCardProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="JumpCloud"
      providerIcon={<JumpCloudIcon />}
      description={t('PREFERENCES.INTEGRATIONS.JUMPCLOUD.DESCRIPTION')}
      wizardRoute={routes.PREFERENCES.INTEGRATIONS.USAGE.JUMPCLOUD.ABSOLUTE_PATH}
    />
  );
};
