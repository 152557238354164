import React, { FC, useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import { Background, Controls, BackgroundVariant, ReactFlow, MarkerType, EdgeTypes, NodeTypes } from '@xyflow/react';

import {
  StartNodeComponent,
  EndNodeComponent,
  TaskNodeComponent,
  GatewayNodeComponent,
  LabelNodeComponent,
} from './NodeComponents';
import { Edge } from './EdgeComponents';

import '@xyflow/react/dist/style.css';
import { modelToRendererGraph } from './modelToRendererGraph';
import { useFitActiveTasksIntoView } from './useFitActiveTasksIntoView';
import { useWorkflowRendererContext } from './WorkflowRendererContext';

export type WorkflowRendererProps = {
  // This prop is mainly for visual tests and is used to disable the animation when fitting active tasks into view
  disableFitActiveTask?: boolean;
};

const edgeTypes: EdgeTypes = {
  edge: Edge,
};

const nodeTypes: NodeTypes = {
  start: StartNodeComponent,
  end: EndNodeComponent,
  task: TaskNodeComponent,
  gateway: GatewayNodeComponent,
  label: LabelNodeComponent,
};

const proOptions = { hideAttribution: true };

export const WorkflowRenderer: FC<WorkflowRendererProps> = ({ disableFitActiveTask = false }) => {
  const { palette } = useTheme();
  const counter = useRef<number>(0);
  const { isEditor, drawerTaskId, model } = useWorkflowRendererContext();

  const { nodes, edges } = useMemo(() => {
    // re-rendering ReactFlow multiple times with the same nodes and edges makes the nodes and edges invisible.
    // couldn't find any other solution than adding increment to all node and edge IDs, see RED-1209
    counter.current += 1;
    return modelToRendererGraph(model, counter.current);
  }, [model]);

  const { setReactFlowInstance } = useFitActiveTasksIntoView({
    nodes,
    skip: !!drawerTaskId || disableFitActiveTask,
  });

  return (
    <ReactFlow
      onInit={(instance) => setReactFlowInstance(instance)}
      nodes={nodes}
      edges={edges}
      defaultNodes={isEditor ? nodes : undefined}
      defaultEdges={isEditor ? edges : undefined}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesDraggable={false}
      maxZoom={1.2}
      minZoom={0.1}
      zoomOnPinch={true}
      zoomOnScroll={false}
      panOnScroll={isEditor}
      preventScrolling={false}
      // multiSelecttionKeyCode - invalid value disables multi-selection completely
      // it's mentioned in github discussions, but not in the docs
      multiSelectionKeyCode={'DISABLED'}
      fitView
      snapToGrid
      snapGrid={[10, 10]}
      attributionPosition="bottom-left"
      defaultEdgeOptions={{
        markerEnd: {
          type: MarkerType.Arrow,
          width: 30,
          height: 30,
          color: palette.core.color5,
          strokeWidth: 0.6,
        },
        style: {
          strokeWidth: 1.5,
        },
      }}
      proOptions={proOptions}
    >
      <Background
        color={palette.core.color4}
        variant={BackgroundVariant.Lines}
        style={{
          background: palette.core.color1,
          strokeDasharray: '7 7',
          opacity: 0.5,
        }}
        gap={170}
        offset={2.5}
      />
      <Controls showInteractive={false} />
    </ReactFlow>
  );
};
