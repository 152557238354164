import { z } from 'zod';

export const numberFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const numberFieldSchema = z.object({
  name: z.string(),
  type: z.literal('NUMBER'),
  metadata: numberFieldMetadataSchema,
});

export type NumberFieldMetadata = z.infer<typeof numberFieldMetadataSchema>;

export type NumberFieldType = z.infer<typeof numberFieldSchema>;
