import React, { FC } from 'react';
import { NodeProps, Node } from '@xyflow/react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Text } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';

import { NodeControls } from './NodeControls';
import { WorkflowStartNode } from '../../model/types';
import { StyledStartEndNode } from './Shared';
import { useNavigate } from 'react-router-dom';
import { parseRequestRef } from '../../../../../hooks/workflows/refUtils';
import { useRouteNavigate } from '@verticeone/router/src';
import { useWorkflowRendererContext } from '../WorkflowRendererContext';

export const StartNodeComponent: FC<NodeProps<Node<WorkflowStartNode>>> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { generatePath } = useRouteNavigate();
  const { accountId } = useWorkflowRendererContext();

  const requestId = props.data.state?.parentRequest
    ? parseRequestRef(props.data.state.parentRequest.ref).requestId
    : undefined;
  const parentRequestPath = props.data.state?.parentRequest
    ? generatePath(props.data.state.parentRequest.route, {
        requestId: requestId,
        account_id: accountId,
      })
    : undefined;

  const nodeContent = (
    <StyledStartEndNode $passed={props.data.state?.passed}>
      <Text variant="button-bold" size="M">
        {props.data.name || t('MODULES.WORKFLOW.NODES.START.DEFAULT_LABEL')}
      </Text>
    </StyledStartEndNode>
  );

  return (
    <NodeControls
      {...props}
      content={
        props.data.state?.parentRequest && parentRequestPath ? (
          <Tooltip
            size="XS"
            title={t('MODULES.WORKFLOW.NODES.START.FROM')}
            button={{
              label: t('MODULES.WORKFLOW.NODES.START.GO_TO_REQUEST'),
              onClick: function handleOnClick() {
                navigate(parentRequestPath);
              },
            }}
            content={
              <Stack gap={4}>
                <Text variant="body-regular" size="XS">
                  {props.data.state?.parentRequest?.name}
                </Text>
                {/*navigation button to be added here*/}
              </Stack>
            }
          >
            {nodeContent}
          </Tooltip>
        ) : (
          nodeContent
        )
      }
    />
  );
};
