import { FC, useState } from 'react';

import {
  useGetAccountIntegrationQuery,
  useListCreateJiraIssueTypeFieldsQuery,
  useListJiraIssueTypesQuery,
} from '@vertice/slices';
import { useAccountContext } from '../../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { useServiceCatalogResources } from '../../../../WorkflowViewer/useServiceCatalogResources';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { JiraIssueType, JiraProject } from '../JiraCommon/types';
import { getCorrespondingTaskService, transformJiraToWorkflowLikeVariables } from '../JiraCommon/utils';
import { EditServiceTaskBase } from '../types';
import { JiraCreateForm } from './JiraCreateForm';

/**
 * Get and prepare data for Jira create form like available workflow variables and Jira variables
 */
export const JiraCreate: FC<EditServiceTaskBase> = (props) => {
  const { task, processDefinition, workflowServiceRef, editorConfig, resources, ...restProps } = props;
  const { accountId } = useAccountContext();
  const taskService = getCorrespondingTaskService(task, resources);
  const serviceCatalogResources = useServiceCatalogResources();
  const { xTypeServiceCatalogResources } = serviceCatalogResources;

  const { data: integrationData, isLoading: isLoadingIntegration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });
  const selectedProject = integrationData?.parameters?.projects?.[0] as JiraProject | undefined;
  const { data: issueTypesData, isLoading: isLoadingIssueTypes } = useListJiraIssueTypesQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
    },
    { skip: !selectedProject }
  );

  const [selectedIssueType, setSelectedIssueType] = useState<JiraIssueType | undefined>(undefined);

  const { data: issueTypeFieldsData, isFetching: isFetchingIssueTypeFields } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
      issueTypeId: selectedIssueType?.id ?? '',
    },
    { skip: !selectedProject?.id || !selectedIssueType?.id }
  );

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });

  const isIntegrationActive = integrationData?.status === 'ACTIVE';

  if (!taskService) return null;

  return (
    <JiraCreateForm
      task={task}
      taskService={taskService}
      projectId={selectedProject?.id ?? ''}
      isFetchingIssueTypeFields={isFetchingIssueTypeFields}
      {...restProps}
      issueTypePickerProps={{
        isIntegrationActive,
        isLoadingIntegration,
        issueTypes: issueTypesData?.issueTypes ?? [],
        isLoadingIssueTypes,
        setSelectedIssueType: setSelectedIssueType,
      }}
      variableMapperProps={{
        isIntegrationActive,
        workflowVariables: {
          udfs: udfVariables,
          request: requestVariables,
        },
        jiraVariables: transformJiraToWorkflowLikeVariables(
          issueTypeFieldsData?.fields ?? [],
          xTypeServiceCatalogResources
        ),
        fromWorkflow: true,
      }}
    />
  );
};
