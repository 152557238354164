import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Paper, Radio, Stack, Typography, Link, styled } from '@mui/material';
import { useGetAccountSettingsQuery, useListAccountUsersQuery } from '@vertice/slices';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ModalWindow } from '@vertice/components';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import NewContact, { NewUserData } from '@vertice/components/src/NewContact/NewContact';
import ApproverAutocomplete from './components/ApproverAutocomplete';
import ApproverThresholdList, { ErrorItem, RowItem } from './components/ApproverThresholdList';
import styles from './SelectApprovers.module.scss';
import {
  AllPowerUsersApprovalConfig,
  SingleApproverApprovalConfig,
  ThresholdBasedApprovalConfig,
  useSetAccountConfigMutation,
} from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { Button, Alert } from '@verticeone/design-system';
import { ContractOwner, UnlimitedThreshold } from './constants';
import clsx from 'clsx';

type ApprovalConfiguration = AllPowerUsersApprovalConfig | SingleApproverApprovalConfig | ThresholdBasedApprovalConfig;
type ApprovalConfigurationType = ApprovalConfiguration['type'] | null;

const mapThresholdListToApproversPerThreshold = (rowItems: RowItem[]): ThresholdBasedApprovalConfig['approvers'] =>
  rowItems.map(({ limit, approver }, index) =>
    index === rowItems.length - 1
      ? { userId: approver! }
      : {
          userId: approver!,
          threshold: limit ? parseFloat(limit) : NaN,
        }
  );

const mapApproversPerThresholdToRowItems = (items: ThresholdBasedApprovalConfig['approvers']) =>
  items.map(({ threshold, userId }) => ({
    limit: threshold === undefined ? null : `${threshold}`,
    approver: userId,
  }));

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecorationColor: 'inherit',
});

const SUPPORT_LINK =
  'https://support.vertice.one/hc/en-us/articles/28005228461713-How-do-I-edit-assignees-in-my-workflows';

export const DeprecatedBanner = () => {
  return (
    <Grid className={styles.wrap} item xs={12} pt={4}>
      <Alert
        size="S"
        variant="outline"
        color="error"
        title={<Trans i18nKey="PREFERENCES.WORKFLOW.APPROVERS.BANNER_DEPRECATED.TITLE" />}
        subtitle={
          <Trans
            i18nKey="PREFERENCES.WORKFLOW.APPROVERS.BANNER_DEPRECATED.SUBTITLE"
            components={{ a: <StyledLink href={SUPPORT_LINK} target="_blank" /> }}
          />
        }
      />
    </Grid>
  );
};

interface SelectApproversProps {
  accountId: string;
  approvalConfiguration?: ApprovalConfiguration;
  disabled?: boolean;
}
const SelectApprovers: FC<SelectApproversProps> = ({ accountId, approvalConfiguration, disabled }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [setAccountConfiguration, { isLoading: isConfigurationUpdating }] = useSetAccountConfigMutation();

  const [configType, setConfigType] = useState<ApprovalConfigurationType>(approvalConfiguration?.type || null);
  const [singleApproverId, setSingleApproverId] = useState<string | null>(
    approvalConfiguration?.type === 'SingleApproverApprovalConfig' ? approvalConfiguration.approver : ContractOwner
  );
  const [thresholdList, setThresholdList] = useState<RowItem[]>(
    approvalConfiguration?.type === 'ThresholdBasedApprovalConfig'
      ? mapApproversPerThresholdToRowItems(approvalConfiguration.approvers)
      : [
          { limit: null, approver: ContractOwner },
          { limit: UnlimitedThreshold, approver: ContractOwner },
        ]
  );

  const isPowerUserApprover = configType === 'AllPowerUsersApprovalConfig';
  const isSingleApprover = configType === 'SingleApproverApprovalConfig';
  const isThresholdApproval = configType === 'ThresholdBasedApprovalConfig';

  const [singleApproverError, setSingleApproverError] = useState(false);
  const [thresholdListErrors, setThresholdListErrors] = useState<ErrorItem[]>([]);

  const [addingContact, setAddingContact] = useState<{ onFulfilled: (approverId: string) => void } | null>(null);

  const { data: accountSettings, isLoading: isAccountSettingsLoading } = useGetAccountSettingsQuery(
    { accountId: accountId! },
    { skip: !accountId }
  );

  const {
    data: usersData,
    isLoading: usersLoading,
    isFetching: usersFetching,
    refetch: reloadUsers,
  } = useListAccountUsersQuery({ accountId: accountId! }, { skip: !accountId });

  const handleApproverIdChange = (approver: string | null) => setSingleApproverId(approver);
  const handleThresholdListChange = (newValue: RowItem[]) => setThresholdList(newValue);
  const handleRadioChange = (type: ApprovalConfiguration['type']) => {
    setSingleApproverError(false);
    setThresholdListErrors([]);
    setConfigType(type);
  };

  const isValid = () => {
    // Skip validation as it's true/false
    if (isPowerUserApprover) {
      return true;
    }
    // Validate single approver fields
    if (isSingleApprover) {
      const valid = !!singleApproverId;
      setSingleApproverError(!valid);
      return valid;
    }

    // Validate threshold list fields
    let valid = true;
    const errors: ErrorItem[] = [];
    const priceList: number[] = [];
    thresholdList.forEach((item, index) => {
      const lastItem = index === thresholdList.length - 1;
      if ((item.limit || lastItem) && !item.approver) {
        // Missing approver for filled limit or for the Unlimited value
        errors.push({ limit: false, approver: true });
        valid = false;
      } else if (
        (item.approver && !lastItem && !item.limit) ||
        (item.limit && priceList.includes(parseFloat(item.limit)))
      ) {
        // Missing limit for filled approver or duplicated amount
        errors.push({ limit: true, approver: false });
        valid = false;
      } else {
        // Without errors
        errors.push({ limit: false, approver: false });
      }

      if (item.limit) {
        priceList.push(parseFloat(item.limit));
      }
    });
    setThresholdListErrors(errors);

    return valid;
  };

  const handleAddContactRequest = ({ onAdded }: { onAdded: (approverId: string) => void }) => {
    setAddingContact({ onFulfilled: onAdded });
  };

  const handleModalClose = () => {
    setAddingContact(null);
  };
  const handleNewContactCreated = (newUser: NewUserData) => {
    if (newUser?.userId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      reloadUsers();
      // Select user in the autocomplete
      if (addingContact && addingContact.onFulfilled) {
        addingContact.onFulfilled(newUser.userId);
      }
    }
    handleModalClose();
  };
  const handleSave = async () => {
    if (!isValid() || !accountSettings) {
      return;
    }

    try {
      switch (configType) {
        case 'AllPowerUsersApprovalConfig':
          await setAccountConfiguration({
            accountId,
            body: { config: { approvalConfiguration: { type: configType } } },
          });
          break;
        case 'SingleApproverApprovalConfig':
          await setAccountConfiguration({
            accountId,
            body: {
              config: { approvalConfiguration: { type: configType, approver: singleApproverId! } },
            },
          });
          break;
        case 'ThresholdBasedApprovalConfig':
          await setAccountConfiguration({
            accountId,
            body: {
              config: {
                approvalConfiguration: {
                  type: configType,
                  approvers: mapThresholdListToApproversPerThreshold(thresholdList),
                },
              },
            },
          });
          break;
      }
      enqueueSnackbar(t('PREFERENCES.WORKFLOW.APPROVAL.SNACKBAR.SUCCESS'), {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(t('PREFERENCES.WORKFLOW.APPROVAL.SNACKBAR.ERROR'), {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Grid container item xs={12}>
        {disabled && <DeprecatedBanner />}
        <Grid className={styles.wrap} item xs={12}>
          <Paper className={styles.narrow}>
            <Typography variant="heading-s">{t('PREFERENCES.WORKFLOW.APPROVERS.TITLE')}</Typography>
            <Box className={styles.subtitle}>
              <Typography variant="body-regular-m">{t('PREFERENCES.WORKFLOW.APPROVERS.PARAGRAPH')}</Typography>
              <Typography variant="button-m">{` ${t(
                'PREFERENCES.WORKFLOW.APPROVERS.PARAGRAPH_ADMIN_NOTE'
              )}`}</Typography>
            </Box>

            <Stack className={styles['radio-group']} gap="32px">
              <Stack className={styles['poweruser-approver-container']} gap="16px">
                <Stack direction="row" className={styles['radio-box']}>
                  <Radio
                    name="poweruser-approver"
                    className={clsx(styles['radio-button'], { [styles['disabled-svg']]: disabled })}
                    checked={isPowerUserApprover}
                    onChange={() => handleRadioChange('AllPowerUsersApprovalConfig')}
                    disabled={disabled}
                  />
                  <Stack
                    flexDirection="column"
                    onClick={disabled ? undefined : () => handleRadioChange('AllPowerUsersApprovalConfig')}
                  >
                    <Typography variant="heading-xs">
                      {t('PREFERENCES.WORKFLOW.APPROVERS.POWERUSER_APPROVER.TITLE')}
                    </Typography>
                    <Typography variant="body-regular-s" className={styles.text}>
                      {t('PREFERENCES.WORKFLOW.APPROVERS.POWERUSER_APPROVER.PARAGRAPH')}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack className={styles['single-approver-container']} gap="16px">
                <Stack direction="row" className={styles['radio-box']}>
                  <Radio
                    name="single-approver"
                    className={clsx(styles['radio-button'], { [styles['disabled-svg']]: disabled })}
                    checked={isSingleApprover}
                    onChange={() => handleRadioChange('SingleApproverApprovalConfig')}
                    disabled={disabled}
                  />
                  <Stack
                    flexDirection="column"
                    onClick={disabled ? undefined : () => handleRadioChange('SingleApproverApprovalConfig')}
                  >
                    <Typography variant="heading-xs">
                      {t('PREFERENCES.WORKFLOW.APPROVERS.SINGLE_APPROVER.TITLE')}
                    </Typography>
                    <Typography variant="body-regular-s" className={styles.text}>
                      {t('PREFERENCES.WORKFLOW.APPROVERS.SINGLE_APPROVER.PARAGRAPH')}
                    </Typography>
                  </Stack>
                </Stack>
                {isSingleApprover && (
                  <Box className={styles['field-container']}>
                    <ApproverAutocomplete
                      options={usersData?.users || []}
                      isLoading={usersLoading || usersFetching}
                      selectedId={singleApproverId}
                      onSelected={handleApproverIdChange}
                      onAddRequest={() => {
                        setAddingContact({ onFulfilled: handleApproverIdChange });
                      }}
                      error={singleApproverError}
                      disabled={disabled}
                    />
                  </Box>
                )}
              </Stack>

              <Stack className={styles['threshold-container']} gap="16px">
                <Stack direction="row" className={styles['radio-box']}>
                  <Radio
                    name="threshold"
                    className={clsx(styles['radio-button'], { [styles['disabled-svg']]: disabled })}
                    checked={isThresholdApproval}
                    onChange={() => handleRadioChange('ThresholdBasedApprovalConfig')}
                    disabled={disabled}
                  />
                  <Stack
                    flexDirection="column"
                    onClick={disabled ? undefined : () => handleRadioChange('ThresholdBasedApprovalConfig')}
                  >
                    <Typography variant="heading-xs">
                      {t('PREFERENCES.WORKFLOW.APPROVERS.APPROVAL_THRESHOLDS.TITLE')}
                    </Typography>
                    <Typography variant="body-regular-s" className={styles.text}>
                      {t('PREFERENCES.WORKFLOW.APPROVERS.APPROVAL_THRESHOLDS.PARAGRAPH')}
                    </Typography>
                  </Stack>
                </Stack>
                {isThresholdApproval && (
                  <Box className={styles['field-container']}>
                    <ApproverThresholdList
                      users={usersData?.users || []}
                      currency={accountSettings?.preferredCurrency || ''}
                      isLoading={usersLoading || usersFetching}
                      value={thresholdList}
                      onValueChanged={handleThresholdListChange}
                      onAddUserRequest={handleAddContactRequest}
                      errorList={thresholdListErrors}
                      disabled={disabled}
                    />
                  </Box>
                )}
              </Stack>
            </Stack>

            <Stack className={styles['buttons-wrap']}>
              <Button
                variant="solid"
                color="primary"
                onClick={handleSave}
                isLoading={isAccountSettingsLoading || isConfigurationUpdating}
                disabled={disabled || isAccountSettingsLoading || isConfigurationUpdating}
              >
                {t('DIALOG.BUTTONS.SAVE')}
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <ModalWindow
        title={<Typography variant="heading-s">{t('PREFERENCES.WORKFLOW.NEW_CONTACT.TITLE')}</Typography>}
        content={<NewContact onContactCreated={handleNewContactCreated} />}
        open={!!addingContact}
        onClose={handleModalClose}
        topActionsPosition
      />
    </>
  );
};

export default SelectApprovers;
