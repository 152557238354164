import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogHeader } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { useCustomFieldsListContext } from '../CustomFieldsListContextProvider';
import { DropdownValuesList } from '@vertice/core/src/modules/saas/contract/components/CustomFieldsList/components/DropdownValuesList';
import { Alert, AlertIconButton } from '@verticeone/design-system';
import { CloseOutlined } from '@mui/icons-material';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const SetValuesDialog = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT_CUSTOMIZATION.SET_VALUES_DIALOG' });
  const { dialogContext, setDialogContext } = useCustomFieldsListContext();
  const [values, setValues] = useState<CustomContractFieldDropdownOption[]>([]);
  const [error, setError] = useState<string | undefined>();

  const noValues = !values || values.length === 0;

  useEffect(() => {
    setValues(dialogContext?.values ?? []);
    setError(undefined);
  }, [dialogContext]);

  const saveValues = async (value: CustomContractFieldDropdownOption[]) => {
    if (!dialogContext) {
      return;
    }

    return dialogContext.onSave(value);
  };

  const handleSave = async () => {
    await saveValues(values);
    setDialogContext(undefined);
  };

  const handleClose = () => {
    setDialogContext(undefined);
  };

  if (!dialogContext) {
    return null;
  }

  return (
    <Dialog open={!!dialogContext} setOpen={(open) => !open && handleClose()} size="M" width={680} align="center">
      <DialogHeader>{t('TITLE')}</DialogHeader>
      <DialogContent>
        {error && (
          <Alert size="S" variant="ghost" color="error" title={error}>
            <AlertIconButton icon={CloseOutlined} onClick={() => setError(undefined)} />
          </Alert>
        )}
        <DropdownValuesList values={values} onValuesChange={setValues} setError={setError} />
      </DialogContent>
      <DialogActions>
        <Button variant="outline" onClick={handleClose}>
          {t('BUTTON.CLOSE')}
        </Button>
        <Button color="primary" variant="solid" onClick={handleSave} disabled={noValues}>
          {t('BUTTON.SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetValuesDialog;
