import dagre from '@dagrejs/dagre';
import partition from 'lodash/partition';

import { WorkflowLabelNode, WorkflowModel } from '../model/types';
import { getNodeDimensions } from './NodeComponents/getNodeDimensions';
import { LABEL_NODE_HEIGHT } from './modelToRendererGraph';

export const getWorkflowLabelNodesWithDimensions = (nodes: WorkflowLabelNode[]): WorkflowLabelNode[] => {
  /*
    The dimensions of the label nodes are calculated based on the width of the longest label node in the group.
    The group of label nodes is determined as nodes that have the same following or preceding node.
    The width of the label node is calculated based on the width of the label name.
   */
  const labelNodesToMap = new Map<
    string,
    {
      nodes: WorkflowLabelNode[];
      maxWidth: number;
    }
  >();

  const labelNodesFromMap = new Map<
    string,
    {
      nodes: WorkflowLabelNode[];
      maxWidth: number;
    }
  >();

  nodes.forEach((node) => {
    const labelNodesTo = labelNodesToMap.get(node.to);
    if (labelNodesTo) {
      labelNodesTo.nodes.push(node);
      labelNodesTo.maxWidth = Math.max(labelNodesTo.maxWidth, node.width);
    } else {
      labelNodesToMap.set(node.to, {
        nodes: [node],
        maxWidth: node.width,
      });
    }
  });

  const labelNodeToIds: string[] = [];

  labelNodesToMap.forEach((labelNodesTo) => {
    if (labelNodesTo.nodes.length > 1) {
      const labelNodeIds = labelNodesTo.nodes.map((node) => node.id);
      labelNodeToIds.push(...labelNodeIds);
    }
  });

  nodes.forEach((node) => {
    if (!labelNodeToIds.includes(node.id)) {
      const labelNodesFrom = labelNodesFromMap.get(node.from);
      if (labelNodesFrom) {
        labelNodesFrom.nodes.push(node);
        labelNodesFrom.maxWidth = Math.max(labelNodesFrom.maxWidth, node.width);
      } else {
        labelNodesFromMap.set(node.from, {
          nodes: [node],
          maxWidth: node.width,
        });
      }
    }
  });

  const workflowLabelNodesWithDimensions: WorkflowLabelNode[] = [];

  labelNodesToMap.forEach((labelNodesTo) => {
    if (labelNodesTo.nodes.length > 1) {
      labelNodesTo.nodes.forEach((node) => {
        workflowLabelNodesWithDimensions.push({
          ...node,
          width: labelNodesTo.maxWidth,
        });
      });
    }
  });

  labelNodesFromMap.forEach((labelNodesFrom) => {
    labelNodesFrom.nodes.forEach((node) => {
      workflowLabelNodesWithDimensions.push({
        ...node,
        width: labelNodesFrom.maxWidth,
      });
    });
  });

  return workflowLabelNodesWithDimensions;
};

export const getDagreGraphFromWorkflowModel = (model: WorkflowModel) => {
  const dagreGraph = new dagre.graphlib.Graph({ compound: true });

  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR', nodesep: 80, ranksep: 96, edgesep: 120 });

  const workflowLabelNodes: WorkflowLabelNode[] = [];

  model.nodes.forEach((node) => {
    if (node.kind === 'label') {
      workflowLabelNodes.push(node);
    } else {
      const nodeDimensions = getNodeDimensions(node);
      dagreGraph.setNode(node.id, nodeDimensions);
    }
  });

  getWorkflowLabelNodesWithDimensions(workflowLabelNodes).forEach((labelNode) => {
    dagreGraph.setNode(labelNode.id, {
      width: labelNode.width,
      height: LABEL_NODE_HEIGHT,
    });
  });

  const [defaultEdges, nonDefaultEdges] = partition(model.edges, (edge) => {
    const isFromExclusiveGateway = model.nodes.some(
      (node) => node.kind === 'gateway' && node.id === edge.from && node.gatewayType === 'Exclusive'
    );
    return isFromExclusiveGateway ? edge.isDefault : false;
  });
  //
  [...nonDefaultEdges, ...defaultEdges].forEach((edge) => {
    dagreGraph.setEdge(edge.from, edge.to, { id: edge.id });
  });

  dagre.layout(dagreGraph);

  return dagreGraph;
};
