/**
 * Returns a copy of the array that has the item at oldIndex moved to newIndex.
 */
export const moveArrayItem = <T>(array: T[], oldIndex: number, newIndex: number): T[] => {
  if (oldIndex < 0 || oldIndex >= array.length || newIndex < 0 || newIndex >= array.length) {
    return array; // Return the array as-is if indices are out of bounds
  }
  const newArray = [...array];
  const item = newArray.splice(oldIndex, 1)[0];
  newArray.splice(newIndex, 0, item);
  return newArray;
};
