import { mapToFormInteraface } from './mapToFormInterface';
import { GenericFormField } from '../types';
import { flow, camelCase, upperFirst } from 'lodash';
import { FormDefinition } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { FormDefinitionCamelCase } from '@vertice/slices/src/api/enhancedCatalogAPI';

const camelToPascal = flow(camelCase, upperFirst);

function transformKeysToPascalCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToPascalCase(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const pascalKey = camelToPascal(key);
      acc[pascalKey] = transformKeysToPascalCase(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}

export function createNewGenericFormDefinition(
  oldDefinition: FormDefinitionCamelCase,
  newUrn: string,
  fields: GenericFormField[]
): FormDefinition {
  // We remove variable property from the field layout
  const layoutFields = fields.map((field) => {
    const { ...fieldWithoutVariable } = field;
    return fieldWithoutVariable;
  });

  //TODO use variable map in interface
  //

  return {
    Kind: oldDefinition.kind,
    ResourceUrn: newUrn,
    Version: '2023-10-11',
    Form: {
      ...transformKeysToPascalCase(oldDefinition.form),
      Name: oldDefinition.form?.name,
      Interface: mapToFormInteraface({ fields }),
      FormUiModel: {
        Kind: 'Vertice:Form',
        Version: '2023-10-11',
        Layout: { Fields: transformKeysToPascalCase(layoutFields) },
      },
    },
  };
}
