import { useCallback } from 'react';
import { DropdownValueListItem } from './types';

export type UseDropdownValuesListModelParams = {
  valueRows?: DropdownValueListItem[];
  setValueRows: (newRows: DropdownValueListItem[]) => void;
};

export const useDropdownValuesListModel = ({ valueRows, setValueRows }: UseDropdownValuesListModelParams) => {
  const updateValue = useCallback(
    (updatedRow: DropdownValueListItem) => {
      if (!valueRows) {
        return;
      }

      setValueRows(valueRows?.map((item) => (item.id === updatedRow.id ? { ...item, ...updatedRow } : item)));
    },
    [valueRows, setValueRows]
  );

  const addValue = useCallback(
    (newValue: DropdownValueListItem) => {
      if (!valueRows) {
        return;
      }

      setValueRows([...valueRows, newValue]);
    },
    [valueRows, setValueRows]
  );

  const removeValue = useCallback(
    (id: string) => {
      if (!valueRows) {
        return;
      }

      setValueRows(valueRows?.filter((item) => item.id !== id, []));
    },
    [valueRows, setValueRows]
  );

  return {
    addValue,
    updateValue,
    removeValue,
  };
};
