import { z } from 'zod';

export const dateFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const dateFieldSchema = z.object({
  name: z.string(),
  type: z.literal('DATE'),
  metadata: dateFieldMetadataSchema,
});

export type DateFieldMetadata = z.infer<typeof dateFieldMetadataSchema>;

export type DateFieldType = z.infer<typeof dateFieldSchema>;
