import { FC } from 'react';
import { DynamicFormField, DynamicFormPresenterConfig, DynamicFormSchema } from '../types';
import { Stack } from '@mui/material';
import { FieldValues, FormProvider, SubmitHandler } from 'react-hook-form';
import { Button, Divider } from '@verticeone/design-system';
import { fieldDefsByType } from '../fields/fields';
import { useTranslation } from 'react-i18next';
import { useDynamicFormPresenterForm } from './hooks/useDynamicFormPresenterForm';
import { FormEntrySkeleton } from '../../fields/FormEntry/FormEntrySkeleton';

type DynamicFormPresenterProps = {
  formSchema: DynamicFormSchema;
  config: DynamicFormPresenterConfig;
  defaultValues?: FieldValues;
  onSubmit?: (values: FieldValues) => Promise<void>;
};

export const DynamicFormPresenter: FC<DynamicFormPresenterProps> = (props) => {
  const { formSchema, onSubmit, defaultValues, config } = props;
  const { t } = useTranslation();

  const form = useDynamicFormPresenterForm({ formSchema, config, defaultValues });

  const submitHandler: SubmitHandler<FieldValues> = async (values) => {
    if (!onSubmit) {
      return;
    }
    await onSubmit(values);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        style={{ display: 'flex', minHeight: 0, height: '100%', minWidth: 0 }}
      >
        <Stack minHeight={0} width="100%" height="100%">
          <Stack padding={6} overflow="auto" minHeight={0} height="100%">
            {formSchema.fields.map(mapFieldToComponent(config))}
          </Stack>
          <Divider />
          <Stack direction="row" padding={6} gap={2}>
            <Stack width="100%">
              <Button
                type="submit"
                variant="solid"
                size="S"
                color="secondary"
                disabled={!onSubmit || form.formState.isSubmitting || !form.formState.isValid}
                isLoading={form.formState.isSubmitting}
              >
                {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.SUBMIT_AND_COMPLETE')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export const DynamicFormPresenterSkeleton = () => (
  <Stack p={6} spacing={10}>
    <FormEntrySkeleton />
    <FormEntrySkeleton />
    <FormEntrySkeleton />
  </Stack>
);

export const mapFieldToComponent = (config: DynamicFormPresenterConfig) => (dynamicField: DynamicFormField) => {
  const Presenter = fieldDefsByType[dynamicField.type]?.presenter;

  return Presenter ? (
    <Presenter key={dynamicField.name} field={dynamicField} config={config} />
  ) : (
    <div key={dynamicField.name}>Unknown field type</div>
  );
};
