import { FC, MutableRefObject, PropsWithChildren, useMemo, useRef } from 'react';
import { useResizeObserver, Tooltip } from '@verticeone/design-system';
import { Stack } from '@mui/material';

import { StyledTooltipContent } from '../../components/StyledTooltipContent';
import { getTextWidth, FontProperties } from '../../Validation/utils';

type ConditionalTooltipProps = PropsWithChildren & {
  value: string;
  offset: number;
  fontProperties?: FontProperties;
};

export const ConditionalTooltip: FC<ConditionalTooltipProps> = ({
  value,
  fontProperties = {
    fontSize: '14px',
    fontName: 'Inter',
  },
  children,
  offset,
}) => {
  const containerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const { width: containerWidth } = useResizeObserver(containerRef);

  const valueWidth = useMemo(() => getTextWidth(value, fontProperties), [value, fontProperties]);
  const isTooltipEnabled = containerWidth && valueWidth > containerWidth - offset;

  return (
    <Stack ref={containerRef}>
      {isTooltipEnabled ? (
        <Tooltip size="S" content={<StyledTooltipContent>{value}</StyledTooltipContent>}>
          <Stack>{children}</Stack>
        </Tooltip>
      ) : (
        children
      )}
    </Stack>
  );
};
