import { FC } from 'react';
import type { FullOption } from 'react-querybuilder';
import { useGetXTypeDatasourceWithParamsQuery } from '@vertice/slices/src/api/enhancedServicesAPI';
import { Select, TextField } from '@verticeone/design-system';

import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { Skeleton } from '@mui/material';
import { ConditionalTooltip } from './ConditionalTooltip';
import { xDatasourceParams } from '../../types';
import { useTranslation } from 'react-i18next';

type DatasourceItem = {
  id: string | object;
  title: string;
};

type SimpleDatasourceItem = Omit<DatasourceItem, 'id'> & {
  id: string;
};

type XTypeDatasourceAsyncSelectProps = {
  xTypeUrn: string;
  onChange: (value?: string) => void;
  value?: string;
  testId?: string;
  disabled?: boolean;
  error?: boolean;
  dataSourceParams?: xDatasourceParams;
};

const isSimpleDatasourceItem = (item: DatasourceItem): item is SimpleDatasourceItem => {
  return typeof item.id === 'string';
};

export const XTypeDatasourceAsyncSelect: FC<XTypeDatasourceAsyncSelectProps> = ({
  xTypeUrn,
  onChange,
  value,
  testId,
  disabled,
  error,
  dataSourceParams,
}) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES',
  });

  const { data, isLoading, isFetching } = useGetXTypeDatasourceWithParamsQuery({
    resourceUrn: xTypeUrn,
    accountId,
    body: {
      params: dataSourceParams || {},
    },
  });

  if (isLoading || isFetching) {
    return <Skeleton variant="rounded" height="90%" width="100%" />;
  }

  const options = (data?.items || []).filter(isSimpleDatasourceItem).map((item) => ({
    value: item.id,
    name: item.id,
    label: item.title,
  }));

  if (!options.length) {
    /*
     * This should never happen, but just in case adding TextField here as fallback.
     * When options are missing for XType it is still to be implemented on the BE side.
     */
    return (
      <TextField
        testId={testId}
        variant="outlined"
        size="S"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type="text"
        disabled={disabled}
        placeholder={t('INPUT_VALUE')}
        error={error}
      />
    );
  }

  const selectedOption = options?.find((option) => option.value === value);

  return (
    <ConditionalTooltip value={selectedOption?.label ?? ''} offset={48}>
      <Select<FullOption<string>, false>
        testId={testId}
        variant="outlined"
        size="S"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        value={selectedOption ?? null}
        onChange={(newValue) => onChange(newValue?.value)}
        options={options}
        menuPosition="fixed"
        isDisabled={disabled}
        placeholder={t('CHOOSE_VALUE')}
        error={error}
      />
    </ConditionalTooltip>
  );
};
