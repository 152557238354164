import { DataGrid, DesignSystemColor, Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { GRID_REORDER_COL_DEF, gridClasses, GridRowOrderChangeParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { DeepPartial } from 'react-hook-form';
import { RadioGroupValueListItem } from './types';
import useRadioGroupValuesListActions from './useRadioGroupValuesListActions';
import { useEditableDataGrid } from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';
import { enhanceRowsWithReorderProperty, getRowId, preProcessNonEmptyCell } from './utils';
import { EditableDataGridContextProvider } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import NoRowsOverlay from '../../../../../../saas/contract/components/NoRowsOverlay';
import { renderRowReorderCell } from '@mui/x-data-grid-pro/components/GridRowReorderCell';

import { Box, Stack, styled } from '@mui/material';
import { moveArrayItem } from '@verticeone/utils/arrays';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .Mui-error': {
    height: '100%',
    border: '1px solid',
    borderColor: theme.palette.error.color1,
  },
}));

export type RadioGroupValuesListProps = {
  values: string[];
  onValuesChange: (values: string[]) => void;
  color: DesignSystemColor;
  errorText?: string;
};

export const RadioGroupValuesList = ({ color, values = [], onValuesChange, errorText }: RadioGroupValuesListProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'DYNAMIC_FORM_BUILDER.DIALOG.RADIO_GROUP_LIST' });
  const apiRef = useGridApiRef();

  const valueRows = useMemo(() => values.map((value) => ({ id: value, value })), [values]);
  const setValueRows = (rows: RadioGroupValueListItem[]) => {
    onValuesChange(rows.map(({ value }) => value));
  };
  const { validateRow, onAddRow, onUpdateRow, onBeforeDeleteRow, onDeleteRow, onErrorsChange } =
    useRadioGroupValuesListActions({ valueRows, setValueRows });

  const handleRowOrderChange = ({ oldIndex, targetIndex }: GridRowOrderChangeParams) =>
    setValueRows(moveArrayItem(valueRows, oldIndex, targetIndex));

  const editableDataGrid = useEditableDataGrid<RadioGroupValueListItem>({
    apiRef,
    defaultMode: 'confirm',
    validateRow,
    onAddRow,
    onUpdateRow,
    onBeforeDeleteRow,
    onDeleteRow,
    onErrorsChange,

    // Add-button-specific
    withAddButton: true,
    createTmpAddRow: (id: string) => ({ id, value: '' } as DeepPartial<RadioGroupValueListItem>),
    addItemButtonLabel: t('ADD_BUTTON'),
    color,
  });

  const staticColumns = useMemo(
    () => [
      {
        ...GRID_REORDER_COL_DEF,
        // This helps to show __reorder__ property in the drag&drop placeholder also for the last added row
        editable: true,
        cellClassName: gridClasses.rowReorderCellContainer,
        renderEditCell: renderRowReorderCell,
      },
      {
        field: 'value',
        headerName: t('VALUE'),
        preProcessEditCellProps: preProcessNonEmptyCell(valueRows),
        valueParser: (value: string) => value.trimStart(),
        disableColumnMenu: true,
        editable: true,
        sortable: false,
        flex: 1,
      },
    ],
    [valueRows, t]
  );

  const columns = useMemo(
    () => [...staticColumns, ...(editableDataGrid.actionsColumn ? [editableDataGrid.actionsColumn] : [])],
    [staticColumns, editableDataGrid.actionsColumn]
  );

  const valueRowsWithReorderProperty = useMemo(
    () => enhanceRowsWithReorderProperty(valueRows, 'value') || [],
    [valueRows]
  );
  const rows = useMemo(
    () => [...valueRowsWithReorderProperty, ...(editableDataGrid.tmpAddRow ? [editableDataGrid.tmpAddRow] : [])],
    [valueRowsWithReorderProperty, editableDataGrid.tmpAddRow]
  );

  return (
    <Stack gap={1}>
      <StyledBox>
        <EditableDataGridContextProvider value={editableDataGrid.context}>
          <DataGrid
            {...editableDataGrid.dataGridProps}
            apiRef={apiRef}
            getRowId={getRowId}
            rows={rows}
            columns={columns}
            slots={{
              ...editableDataGrid.dataGridProps.slots,
              noRowsOverlay: () => <NoRowsOverlay text={t('NO_ROWS')} />,
            }}
            sortingMode="client"
            disableRowSelectionOnClick
            rowReordering
            onRowOrderChange={handleRowOrderChange}
            columnHeaderHeight={48}
            autoHeight={true}
            color={color}
          />
        </EditableDataGridContextProvider>
      </StyledBox>
      {errorText && (
        <Box px={4}>
          <Text color="error2" size="XS" variant="body-regular">
            {errorText}
          </Text>
        </Box>
      )}
    </Stack>
  );
};
