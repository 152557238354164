import { bffeWorkflowsAPI as api } from '../../api/bffeWorkflowsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    updateServiceCatalogServiceResource: build.mutation<
      UpdateServiceCatalogServiceResourceApiResponse,
      UpdateServiceCatalogServiceResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog/service`,
        method: 'POST',
        body: queryArg.updateServiceCatalogResourceService,
        params: {
          resourceUrn: queryArg.resourceUrn,
        },
      }),
    }),
    listServiceWorkflows: build.query<ListServiceWorkflowsApiResponse, ListServiceWorkflowsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows`,
        params: {
          serviceRef: queryArg.serviceRef,
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    listWorkflowVersions: build.query<ListWorkflowVersionsApiResponse, ListWorkflowVersionsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    getWorkflowVersion: build.query<GetWorkflowVersionApiResponse, GetWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}`,
      }),
    }),
    listTasks: build.query<ListTasksApiResponse, ListTasksApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tasks`,
        params: {
          maxResults: queryArg.maxResults,
          nextToken: queryArg.nextToken,
          taskStatus: queryArg.taskStatus,
        },
      }),
    }),
    getTaskOverview: build.query<GetTaskOverviewApiResponse, GetTaskOverviewApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}` }),
    }),
    getStatsForRequestsByVendors: build.query<
      GetStatsForRequestsByVendorsApiResponse,
      GetStatsForRequestsByVendorsApiArg
    >({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/stats/requestsByVendors` }),
    }),
    listRequests: build.query<ListRequestsApiResponse, ListRequestsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        params: {
          maxResults: queryArg.maxResults,
          nextToken: queryArg.nextToken,
          contractId: queryArg.contractId,
        },
      }),
    }),
    listRequestsDetails: build.mutation<ListRequestsDetailsApiResponse, ListRequestsDetailsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/details`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listRequestTasks: build.query<ListRequestTasksApiResponse, ListRequestTasksApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/tasks`,
        params: {
          maxResults: queryArg.maxResults,
          nextToken: queryArg.nextToken,
        },
      }),
    }),
    getRequestJournal: build.query<GetRequestJournalApiResponse, GetRequestJournalApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/journal` }),
    }),
    listBuyersBoardRequests: build.query<ListBuyersBoardRequestsApiResponse, ListBuyersBoardRequestsApiArg>({
      query: (queryArg) => ({
        url: `/buyersBoard`,
        params: {
          includeAll: queryArg.includeAll,
        },
      }),
    }),
    listBuyersBoardCustomerRequests: build.query<
      ListBuyersBoardCustomerRequestsApiResponse,
      ListBuyersBoardCustomerRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/buyersBoard/customer/requests`,
        params: {
          includeAll: queryArg.includeAll,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bffeWorkflowsAPICodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type UpdateServiceCatalogServiceResourceApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type UpdateServiceCatalogServiceResourceApiArg = {
  accountId: string;
  resourceUrn: string;
  /** Update service catalog service resource */
  updateServiceCatalogResourceService: UpdateServiceCatalogResourceService;
};
export type ListServiceWorkflowsApiResponse = /** status 200 Get workflows of specific type */ WorkflowsResponse;
export type ListServiceWorkflowsApiArg = {
  accountId: string;
  serviceRef?: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type ListWorkflowVersionsApiResponse =
  /** status 200 Get workflows versions of specific type */ WorkflowVersionsResponse;
export type ListWorkflowVersionsApiArg = {
  accountId: string;
  workflowId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type GetWorkflowVersionApiResponse = /** status 200 Get workflow version info */ WorkflowVersion;
export type GetWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
};
export type ListTasksApiResponse = /** status 200 List of contract tasks */ {
  items: RequestTask[];
  nextToken?: string | null;
};
export type ListTasksApiArg = {
  accountId: string;
  maxResults?: number;
  nextToken?: string | null;
  taskStatus?: any;
};
export type GetTaskOverviewApiResponse = /** status 200 Get task and its request overview */ TaskOverview;
export type GetTaskOverviewApiArg = {
  accountId: string;
  taskId: string;
};
export type GetStatsForRequestsByVendorsApiResponse = /** status 200 Stats of requests by vendors */ {
  stats: {
    [key: string]: {
      activeRequestsCount: number;
    };
  };
};
export type GetStatsForRequestsByVendorsApiArg = {
  accountId: string;
};
export type ListRequestsApiResponse = /** status 200 List of requests */ {
  items: Request[];
  nextToken?: string | null;
};
export type ListRequestsApiArg = {
  accountId: string;
  maxResults?: number;
  nextToken?: string | null;
  contractId?: string;
};
export type ListRequestsDetailsApiResponse = /** status 200 List of requests details */ Request[];
export type ListRequestsDetailsApiArg = {
  accountId: string;
  /** Retrieves requests details */
  body: string[];
};
export type ListRequestTasksApiResponse = /** status 200 List of contract tasks */ {
  items: RequestTask[];
  nextToken?: string | null;
};
export type ListRequestTasksApiArg = {
  accountId: string;
  requestId: string;
  maxResults?: number;
  nextToken?: string | null;
};
export type GetRequestJournalApiResponse = /** status 200 Request journal response */ {
  items: JournalItem[];
};
export type GetRequestJournalApiArg = {
  accountId: string;
  requestId: string;
};
export type ListBuyersBoardRequestsApiResponse = /** status 200 List of requests for buyers board */ {
  items: BuyersBoardOwner[];
};
export type ListBuyersBoardRequestsApiArg = {
  includeAll?: boolean;
};
export type ListBuyersBoardCustomerRequestsApiResponse = /** status 200 List of customer requests for buyers board */ {
  items: BuyersBoardCustomer[];
};
export type ListBuyersBoardCustomerRequestsApiArg = {
  includeAll?: boolean;
};
export type UpdateServiceCatalogResourceService = {
  /** Custom name for the service */
  name?: string;
  /** Handler URN.
    Example: `urn:verticeone:vertice:123456:vertice-workflows:workflow/default_triage_workflow`
     */
  handlerUrn: string;
};
export type Workflow = {
  id: string;
  workflowName: string;
  activeInstances: number;
  completedInstances: number;
  activeTasks: number;
  activeVersionId?: string;
  activeVersionName?: string;
  lastInvokedAt?: string;
};
export type WorkflowsResponse = {
  serviceRef?: string;
  workflows: Workflow[];
  nextToken?: string | null;
};
export type WorkflowVersion = {
  id: string;
  workflowName: string;
  versionId: string;
  versionName?: string;
  /** Urn of the workflow (without version part) */
  workflowRef: string;
  serviceRef?: string;
  activeInstances: number;
  completedInstances: number;
  status: 'PENDING' | 'ACTIVE' | 'ARCHIVED';
  createdAt?: string;
  lastInvokedAt?: string;
  updatedAt?: string;
  workflowDefinition?: {
    [key: string]: any;
  };
  workflowDefinitionStatus?: {
    warning: number;
    error: number;
    info: number;
    stale?: boolean;
  };
};
export type WorkflowVersionsResponse = {
  serviceRef?: string;
  workflows: WorkflowVersion[];
  nextToken?: string | null;
};
export type TaskStatus = 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'FAILED' | 'TERMINATED';
export type Task = {
  /** Reference to the task.
    Example: `123456`
     */
  ref: string;
  /** For now optional to avoid breaking other endpoints, but should be required in the future.
   */
  taskId?: string;
  type: 'SERVICE' | 'USER';
  status: TaskStatus;
  /** Reference to the service resource.
    Example: `urn:verticeone:vertice::servicecatalog:service/Vertice/SaaS/Contracts/Negotiation/v1`
     */
  resourceUrn?: string;
  /** Task assignees. */
  assignment: string[];
  /** Task name. */
  name?: string;
  /** Task description. */
  description?: string;
  /** Workflow instance reference. */
  workflowInstanceRef: string;
  /** Workflow instance name. */
  workflowInstanceName?: string;
  input: {
    [key: string]: any;
  };
  result?: {
    [key: string]: any;
  };
  createdAt: string;
  closedAt?: string;
  fulfilledBy?: string;
  configurations?: {
    [key: string]: any;
  }[];
  draft?: {
    [key: string]: any;
  };
};
export type RequestTaskExtension = {
  /** Request reference. */
  requestRef?: string;
  /** Request reference. */
  childRequestRef?: string;
  requestType?: string;
  requestName?: string;
  /** Workflow name. */
  workflowName?: string;
};
export type RequestTask = Task & RequestTaskExtension;
export type RequestOverview = {
  ref: string;
  name: string;
  type?: string;
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'TERMINATED' | 'FAILED';
  /** Reference for the service catalog item.
   */
  serviceRef?: string;
};
export type WorkflowOverview = {
  ref: string;
  name?: string;
  instanceName?: string;
};
export type TaskOverview = {
  task: Task;
  request?: RequestOverview;
  workflow?: WorkflowOverview;
};
export type Request = {
  ref: string;
  serviceRef: string;
  serviceName?: string;
  name: string;
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'CANCELLED' | 'TERMINATED' | 'FAILED';
  requestorRef: string;
  sourceRef: string;
  executionRef?: string | null;
  /** Workflow name. */
  workflowName?: string;
  /** This schema defines the structure that is always provided on process input by Vertice Services and is used internally by the Vertice platform.
   */
  verticeReserved?: {
    /** The account ID of the account that the request is being made for. Will always be present.
     */
    accountId?: string;
    /** The account ID this request is running on behalf of. Will be present if the request is being made on behalf of another account.
     */
    parentAccountId?: string;
    vendor?: {
      name?: string;
      id?: string;
      type?: 'PREDEFINED' | 'INLINE';
    };
    contractId?: string;
    /** If not provided then it is copied from the parent request if that belongs to the same account
     */
    requestOwner?: string;
    request?: {
      [key: string]: any;
    };
    [key: string]: any;
  };
  widgetData?: {
    [key: string]: any;
  };
  result?: {
    [key: string]: any;
  };
  resultType?: string;
  createdAt: string;
  createdBy: string | null;
  closedAt?: string;
  updatedAt?: string;
  changedBy?: string | null;
  tasks?: any[];
  taskAssignment?: string[];
  unreadMessages?: number;
  totalMessages?: number;
  parentRequestRef?: string;
  owner?: string;
  error?: string;
};
export type DateTime = string;
export type JournalItem = {
  actor?: string | null;
  intents: string[];
  delta: string;
  timestamp: DateTime;
  /** Task ID, if the journal item is related to a task */
  taskId?: string;
  /** Execution ID, if the journal item is related to an execution */
  executionId?: string;
  /** Request ID, if the journal item is related to a request */
  requestId?: string;
};
export type BuyersBoardRequest = {
  ref: string;
  serviceRef?: string;
  parentRequestRef: string;
  parentServiceRef?: string;
  title: string;
  customer: string;
  customerName: string;
  vendorId?: string;
  vendorName?: string;
  deadline: string;
  lastUpdated: string;
  widgetData?: {
    [key: string]: any;
  };
  tasks?: any[];
  workflowName?: string;
  taskAssignment?: string[];
};
export type BuyersBoardOwner = {
  owner: string;
  requests: BuyersBoardRequest[];
};
export type BuyersBoardCustomerRequest = {
  name: string;
  ref: string;
  serviceRef?: string;
  parentRequestRef?: string;
  parentServiceRef?: string;
  customer: string;
  customerName: string;
  vendorId?: string;
  vendorName?: string;
  lastUpdated?: string;
  widgetData?: {
    [key: string]: any;
  };
  tasks?: any[];
};
export type BuyersBoardCustomer = {
  accountId: string;
  requests: BuyersBoardCustomerRequest[];
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useUpdateServiceCatalogServiceResourceMutation,
  useListServiceWorkflowsQuery,
  useLazyListServiceWorkflowsQuery,
  useListWorkflowVersionsQuery,
  useLazyListWorkflowVersionsQuery,
  useGetWorkflowVersionQuery,
  useLazyGetWorkflowVersionQuery,
  useListTasksQuery,
  useLazyListTasksQuery,
  useGetTaskOverviewQuery,
  useLazyGetTaskOverviewQuery,
  useGetStatsForRequestsByVendorsQuery,
  useLazyGetStatsForRequestsByVendorsQuery,
  useListRequestsQuery,
  useLazyListRequestsQuery,
  useListRequestsDetailsMutation,
  useListRequestTasksQuery,
  useLazyListRequestTasksQuery,
  useGetRequestJournalQuery,
  useLazyGetRequestJournalQuery,
  useListBuyersBoardRequestsQuery,
  useLazyListBuyersBoardRequestsQuery,
  useListBuyersBoardCustomerRequestsQuery,
  useLazyListBuyersBoardCustomerRequestsQuery,
} = injectedRtkApi;
