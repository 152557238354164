import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Card, Text, Button, ToggleSwitch } from '@verticeone/design-system';

type IntegrationCardProps = {
  id: string;
  name: string;
  authType: string;
  enabled: boolean;
};

const IntegrationCard = ({ id, name, authType, enabled }: IntegrationCardProps) => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();

  const goToSetup = () => navigate(routes.PREFERENCES.INTEGRATIONS.CUSTOM.EDIT, { id });

  return (
    <Card>
      <Stack p={4} direction="column" borderBottom="1px solid" borderColor="core.color3">
        <Text variant="heading" size="S" color="text1">
          {name}
        </Text>
        <Text variant="heading" size="S" color="text1" sx={{ opacity: 0.4 }}>
          Custom Integration
        </Text>
        <Text variant="body-regular" size="S" color="text2" pt={4}>
          Type: {authType}
        </Text>
      </Stack>
      <Stack p={4} justifyContent="space-between" alignItems="center" direction="row">
        <Button variant="outline" size="S" color="neutral" onClick={goToSetup}>
          {t('PREFERENCES.INTEGRATIONS.SETUP')}
          <ArrowForwardIos sx={{ opacity: 0.6 }} />
        </Button>
        <Stack gap={2} direction="row" alignItems="center">
          <Text variant="label" size="S" color="primary" sx={{ opacity: 0.4 }}>
            ON
          </Text>
          <ToggleSwitch size="S" color="primary" checked={enabled} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default IntegrationCard;
