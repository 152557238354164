import { z } from 'zod';

export const shortTextFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const shortTextFieldSchema = z.object({
  name: z.string(),
  type: z.literal('SHORT_TEXT'),
  metadata: shortTextFieldMetadataSchema,
});

export type ShortTextFieldType = z.infer<typeof shortTextFieldSchema>;

export type ShortTextFieldMetadata = z.infer<typeof shortTextFieldMetadataSchema>;
