import { createPortableRoutes } from '@verticeone/router/src/routes/utils';

export const preferencesRoutes = createPortableRoutes({
  PREFERENCES: {
    path: '/preferences',
    children: {
      INTEGRATIONS: {
        path: 'integrations',
        children: {
          WORKFLOWS: {
            path: 'workflows',
            children: {
              JIRA: {
                path: 'jira',
              },
            },
          },
        },
      },
    },
  },
} as const);
