import { FC } from 'react';

import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '../../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { EditorDrawerScrollContainer } from '../../../EditorDrawer';
import { JiraTicketLink } from '../JiraCommon/JiraTicketLink';
import { ConnectionFormType } from '../JiraCommon/types';
import { ISSUE_KEY_INPUT_FIELD_VALUE, SUMMARY_INPUT_FIELD_VALUE } from '../utils';
import { FieldsSection } from './FieldsSection';
import { ResolutionSection } from './ResolutionSection';

type JiraSyncCompleteProps = {
  taskOverview?: TaskOverview;
};

export const JiraSyncComplete: FC<JiraSyncCompleteProps> = ({ taskOverview }) => {
  const { accountId } = useAccountContext();

  const { data: integrationData } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });

  const domainUrl = (integrationData?.parameters?.connectionForm as ConnectionFormType)?.domain;
  const ticketKey = taskOverview?.task?.result?.[ISSUE_KEY_INPUT_FIELD_VALUE];
  const ticketSummary = taskOverview?.task?.result?.[SUMMARY_INPUT_FIELD_VALUE];

  const ticketUrl = domainUrl && ticketKey ? `${domainUrl}browse/${ticketKey}` : undefined;
  const ticketTitle = ticketKey && ticketSummary ? `${ticketKey}: ${ticketSummary}` : undefined;

  return (
    <EditorDrawerScrollContainer>
      <JiraTicketLink url={ticketUrl} title={ticketTitle} />
      <ResolutionSection taskOverview={taskOverview} />
      <FieldsSection taskOverview={taskOverview} />
    </EditorDrawerScrollContainer>
  );
};
