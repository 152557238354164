import { FC, useState } from 'react';

import { VariableChip } from '../../../../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../../../../VariableSelector/VariablesDialog';
import type { Variable } from '../../../../../types';
import { useJiraVariablesGroups } from './useJiraVariableGroups';

type JiraVariableSelectorProps = {
  variables: Variable[];
  selectedVariable?: Variable;
  onSelectedVariableChange: (variable?: Variable) => void;
  isVariableTypeDisplayed?: boolean;
  isDisabled?: boolean;
  typeFilter?: string;
  selectedJiraVariables?: string[];
};

export const JiraVariableSelector: FC<JiraVariableSelectorProps> = ({
  variables,
  selectedVariable,
  onSelectedVariableChange,
  isVariableTypeDisplayed,
  isDisabled,
  typeFilter,
  selectedJiraVariables,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const variablesGroups = useJiraVariablesGroups({ variables });

  const handleVariableClick = (variable: Variable) => {
    onSelectedVariableChange(variable);
    setIsDialogOpen(false);
  };

  return (
    <>
      <VariableChip
        label={selectedVariable?.label}
        typeLabel={isVariableTypeDisplayed ? selectedVariable?.type.labels?.[0] : undefined}
        onClick={() => setIsDialogOpen(true)}
        withIcon
        isDisabled={isDisabled}
      />
      <VariablesDialog
        variablesGroups={variablesGroups}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
        typeFilter={typeFilter}
        disabledVariables={selectedJiraVariables}
        selectedVariableId={selectedVariable?.id}
      />
    </>
  );
};
