import type { RuleGroupType, RuleType, ParseJsonLogicOptions } from 'react-querybuilder';

export const DEFAULT_RULE: RuleType = {
  field: '',
  value: '',
  operator: '',
};

export const DEFAULT_QUERY: RuleGroupType = {
  combinator: 'and',
  rules: [DEFAULT_RULE],
};

export const JSON_LOGIC_OPERATIONS: ParseJsonLogicOptions['jsonLogicOperations'] = {
  '===': (value) => ({ operator: '===', field: value[0].var, value: value[1] }),
  '!==': (value) => ({ operator: '!==', field: value[0].var, value: value[1] }),
  // Legacy operations
  '==': (value) =>
    typeof value[1] === 'boolean'
      ? { operator: '===', field: value[0].var, value: value[1] }
      : { operator: '=', field: value[0].var, value: value[1] },
  '!=': (value) =>
    typeof value[1] === 'boolean'
      ? { operator: '!==', field: value[0].var, value: value[1] }
      : { operator: '!=', field: value[0].var, value: value[1] },
};
