import { Stack } from '@mui/material';
import { FC, useEffect, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField, TextFieldArea, TextFieldCaption } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { EditorDrawerGroup } from '../../EditorDrawer';
import { EditServiceTaskFormData } from './schema';

type TaskPropertiesProps = {
  onDirty: () => void;
};

export const TaskProperties: FC<TaskPropertiesProps> = ({ onDirty }) => {
  const { t } = useTranslation();
  const id = useId();

  const {
    register,
    formState: { errors, isDirty },
  } = useFormContext<EditServiceTaskFormData>();

  useEffect(() => {
    if (isDirty) {
      onDirty();
    }
  }, [isDirty, onDirty]);

  return (
    <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.TASK_PROPERTIES')}>
      <Stack gap={4}>
        <Stack gap={1}>
          <TextFieldCaption
            required
            label={t('INTELLIGENT_WORKFLOWS.JIRA.TASK_NAME')}
            size="XS"
            htmlFor={`${id}-name`}
          />
          <TextField
            {...register('name')}
            id={`${id}-name`}
            variant="outlined"
            size="S"
            fullWidth
            autoComplete="off"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            error={!!errors.name}
          />
        </Stack>
        <Stack gap={1}>
          <TextFieldCaption
            label={t('INTELLIGENT_WORKFLOWS.JIRA.TASK_DESCRIPTION')}
            size="XS"
            htmlFor={`${id}-description`}
          />
          <TextFieldArea
            {...register('description')}
            id={`${id}-description`}
            variant="outline"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            autoComplete="off"
            maxRows={6}
            style={{ resize: 'none' }}
          />
        </Stack>
      </Stack>
    </EditorDrawerGroup>
  );
};
