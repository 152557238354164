import type {
  ListTasksApiResponse,
  RequestTask,
  Task,
  TaskStatus,
} from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { getFullName } from '@verticeone/utils/formatting';
import { findKey } from 'lodash';

import { parseUserRef, parseUserTaskRef } from '../../../../hooks/workflows/refUtils';
import type { UsersByIdReturn } from '../../../../hooks/useRelevantUsersById';
import { FilterStatus } from './queryParams';
import dayjs from 'dayjs';

export type TaskRowAssignee = {
  fullName: string;
  id: string;
  email?: string;
  valid: boolean;
};

export type TaskRow = RequestTask & {
  assignees: TaskRowAssignee[];
  id: string;
};

export const statusMap: Record<Exclude<FilterStatus, 'ALL'>, Array<Partial<TaskStatus>>> = {
  COMPLETED: ['COMPLETED', 'TERMINATED', 'FAILED'],
  IN_PROGRESS: ['PENDING', 'ACTIVE'],
};

export const getAssignedUsersFromTaskList = (data?: ListTasksApiResponse) => {
  return data?.items.flatMap((task) => task.assignment.map((ref) => parseUserRef(ref).userId)) || [];
};

export const taskStatusToFilterStatus = (status: TaskStatus) => {
  return findKey(statusMap, (statuses) => statuses.includes(status)) as Exclude<FilterStatus, 'ALL'>;
};

export const getDaysActive = (start: string, end?: string) => {
  return Math.ceil(dayjs(end).diff(dayjs(start), 'day', true));
};

export const getTaskRows = (tasks: Task[], usersById: UsersByIdReturn): Array<TaskRow> => {
  return (
    tasks.map((task) => {
      const assignees =
        task?.assignment?.map(parseUserRef).map(({ userId }) => ({
          fullName: getFullName(usersById[userId]) || userId,
          id: userId,
          email: usersById[userId]?.email,
          valid: !!usersById[userId],
        })) || [];
      return {
        assignees,
        id: parseUserTaskRef(task.ref).taskId,
        ...task,
      };
    }) || []
  );
};
