import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Text } from '@verticeone/design-system';
import { EditorDrawerGroup } from '../../../EditorDrawer';
import { findValueInTaskObj } from '../JiraCommon/utils';
import { JIRA_FIELD_PREFIX_V2_ESCAPED, SUMMARY_INPUT_FIELD_VALUE } from '../utils';
import { INPUT_RESOLUTION_KEY, INPUT_STATUS_KEY } from './constants';

type FieldsSectionProps = {
  taskOverview?: TaskOverview;
};

export const FieldsSection: FC<FieldsSectionProps> = ({ taskOverview }) => {
  const { t } = useTranslation();

  const fieldKeys = Object.keys(taskOverview?.task.input ?? {}).filter((key) => {
    const fieldPrefix = new RegExp(`${JIRA_FIELD_PREFIX_V2_ESCAPED}(.*?)<(.*?)>`);
    const skippedFields = new RegExp(INPUT_STATUS_KEY.source + '|' + INPUT_RESOLUTION_KEY.source);

    return fieldPrefix.test(key) && !skippedFields.test(key);
  });

  if (fieldKeys.length === 0) return null;

  return (
    <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.FIELDS_GROUP_TITLE')}>
      <Stack gap={4}>
        {fieldKeys.map((key) => {
          const inputFieldKey = findValueInTaskObj(key, taskOverview?.task.input);
          const fieldValue = taskOverview?.task.result?.[inputFieldKey];

          if (inputFieldKey === SUMMARY_INPUT_FIELD_VALUE) return null;

          return (
            <Stack gap={2} key={inputFieldKey}>
              <Text variant="caption" size="XS" color="text2">
                {inputFieldKey}
              </Text>
              <Text variant="body-regular" size="S" color="text1">
                {typeof fieldValue === 'object' ? fieldValue?.title : fieldValue}
              </Text>
            </Stack>
          );
        })}
      </Stack>
    </EditorDrawerGroup>
  );
};
