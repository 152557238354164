import type { Variable } from '../../../../../types';
import { useTranslation } from 'react-i18next';
import { countSelectableVariables } from '../../../../../VariableSelector/utils';
import type { OriginsWithVariables, VariablesGroup } from '../../../../../VariableSelector/types';

export const useJiraVariablesGroups = ({ variables }: { variables: Variable[] }): VariablesGroup[] => {
  const { t } = useTranslation();

  const originsWithVariables: OriginsWithVariables = {
    jira: {
      origin: {
        id: 'jira-available',
        label: t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.PICKER_GROUPS.JIRA.AVAILABLE'),
        kind: 'jira',
      },
      variables,
    },
  };

  return [
    {
      originsWithVariables,
      count: countSelectableVariables(variables),
      title: t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.PICKER_GROUPS.JIRA.JIRA_VARIABLES'),
      id: 'workflow.request',
    },
  ];
};
