import { useTranslation } from 'react-i18next';

import { useRoutes } from '@verticeone/router/src';
import { OktaIcon } from '@vertice/assets';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type OktaSetupCardProps = {
  providerId: string;
};

export const OktaSetupCard = ({ providerId }: OktaSetupCardProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="Okta"
      providerIcon={<OktaIcon />}
      description={t('PREFERENCES.INTEGRATIONS.OKTA.DESCRIPTION')}
      wizardRoute={routes.PREFERENCES.INTEGRATIONS.USAGE.OKTA.ABSOLUTE_PATH}
    />
  );
};
