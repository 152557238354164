import { useTheme } from '@mui/material';
import { Text, TextProps } from '@verticeone/design-system';
import { FC } from 'react';

type FormFieldDescriptionProps = Omit<TextProps, 'variant' | 'size' | 'color'>;

export const FormFieldDescription: FC<FormFieldDescriptionProps> = (props) => {
  const { children, ...otherProps } = props;
  const theme = useTheme();

  return (
    <Text color={theme.palette.text.color2} variant="body-regular" size="S" {...otherProps}>
      {children}
    </Text>
  );
};
