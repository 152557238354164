import React from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatsBar } from '@vertice/core/src/components/StatsBar';
import CompletedSpendStatsContainer from './StatsContainer/CompletedSpendStatsContainer';
import InFlightSpendStatsContainer from './StatsContainer/InFlightSpendStatsContainer';
import OpenRequestsStatsContainer from './StatsContainer/OpenRequestsStatsContainer';
import OverdueRequestsStatsContainer from './StatsContainer/OverdueRequestsStatsContainer';
import { Card, Text } from '@verticeone/design-system';

const StyledStatsBar = styled(StatsBar)({
  borderRadius: 0,
  border: 'none',
});

const OverviewStatBar = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.OVERVIEW' });

  return (
    <Stack gap={4}>
      <Text variant="heading" size="S" tag="h2">
        {t('TITLE')}
      </Text>
      <Card testId="overview">
        <StyledStatsBar>
          <CompletedSpendStatsContainer />
          <InFlightSpendStatsContainer />
          <OpenRequestsStatsContainer />
          <OverdueRequestsStatsContainer />
        </StyledStatsBar>
      </Card>
    </Stack>
  );
};

export default OverviewStatBar;
