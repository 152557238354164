import { SxProps } from '@mui/material';
import styled from '@mui/styled-engine';
import { SVGProps } from 'react';

const BasePriorityHighestOutlined = ({ width, height, className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="PriorityHighestIcon"
    focusable="false"
    aria-hidden="true"
    {...props}
  >
    {/*original mui icon content - exclamation mark*/}
    <circle cx="12" cy="19" r="2"></circle>
    <path d="M10 3h4v12h-4z"></path>
    {/*copy moved left*/}
    <circle cx="12" cy="19" r="2" transform="translate(-7)"></circle>
    <path d="M10 3h4v12h-4z" transform="translate(-7)"></path>
    {/*copy moved right*/}
    <circle cx="12" cy="19" r="2" transform="translate(7)"></circle>
    <path d="M10 3h4v12h-4z" transform="translate(7)"></path>
  </svg>
);

export const PriorityHighestOutlined = styled(({ sx, ...props }: SVGProps<SVGSVGElement> & { sx?: SxProps }) => (
  <BasePriorityHighestOutlined {...props} />
))(({ sx }) => ({ ...sx }));
