import { FieldDef } from '../types';
import {
  getDateJsonSchema,
  getDateJsonSchemaFromMetadata,
  getDatePresenterDefault,
  getDatePresenterSchema,
} from './utils';
import { DateFieldType } from './types';
import { DateFieldBuilderCard } from './DateFieldBuilderCard';
import { DateFieldPresenter } from './DateFieldPresenter';
import { DateFieldBuilderDialog } from './DateFieldBuilderDialog';

export type { DateFieldType, DateFieldMetadata } from './types';
export { dateFieldSchema, dateFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<DateFieldType> = {
  type: 'DATE',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.DATE',
  builderCard: DateFieldBuilderCard,
  builderDialog: DateFieldBuilderDialog,
  presenter: DateFieldPresenter,
  getPresenterDefault: getDatePresenterDefault,
  getPresenterSchema: getDatePresenterSchema,
  getJsonSchemaFromMetadata: getDateJsonSchemaFromMetadata,
  getJsonSchema: getDateJsonSchema,
};
