import { FieldDef } from '../types';
import {
  getLineItemsJsonSchema,
  getLineItemsJsonSchemaFromMetadata,
  getLineItemsPresenterDefault,
  getLineItemsPresenterSchema,
} from './utils';
import { LineItemsFieldType } from './types';
import { LineItemsFieldBuilderCard } from './LineItemsFieldBuilderCard';
import { LineItemsFieldPresenter } from './LineItemsFieldPresenter';
import { LineItemsFieldBuilderDialog } from './LineItemsFieldBuilderDialog';

export type { LineItemsFieldType, LineItemsFieldMetadata } from './types';
export { lineItemsFieldSchema, lineItemsFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<LineItemsFieldType> = {
  type: 'LINE_ITEMS',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.LINE_ITEMS.FIELD_TYPE_NAME',
  builderCard: LineItemsFieldBuilderCard,
  builderDialog: LineItemsFieldBuilderDialog,
  presenter: LineItemsFieldPresenter,
  getPresenterDefault: getLineItemsPresenterDefault,
  getPresenterSchema: getLineItemsPresenterSchema,
  getJsonSchemaFromMetadata: getLineItemsJsonSchemaFromMetadata,
  getJsonSchema: getLineItemsJsonSchema,
};
