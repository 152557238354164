import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import type { FieldSelectorProps } from 'react-querybuilder';
import { useWindowDimensions } from '@verticeone/design-system';

import type { Variable } from '../../types';
import { findVariableById } from '../../VariableSelector/utils';
import { WorkflowVariableSelector } from '../../forms/EditGatewayForm/WorkflowVariableSelector';
import { DRAWER_WIDTH } from '../../../WorkflowViewer/constants';
import { useTranslation } from 'react-i18next';
import { validationError } from '../utils';

const useVariableTypeHints = (variableTypeLabel?: string[]) => {
  const { t } = useTranslation();

  if (!variableTypeLabel) {
    return undefined;
  }

  if (variableTypeLabel.includes('Text')) {
    return t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.HINTS.TEXT_CASE_SENSITIVE');
  }

  return undefined;
};

export const FieldSelector: FC<FieldSelectorProps> = ({
  handleOnChange,
  context,
  value,
  rule,
  disabled,
  validation,
}) => {
  const { requestVariables, udfVariables } = context;

  const [selectedVariable, setSelectedVariable] = useState<Variable | undefined>(() => {
    if (value) {
      return findVariableById([...requestVariables, ...udfVariables], value) ?? undefined;
    }
  });
  const variableTypeHint = useVariableTypeHints(selectedVariable?.type.labels);

  const handleVariableChange = (variable: Variable) => {
    setSelectedVariable(variable);
    handleOnChange(variable.idOverride ?? variable.id);
  };

  const { width } = useWindowDimensions();

  // If the width is bigger than 1280px, the drawer is locked on 900px and variable picker should have that offset
  // Else if the width is smaller than 1024px, the variable picker should have 0 offset (appear in the middle of the screen)
  // Else the variable picker should have half the screen width offset so it's not offscreen
  const positionFromTheRight = width > 1440 ? DRAWER_WIDTH : width < 1024 ? 0 : width / 2;
  const error = validationError(validation) && !rule.field;

  return (
    <Stack sx={{ zIndex: 0 }}>
      <WorkflowVariableSelector
        testId="field-selector"
        isVariableTypeDisplayed={false}
        udfVariables={udfVariables}
        allRequestVariables={requestVariables}
        onSelectedVariableChange={handleVariableChange}
        selectedVariable={selectedVariable}
        positionFromTheRight={positionFromTheRight}
        selectedVariableHint={variableTypeHint}
        isDisabled={disabled}
        error={error}
      />
    </Stack>
  );
};
