import { FieldDef } from '../types';
import {
  getNumberJsonSchema,
  getNumberJsonSchemaFromMetadata,
  getNumberPresenterDefault,
  getNumberPresenterSchema,
} from './utils';
import { NumberFieldType } from './types';
import { NumberFieldBuilderCard } from './NumberFieldBuilderCard';
import { NumberFieldPresenter } from './NumberFieldPresenter';
import { NumberFieldBuilderDialog } from './NumberFieldBuilderDialog';

export type { NumberFieldType, NumberFieldMetadata } from './types';
export { numberFieldSchema, numberFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<NumberFieldType> = {
  type: 'NUMBER',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.NUMBER',
  builderCard: NumberFieldBuilderCard,
  builderDialog: NumberFieldBuilderDialog,
  presenter: NumberFieldPresenter,
  getPresenterDefault: getNumberPresenterDefault,
  getPresenterSchema: getNumberPresenterSchema,
  getJsonSchemaFromMetadata: getNumberJsonSchemaFromMetadata,
  getJsonSchema: getNumberJsonSchema,
};
