import { ZodObject, ZodRawShape, z } from 'zod';
import { RadioGroupFieldMetadata, RadioGroupFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';

export function getRadioGroupPresenterSchema(field: RadioGroupFieldType): ZodObject<ZodRawShape> {
  const enumValue = z.custom<string>((val) => field.metadata.values?.includes(val) ?? false);

  return z.object({
    [field.name]: field.metadata.required ? enumValue : enumValue.nullish(),
  });
}

export function getRadioGroupPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<RadioGroupFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getRadioGroupJsonSchemaFromMetadata(metadata: RadioGroupFieldMetadata): JsonSchema {
  const enumValues = metadata.values ?? [];
  return metadata.required
    ? { type: 'string', enum: enumValues, title: metadata.label }
    : { type: ['string', 'null'], enum: [...enumValues, null], title: metadata.label };
}

export function getRadioGroupJsonSchema(field: RadioGroupFieldType) {
  return {
    required: [field.name],
    properties: {
      [field.name]: getRadioGroupJsonSchemaFromMetadata(field.metadata),
    },
  };
}
