import { FieldMapping, ResourceVersion, TaskIOMapping, TaskIOMappingConfiguration } from '../../../../definitionsTypes';

export class IOMapping {
  public version: ResourceVersion = '2023-10-11';
  public kind: TaskIOMappingConfiguration['kind'] = 'ProcessEngine:TaskIOMapping';
  public inputFields: FieldMapping[] = [];
  public outputFields: FieldMapping[] = [];

  constructor(ioMapping?: TaskIOMappingConfiguration) {
    if (ioMapping) {
      this.inputFields = [...ioMapping.mapping.inputFields];
      this.outputFields = [...ioMapping.mapping.outputFields];
      this.version = ioMapping.version;
      this.kind = ioMapping.kind;
    }
  }

  removeInputMapping(name: string) {
    this.inputFields = this.inputFields.filter((field) => field.name !== name);
  }

  removeOutputMapping(name: string) {
    this.outputFields = this.outputFields.filter((field) => field.name !== name);
  }

  clearInputMapping() {
    this.inputFields = [];
  }

  clearOutputMapping() {
    this.outputFields = [];
  }

  addInputMapping(name: string, value: string, mappingType: FieldMapping['mappingType'] = 'JMESPathExpression') {
    this.removeInputMapping(name);
    this.inputFields = [...this.inputFields, { name, value, mappingType }];
  }

  addOutputMapping(name: string, value: string, mappingType: FieldMapping['mappingType'] = 'JMESPathExpression') {
    this.removeOutputMapping(name);
    this.outputFields = [...this.outputFields, { name, value, mappingType }];
  }

  getIOMapping(): TaskIOMapping {
    return { inputFields: this.inputFields, outputFields: this.outputFields };
  }

  getInputIOMapping(): FieldMapping[] {
    return this.inputFields;
  }

  getOutputIOMapping(): FieldMapping[] {
    return this.outputFields;
  }

  getIOMappingConfiguration(): TaskIOMappingConfiguration {
    return {
      kind: this.kind,
      version: this.version,
      mapping: {
        inputFields: this.inputFields,
        outputFields: this.outputFields,
      },
    };
  }
}
