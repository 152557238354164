import { FC } from 'react';
import { Edge as ReactFlowEdge, BaseEdge, EdgeProps } from '@xyflow/react';
import { useTheme } from '@mui/material';
import { Point, WorkflowEdge } from '../../model/types';
import { generateSVGPath } from './utils';

const NODE_MARGIN = 24;

const addCornerPoints = (points: Point[]) => {
  const roundedPoints = points.map((point) => ({
    x: Math.round(point.x),
    y: Math.round(point.y),
  }));

  const finalPoints = [];

  for (let i = 0; i < roundedPoints.length - 1; i++) {
    const currentPoint = roundedPoints[i];
    const nextPoint = roundedPoints[i + 1];

    // The corner points are added only if the current and next points are not in the same row or column
    if (currentPoint.x !== nextPoint.x && currentPoint.y !== nextPoint.y) {
      const dx = nextPoint.x - currentPoint.x;
      const dy = nextPoint.y - currentPoint.y;

      finalPoints.push({ x: currentPoint.x, y: currentPoint.y });

      if ((dx > 0 && dy > 0) || (dx < 0 && dy < 0) || (dx > 0 && dy < 0)) {
        finalPoints.push({ x: nextPoint.x, y: currentPoint.y });
        // (dx < 0 && dy > 0)
      } else {
        finalPoints.push({ x: currentPoint.x + NODE_MARGIN, y: currentPoint.y });
        finalPoints.push({ x: currentPoint.x + NODE_MARGIN, y: nextPoint.y });
      }
    } else {
      finalPoints.push({ x: currentPoint.x, y: currentPoint.y });
    }

    if (i === points.length - 2) {
      finalPoints.push({ x: nextPoint.x, y: nextPoint.y });
    }
  }

  return finalPoints;
};

export const Edge: FC<EdgeProps<ReactFlowEdge<WorkflowEdge>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  markerEnd,
  style,
  data,
}) => {
  const { palette } = useTheme();

  const isPassedEdge = data?.state?.passed;
  const isSelectedEdge = data?.state?.selected;

  const color = isSelectedEdge ? palette.secondary.color2 : isPassedEdge ? palette.success.color2 : palette.core.color5;

  const points = data?.points?.slice() || [];

  // We are not interested in the source and target points from the dagre graph
  points.pop();
  points.shift();

  // Add the source and target points calculated by reactflow
  points.unshift({ x: sourceX, y: sourceY });
  points.push({ x: targetX - NODE_MARGIN, y: targetY });
  points.push({ x: targetX, y: targetY });

  const path = generateSVGPath(addCornerPoints(points));

  return (
    <BaseEdge
      id={id}
      path={path}
      markerEnd={markerEnd}
      style={{
        ...style,
        stroke: color,
      }}
    />
  );
};
