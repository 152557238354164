import { FC } from 'react';
import { CommentsBox } from '@verticeone/design-system';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';
import { useConversations } from '../../useConversations';

type ConversationContextProps = ReturnType<typeof useConversations> & ReturnType<typeof useConversationUsers>;

export type CommentPanelProps = {
  accountId: string;
  canEditComents: boolean;
  userId: string;
} & ConversationContextProps;

export const CommentPanel: FC<CommentPanelProps> = ({ accountId, canEditComents, userId, ...props }) => {
  const {
    messages,
    cursor,
    sendMessage,
    removeMessage,
    updateCursor,
    editMessage,
    isLoading: isLoadingConversation,
    isMessagePending,
    usersById,
    isLoadingUsers,
  } = props;

  return (
    <CommentsBox
      users={usersById}
      currentUser={userId}
      canEditComents={canEditComents}
      comments={messages}
      cursor={cursor}
      onSendComment={sendMessage}
      onRemoveComment={removeMessage}
      onUpdateCursor={updateCursor}
      onEditComment={editMessage}
      isLoading={isLoadingUsers || isLoadingConversation}
      isSending={isMessagePending}
    />
  );
};
