import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChipButton, type DesignSystemColor } from '@verticeone/design-system';
import type { TaskRow } from '../../../dataSource';
import type { FilterStatus } from '../../../dataSource/queryParams';
import { taskStatusToFilterStatus } from '../../../dataSource/utils';

type TaskStatusProps = {
  taskRow: TaskRow;
};

const statusColorMap: Record<Exclude<FilterStatus, 'ALL'>, DesignSystemColor> = {
  COMPLETED: 'success',
  IN_PROGRESS: 'secondary',
};

export const TaskStatus = ({ taskRow }: TaskStatusProps) => {
  const { t } = useTranslation();
  const filterStatus = taskStatusToFilterStatus(taskRow.status);

  return (
    <Stack direction="row" gap={4} alignItems="center" minWidth={0}>
      <ChipButton variant="ghost" size="XS" color={statusColorMap[filterStatus]} isActive={false}>
        {t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.FILTER.${filterStatus}`)}
      </ChipButton>
    </Stack>
  );
};
