import { useCallback } from 'react';
import { RadioGroupValueListItem } from './types';

export type UseRadioGroupValuesListModelParams = {
  valueRows?: RadioGroupValueListItem[];
  setValueRows: (newRows: RadioGroupValueListItem[]) => void;
};

export const useRadioGroupValuesListModel = ({ valueRows, setValueRows }: UseRadioGroupValuesListModelParams) => {
  const updateValue = useCallback(
    (updatedRow: RadioGroupValueListItem) => {
      if (!valueRows) {
        return;
      }

      setValueRows(valueRows?.map((item) => (item.id === updatedRow.id ? { ...item, ...updatedRow } : item)));
    },
    [valueRows, setValueRows]
  );

  const addValue = useCallback(
    (newValue: RadioGroupValueListItem) => {
      if (!valueRows) {
        return;
      }

      setValueRows([...valueRows, newValue]);
    },
    [valueRows, setValueRows]
  );

  const removeValue = useCallback(
    (id: string) => {
      if (!valueRows) {
        return;
      }

      setValueRows(valueRows?.filter((item) => item.id !== id, []));
    },
    [valueRows, setValueRows]
  );

  return {
    addValue,
    updateValue,
    removeValue,
  };
};
