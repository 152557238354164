export const sanitizeFloatString = (value: string) => {
  const replacedValue = value
    .replace(',', '.')
    .replace(/[^0-9.-]/g, '')
    .replace(/(\..*)\./g, '$1')
    .replace(/(-.*)-/g, '$1'); // Ensure only one minus sign at the start

  const floatValue = parseFloat(replacedValue);
  return isNaN(floatValue) || replacedValue.endsWith('.') || parseFloat(replacedValue.split('.')[1]) === 0
    ? replacedValue
    : floatValue.toString();
};

export const parseNumber = (value: string) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? null : parsedValue;
};
