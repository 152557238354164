import { catalogAPI as api } from '../../api/catalogAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    deployCatalogResourceDefinition: build.mutation<
      DeployCatalogResourceDefinitionApiResponse,
      DeployCatalogResourceDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/deploy/accounts/${queryArg.accountId}/catalog-resources`,
        method: 'POST',
        body: queryArg.deployCatalogResource,
      }),
    }),
    listCatalogResources: build.query<ListCatalogResourcesApiResponse, ListCatalogResourcesApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/catalog-resources`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
          includeAll: queryArg.includeAll,
          kind: queryArg.kind,
          expand: queryArg.expand,
        },
      }),
    }),
    createCatalogResource: build.mutation<CreateCatalogResourceApiResponse, CreateCatalogResourceApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/catalog-resources`,
        method: 'POST',
        body: queryArg.createCatalogResource,
      }),
    }),
    describeCatalogResource: build.query<DescribeCatalogResourceApiResponse, DescribeCatalogResourceApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/catalog-resources/${queryArg.resourceId}`,
        params: {
          expand: queryArg.expand,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as catalogAPICodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type DeployCatalogResourceDefinitionApiResponse = /** status 200 Object reference urns */ Reference[];
export type DeployCatalogResourceDefinitionApiArg = {
  accountId: string;
  /** Deploy service catalog resource */
  deployCatalogResource: DeployCatalogResource;
};
export type ListCatalogResourcesApiResponse = /** status 200 List of customer's catalog resources */ {
  items?: CatalogResource[];
  /** Token to get the next page of results.
   */
  nextToken?: string;
};
export type ListCatalogResourcesApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  /** Returns also non-active resources. By default only active resources are returned. */
  includeAll?: boolean;
  /** Filter resources by kind. */
  kind?: string;
  /** Expand registered x-types in schemas */
  expand?: boolean;
};
export type CreateCatalogResourceApiResponse = /** status 200 Object reference urn */ Reference;
export type CreateCatalogResourceApiArg = {
  accountId: string;
  /** Create service catalog resource */
  createCatalogResource: CreateCatalogResource;
};
export type DescribeCatalogResourceApiResponse = /** status 200 Describe a catalog resource */ {
  resource?: CatalogResource;
};
export type DescribeCatalogResourceApiArg = {
  accountId: string;
  /** Id of the resource. It should be last part of urn so resource type name + resource path. */
  resourceId: string;
  /** Expand registered x-types in schemas */
  expand?: boolean;
};
export type Reference = {
  ref: string;
};
export type DeployCatalogResource = {
  /** Service catalog resource definition serialized as json or yaml. */
  definition: string;
  definitionType: 'application/json' | 'application/yaml';
};
export type Dom = string;
export type Obj = string;
export type Act = string;
export type Condition = {
  Dom: Dom;
  Obj: Obj;
  Act: Act;
};
export type AllOf = {
  AllOf: Condition[];
};
export type AnyOf = {
  AnyOf: Condition[];
};
export type PermissionAction = {
  Conditions: AllOf | AnyOf;
};
export type Permissions = {
  Read?: PermissionAction;
  Execute?: PermissionAction;
};
export type ResourceThumbnail = {
  Type: 'PREDEFINED';
  Id: string;
};
export type ResourceManifest = {
  /** Type/Kind of given manifest
   */
  Kind: string;
  /** Ordinal version of given manifest
    Version must support lexicographic ordering so it's unambiguously apparent if version is greater
    or lower than another one (based on the semantics).
     */
  Version: string;
  /** Category is a high-level classification of the resource.
    It can be used to group resources in the catalog.
     */
  Category?: string;
  Permissions?: Permissions;
  Thumbnail?: ResourceThumbnail;
};
export type ResourceDefinition = ResourceManifest & {
  /** Vertice Unique Resource Name
    This URN uniquely identifies/addresses Vertice resources.
     */
  ResourceUrn: string;
};
export type DocumentationType = 'text/markdown';
export type ResourceMetadata = {
  /** Name of the resource
   */
  Name: string;
  /** Description of the resource
   */
  Description?: string;
  /** Provides human readable, structured documentation that can be used to understand the purpose and
    usage of the resource.
     */
  Documentation?: {
    Type?: DocumentationType;
    Public?: string;
    Internal?: string;
  };
};
export type NonNegativeInteger = number;
export type NonNegativeIntegerDefault0 = number;
export type SchemaArray = Root[];
export type StringArray = string[];
export type SimpleTypes = 'array' | 'boolean' | 'integer' | 'null' | 'number' | 'object' | 'string';
export type Root =
  | boolean
  | {
      title?: string;
      description?: string;
      default?: any | null;
      readOnly?: boolean;
      writeOnly?: boolean;
      examples?: (any | null)[];
      multipleOf?: number;
      maximum?: number;
      exclusiveMaximum?: number;
      minimum?: number;
      exclusiveMinimum?: number;
      maxLength?: NonNegativeInteger;
      minLength?: NonNegativeIntegerDefault0;
      pattern?: string;
      additionalItems?: Root;
      items?: Root | SchemaArray;
      maxItems?: NonNegativeInteger;
      minItems?: NonNegativeIntegerDefault0;
      uniqueItems?: boolean;
      contains?: Root;
      maxProperties?: NonNegativeInteger;
      minProperties?: NonNegativeIntegerDefault0;
      required?: StringArray;
      additionalProperties?: Root;
      definitions?: {
        [key: string]: Root;
      };
      properties?: {
        [key: string]: Root;
      };
      patternProperties?: {
        [key: string]: Root;
      };
      dependencies?: {
        [key: string]: Root | StringArray;
      };
      propertyNames?: Root;
      const?: any | null;
      enum?: (any | null)[];
      type?: SimpleTypes | SimpleTypes[];
      format?: string;
      contentMediaType?: string;
      contentEncoding?: string;
      if?: Root;
      then?: Root;
      else?: Root;
      allOf?: SchemaArray;
      anyOf?: SchemaArray;
      oneOf?: SchemaArray;
      not?: Root;
      'x-type'?: string;
      'x-auto'?: boolean;
      'x-enabled'?: boolean;
    };
export type Input = {
  JsonSchema: Root;
};
export type Output = {
  Documents?: {
    /** Copy created documents to the parent request
     */
    copy?: boolean;
  };
  JsonSchema: Root;
};
export type InvocationInterface = {
  Input?: Input;
  Output?: Output;
};
export type FormInterface = {
  Interface: InvocationInterface;
};
export type FieldType =
  | 'SHORT_TEXT'
  | 'LONG_TEXT'
  | 'DROPDOWN'
  | 'RADIO_GROUP'
  | 'NUMBER'
  | 'DATE'
  | 'CHECKBOX'
  | 'MONEY'
  | 'LINE_ITEMS'
  | 'FILE_UPLOAD';
export type BaseFieldSchema = {
  Type: FieldType;
  Name: string;
};
export type MetaDataCommon = {
  Label: string;
  Description?: string;
  Required: boolean;
};
export type ShortTextFieldSchema = BaseFieldSchema & {
  Type: 'SHORT_TEXT';
  Metadata: MetaDataCommon;
};
export type LongTextFieldSchema = BaseFieldSchema & {
  Type: 'LONG_TEXT';
  Metadata: MetaDataCommon;
};
export type MetaDataDropDown = MetaDataCommon & {
  Values: string[];
};
export type DropDownFieldSchema = BaseFieldSchema & {
  Type: 'DROPDOWN';
  Metadata: MetaDataDropDown;
};
export type MetaDataRadioGroup = MetaDataCommon & {
  Values: string[];
};
export type RadioGroupFieldSchema = BaseFieldSchema & {
  Type: 'RADIO_GROUP';
  Metadata: MetaDataRadioGroup;
};
export type NumberFieldSchema = BaseFieldSchema & {
  Type: 'NUMBER';
  Metadata: MetaDataCommon;
};
export type DateFieldSchema = BaseFieldSchema & {
  Type: 'DATE';
  Metadata: MetaDataCommon;
};
export type CheckboxFieldSchema = BaseFieldSchema & {
  Type: 'CHECKBOX';
  Metadata: MetaDataCommon;
};
export type MoneyFieldSchema = BaseFieldSchema & {
  Type: 'MONEY';
  Metadata: MetaDataCommon;
};
export type LineItemsFieldSchema = BaseFieldSchema & {
  Type: 'LINE_ITEMS';
  Metadata: MetaDataCommon;
};
export type FileUploadFieldSchema = BaseFieldSchema & {
  Type: 'FILE_UPLOAD';
  Metadata: MetaDataCommon;
};
export type FieldSchema =
  | ShortTextFieldSchema
  | LongTextFieldSchema
  | DropDownFieldSchema
  | RadioGroupFieldSchema
  | NumberFieldSchema
  | DateFieldSchema
  | CheckboxFieldSchema
  | MoneyFieldSchema
  | LineItemsFieldSchema
  | FileUploadFieldSchema;
export type LayoutLanguageSchema = {
  Fields: FieldSchema[];
};
export type FormUiModel = {
  FormUiModel?: {
    Kind: string;
    Version: string;
    /** Vertice Layout Language */
    Layout: LayoutLanguageSchema;
  };
};
export type Form = ResourceMetadata & FormInterface & FormUiModel;
export type FormManifest = {
  Form?: Form;
};
export type FormDefinition = ResourceDefinition & FormManifest;
export type Urn = string;
export type WidgetsDefinition = {
  /** List of widgets
   */
  Widgets?: Urn[];
};
export type Arn = string;
export type MoustacheTemplate = string;
export type ServiceProvider = {
  HandlerUrn: Urn | Arn | MoustacheTemplate;
  HandlerConfiguration?: {
    [key: string]: any;
  };
  ExecutionConfiguration?: {
    /** Applies to lambda handlers only. If true, the request won't be finished automatically and requires explicit completion
     */
    Async?: boolean;
  };
  Interface?: InvocationInterface;
  /** Moustache templates to create the integration request payload and process the response
   */
  Integration?: {
    Request?: {
      /** Request template is used to create the handler request payload  from task input.
            It is guaranteed the input matches the `Interface.Input` schema. Values from the input
            can be referenced directly. Additionally, there is also a special `requestContext` object that can be used.
             */
      MappingTemplate?:
        | {
            [key: string]: any;
          }
        | string;
    };
    Response?: {
      /** Response template is used to create the task output from the invoked handler output.
            It is required for the output to match the `Interface.Input` schema.
             */
      MappingTemplate?:
        | {
            [key: string]: any;
          }
        | string;
    };
  };
};
export type Service = ResourceMetadata &
  WidgetsDefinition & {
    ServiceProvider: ServiceProvider;
  };
export type ServiceManifest = {
  Service?: Service;
};
export type ServiceDefinition = ResourceDefinition & ServiceManifest;
export type TaskStaticTemplateDefinition = {
  [key: string]: any;
};
export type BlockTemplateLayoutElement = {
  ref: string;
  elementType: 'Shape' | 'Edge';
  position: {
    type: 'RELATIVE' | 'ABSOLUTE';
    x: number;
    y: number;
  };
};
export type BlockStaticTemplateDefinition = {
  ProcessDefinition?: {
    [key: string]: any;
  };
  LayoutDefinition?: {
    elements?: BlockTemplateLayoutElement[];
  };
};
export type StaticTemplateProvider = {
  TemplateDefinition: TaskStaticTemplateDefinition | BlockStaticTemplateDefinition;
};
export type Template = {
  /** Scope of the template where it can be used.
   */
  TemplateScope: 'Task' | 'Block';
  /** Template parameters are the parameters that are required to instantiate the template.
    It's a declaration of properties and metadata that define the template parameters.
     */
  TemplateParameters?: {
    JsonSchema?: Root;
  };
  TemplateProvider: StaticTemplateProvider;
};
export type TemplateManifest = {
  Template?: ResourceMetadata & Template;
};
export type TemplateDefinition = ResourceDefinition & TemplateManifest;
export type FunctionProvider = {
  HandlerUrn: Urn | Arn | MoustacheTemplate;
  HandlerConfiguration?: {
    [key: string]: any;
  };
  Interface?: InvocationInterface;
  /** Moustache templates to create the integration request payload and process the response
   */
  Integration?: {
    Request?: {
      /** Request template is used to create the handler request payload  from task input.
            It is guaranteed the input matches the `Interface.Input` schema. Values from the input
            can be referenced directly. Additionally, there is also a special `requestContext` object that can be used.
             */
      MappingTemplate?: {
        [key: string]: any;
      };
    };
    Response?: {
      /** Response template is used to create the task output from the invoked handler output.
            It is required for the output to match the `Interface.Input` schema.
             */
      MappingTemplate?: {
        [key: string]: any;
      };
    };
  };
};
export type UdfFunction = ResourceMetadata & {
  FunctionProvider: FunctionProvider;
};
export type FunctionManifest = {
  Function?: UdfFunction;
};
export type FunctionDefinition = ResourceDefinition & FunctionManifest;
export type WidgetProvider = {
  CaptureMask: string[];
  /** JSON schema of the form
   */
  Schema: {
    JsonSchema?: Root;
  };
  /** Target location of the captured data
   */
  Target: string;
}[];
export type Widget = ResourceMetadata & {
  WidgetProvider?: WidgetProvider;
};
export type WidgetManifest = {
  Widget?: Widget;
};
export type WidgetDefinition = ResourceDefinition & WidgetManifest;
export type XTypeOperators = {
  Operators?: {
    /** List of all allowed operators
     */
    Allowed: string[];
    [key: string]: any;
  };
};
export type XTypeDataSourceItems = {
  title: string;
  id: string | object;
  'x-enabled'?: boolean;
}[];
export type XTypeDataSource = {
  /** Datasource for the x-type. Specify either static Items or a dynamic Handler. If both are specified, static Items are used.
   */
  Datasource?: {
    /** If true, there is a datasource available for this xtype
     */
    Provided: boolean;
    Items?: XTypeDataSourceItems;
    /** Function that provides the datasource.
        This provider is a lambda function specified by its ARN.
         */
    Handler?: {
      HandlerRef?: Arn;
    };
  };
};
export type XTypeSchema = {
  /** JSON schema of the x-type
   */
  Schema: {
    JsonSchema: Root;
  };
};
export type XType = ResourceMetadata & XTypeOperators & XTypeDataSource & XTypeSchema;
export type XTypeManifest = {
  XType?: XType;
};
export type XTypeDefinition = ResourceDefinition & XTypeManifest;
export type Resource =
  | FormDefinition
  | ServiceDefinition
  | TemplateDefinition
  | FunctionDefinition
  | WidgetDefinition
  | XTypeDefinition;
export type CatalogResource = {
  urn: string;
  name: string;
  status: string;
  managedBy?: string;
  definition: Resource;
};
export type CreateCatalogResource = {
  /** Unique path of the resource. Recommended approach is to include version suffix vx in the path. */
  path: string;
  manifest: ResourceManifest;
  definition?: Form | Service | Template | UdfFunction | Widget | XType;
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useDeployCatalogResourceDefinitionMutation,
  useListCatalogResourcesQuery,
  useLazyListCatalogResourcesQuery,
  useCreateCatalogResourceMutation,
  useDescribeCatalogResourceQuery,
  useLazyDescribeCatalogResourceQuery,
} = injectedRtkApi;
