import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const CATALOG_TAG_RESOURCES = 'CatalogResource';

export const catalogAPI = createApi({
  reducerPath: 'catalogAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.CATALOG),
  refetchOnMountOrArgChange: true,
  tagTypes: [CATALOG_TAG_RESOURCES],
  endpoints: () => ({}),
});
