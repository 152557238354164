import React from 'react';
import { FieldPath, FieldValues, useFormState } from 'react-hook-form';
import { TextField, TextFieldCaption } from '@verticeone/design-system';
import FormControl from './FormControl';
import { UseFormRegister, Control } from 'react-hook-form/dist/types/form';

type IntegrationNameFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
};

const IntegrationNameField = <T extends FieldValues>({ name, register, control }: IntegrationNameFieldProps<T>) => {
  const { errors } = useFormState({ control, name });

  return (
    <FormControl>
      <TextFieldCaption label={'Integration Name'} htmlFor="integration-name" size="XS" required />
      <TextField size="S" id="integration-name" error={!!errors} placeholder={'e.g. HubSpot'} {...register(name)} />
    </FormControl>
  );
};

export default IntegrationNameField;
