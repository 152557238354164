import React, { ChangeEvent, useEffect, useState } from 'react';
import { IconButton, Stack, TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';
import { AccountUser } from '@vertice/slices';
import { Button } from '@verticeone/design-system';
import ApproverAutocomplete from './ApproverAutocomplete';
import styles from './ApproverThresholdList.module.scss';
import { ContractOwner } from '../constants';

interface ApproverThresholdListProps {
  users: AccountUser[];
  currency: string;
  isLoading: boolean;
  value: RowItem[];
  onValueChanged?: (value: RowItem[]) => void;
  onAddUserRequest?: (args: { onAdded: (userId: string) => void }) => void;
  errorList?: ErrorItem[];
  disabled?: boolean;
}

const MAX_ROW_COUNT = 5;
const pricePattern = /^[0-9]*(\.?[0-9]?[0-9]?)?$/;

const defaultValue = [
  { limit: null, approver: null },
  { limit: null, approver: null },
];

export interface ErrorItem {
  limit: boolean;
  approver: boolean;
}

export interface RowItem {
  limit: string | null;
  approver: string | null;
}

const ApproverThresholdList: React.FC<ApproverThresholdListProps> = ({
  users,
  isLoading,
  value = defaultValue,
  onValueChanged,
  onAddUserRequest,
  errorList,
  disabled,
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<RowItem[]>(value);
  const [errors, setErrors] = useState<ErrorItem[]>(errorList || items.map(() => ({ limit: false, approver: false })));

  useEffect(() => {
    setErrors(errorList ?? items.map(() => ({ limit: false, approver: false })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorList]);

  useEffect(() => {
    setItems(value);
  }, [value]);

  const updateValue = (newValue: RowItem[]) => {
    if (onValueChanged) {
      onValueChanged(newValue);
    } else {
      setItems(newValue);
    }
  };

  const handleApproverChange = (rowIndex: number) => (approver: string | null) => {
    updateValue([
      ...items.slice(0, rowIndex),
      { limit: items[rowIndex].limit, approver },
      ...items.slice(rowIndex + 1, items.length),
    ]);
  };

  const handleLimitChange = (rowIndex: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const limit = event.target.value;

    if (pricePattern.test(limit)) {
      const rowCount = items.length;
      updateValue([
        ...items.slice(0, rowIndex),
        { limit, approver: items[rowIndex].approver },
        ...items.slice(rowIndex + 1, rowCount),
      ]);
    }
  };

  const handleRowAdd = () => {
    const rowCount = items.length;
    const lastItem = items[rowCount - 1];

    updateValue([
      ...items.slice(0, rowCount - 1),
      { limit: null, approver: ContractOwner },
      { limit: lastItem.limit, approver: lastItem.approver },
    ]);
  };

  const handleRemoveRow = (index: number) => {
    updateValue([...items.filter((e, i) => i !== index)]);
  };

  const inputProps = {
    classes: {
      root: styles['input-root'],
      input: styles['inner-input'],
      notchedOutline: styles.outline,
      focused: styles.focused,
      error: styles.error,
      disabled: styles.disabled,
    },
  };

  return (
    <Stack className={styles['approver-threshold-list']} gap="12px">
      {items.map((rowItem, index, rowItems) => {
        const lastRow = index === items.length - 1;
        const rowErrors = errors.length > index ? errors[index] : { limit: false, approver: false };
        return (
          // Note: items.length in the key is a trick to re-mount items when new row is added
          // - otherwise text input of ApproverAutocomplete contains old invalid value when new row is added
          <Stack className={styles.row} direction="row" gap="8px" key={`${index}-${items.length}`}>
            <TextField
              className={styles['amount-field']}
              disabled={lastRow || disabled}
              value={lastRow ? t('PREFERENCES.WORKFLOW.APPROVERS.THRESHOLD_LIST.UNLIMITED') : rowItem.limit || ''}
              label={t('PREFERENCES.WORKFLOW.APPROVERS.THRESHOLD_LIST.VALUE_UP_TO')}
              placeholder={t('PREFERENCES.WORKFLOW.APPROVERS.THRESHOLD_LIST.VALUE')}
              error={rowErrors.limit}
              InputLabelProps={{ shrink: true, classes: { root: styles.label } }}
              InputProps={inputProps}
              onChange={handleLimitChange(index)}
            />
            <ApproverAutocomplete
              fullWidth
              options={users || []}
              selectedId={rowItem.approver}
              isLoading={isLoading}
              onSelected={handleApproverChange(index)}
              onAddRequest={() => {
                if (onAddUserRequest) {
                  onAddUserRequest({ onAdded: handleApproverChange(index) });
                }
              }}
              labeled
              error={rowErrors.approver}
              disabled={disabled}
            />
            {index !== 0 && index !== rowItems.length - 1 && (
              <IconButton
                onClick={() => handleRemoveRow(index)}
                className={styles['delete-button']}
                disabled={disabled}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Stack>
        );
      })}
      {items.length < MAX_ROW_COUNT && (
        <Button
          sx={{ alignSelf: 'flex-start' }}
          variant="ghost"
          color="primary"
          size="S"
          onClick={handleRowAdd}
          disabled={disabled}
        >
          {t('PREFERENCES.WORKFLOW.APPROVERS.THRESHOLD_LIST.ADD_THRESHOLD')}
        </Button>
      )}
    </Stack>
  );
};

export default ApproverThresholdList;
