import { PredefinedFormDef } from '../shared/types';
import { RenewalRequirementsForm } from './RenewalRequirementsForm';
import { zodSchema, FormData } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/renewal\/requirementsGathering\/v\d+$/,
  component: RenewalRequirementsForm,
  schema: zodSchema,
  draftEnabled: true,
  pages: [
    {
      title: 'Vendor & Products',
      id: 'vendor_and_products',
      fields: ['vendor', 'products', 'renewalType', 'piiExpectedToBeHeld'],
    },
    {
      title: 'Documents',
      id: 'documents',
      fields: [],
    },
    {
      title: 'Contract details',
      id: 'contract_details',
      fields: ['targetSignDate', 'approvedBudget', 'contractCurrency', 'additionalNotes', 'desiredContractLength'],
    },
    {
      title: 'Contact & Permissions',
      id: 'contact_and_permissions',
      fields: [
        'contactVendorDirectly',
        'mainVendorContact',
        'mainVendorContactName',
        'mainVendorContactEmail',
        'discussMultiYearDeals',
        'discussCompetitors',
        'discussCaseStudies',
      ],
    },
  ],
  getDefaultValues: (getValue) => ({
    products: getValue('products'),
    vendor: getValue('vendor'),
    renewalType: getValue('renewalType', null),

    targetSignDate: getValue('targetSignDate', null),
    desiredContractLength: getValue('desiredContractLength'),

    verticeNegotiationRequested: getValue('verticeNegotiationRequested', false),
    internalNegotiationRequested: getValue('internalNegotiationRequested', false),

    legalReviewRequired: getValue('legalReviewRequired', true),
    securityReviewRequired: getValue('securityReviewRequired', true),
    piiExpectedToBeHeld: getValue('piiExpectedToBeHeld'),

    additionalNotes: getValue('additionalNotes'),

    mainVendorContact: getValue('mainVendorContact', true),
    mainVendorContactName: getValue('mainVendorContactName'),
    mainVendorContactEmail: getValue('mainVendorContactEmail'),
    contactVendorDirectly: getValue('contactVendorDirectly'),
    contractCurrency: getValue('contractCurrency', null),

    approvedBudget: getValue('approvedBudget', null),

    discussMultiYearDeals: getValue('discussMultiYearDeals'),
    discussPlannedGrowth: getValue('discussPlannedGrowth'),
    discussCompetitors: getValue('discussCompetitors'),
    discussCaseStudies: getValue('discussCaseStudies'),

    parentAccountId: getValue('parentAccountId'),
  }),
  transformOutput: (formData) => ({
    ...formData,
    desiredContractLength: formData.desiredContractLength ?? undefined,
  }),
};
