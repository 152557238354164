import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { SSOSetupCard } from './components/SSOSetupCard';
import { SCIMSetupCard } from './components/SCIMSetupCard';
import { SCIMSetupType } from './components/common';
import { CardsList } from '../components/CardsList';
import { CardWrapper } from '../components/CardWrapper';

export const Security = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();

  return (
    <Stack direction="column" gap={6}>
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.SECURITY')}
      </Text>
      <CardsList>
        <CardWrapper>
          <SSOSetupCard />
        </CardWrapper>
        {isEnabled(FEATURES.SAML_SCIM) && isEnabled(FEATURES.SSO) && (
          <>
            <CardWrapper>
              <SCIMSetupCard type={SCIMSetupType.ENTRA} />
            </CardWrapper>
            <CardWrapper>
              <SCIMSetupCard type={SCIMSetupType.OKTA} />
            </CardWrapper>
          </>
        )}
      </CardsList>
    </Stack>
  );
};
