import React, { useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';

import { Divider } from '@verticeone/design-system';

import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import { RequestDetailSkeleton, WorkflowVisualizerSkeleton } from './RequestDetailSkeleton';
import { RequestHeaderMenu } from './components/RequestHeaderMenu';
import { RequestStatusChip } from '../components/RequestStatusChip';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useRequestContext } from './RequestContext';
import { CollapsibleSection } from './components/CollapsibleSection';
import { VisualizerSection, ConversationSection } from './sections';
import { useRequestWidgets } from './widgets/usePredefinedWidgets';
import { RequestLogo } from '../RequestsListPage/components/cells/RequestLogo';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { getDataForWidget } from './widgets/shared/utils';
import { DETAILS_WIDGET_URN_PATTERN } from './widgets/Details/widgetDef';
import { useNavigate } from 'react-router-dom';

export const RequestDetailPage = () => {
  const { accountId } = useAccountContext();
  const {
    requestId,
    request,
    parentRequest,
    parentRequestPath,
    isLoading,
    workflowVersion,
    isExecutedByWorkflow,
    additionalWidgets,
    applicationIdentifier,
    isRequestAndWorkflowMismatch,
  } = useRequestContext();

  const navigate = useNavigate();

  const parentRequestRef = parentRequest ? parseRequestRef(parentRequest.ref) : undefined;
  const parentRequestAccountId = parentRequestRef?.accountId;

  // FIXME: Currently the page is not re-mounting when we open the child request from parent, hence this hack
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mountingStatus = useMemo(() => request?.status, [request?.ref]);
  const isSubprocess = parentRequest !== undefined;

  const parentRequestVendor =
    getDataForWidget(parentRequest!, DETAILS_WIDGET_URN_PATTERN)?.vendor ?? parentRequest?.input.vendor;
  const requestVendor = getDataForWidget(request!, DETAILS_WIDGET_URN_PATTERN)?.vendor ?? request?.input?.vendor;

  const widgets = useRequestWidgets(request, additionalWidgets);
  const parentRequestConversationData = useMemo(
    () =>
      parentRequestRef && accountId === 'VERTICE_WORKFLOW_ADMINISTRATION'
        ? {
            ...parentRequestRef,
          }
        : undefined,
    [parentRequestRef, accountId]
  );
  const requestConversationData = useMemo(
    () => ({
      accountId,
      requestId,
      owner: request?.owner,
    }),
    [accountId, requestId, request?.owner]
  );

  // This useEffect is to redirect from subprocess to parent request when subprocess is completed
  // REF: https://vertice.atlassian.net/browse/RED-1939
  useEffect(() => {
    if (!isSubprocess) return;

    if (mountingStatus !== request?.status && request?.status === 'COMPLETED' && !!parentRequestPath) {
      navigate(parentRequestPath);
    }
  }, [request?.status, mountingStatus, isSubprocess, parentRequestPath, navigate]);

  if (isLoading || request === undefined) {
    return (
      <IntelligentWorkflowsLayout isLoading={isLoading}>
        <RequestDetailSkeleton />
      </IntelligentWorkflowsLayout>
    );
  }

  return (
    <IntelligentWorkflowsLayout
      currentPageName={request.name}
      currentPageAdornments={{
        start: <RequestLogo vendor={requestVendor} isChild={parentRequest ? true : false} size="XS" />,
        end: <RequestStatusChip status={request.status} variant={'ghost'} />,
      }}
      parentPageName={parentRequest?.name}
      parentPagePath={parentRequestPath}
      parentPageAccountId={parentRequestAccountId}
      parentPageAdornments={{
        start: parentRequest ? <RequestLogo vendor={parentRequestVendor} isChild={false} size="XS" /> : undefined,
      }}
      actions={<RequestHeaderMenu isRequestTerminationEnabled={applicationIdentifier === 'IAT'} request={request} />}
      isLoading={isLoading}
    >
      <Stack gap={4}>
        {isExecutedByWorkflow && (
          <>
            {isRequestAndWorkflowMismatch ? (
              <WorkflowVisualizerSkeleton />
            ) : (
              <VisualizerSection request={request} workflowVersion={workflowVersion} />
            )}
          </>
        )}
        <Divider />
        <Stack gap={4} divider={<Divider />} direction={{ xs: 'column', md: 'row' }} width={'100%'}>
          <Stack gap={4} divider={<Divider />} width={'100%'}>
            {widgets?.map(({ widgetDef, widgetData }, index) => (
              <CollapsibleSection key={index} titleTranslationKey={widgetDef.titleTranslationKey}>
                <widgetDef.component widgetData={widgetData} />
              </CollapsibleSection>
            ))}
          </Stack>
          <Stack
            minHeight={768}
            maxHeight={'100%'}
            maxWidth={{ xs: '100%', md: 400 }}
            minWidth={{ xs: '100%', md: 400 }}
          >
            <ConversationSection request={requestConversationData} parentRequest={parentRequestConversationData} />
          </Stack>
        </Stack>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
