import { FC, Fragment, useCallback, useState } from 'react';
import { DynamicFormField } from '../../../../../../forms/dynamicForms';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { fieldDefsByType } from '../../../../../../forms/dynamicForms/fields/fields';
import { Stack } from '@mui/material';
import { CUSTOM_FORM_FIELD_NAME, CUSTOM_FORM_MAX_FIELDS, VARIABLES_FIELD_NAME } from './constants';
import { FieldCard, SelectNewField } from '../../../../../../forms/dynamicForms/DynamicFormBuilder';
import { EditUserTaskFormData } from '../schema';
import { ActiveEditingField, CustomFormFieldDialog } from './CustomFormFieldDialog';
import { CustomFormEditContextProvider } from './CustomFormEditContext';
import { NodeId } from '../../../types';
import { ProcessDefinition } from '../../../../../definitionsTypes';
import { useDroppable, DndContext, DragEndEvent, DragStartEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

type CustomFormEditProps = {
  nodeId: NodeId;
  processDefinition: ProcessDefinition;
};

export const CustomFormEdit: FC<CustomFormEditProps> = (props) => {
  const { nodeId, processDefinition } = props;
  const [activeField, setActiveField] = useState<ActiveEditingField | null>(null);

  const { control, setValue } = useFormContext<EditUserTaskFormData>();
  const [activeDrag, setActiveDrag] = useState<(DynamicFormField & { id: string }) | null>(null);
  const { fields, remove, update, append, move } = useFieldArray({ control, name: CUSTOM_FORM_FIELD_NAME });

  const handleSubmit = useCallback(
    (values: DynamicFormField) => {
      activeField && activeField?.index !== null ? update(activeField.index, values) : append(values);
      setActiveField(null);
    },
    [activeField, append, update, setActiveField]
  );

  const { setNodeRef } = useDroppable({ id: 'field-list' });

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveDrag(fields.find((f) => f.id === active.id) ?? null);
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((f) => f.id === active.id);
      const newIndex = fields.findIndex((f) => f.id === over.id);

      return move(oldIndex, newIndex);
    }
  };

  return (
    <CustomFormEditContextProvider nodeId={nodeId} processDefinition={processDefinition}>
      <Stack gap={4} ref={setNodeRef}>
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} collisionDetection={closestCenter}>
          <SortableContext items={fields}>
            <Stack gap={4} ref={setNodeRef}>
              {fields.map((field, index) => {
                const removeField = () => {
                  remove(index);
                  setValue(`${VARIABLES_FIELD_NAME}.${field.name}`, null);
                };
                const editField = () => setActiveField({ index, fieldType: field.type });
                const changeField = (metadata: any) => update(index, { ...field, metadata });

                return (
                  <FieldCard.Draggable key={field.id} id={field.id}>
                    {fieldToComponent(field, removeField, editField, changeField)}
                  </FieldCard.Draggable>
                );
              })}
            </Stack>
          </SortableContext>
          {fields.length < CUSTOM_FORM_MAX_FIELDS && (
            <SelectNewField onAdd={(fieldType) => setActiveField({ index: null, fieldType })} />
          )}
          <FieldCard.DragOverlay id={activeDrag?.id ?? null}>
            {activeDrag ? fieldToComponent(activeDrag) : null}
          </FieldCard.DragOverlay>
          <CustomFormFieldDialog
            activeField={activeField}
            onClose={() => setActiveField(null)}
            onSubmit={handleSubmit}
          />
        </DndContext>
      </Stack>
    </CustomFormEditContextProvider>
  );
};

const fieldToComponent = (
  field: DynamicFormField,
  onRemove?: () => void,
  onEdit?: () => void,
  onChange?: (values: DynamicFormField['metadata']) => void
) => {
  const BuilderCard = fieldDefsByType[field.type]?.builderCard;

  return BuilderCard ? (
    <BuilderCard field={field} onRemove={onRemove} onEdit={onEdit} onChange={onChange} />
  ) : (
    <Fragment key={field.name} />
  );
};
