import type { Variable } from '../types';
import { FC, useMemo, useState } from 'react';
import { Button, IconButton, IconWrapper, TextField, Text } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { Add, Check } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { TypeBadge } from './TypeBadge';
import { VariablesGroup } from './types';
import { useTranslation } from 'react-i18next';
import { fixInvalidCharacters, fixInvalidNameFormat, fixInvalidUppercaseWords } from './variableNameUtils';
import { createValidationObj, createVariable, validate } from './customVariableUtils';

type CustomVariableFooterProps = {
  addVariableDefaults: Pick<Variable, 'origin' | 'type'>;
  onAddLocalVariable: (newVariable: Variable) => void;
  variablesGroups: VariablesGroup[];
};

const DEFAULT_NAME_INPUT_WIDTH = 110;

export const CustomVariableFooter: FC<CustomVariableFooterProps> = ({
  addVariableDefaults,
  onAddLocalVariable,
  variablesGroups,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR',
  });

  const [editing, setEditing] = useState(false);
  const [name, setName] = useState('');
  const [inputWidth, setInputWidth] = useState(DEFAULT_NAME_INPUT_WIDTH);

  const typeLabel = addVariableDefaults?.type.labels?.[0];

  const variable = useMemo<Variable>(() => createVariable(addVariableDefaults, name), [name, addVariableDefaults]);
  const validation = useMemo(() => createValidationObj(name, variablesGroups), [name, variablesGroups]);
  const isValid = useMemo(() => validate(validation), [validation]);

  const handleAddVariable = () => {
    if (!isValid) {
      return;
    }
    onAddLocalVariable(variable);
    setEditing(false);
    setName('');
    setInputWidth(DEFAULT_NAME_INPUT_WIDTH);
  };

  const handleFixFormat = () => {
    setName((currentName) => fixInvalidNameFormat(currentName));
  };
  const handleFixCharacters = () => {
    setName((currentName) => fixInvalidCharacters(currentName));
  };
  const handleFixCase = () => {
    setName((currentName) => fixInvalidUppercaseWords(currentName));
  };

  return (
    <Stack paddingBlock={2} alignItems="start">
      {editing ? (
        <Stack paddingInline={4} alignItems="center" justifyContent="space-between" direction="row" width="100%">
          <Stack gap={1}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent={'start'} minWidth={0}>
              <TextField
                autoFocus
                hiddenLabel
                value={name}
                variant="ghost"
                size="S"
                onChange={(e) => {
                  setInputWidth(e.target.value ? e.target.scrollWidth : DEFAULT_NAME_INPUT_WIDTH);
                  setName(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddVariable();
                  }
                }}
                placeholder={t('CUSTOM_VARIABLE_NAME_PLACEHOLDER')}
                inputProps={{
                  style: { width: inputWidth },
                }}
              />
              {typeLabel && <TypeBadge label={typeLabel} />}
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              {!validation.isValidFormat ? (
                <>
                  <Text variant="body-regular" size="S" color="error1">
                    {t('MESSAGES.INVALID_VARIABLE_FORMAT')}
                  </Text>
                  <Button size="XS" onClick={handleFixFormat}>
                    {t('ACTIONS.FIX_CUSTOM_VARIABLE_NAME')}
                  </Button>
                </>
              ) : !validation.hasValidCharacters ? (
                <>
                  <Text variant="body-regular" size="S" color="error1">
                    {t('MESSAGES.INVALID_VARIABLE_CHARACTERS')}
                  </Text>
                  <Button size="XS" onClick={handleFixCharacters}>
                    {t('ACTIONS.FIX_CUSTOM_VARIABLE_NAME')}
                  </Button>
                </>
              ) : validation.alreadyExists ? (
                <Text variant="body-regular" size="S" color="error1">
                  {t('MESSAGES.VARIABLE_EXISTS')}
                </Text>
              ) : !validation.hasValidCase ? (
                <>
                  <Text variant="body-regular" size="S" color="error1">
                    {t('MESSAGES.INVALID_CASE')}
                  </Text>
                  <Button size="XS" onClick={handleFixCase} disabled={validation.isEmpty}>
                    {t('ACTIONS.FIX_CUSTOM_VARIABLE_NAME')}
                  </Button>
                </>
              ) : undefined}
            </Stack>
          </Stack>
          <IconButton
            variant="plain"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            icon={Check}
            onClick={handleAddVariable}
            disabled={!isValid}
          />
        </Stack>
      ) : (
        <Button variant="plain" size="S" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} onClick={() => setEditing(true)}>
          <IconWrapper icon={Add} />
          {t('ACTIONS.ADD_CUSTOM_VARIABLE')}
        </Button>
      )}
    </Stack>
  );
};
