import React from 'react';
import { Stack } from '@mui/material';
import { Button } from '@verticeone/design-system';

type SaveIntegrationButtonProps = {
  isFormValid: boolean;
};

const SaveIntegrationButton = ({ isFormValid }: SaveIntegrationButtonProps) => {
  return (
    <Stack direction="row">
      <Button variant="solid" size="M" color="primary" type="submit" disabled={!isFormValid}>
        {'Save'}
      </Button>
    </Stack>
  );
};

export default SaveIntegrationButton;
