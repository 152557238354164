import React, { useMemo } from 'react';
import { Stack } from '@mui/material';

import { TaskDrawer } from './TaskDrawer/TaskDrawer';
import { IntelligentWorkflowsLayout } from '../components/IntelligentWorkflowsLayout';

import { SearchBar, Table, Filter, MyTasksOnlySwitch } from './components';
import { type TaskRow, useTasks } from './dataSource';
import {
  useActiveRowParam,
  useMyTasksOnlyParam,
  useFilterParam,
  useSearchParam,
  type FilterStatus,
} from './dataSource/queryParams';
import {
  useGetFilteredTasksAssignedToUser,
  useGetFilteredTasksByFilter,
  useGetFilteredTasksBySearch,
} from './dataSource/filters';

export const TasksListPage = () => {
  const { data, isLoading } = useTasks();
  const [searchValue] = useSearchParam();
  const [filterValue] = useFilterParam();
  const [myTasksOnlyValue] = useMyTasksOnlyParam();
  const [activeRowValue, setActiveRowValue] = useActiveRowParam();
  const getFilteredTasksAssignedToUser = useGetFilteredTasksAssignedToUser();
  const getFilteredTasksBySearch = useGetFilteredTasksBySearch();
  const getFilteredTasksByFilter = useGetFilteredTasksByFilter();

  const preFilteredData = useMemo(() => {
    return data.filter((item) =>
      [getFilteredTasksAssignedToUser(myTasksOnlyValue), getFilteredTasksBySearch(searchValue)].every((predicate) =>
        predicate(item)
      )
    );
  }, [data, getFilteredTasksAssignedToUser, getFilteredTasksBySearch, myTasksOnlyValue, searchValue]);

  const filteredData: Record<FilterStatus | 'selectedFilter', Array<TaskRow>> = useMemo(
    () => ({
      selectedFilter: preFilteredData.filter(getFilteredTasksByFilter(filterValue)),
      ALL: preFilteredData.filter(getFilteredTasksByFilter('ALL')),
      IN_PROGRESS: preFilteredData.filter(getFilteredTasksByFilter('IN_PROGRESS')),
      COMPLETED: preFilteredData.filter(getFilteredTasksByFilter('COMPLETED')),
    }),
    [preFilteredData, getFilteredTasksByFilter, filterValue]
  );

  const activeRow = useMemo(() => data.find((row) => row.id === activeRowValue), [activeRowValue, data]);

  return (
    <IntelligentWorkflowsLayout>
      <Stack gap={8}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={6}>
            <SearchBar rowCount={filteredData.selectedFilter.length} />
            <MyTasksOnlySwitch />
          </Stack>
          <Filter
            ALL={filteredData.ALL.length}
            IN_PROGRESS={filteredData.IN_PROGRESS.length}
            COMPLETED={filteredData.COMPLETED.length}
          />
        </Stack>
        <Table rows={filteredData.selectedFilter} isLoading={isLoading} />
      </Stack>
      {activeRow ? <TaskDrawer taskRow={activeRow} onClose={() => setActiveRowValue('')} /> : null}
    </IntelligentWorkflowsLayout>
  );
};
