import dagre from '@dagrejs/dagre';
import { WorkflowEdge, WorkflowLabelNode, WorkflowModel, WorkflowNodeFixedDimensions } from '../model/types';
import { RendererEdge, RendererNode } from './types';
import { assertExhausted } from '@verticeone/utils/logic';
import { getNodeDimensions } from './NodeComponents/getNodeDimensions';
import { MarkerType } from '@xyflow/react';
import { getDagreGraphFromWorkflowModel, getWorkflowLabelNodesWithDimensions } from './getDagreGraphFromWorkflowModel';

const getId = (originalId: string, counter: number) => `id-${originalId}-${counter}`;

const modelNodeToRendererNode = (
  node: WorkflowNodeFixedDimensions,
  positions: dagre.graphlib.Graph,
  counter: number
): RendererNode => {
  const kind = node.kind;
  const nodeWithPosition = positions.node(node.id);

  const nodeDimensions = getNodeDimensions(node);
  const position = {
    x: nodeWithPosition.x - nodeDimensions.width / 2,
    y: nodeWithPosition.y - nodeDimensions.height / 2,
  };

  const id = getId(node.id, counter);

  switch (kind) {
    case 'start':
      return {
        id,
        type: 'start',
        data: node,
        position,
        selectable: false,
        deletable: false,
      };
    case 'end':
      return {
        id,
        type: 'end',
        data: node,
        position,
        selectable: false,
        deletable: false,
      };
    case 'task':
      return {
        id,
        type: 'task',
        data: node,
        position,
        selectable: false,
        deletable: false,
      };
    case 'gateway':
      return {
        id,
        type: 'gateway',
        data: node,
        position,
        deletable: false,
      };
    default:
      assertExhausted(kind);
      throw new Error('Unknown node kind');
  }
};

export const LABEL_NODE_HEIGHT = 24;

const modelLabelNodeToRendererLabelNode = (
  node: WorkflowLabelNode,
  positions: dagre.graphlib.Graph,
  counter: number
): RendererNode => {
  const nodeWithPosition = positions.node(node.id);

  const position = {
    x: nodeWithPosition.x - node.width / 2,
    y: nodeWithPosition.y - LABEL_NODE_HEIGHT / 2,
  };

  const id = getId(node.id, counter);

  return {
    id,
    type: 'label',
    data: node,
    position,
    deletable: false,
  };
};

const modelEdgeToRendererEdge = (
  edge: WorkflowEdge,
  counter: number,
  positions: dagre.graphlib.Graph
): RendererEdge => {
  const source = getId(edge.from, counter);
  const target = getId(edge.to, counter);

  return {
    label: edge.name,
    type: 'edge',
    id: edge.id,
    source,
    target,
    data: {
      ...edge,
      points: positions.edge(edge.from, edge.to)?.points,
    },
    markerEnd: {
      type: MarkerType.Arrow,
      width: 30,
      height: 30,
      strokeWidth: 0.6,
      color: edge?.state?.selected ? '#0057fa' : edge?.state?.passed ? '#16A249' : '#94A3B8',
    },
  };
};

export const modelToRendererGraph = (
  model: WorkflowModel,
  counter: number
): { nodes: RendererNode[]; edges: RendererEdge[] } => {
  const positions = getDagreGraphFromWorkflowModel(model);

  const labelNodes: WorkflowLabelNode[] = [];
  const otherNodes: WorkflowNodeFixedDimensions[] = [];

  model.nodes.forEach((node) => {
    if (node.kind === 'label') {
      labelNodes.push(node);
    } else {
      otherNodes.push(node);
    }
  });

  const rendererNodes = otherNodes.map((node) => modelNodeToRendererNode(node, positions, counter));
  const rendererLabelNodes = getWorkflowLabelNodesWithDimensions(labelNodes).map((node) =>
    modelLabelNodeToRendererLabelNode(node, positions, counter)
  );
  const edges = model.edges
    // sort edges so that passed or selected edges are always on top
    .sort(
      (a, b) =>
        Number((a.state?.passed || a.state?.selected) ?? false) -
        Number((b.state?.passed || b.state?.selected) ?? false)
    )
    .map((edge) => modelEdgeToRendererEdge(edge, counter, positions));

  return {
    nodes: [...rendererNodes, ...rendererLabelNodes],
    edges,
  };
};
