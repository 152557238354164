import { z } from 'zod';
import { CheckboxFieldType, checkboxFieldSchema } from './fields/CheckboxField';
import { DateFieldType, dateFieldSchema } from './fields/DateField';
import { DropdownFieldType, dropdownFieldSchema } from './fields/DropdownField';
import { MoneyFieldType, moneyFieldSchema } from './fields/MoneyField';
import { NumberFieldType, numberFieldSchema } from './fields/NumberField';
import { ShortTextFieldType, shortTextFieldSchema } from './fields/ShortTextField';
import { LongTextFieldType, longTextFieldSchema } from './fields/LongTextField';
import { LineItemsFieldType, lineItemsFieldSchema } from './fields/LineItemsField';
import { RadioGroupFieldType, radioGroupFieldSchema } from './fields/RadioGroupField';
import { FileUploadFieldType, fileUploadFieldSchema } from './fields/FileUploadField';

export type DynamicFormField =
  | CheckboxFieldType
  | DateFieldType
  | DropdownFieldType
  | LineItemsFieldType
  | LongTextFieldType
  | MoneyFieldType
  | NumberFieldType
  | RadioGroupFieldType
  | ShortTextFieldType
  | FileUploadFieldType;

export type DynamicFormSchema = {
  fields: DynamicFormField[];
};

export const dynamicFormFieldSchema = z.union([
  checkboxFieldSchema,
  shortTextFieldSchema,
  lineItemsFieldSchema,
  longTextFieldSchema,
  dateFieldSchema,
  dropdownFieldSchema,
  radioGroupFieldSchema,
  moneyFieldSchema,
  numberFieldSchema,
  fileUploadFieldSchema,
]);

// Some fileds (e.g. FileUpload) might look slightly different in preview mode (drop area visible) vs. readOnly mode (only file list visible)
export type DynamicFormPresenterMode = 'readOnly' | 'readWrite' | 'preview';

export type DynamicFormPresenterConfig = {
  mode: DynamicFormPresenterMode;
  defaultCurrency: string;
  filePathPrefix: string;
};
