import { useTranslation } from 'react-i18next';
import unique from 'lodash/uniq';

import type { Variable } from '../../types';
import type { VariablesGroup } from '../../VariableSelector/types';
import { countSelectableVariables, groupVariablesByOrigin } from '../../VariableSelector/utils';
import { useMemo } from 'react';

const sortVariablesByKind = (variables: Variable[]) => {
  const serviceInputVariables = [];
  const taskVariables = [];
  const edgeVariables = [];

  for (const variable of variables) {
    if (variable.origin.kind === 'vertice-account-service') {
      serviceInputVariables.push(variable);
    } else if (variable.origin.kind === 'vertice-task-output-mapping') {
      taskVariables.push(variable);
    } else if (variable.origin.kind === 'vertice-edge-mapping') {
      edgeVariables.push(variable);
    }
  }

  return { serviceInputVariables, taskVariables, edgeVariables };
};

export const useWorkflowVariablesGroups = ({
  allRequestVariables,
  udfVariables,
}: {
  allRequestVariables: Variable[];
  udfVariables: Variable[];
}): VariablesGroup[] => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR' });

  const variablesGroups = useMemo(() => {
    const { serviceInputVariables, taskVariables, edgeVariables } = sortVariablesByKind(allRequestVariables);

    const varGroups: VariablesGroup[] = [];

    if (serviceInputVariables.length) {
      varGroups.push({
        originsWithVariables: groupVariablesByOrigin({ variables: serviceInputVariables, hideOrigin: true }),
        count: countSelectableVariables(serviceInputVariables),
        title: t('LIST.WORKFLOW_INPUTS'),
        id: 'workflow.inputs',
        filterLabel: t('FILTER.INPUTS'),
        expandedSectionsIds: unique(serviceInputVariables.map((variable) => variable.origin.id)),
      });
    }

    if (taskVariables.length) {
      varGroups.push({
        originsWithVariables: groupVariablesByOrigin({ variables: taskVariables }),
        count: countSelectableVariables(taskVariables),
        title: t('LIST.TASKS_VARIABLES'),
        id: 'workflow.tasks',
        filterLabel: t('FILTER.TASKS'),
      });
    }

    if (edgeVariables.length) {
      varGroups.push({
        originsWithVariables: groupVariablesByOrigin({ variables: edgeVariables }),
        count: countSelectableVariables(edgeVariables),
        title: t('LIST.ROUTES_VARIABLES'),
        id: 'workflow.routes',
        filterLabel: t('FILTER.ROUTES'),
      });
    }

    if (udfVariables.length) {
      const activeUdfVariables = udfVariables.filter((variable) => !variable.isDeprecated);

      varGroups.push({
        originsWithVariables: groupVariablesByOrigin({ variables: activeUdfVariables, hideOrigin: true }),
        count: countSelectableVariables(activeUdfVariables),
        title: t('LIST.GLOBAL_VARIABLES'),
        id: 'workflow.global',
        filterLabel: t('FILTER.GLOBAL'),
        expandedSectionsIds: unique(activeUdfVariables.map((variable) => variable.origin.id)),
      });
    }
    return varGroups;
  }, [allRequestVariables, t, udfVariables]);
  return variablesGroups;
};
