import { z } from 'zod';

export const fileUploadFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const fileUploadFieldSchema = z.object({
  name: z.string(),
  type: z.literal('FILE_UPLOAD'),
  metadata: fileUploadFieldMetadataSchema,
});

export const fileUploadSchema = z.object({
  fullPath: z.string(),
  name: z.string(),
});

export type FileUploadFieldType = z.infer<typeof fileUploadFieldSchema>;

export type FileUploadFieldMetadata = z.infer<typeof fileUploadFieldMetadataSchema>;
