import React from 'react';
import { Control, FieldPath, FieldValues, UseFormRegister, useFormState } from 'react-hook-form';
import { TextField, TextFieldCaption, Text } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import FormControl from './FormControl';

type ApiKeyFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
};

const ApiKeyField = <T extends FieldValues>({ name, register, control }: ApiKeyFieldProps<T>) => {
  const { errors } = useFormState({ control, name });

  return (
    <FormControl>
      <Stack>
        <TextFieldCaption label={'API Key'} htmlFor="api-key" size="XS" required />
        <Text variant="body-regular" size="XS" color="text3">
          {'Generate an API key from the target system and paste it here'}
        </Text>
      </Stack>
      <TextField id="api-key" size="S" error={!!errors} placeholder={'API key'} {...register(name)} />
    </FormControl>
  );
};

export default ApiKeyField;
