import { z } from 'zod';
import { t } from 'i18next';

export const dropdownFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
  values: z
    .string()
    .array()
    .min(1, { message: t('DYNAMIC_FORM_BUILDER.DIALOG.ADD_AT_LEAST_ONE_VALUE') }),
});

export const dropdownFieldSchema = z.object({
  name: z.string(),
  type: z.literal('DROPDOWN'),
  metadata: dropdownFieldMetadataSchema,
});

export type DropdownFieldMetadata = z.infer<typeof dropdownFieldMetadataSchema>;

export type DropdownFieldType = z.infer<typeof dropdownFieldSchema>;
