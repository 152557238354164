import { Stack, styled } from '@mui/material';
import { Button, Dialog, Divider, IconWrapper } from '@verticeone/design-system';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchInput from '../../../../../components/SearchInput';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import type { Variable, VariableId } from '../types';
import { CustomVariableFooter } from './CustomVariableFooter';
import { DialogScrollProvider, useDialogScroll } from './DialogScrollProvider';
import { VariablesList } from './VariablesList';
import type { FilterOption, VariablesGroup } from './types';
import {
  filterVariablesGroupsByGroupId,
  filterVariablesGroupsBySearchValue,
  setVariablesGroupsByVariableId,
} from './utils';

const DialogHeader = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.neutral.color3}`,
}));

const DialogContentContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  overflowY: 'auto',
}));

const DialogContent = ({ children }: PropsWithChildren) => {
  const { containerRef } = useDialogScroll();

  return <DialogContentContainer ref={containerRef}>{children}</DialogContentContainer>;
};

const useVariablesGroupsFilterOptions = ({
  variablesGroups,
  allVariablesCount,
}: {
  variablesGroups: VariablesGroup[];
  allVariablesCount: number;
}): FilterOption[] => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.FILTER',
  });

  return useMemo(() => {
    return [
      {
        id: 'all',
        label: t('ALL') + ` (${allVariablesCount})`,
      },
      ...variablesGroups
        .filter((variablesGroup) => !!variablesGroup.filterLabel)
        .map((group) => ({
          id: group.id,
          label: group.filterLabel + ` (${group.count})`,
        })),
    ];
  }, [variablesGroups, allVariablesCount, t]);
};

const useVariablesDialogSetup = (variablesGroups: VariablesGroup[]) => {
  const allVariablesCount = variablesGroups.reduce((count, group) => count + group.count, 0);
  const filterOptions = useVariablesGroupsFilterOptions({
    allVariablesCount,
    variablesGroups,
  });

  return {
    allVariablesCount,
    filterOptions,
  };
};

const useVariablesFilterState = () => {
  const [searchValue, setSearchValue] = useState('');
  const [groupFilter, setGroupFilter] = useState('all');

  return {
    searchValue,
    setSearchValue,
    groupFilter,
    setGroupFilter,
  };
};

export type VariablesDialogProps = {
  variablesGroups: VariablesGroup[];
  typeFilter?: string;
  disabledVariables?: VariableId[];
  isOpen: boolean;
  onClose: () => void;
  onVariableClick: (variable: Variable) => void;
  onAddLocalVariable?: (variable: Variable) => void;
  selectedVariableId?: VariableId;
  positionFromTheRight?: number;
  addVariableDefaults?: Pick<Variable, 'origin' | 'type'>;
  withBackdrop?: boolean;
};

export const VariablesDialog: FC<VariablesDialogProps> = ({
  isOpen,
  onClose,
  variablesGroups,
  typeFilter,
  disabledVariables,
  onVariableClick,
  onAddLocalVariable,
  selectedVariableId,
  positionFromTheRight,
  addVariableDefaults,
  withBackdrop = false,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR' });
  const { searchValue, setGroupFilter, groupFilter, setSearchValue } = useVariablesFilterState();

  const setVariablesGroups = setVariablesGroupsByVariableId({ variableId: selectedVariableId, variablesGroups });
  const filteredVariablesGroupsBySearch = filterVariablesGroupsBySearchValue({
    variablesGroups: setVariablesGroups,
    searchValue,
  });
  const { allVariablesCount, filterOptions } = useVariablesDialogSetup(filteredVariablesGroupsBySearch);
  const filteredVariableGroupsByGroupId = filterVariablesGroupsByGroupId({
    variablesGroups: filteredVariablesGroupsBySearch,
    groupId: groupFilter,
  });

  const handleAddLocalVariable = onAddLocalVariable
    ? (newVariable: Variable) => {
        onAddLocalVariable(newVariable);
        setSearchValue('');
      }
    : undefined;

  return (
    <Dialog
      paperSx={
        positionFromTheRight
          ? {
              right: positionFromTheRight,
              position: 'absolute',
              marginRight: 4,
            }
          : undefined
      }
      size="M"
      withTransparentBackdrop={!withBackdrop}
      open={isOpen}
      onClose={onClose}
    >
      <DialogHeader width="100%" gap={2} direction="column">
        <SearchInput
          sx={{ flexGrow: 1 }}
          variant="outlined"
          value={searchValue}
          onSearch={setSearchValue}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size="S"
          placeholder={t('FILTER.SEARCH_PLACEHOLDER', {
            count: allVariablesCount,
          })}
          focusOnMount
        />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {filterOptions.map((filterOption) => (
            <Button
              key={filterOption.id}
              color={filterOption.id === groupFilter ? 'secondary' : 'neutral'}
              variant={filterOption.id === groupFilter ? 'ghost' : 'outline'}
              onClick={() => setGroupFilter(filterOption.id)}
              size="XS"
            >
              {filterOption.icon && <IconWrapper size="XS" icon={filterOption.icon} />}
              {filterOption.label}
            </Button>
          ))}
        </Stack>
      </DialogHeader>
      <DialogScrollProvider>
        <DialogContent>
          <Stack gap={4}>
            {filteredVariableGroupsByGroupId.length > 0
              ? filteredVariableGroupsByGroupId.map((variableGroup) => (
                  <VariablesList
                    highlightedLabel={searchValue}
                    key={searchValue ? `${variableGroup.id}-searching` : variableGroup.id}
                    title={variableGroup.title}
                    originsWithVariables={variableGroup.originsWithVariables}
                    typeFilter={typeFilter}
                    disabledVariables={disabledVariables}
                    onVariableClick={onVariableClick}
                    selectedVariableId={selectedVariableId}
                    expandedSectionsIds={variableGroup.expandedSectionsIds || undefined}
                    variablesCount={variableGroup.count}
                  />
                ))
              : searchValue
              ? t('MESSAGES.NO_VARIABLES_MATCHING_QUERY', { query: searchValue })
              : t('MESSAGES.NO_VARIABLES')}
          </Stack>
        </DialogContent>
      </DialogScrollProvider>
      {addVariableDefaults && handleAddLocalVariable && (
        <Stack>
          <Divider />
          <CustomVariableFooter
            addVariableDefaults={addVariableDefaults}
            onAddLocalVariable={handleAddLocalVariable}
            variablesGroups={variablesGroups}
          />
        </Stack>
      )}
    </Dialog>
  );
};
