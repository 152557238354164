import { Box, Stack } from '@mui/material';
import { FC, useState } from 'react';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetTaskOverviewQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useLoggedUser } from '@verticeone/auth/src';
import { Divider, Drawer, Loader } from '@verticeone/design-system';
import { TaskContextProvider } from './TaskContext';
import { TaskDrawerContent } from './TaskDrawerContent';
import { TaskDrawerHeader } from './TaskDrawerHeader';
import { TaskDrawerInfo } from './TaskDrawerInfo/TaskDrawerInfo';
import type { TaskRow } from '../dataSource';
import { parseRequestRef } from '../../../../hooks/workflows/refUtils';
import { useGetTaskUnreadCount } from './useGetTaskUnreadCount';
import useLocalStorage from './useLocalStorageBoolean';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';

type TaskDrawerProps = {
  taskRow: TaskRow;
  onClose: () => void;
};

const TASK_COMMENTS_OPEN_KEY = 'taskCommentsOpen';
const DRAWER_WIDTH = '900px';
const EXPANDED_DRAWER_WIDTH = '1284px';

export const TaskDrawer: FC<TaskDrawerProps> = ({ taskRow, onClose }) => {
  const { userId } = useLoggedUser();
  const [open, setOpen] = useState(true);
  const { accountId } = useAccountContext();
  const [isCommentsOpenValue, setIsCommentsOpen] = useLocalStorage(TASK_COMMENTS_OPEN_KEY, false);

  const isCommentsOpen = isCommentsOpenValue;

  const { data: taskOverview, isLoading } = useGetTaskOverviewQuery({ accountId, taskId: taskRow.id });
  const isTaskOverviewMismatch = taskOverview?.task.taskId !== taskRow.taskId;

  const unreadCount = useGetTaskUnreadCount({
    accountId,
    requestId: taskRow ? parseRequestRef(taskRow?.requestRef!).requestId : undefined,
    taskRef: taskRow?.ref!,
    skip: !taskRow,
  });

  const toggleComments = () => {
    setIsCommentsOpen(!isCommentsOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
      // Hack to get the drawer to transition properly and not get stuck in a weird state
      setOpen(true);
    }, 200);
  };

  const width = isCommentsOpen ? EXPANDED_DRAWER_WIDTH : DRAWER_WIDTH;

  return (
    <Drawer open={open} size="S" width={width} onClose={handleClose}>
      <Stack minHeight={0} height={'100vh'} overflow={'hidden'} divider={<Divider />}>
        <TaskDrawerHeader
          taskRow={taskRow}
          userId={userId}
          onClose={handleClose}
          isCommentsOpen={isCommentsOpen}
          toggleComments={toggleComments}
          unreadCount={unreadCount}
        />
        {isLoading || isTaskOverviewMismatch ? (
          <Loader size={20} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />
        ) : (
          <TaskContextProvider task={taskRow} taskOverview={taskOverview}>
            <TaskDrawerInfo />
            <Divider />
            {/*
            Setting flex-shrink to very high number (compared to default flex-shrink of TaskDrawerInfo = 1)
            causes TaskDrawerInfo to show in full height as long as there is still some screen space left.
            The TaskDrawerInfo can have a bigger height when there is long task description expanded.
            */}
            <Box flexGrow={1} flexShrink={3000} minHeight={0}>
              <TaskDrawerContent key={taskRow.id} onCompleted={handleClose} isCommentsOpen={isCommentsOpen} />
            </Box>
          </TaskContextProvider>
        )}
      </Stack>
    </Drawer>
  );
};
