import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { LineItem, LineItemType } from '../../../types';
import { useTranslation } from 'react-i18next';
import { DesignSystemColor, GridSelect, SelectColor, SimpleOption } from '@verticeone/design-system';
import { useLineItemTypeOptions } from './useLineItemTypeOptions';

export const EditableLineItemTypeCell = ({
  id,
  value,
  field,
  color,
}: GridCellParams<LineItem, LineItemType> & { color: DesignSystemColor }) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const options = useLineItemTypeOptions();
  return (
    <GridSelect<SimpleOption, false>
      isMulti={false}
      value={options.find((opt) => opt.value === value)}
      options={options}
      placeholder={t('DYNAMIC_FORM_BUILDER.FIELD.LINE_ITEMS.LINE_TYPE')}
      menuPlacement="auto"
      onChange={(newValue) => {
        if (!newValue) return;
        void apiRef.current.setEditCellValue({ id, field, value: newValue.value });
      }}
      color={color as SelectColor}
    />
  );
};
