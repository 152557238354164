import { GRID_REORDER_COL_DEF, GridPreProcessEditCellProps, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { DropdownValueListItem } from './types';

export const getRowId = (row: GridRowModel) => row.id;

export const normalizeValue = (value: string) => value.trim().replace(/\s\s+/g, ' ');

export const enhanceRowsWithReorderProperty = (rows: GridRowsProp, propName: string) =>
  rows.map((item) => ({ ...item, [GRID_REORDER_COL_DEF.field]: item[propName] }));

export const preProcessNonEmptyCell =
  (existingItems: DropdownValueListItem[]) => (params: GridPreProcessEditCellProps) => {
    const rowId = params.row.id;
    const valueName = normalizeValue(params.props.value);
    const isEmpty = !valueName;
    const valueExists =
      valueName && existingItems.some((item: DropdownValueListItem) => item.value === valueName && item.id !== rowId);
    return { ...params.props, error: isEmpty || valueExists };
  };
