import { Button } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export type PreviewFormButtonProps = { onClick: () => void };

export const PreviewFormButton = ({ onClick }: PreviewFormButtonProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.FORM_PREVIEW',
  });
  return (
    <motion.div layoutId="preview-button">
      {/* ^^ Morph animation between close preview & open preview button */}
      <Button variant="solid" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} onClick={onClick}>
        {t('PREVIEW_FORM')}
      </Button>
    </motion.div>
  );
};
