import { PropsWithChildren, useState } from 'react';
import { useValidateWorkflow } from './useValidateWorkflow';
import { WorkflowValidationContextProvider } from './WorkflowValidationContext';

export const WorkflowValidationProvider = ({ children }: PropsWithChildren) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const validations = useValidateWorkflow();

  const isWorkflowValid = !validations.errors?.length && !validations.warnings?.length && !validations.infos?.length;

  return (
    <WorkflowValidationContextProvider
      value={{
        ...validations,
        isWorkflowValid,
        isValidationDrawerOpen: isDrawerOpen,
        setIsValidationDrawerOpen: setIsDrawerOpen,
      }}
    >
      {children}
    </WorkflowValidationContextProvider>
  );
};
