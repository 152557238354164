import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useFormatDate } from '@verticeone/utils/formatting';

import type { TaskRow } from '../../../dataSource';

type TaskDateCompletedProps = {
  taskRow: TaskRow;
};

export const TaskDateCompleted = ({ taskRow }: TaskDateCompletedProps) => {
  const formatDate = useFormatDate();

  return (
    <Stack minWidth={0} direction="row" alignItems="center" gap={0.5}>
      {!!taskRow.closedAt && (
        <Text variant="body-regular" size="S" color="text1">
          {formatDate(taskRow.closedAt)}
        </Text>
      )}
    </Stack>
  );
};
