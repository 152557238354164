import { ChangeEvent, FC, useState } from 'react';
import { TextField } from '@verticeone/design-system';

import { sanitizeFloatString, parseNumber } from '../../../../../forms/fields/FormNumberField/utils';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';

type NumberFieldProps = {
  value: number | string;
  onChange: (value: number | string) => void;
  testId?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
};

export const NumberField: FC<NumberFieldProps> = ({ value, onChange, testId, placeholder, disabled, error }) => {
  const [localValue, setLocalValue] = useState<string>(value.toString());

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const floatStringValue = sanitizeFloatString(newValue);
    const numberValue = parseNumber(newValue);

    setLocalValue(floatStringValue);

    if (numberValue === null || isNaN(numberValue)) {
      if (floatStringValue === '') {
        onChange('');
      }
      return;
    }

    onChange(numberValue);
  };

  return (
    <TextField
      fullWidth
      testId={testId}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      type="text"
      value={localValue}
      variant="outlined"
      onChange={handleOnChange}
      disabled={disabled}
      placeholder={placeholder}
      error={error}
    />
  );
};
