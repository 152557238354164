import React, { FC, useState } from 'react';
import { Stack, styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { rgba } from 'polished';
import { Text, IconWrapper } from '@verticeone/design-system';

import { NodeControls } from './NodeControls';
import { GatewayNodeState, WorkflowGatewayNode } from '../../model/types';
import { GatewayIconMap } from '../../../components/icons/Icons';
import { useWorkflowRendererContext } from '../WorkflowRendererContext';

import { ValidationCheck } from './ValidationCheck';

const StyledGatewayNode = styled(Stack)<{
  $state?: GatewayNodeState;
  $hasValidationMessage: 'error' | 'warning' | null;
}>(({ theme: { palette }, $state }) => ({
  // TODO: Add $hasValidationMessage to the styles when we have designs
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  span: {
    color: palette.text.color2,
    position: 'absolute',
    top: 30,
    width: 100,
  },
  zIndex: 1,
  // diamond shape
  '&:before': {
    content: '""',
    position: 'absolute',
    border: 'none',
    inset: '16%',
    transform: 'rotateZ(45deg)',
    background: $state?.selected
      ? palette.secondary.color2
      : $state?.passed
      ? palette.success.color4
      : palette.secondary.color4,
    zIndex: 3,
  },
  '&:hover:before': {
    background: $state?.selected ? undefined : palette.secondary.hover.color4,
  },
  // gradient border in diamond shape
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: '16%',
    padding: '2px',
    background: $state?.passed
      ? palette.success.color3
      : `linear-gradient(135deg, ${rgba(palette.secondary.color4, 0.9)} 0%, ${rgba(palette.text.color1, 0.1)} 100%)`,
    transform: 'rotateZ(45deg) scale(1.1)',
    zIndex: 2,
  },
  '&:hover:after': {
    background: $state?.selected ? undefined : palette.secondary.hover.color3,
  },
  svg: {
    color: $state?.selected ? palette.primary.color4 : palette.neutral.color2,
    zIndex: 4,
  },
  '&:hover svg': {
    color: $state?.selected ? undefined : palette.secondary.hover.color2,
  },
}));

export const GatewayNodeComponent: FC<NodeProps<Node<WorkflowGatewayNode>>> = (props) => {
  const Icon = GatewayIconMap[props.data.gatewayType];

  const { isEditor } = useWorkflowRendererContext();
  const [hasValidationMessage, setHasValidationMessage] = useState<'error' | 'warning' | null>(null);

  return (
    <>
      <NodeControls
        {...props}
        content={
          <StyledGatewayNode $state={props.data.state} $hasValidationMessage={hasValidationMessage}>
            <IconWrapper icon={Icon} />
            <Text variant="heading" size="XS">
              {props.data.name}
            </Text>
          </StyledGatewayNode>
        }
      />
      {isEditor && <ValidationCheck setValidationResult={setHasValidationMessage} id={props.data.id} />}
    </>
  );
};
