import {
  isRuleGroupType,
  isRuleGroupTypeIC,
  QueryValidator,
  ValidationMap,
  type RuleGroupType,
  type RuleGroupTypeAny,
  type RuleGroupTypeIC,
  type RuleType,
  ValidationResult,
} from 'react-querybuilder';

import isEqual from 'lodash/isEqual';
import cloneDeepWith from 'lodash/cloneDeepWith';
import omit from 'lodash/omit';

export const isQueryValid = (query: RuleGroupTypeAny | RuleType): boolean => {
  if (isRuleGroupType(query) || isRuleGroupTypeIC(query)) {
    const { rules } = query;

    if (!rules.length) {
      return false;
    }

    return rules.every((rule) => {
      if (isRuleGroupType(rule)) {
        return isQueryValid(rule);
      }

      if (isRuleGroupTypeIC(rule)) {
        return isQueryValid(rule);
      }

      if (typeof rule === 'string') {
        return rule !== '';
      }

      return validRule(rule);
    });
  }

  return validRule(query);
};

const validRule = ({ field, value, operator }: RuleType<string, string, any, string>): boolean =>
  field !== '' && value !== '' && operator !== '';

export const isEmptyRule = (rule: RuleGroupType | RuleType | RuleGroupTypeIC) => {
  return !isRuleGroupType(rule) && !isRuleGroupTypeIC(rule) && !rule.field && !rule.operator && !rule.value;
};

export const ruleValidator: QueryValidator = (group): ValidationMap => {
  const validations: ValidationMap = {};

  const groups: RuleGroupTypeAny[] = [group];
  while (groups.length) {
    const { rules } = groups.pop()!;

    for (const rule of rules) {
      if (isRuleGroupType(rule) || isRuleGroupTypeIC(rule)) {
        groups.push(rule);
      } else if (typeof rule === 'object' && rule.id) {
        validations[rule.id] = validRule(rule);
      }
    }
  }

  return validations;
};

export const validationError = (validation: boolean | ValidationResult | undefined | null) => {
  if (validation === undefined || validation === null) {
    return false;
  }

  return !(typeof validation === 'boolean' ? validation : validation.valid);
};

export const isEqualRule = (a: RuleGroupType, b: RuleGroupType) => {
  // QueryBuilder autogenerates ids for each rule on first render if not present,
  // so for compare they need to be omited
  const omitNestedIds = (object: any): any =>
    cloneDeepWith(object, (value) => {
      if (value && typeof value === 'object' && 'id' in value) {
        return omitNestedIds(omit(value, 'id'));
      }
    });

  return isEqual(omitNestedIds(a), omitNestedIds(b));
};
