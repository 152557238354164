import { FormDefinition, useCreateCatalogResourceMutation } from '@vertice/slices/src/openapi/codegen/catalogAPI';

import { useCallback } from 'react';

export type UseDeployCustomFormProps = {
  accountId: string;
};

export const useDeployCustomForm = (props: UseDeployCustomFormProps) => {
  const { accountId } = props;

  const [deployCatalogFormResource] = useCreateCatalogResourceMutation();

  const deployCustomForm = useCallback(
    async (payload: FormDefinition) => {
      try {
        const newFormPath = payload.ResourceUrn.split('/').slice(1).join('/');

        await deployCatalogFormResource({
          accountId: accountId,
          createCatalogResource: {
            path: newFormPath,
            manifest: {
              Kind: payload.Kind,
              Version: payload.Version,
            },
            definition: payload.Form,
          },
        }).unwrap();
      } catch (e) {
        return 'error';
      }
      return 'success';
    },
    [accountId, deployCatalogFormResource]
  );

  return { deployCustomForm };
};
