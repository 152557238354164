import { useCallback, useMemo } from 'react';
import { EditUserTaskFormData } from '../../forms/EditUserTaskForm/schema';
import { Task, TaskAssignmentConfiguration, TaskDefinition } from '../../../../definitionsTypes';
import {
  getAssignmentConfigurationsFromAssignees,
  getAssignmentConfigurationsFromConditionalAssignees,
} from '../../forms/EditUserTaskForm/assignmentOptions';
import {
  getTaskFormConfiguration,
  isAssignmentConfiguration,
  replaceConfigurationInTaskDefinition,
} from '../../../../definitions/taskDefinition';
import { isTaskIOMappingConfiguration } from '../../../../pocWorkflowSchema';
import { IOMapping } from '../../forms/EditUserTaskForm/ioMapping';
import { toJMESPathExpression } from '../../hooks/utils';
import {
  createNewGenericFormDefinition,
  createNewGenericFormUrn,
  isArrayEqual,
} from '../../forms/EditUserTaskForm/CustomForm';
import { CatalogResourceCamelCase } from '@vertice/slices/src/api/enhancedCatalogAPI';
import { FormDefinition } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { isFormCatalogResource } from '../../../../utils/catalogResourceUtils';
import { DynamicFormField } from '../../../../../forms/dynamicForms';
import { isUrnTaskCustomForm } from '../../../../task/TaskDrawer/TaskCustomForm';

export type UseTaskFormSubmitProps = {
  task: TaskDefinition;
  onSave: (task: TaskDefinition) => void;
  customForm?: CatalogResourceCamelCase;
  deployCustomForm: (payload: FormDefinition) => Promise<'success' | 'error'>;
  workflowId: string;
  workflowVersion: string;
  accountId: string;
};

type AttachFormConfig = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
  customForm?: CatalogResourceCamelCase;
  deployCustomForm: (payload: FormDefinition) => Promise<'success' | 'error'>;
};
/**\
 * The variables array for generic widgets:
 *    - urn:verticeone:vertice::services:widget/core/key-dates/simple/v1
 *    - urn:verticeone:vertice::services:widget/core/key-costs/simple/v1
 * These are explicitly mapped for every DATE and MONEY field and hidden from the user.
 */
export const GEN_KEY_COST = 'keyCost';
export const GEN_KEY_COST_SOURCE = 'keyCostSource';
export const GEN_KEY_DATE = 'keyDate';
export const GEN_KEY_DATE_SOURCE = 'keyDateSource';
export const RESERVED_VARIABLES: string[] = [
  GEN_KEY_COST,
  GEN_KEY_COST_SOURCE,
  GEN_KEY_DATE,
  GEN_KEY_DATE_SOURCE,
] as const;

export const useTaskFormSubmit = (props: UseTaskFormSubmitProps) => {
  const { task, onSave, customForm, deployCustomForm, workflowVersion, workflowId, accountId } = props;

  const attachFormDefinitionConfig = useMemo<AttachFormConfig>(() => {
    return { accountId, workflowVersion, workflowId, customForm, deployCustomForm };
  }, [accountId, workflowId, workflowVersion, customForm, deployCustomForm]);

  const handleSubmit = useCallback(
    async (values: EditUserTaskFormData) => {
      let updatedTask = task;

      updatedTask = attachBaseAttributes(updatedTask, values);
      updatedTask = attachAssignees(updatedTask, values);
      updatedTask = attachApprovalFormIOMapping(updatedTask, values);
      updatedTask = await attachFormDefinition(updatedTask, values, attachFormDefinitionConfig);
      updatedTask = attachIOMappingFromCustomForm(updatedTask, values, attachFormDefinitionConfig);

      onSave(updatedTask);
    },
    [onSave, task, attachFormDefinitionConfig]
  );

  return { handleSubmit };
};

const attachBaseAttributes = (task: TaskDefinition, values: EditUserTaskFormData) => {
  const updatedTask = updateTask(task, { name: values.name, description: values.description });
  return updatedTask;
};

const attachAssignees = (task: TaskDefinition, values: EditUserTaskFormData) => {
  const defaultAssignment = getAssignmentConfigurationsFromAssignees(values.defaultAssignees);
  const conditionalAssignees = getAssignmentConfigurationsFromConditionalAssignees(values.conditionalAssignees);
  const newAssignmentConfigs = [...defaultAssignment, ...conditionalAssignees];

  const configurations = replaceAssignmentConfigs(task.task.configurations, newAssignmentConfigs);

  return updateTask(task, { configurations });
};

const attachApprovalFormIOMapping = (task: TaskDefinition, values: EditUserTaskFormData) => {
  const { buttonLabels, formHeading, isSimpleApprovalForm } = values;

  if (!isSimpleApprovalForm) return task;

  const newMapping = new IOMapping(task.task.configurations?.find(isTaskIOMappingConfiguration));

  if (buttonLabels) newMapping.addInputMapping('buttonLabels', toJMESPathExpression(buttonLabels));
  if (formHeading) newMapping.addInputMapping('formHeading', toJMESPathExpression(formHeading));

  return replaceConfigurationInTaskDefinition(task, newMapping.getIOMappingConfiguration());
};

const attachFormDefinition = async (task: TaskDefinition, values: EditUserTaskFormData, config: AttachFormConfig) => {
  let formConfiguration = getTaskFormConfiguration(task);
  const { customForm, deployCustomForm, ...configRestProps } = config;

  if (!formConfiguration || !customForm || !isFormCatalogResource(customForm.definition)) return task;

  const oldCustomFormFields = customForm.definition.form?.formUiModel?.layout?.fields ?? [];
  const isCustomFormEdited = !isArrayEqual(values.customFormFields ?? [], oldCustomFormFields ?? []);

  if (!isCustomFormEdited) return task;

  const newFormUrn = createNewGenericFormUrn(formConfiguration.formUrn, { taskId: task.task.id, ...configRestProps });
  const formDefinition = createNewGenericFormDefinition(
    customForm.definition,
    newFormUrn,
    values?.customFormFields ?? []
  );

  const result = await deployCustomForm(formDefinition);

  if (result === 'error') return task;

  formConfiguration = { ...formConfiguration, formUrn: newFormUrn };
  return replaceConfigurationInTaskDefinition(task, formConfiguration);
};

const attachIOMappingFromCustomForm = (
  task: TaskDefinition,
  values: EditUserTaskFormData,
  config: AttachFormConfig
) => {
  const variables = values.customFormVariables;
  const dynamicFields = values.customFormFields ?? [];
  const formUrn = config.customForm?.urn;

  if (!variables || !formUrn || !isUrnTaskCustomForm(formUrn)) return task;

  const newMapping = new IOMapping(task.task.configurations?.find(isTaskIOMappingConfiguration));

  newMapping.clearOutputMapping();

  Object.entries(variables).forEach(([fieldName, variableId]) => {
    if (!variableId || !isNameInFormFields(fieldName, dynamicFields) || isReservedVar(variableId)) return;
    newMapping.addOutputMapping(variableId, fieldName);
    processMetaMappings(newMapping, getFieldByName(fieldName, dynamicFields));
  });

  return replaceConfigurationInTaskDefinition(task, newMapping.getIOMappingConfiguration());
};

const updateTask = (taskDefinition: TaskDefinition, newAttrs: Partial<Task>): TaskDefinition => ({
  ...taskDefinition,
  task: {
    ...taskDefinition.task,
    ...newAttrs,
  },
});

const isNameInFormFields = (name: string, formFields: DynamicFormField[]): boolean => {
  return formFields.find((field) => field.name === name) !== undefined;
};

const isReservedVar = (name?: string): boolean => {
  if (!name) return false;
  return RESERVED_VARIABLES.includes(name);
};

const getFieldByName = (name: string, formFields: DynamicFormField[]): DynamicFormField | undefined => {
  return formFields.find((field) => field.name === name);
};

const processMetaMappings = (mapping: IOMapping, field?: DynamicFormField) => {
  if (field?.type === 'DATE') {
    mapping.addOutputMapping(GEN_KEY_DATE, field.name);
    mapping.addOutputMapping(GEN_KEY_DATE_SOURCE, `\`${field.metadata.label}\``);
  }
  if (field?.type === 'MONEY') {
    mapping.addOutputMapping(GEN_KEY_COST, field.name);
    mapping.addOutputMapping(GEN_KEY_COST_SOURCE, `\`${field.metadata.label}\``);
  }
};

const replaceAssignmentConfigs = (
  configs: Task['configurations'],
  assignments: TaskAssignmentConfiguration[]
): Task['configurations'] => {
  let newConfigs = configs ?? [];

  newConfigs = newConfigs.filter((c) => !isAssignmentConfiguration(c));
  newConfigs.push(...assignments);

  return newConfigs;
};
