import { ExpandMoreTwoTone, SvgIconComponent } from '@mui/icons-material';
import { Node } from '@xyflow/react';
import { Stack, useTheme } from '@mui/material';
import { ValidationItem as ValidationItemType } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import {
  Accordion,
  AccordionDetails,
  Button,
  IconWrapper,
  Placeholder,
  Text,
  Tooltip,
  TooltipInfoTrigger,
} from '@verticeone/design-system';
import { AccordionSummary } from '@mui/material';
import { useWorkflowValidationContext } from './WorkflowValidationContext';
import { useTranslation } from 'react-i18next';
import { useReactFlow } from '@xyflow/react';
import React, { FunctionComponent, SVGProps } from 'react';
import { styled } from '@mui/system';

type ValidationItemResolve = (node?: Node) => void;

type ValidationSectionProps = {
  type: 'error' | 'warning' | 'info';
  title: string;
  tooltip?: string;
  Icon: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  items: ValidationItemType[];
  handleResolve: ValidationItemResolve;
};

type ValidationItemProps = {
  message: string;
  taskIds?: string[];
  handleResolve: ValidationItemResolve;
};

const HoverRow = styled(Stack)(({ theme }) => ({
  marginLeft: 4,
  padding: 6,
  borderRadius: 12,
  '&:hover': {
    backgroundColor: theme.palette.core.color1,
  },
}));

const ValidationItem = ({ message, taskIds, handleResolve }: ValidationItemProps) => {
  const { setIsValidationDrawerOpen } = useWorkflowValidationContext();
  const { getNodes } = useReactFlow();
  const { t } = useTranslation();

  return (
    <HoverRow direction="row" alignItems="center" gap={2} pl={4.5} width="100%" justifyContent="space-between">
      <Text size="S" variant="body-regular" color="text2">
        {message}
      </Text>
      {taskIds && taskIds.length > 0 && (
        <Button
          variant="ghost"
          size="XS"
          color="neutral"
          onClick={() => {
            setIsValidationDrawerOpen(false);

            const allNodes = getNodes();
            const nodes = taskIds.map((id) => allNodes.find((node) => node.data.id === id));
            nodes.forEach((node) => handleResolve(node));
          }}
        >
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.RESOLVE')}
        </Button>
      )}
    </HoverRow>
  );
};

export const ValidationSection = ({ type, title, Icon, items, handleResolve, tooltip }: ValidationSectionProps) => {
  const { palette } = useTheme();

  const color = palette[type];

  return (
    <Stack py={6} px={4}>
      <Accordion size="XXS" defaultExpanded variant="label" sx={{ '& .MuiAccordionSummary-root': { minHeight: 20 } }}>
        <AccordionSummary expandIcon={<ExpandMoreTwoTone />} sx={{ flexDirection: 'row-reverse', margin: 0 }}>
          <Stack direction="row" gap={1} alignItems="center" pl={2}>
            <Stack
              width={20}
              height={20}
              bgcolor={color.color4}
              borderRadius={1}
              p={0.5}
              mr={1}
              justifyContent="center"
              alignItems="center"
            >
              <IconWrapper size="S" fill={color.color1} htmlColor={color.color1} icon={Icon} />
            </Stack>
            <Text variant="heading" size="XS" color={color.color1}>
              {title} ({items.length})
            </Text>
            <Tooltip title={tooltip} minified disableHoverListener={!title} size="S">
              <TooltipInfoTrigger opacity={0.6} />
            </Tooltip>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={2} ml={4.5} borderLeft={`1px solid ${palette.core.color3}`}>
            {items.map((item, index) => (
              <ValidationItem
                key={[type, index].join('-')}
                message={item.message}
                taskIds={item.detail ? item.detail.activityIds : undefined}
                handleResolve={handleResolve}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export const ValidationSectionSkeleton = () => {
  return (
    <Stack py={6} px={4}>
      <Accordion size="XXS" defaultExpanded variant="label" alwaysExpanded>
        <AccordionSummary expandIcon={<ExpandMoreTwoTone />} sx={{ flexDirection: 'row-reverse' }}>
          <Stack direction="row" gap={1} alignItems="center" pl={2}>
            <Placeholder width={200} height={20} variant="rounded" />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack pl={8.5} gap={2}>
            {Array.from({ length: 3 }).map((_, index) => (
              <Placeholder key={index} width={`calc(100% * ${1 / Math.sqrt(index + 1)}`} variant="rounded" />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
