import { FieldValues, useController } from 'react-hook-form';
import { CommonFormFieldProps } from '../../../../types';
import { LineItemsTable } from './LineItemsTable';
import { DesignSystemColor } from '@verticeone/design-system';
import { useCallback } from 'react';
import { LineItem } from '../types';

type LineItemsValuesListFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  editMode: boolean;
  color: DesignSystemColor;
};

export type LineItemsTableFieldComponentType<FormDataType extends FieldValues> = (
  props: LineItemsValuesListFieldProps<FormDataType>
) => JSX.Element;

export const LineItemsTableField = <FormDataType extends FieldValues = never>({
  name,
  required,
  color,
  editMode,
}: LineItemsValuesListFieldProps<FormDataType>) => {
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController<FormDataType>({
    name,
    rules: { required },
  });

  const handleChange = useCallback(
    (newValue: LineItem[]) => onChange(newValue.length === 0 ? null : newValue),
    [onChange]
  );

  return (
    <LineItemsTable
      editMode={editMode}
      color={color}
      values={value ?? []}
      onValuesChange={handleChange}
      errorText={invalid ? error?.message : undefined}
    />
  );
};
