type Money = {
  value: number;
  currency: string;
};

export const isValidMoney = (value: unknown): value is Money => {
  return (
    !!value &&
    typeof value === 'object' &&
    'value' in value &&
    'currency' in value &&
    typeof value.value === 'number' &&
    typeof value.currency === 'string'
  );
};
