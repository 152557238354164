import { FC, useEffect, useMemo, useState } from 'react';

import {
  useGetAccountIntegrationQuery,
  useGetJiraWebhookConfigQuery,
  useListEditJiraIssueTypeFieldsQuery,
  useListJiraFieldOptionsQuery,
} from '@vertice/slices';
import { useAccountContext } from '../../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { isIOMappingConfiguration } from '../../../../../definitions/taskDefinition';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { JiraProject, WorkflowVariables } from '../JiraCommon/types';
import {
  getCorrespondingTaskService,
  ISSUE_TYPE_ID_NAME,
  transformJiraToWorkflowLikeVariables,
} from '../JiraCommon/utils';
import { EditServiceTaskBase } from '../types';
import { JiraSyncTaskFormData } from './formSchema';
import { JiraSyncForm } from './JiraSyncForm';
import { getAvailableCreateJiraTasks, getJiraSyncInputMapping } from './utils';
import { useServiceCatalogResources } from '../../../../WorkflowViewer/useServiceCatalogResources';

/**
 * Get and prepare data for Jira create form like available workflow variables and Jira variables
 */
export const JiraSync: FC<EditServiceTaskBase> = (props) => {
  const { task, processDefinition, workflowServiceRef, editorConfig, resources, ...restProps } = props;
  const { accountId } = useAccountContext();
  const taskService = getCorrespondingTaskService(task, resources);
  const serviceCatalogResources = useServiceCatalogResources();
  const { xTypeServiceCatalogResources } = serviceCatalogResources;
  const syncWebhookQuery = useGetJiraWebhookConfigQuery({ accountId });
  const { data: integrationData, isLoading: isLoadingIntegration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });
  const isIntegrationActive = integrationData?.status === 'ACTIVE';
  const selectedProject = integrationData?.parameters?.projects?.[0] as JiraProject | undefined;

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });

  const [workflowVariables, setWorkflowVariables] = useState<WorkflowVariables>({
    request: requestVariables,
    udfs: udfVariables,
  });
  const handleSetWorkflowVariables = (newValue: WorkflowVariables) => {
    setWorkflowVariables(newValue);
  };

  useEffect(() => {
    setWorkflowVariables({ request: requestVariables, udfs: udfVariables });
  }, [requestVariables, udfVariables]);

  const allVariables = useMemo(() => [...workflowVariables.udfs, ...workflowVariables.request], [workflowVariables]);

  const [referencedTaskId, setReferencedTaskId] = useState<string | null | undefined>();
  // Initial referencedTaskId setup
  useEffect(() => {
    if (!task || !taskService || !allVariables.length) {
      setReferencedTaskId(undefined);
    } else if (referencedTaskId === undefined) {
      const { createJiraTasksByTickets } = getAvailableCreateJiraTasks(allVariables);
      const { ticketId } = getJiraSyncInputMapping(task);
      setReferencedTaskId(ticketId ? createJiraTasksByTickets[ticketId]?.id : null);
    }
  }, [allVariables, task, taskService, referencedTaskId]);

  /* Field type used in Jira create this Jira Sync ticket references.
   * So we can show its properties to be synced.
   */
  const issueTypeId = useMemo(() => {
    if (referencedTaskId === undefined) return undefined;

    const referencedTask = processDefinition.process.tasks?.filter(
      (processTask) => processTask.task.id === referencedTaskId
    )[0];

    if (referencedTask) {
      const ioMappingConfiguration = referencedTask.task.configurations?.find(isIOMappingConfiguration);
      const inputField = ioMappingConfiguration?.mapping.inputFields.find((input) => input.name === ISSUE_TYPE_ID_NAME);
      return inputField ? inputField.value : null;
    }

    return null;
  }, [processDefinition, referencedTaskId]);

  const filterStatus = useMemo(() => {
    return taskService ? getJiraSyncInputMapping(task).filterStatus : [];
  }, [task, taskService]);

  const { data: issueTypeFieldsData, isFetching: isFetchingIssueTypeFields } = useListEditJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
      issueTypeId: issueTypeId ?? '',
    },
    { skip: !selectedProject?.id || !issueTypeId }
  );

  const { data: statuses, isLoading: isLoadingStatuses } = useListJiraFieldOptionsQuery(
    {
      accountId: accountId,
      projectId: selectedProject?.id ?? '',
      issueTypeId: issueTypeId ?? '',
      fieldName: 'status',
    },
    { skip: !selectedProject?.id || !issueTypeId }
  );

  const jiraVariables = useMemo(() => {
    return issueTypeFieldsData?.fields
      ? transformJiraToWorkflowLikeVariables(issueTypeFieldsData?.fields, xTypeServiceCatalogResources)
      : undefined;
  }, [issueTypeFieldsData, xTypeServiceCatalogResources]);

  const [defaultValues, setDefaultValues] = useState<JiraSyncTaskFormData | undefined>();

  useEffect(() => {
    if (!task || referencedTaskId === undefined) return undefined;

    setDefaultValues({
      name: task.task.name || '',
      description: task.task.description || '',
      taskId: referencedTaskId || '',
      // This is fallback default. Setup default from previously saved variables is done in JiraSyncForm,
      // because it needs to access RHF api dynamically after values for variables are known/pre-created and this has to wait
      // until taskId is known -> Jira issue is known -> Jira issue's fields are known
      variables: [],
      filterStatus: filterStatus,
    });
  }, [task, referencedTaskId, filterStatus]);

  return (
    <>
      {taskService && defaultValues && (
        <JiraSyncForm
          task={task}
          taskService={taskService}
          defaultValues={defaultValues}
          workflowVariables={workflowVariables}
          setWorkflowVariables={handleSetWorkflowVariables}
          isLoadingIntegration={isLoadingIntegration}
          isFetchingIssueTypeFields={isFetchingIssueTypeFields}
          {...restProps}
          hasReferencedIssueType={!!issueTypeId}
          noReferenceIssuePlaceholder={'Není jira field'}
          onTaskChange={setReferencedTaskId}
          syncWebhookQuery={syncWebhookQuery}
          completeCriteriaProps={{
            isLoading: isLoadingStatuses,
            availableStatuses: statuses?.items ?? [],
          }}
          variableMapperProps={{
            isIntegrationActive,
            jiraVariables: jiraVariables,
            fromWorkflow: false,
            allowCreateVariable: true,
          }}
        />
      )}
    </>
  );
};
