import { Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system';

type EditorDrawerFooterProps = {
  onDiscard: () => void;
  disabled?: boolean;
};

const EditorDrawerFooterContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  borderTop: `1px solid ${theme.palette.core.color3}`,
}));

export const EditorDrawerFooter: FC<EditorDrawerFooterProps> = ({ onDiscard, disabled }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS' });
  const { isSubmitting } = useFormState();

  return (
    <EditorDrawerFooterContainer direction="row" justifyContent="space-between">
      <Button onClick={onDiscard} variant="plain" color="neutral" size="S">
        {t('DISCARD_CHANGES')}
      </Button>
      <Button
        variant="solid"
        color="secondary"
        size="S"
        type="submit"
        isLoading={isSubmitting}
        disabled={isSubmitting || disabled}
      >
        {t('APPLY')}
      </Button>
    </EditorDrawerFooterContainer>
  );
};
