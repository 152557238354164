import { FunctionComponent, useMemo } from 'react';

import { getTaskServiceConfiguration } from '../../../../definitions/taskDefinition';
import { ProcessDefinition, TaskDefinition } from '../../../../definitionsTypes';
import { useGetServiceCatalogResources } from '../../../../hooks/useGetServiceCatalogResources';
import { getLastPartDiversioned } from '../../../../utils';
import { EditorDrawer } from '../../EditorDrawer';
import { JiraCreate } from './JiraCreate/JiraCreate';
import { JiraSync } from './JiraSync/JiraSync';
import { EditServiceTaskBase } from './types';

const EditServiceTaskComponentMapping: Record<string, FunctionComponent<EditServiceTaskBase>> = {
  'form/core/jira/createTicket': JiraCreate, // legacy identifier, will be removed as a part of https://vertice.atlassian.net/browse/GREEN-1268
  'form/integrations/jira/createTicket': JiraCreate,
  'form/core/jira/syncTicket': JiraSync, // legacy identifier, will be removed as a part of https://vertice.atlassian.net/browse/GREEN-1268
  'form/integrations/jira/syncTicket': JiraSync,
};

type EditorConfig = {
  allowContractOwnerAssignment: boolean;
};

export type EditServiceTaskDrawerProps = {
  isOpen: boolean;
  task?: TaskDefinition;
  onClose: VoidFunction;
  onSave: (task: TaskDefinition) => void;
  onDirty: () => void;
  editorConfig: EditorConfig;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
};

export const EditServiceTaskDrawer = ({
  isOpen,
  task,
  processDefinition,
  ...restProps
}: EditServiceTaskDrawerProps) => {
  const { resources } = useGetServiceCatalogResources({});

  const EditServiceTaskComponent = useMemo(() => {
    if (task) {
      const taskServiceConfiguration = getTaskServiceConfiguration(task);
      const correspondingTaskService = resources.find(
        (service) => service.urn === taskServiceConfiguration?.resourceUrn
      );

      const componentKey = getLastPartDiversioned(
        correspondingTaskService?.definition.ServiceProvider.Interface.Input.JsonSchema['x-type']
      );
      return componentKey ? EditServiceTaskComponentMapping[componentKey] : null;
    }
  }, [resources, task]);

  return (
    <EditorDrawer open={isOpen}>
      {EditServiceTaskComponent && task && processDefinition && (
        <EditServiceTaskComponent
          task={task}
          resources={resources}
          processDefinition={processDefinition}
          {...restProps}
        />
      )}
    </EditorDrawer>
  );
};
