import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { Stack, styled } from '@mui/material';
import { CheckOutlined, EditOutlined } from '@mui/icons-material';
import { Button, IconButton, ifExpression, Text } from '@verticeone/design-system';

import FormTextField from '../../../../../../forms/fields/FormTextField';
import type { Gateway } from '../../../../../definitionsTypes';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { EDGE_NAME_CHAR_LIMIT, EditGatewayFormData } from '../schema';

const EDGE_NAME_CHAR_THRESHOLD = EDGE_NAME_CHAR_LIMIT - 20;

type EdgeNameButtonProps = {
  $withIconOnHover: boolean;
};

const EdgeNameButton = styled(Button)<EdgeNameButtonProps>(({ $withIconOnHover }) => ({
  display: 'block',
  padding: '0px !important',
  ...ifExpression($withIconOnHover, {
    '& svg': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& svg': {
        visibility: 'visible',
      },
    },
  }),
}));

type EdgeNameInputProps = {
  edge: FieldArrayWithId<EditGatewayFormData, 'edges'>;
  index: number;
  gatewayType: Gateway['gatewayType'];
};

export const EdgeNameInput: FC<EdgeNameInputProps> = ({ index, edge, gatewayType }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY' });

  const { watch, trigger, getValues, setValue } = useFormContext<EditGatewayFormData>();

  const [isEditingEdgeName, setIsEditingEdgeName] = useState(false);

  const isEdgeFromExclusiveGateway = gatewayType === 'Exclusive';
  const isDefaultEdge = edge.conditionType === 'DefaultFlow';

  const edgeLabelBase = t('EDGES.LABEL');
  const edgeLabel =
    isDefaultEdge && isEdgeFromExclusiveGateway ? edgeLabelBase + ` ${t('EDGES.IF_ELSE')}` : edgeLabelBase;

  const edgeName = watch(`edges.${index}.name`);
  const edgeNameCharCount = edgeName.trim().length;
  const edgeNameCharCountLeft = EDGE_NAME_CHAR_LIMIT - edgeNameCharCount;
  const isCharLimitHelperTextVisible = edgeNameCharCount > EDGE_NAME_CHAR_THRESHOLD;

  return (
    <Stack gap={1} alignItems="flex-start">
      <Text size="XXS" color="text3" variant="label">
        {edgeLabel}
      </Text>
      {isEditingEdgeName ? (
        <Stack direction="row" width="100%" gap={2}>
          <FormTextField<EditGatewayFormData>
            id={`edge-name-text-field-${index}`}
            name={`edges.${index}.name`}
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            placeholder={t('EDGES.PLACEHOLDER')}
            helperText={
              isCharLimitHelperTextVisible
                ? t('EDGES.CHAR_COUNT', { count: Math.max(0, edgeNameCharCountLeft) })
                : undefined
            }
          />
          <IconButton
            variant="outline"
            size="S"
            icon={CheckOutlined}
            onClick={() => {
              setValue(`edges.${index}.name`, getValues(`edges.${index}.name`)?.trim() ?? '');
              void trigger(`edges.${index}.name`, { shouldFocus: true });

              if (edgeNameCharCountLeft < 0) {
                return;
              }

              setIsEditingEdgeName(false);
            }}
          />
        </Stack>
      ) : (
        <EdgeNameButton
          $withIconOnHover={!!edgeName}
          size="S"
          onClick={() => setIsEditingEdgeName(true)}
          variant="plain"
        >
          {edgeName || t('EDGES.PLACEHOLDER')} <EditOutlined />
        </EdgeNameButton>
      )}
    </Stack>
  );
};
