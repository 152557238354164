import { ConnectionTestInput, useTestIntegrationConnectionMutation } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export type TestConnectionButtonData = {
  protocol: string;
  hostname: string;
  path: string;
  auth: ConnectionTestInput['auth'];
  method?: ConnectionTestInput['method'];
};

type UseTestConnectionProps = {
  serialize: () => TestConnectionButtonData;
};

const useTestConnection = ({ serialize }: UseTestConnectionProps) => {
  const { accountId } = useAccountContext();
  const [testGenericIntegration, state] = useTestIntegrationConnectionMutation();
  const getHostname = (_hostname: string, path: string) =>
    [
      _hostname,
      !!_hostname.length && !_hostname.endsWith('/') && !!path.length && !path.startsWith('?') ? '/' : '',
    ].join('');

  const testConnection = () => {
    const { path, hostname, protocol, method, auth } = serialize();

    void testGenericIntegration({
      accountId,
      connectionTestInput: {
        url: [`${protocol}://`, getHostname(hostname, path), path].join(''),
        method: method ?? 'GET',
        auth,
      },
    });
  };

  return [testConnection, state] as const;
};

export default useTestConnection;
