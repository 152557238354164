import { parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { BaseSkeletonCell } from './SkeletonCells';
import { useResolveUsersContextInfo } from '../../../../../../hooks/useResolveUsersInfo';
import React, { useMemo } from 'react';
import { RequestRow } from '../../types';
import { useLoggedUser } from '@verticeone/auth/src';
import { AssignedUsers } from '../../../../task/components/Table/Columns/TaskAssignees';

type AssigneesCellProps = {
  assignees?: string[];
  activeTasks: RequestRow['activeTasks'];
  isLegacy?: boolean;
};

export const AssigneesCell = ({ assignees, activeTasks, isLegacy }: AssigneesCellProps) => {
  const { t } = useTranslation();
  const { userId: loggedUserId } = useLoggedUser();

  const isManagedService = activeTasks?.every((task) => task.managed && task.type === 'SERVICE');

  const assigneeIds = useMemo(() => assignees?.map((assignee) => parseUserRef(assignee).userId) || [], [assignees]);
  const { getFullName, isLoading } = useResolveUsersContextInfo(assigneeIds);
  const assigneeDetails = useMemo(
    () =>
      assigneeIds
        .map((assignee) => ({
          id: assignee,
          fullName: getFullName(assignee),
        }))
        .sort((assigneeA, assigneeB) => assigneeA.fullName.localeCompare(assigneeB.fullName)),
    [assigneeIds, getFullName]
  );

  const shouldShowDash = isManagedService || isLegacy;

  if (isLoading) {
    return <BaseSkeletonCell />;
  }

  if (assigneeDetails.length === 0) {
    return (
      <Text variant="body-regular" size="S" color="text1">
        {shouldShowDash ? '-' : t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.NO_ASSIGNEES')}
      </Text>
    );
  }

  const loggedInUserAsAssignee = assigneeDetails.find((assignee) => assignee.id === loggedUserId);
  const highlightedAssignee = loggedInUserAsAssignee ?? assigneeDetails[0];
  const assigneesWithoutHighlighted = assigneeDetails.filter((assignee) => assignee.id !== highlightedAssignee.id);

  return (
    <Text variant="body-regular" size="S" color="text1">
      <AssignedUsers
        assignees={[highlightedAssignee.fullName, ...assigneesWithoutHighlighted.map(({ fullName }) => fullName)]}
      />
    </Text>
  );
};
