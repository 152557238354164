import { Routes, Route } from 'react-router-dom';
import { Preferences } from './Preferences';
import OktaSetupWizard from './Integrations/DiscoveryAndUsage/components/OktaSetup/OktaSetupWizard';
import { AccountingSetupWizard } from './Integrations/Accounting/components';
import { EntraSetupWizard } from './Integrations/DiscoveryAndUsage/components/EntraSetup/EntraSetupWizard';
import OneLoginSetupWizard from './Integrations/DiscoveryAndUsage/components/OneLogin/OneLoginWizard';
import JumpCloudSetupWizard from './Integrations/DiscoveryAndUsage/components/JumpCloud/JumpCloudWizard';
import GoogleSetupWizard from './Integrations/DiscoveryAndUsage/components/Google/GoogleSetupWizard';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { SlackSetupWizardV2 } from './Integrations/Notifications/components/Slack/V2/SlackSetupWizardV2';
import { SlackSetupWizard as SlackSetupWizardOld } from './Integrations/Notifications/components/Slack/V1/SlackSetupWizard';
import { JiraSetup } from './Integrations/Workflows/Jira';
import { VisoTrustSetup } from './Integrations/Workflows/VisoTrust';
import { AWSSetupWizard } from './Integrations/AWS/components';
import SlackIntegrationWizard from './User/SlackIntegration/Wizard/SlackIntegrationWizard';
import { useRoutes } from '@verticeone/router/src';

import { Company as CompanyPage } from '@vertice/components';
import DirectoryPage from './Directory/Directory';
import WorkflowsPage from './Workflows/Workflows';
import CustomizationPage from './Customization/Customization';
import UserPage from './User/User';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { Integrations } from './Integrations';
import BaseCustomIntegration from './Integrations/Custom/page/BaseCustomIntegration';
import CreateIntegration from './Integrations/Custom/page/CreateIntegration';
import EditIntegration from './Integrations/Custom/page/EditIntegration';

const PreferencesRouter = () => {
  const { PREFERENCES } = useRoutes();
  const { isEnabled } = useFeatures();
  const SlackSetupWizard = isEnabled(FEATURES.INTEGRATION_SLACK_V2) ? SlackSetupWizardV2 : SlackSetupWizardOld;

  return (
    <Routes>
      <Route element={<Preferences />}>
        <Route path={PREFERENCES.COMPANY.PATH} element={<CompanyPage />} />
        <Route path={PREFERENCES.DIRECTORY.PATH} element={<DirectoryPage />} />
        <Route path={PREFERENCES.WORKFLOWS.PATH} element={<WorkflowsPage />} />
        <Route path={PREFERENCES.CUSTOMIZATION.PATH} element={<CustomizationPage />} />
        <Route path={PREFERENCES.USER.PATH} element={<UserPage />} />
        <Route path={PREFERENCES.USER.PATH}>
          <Route path={PREFERENCES.USER.SLACK_WIZARD.PATH} element={<SlackIntegrationWizard />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.PATH} element={<Integrations />} />
      </Route>

      <Route path={PREFERENCES.INTEGRATIONS.PATH}>
        <Route path={PREFERENCES.INTEGRATIONS.ACCOUNTING.PATH} element={<AccountingSetupWizard />} />
        <Route path={PREFERENCES.INTEGRATIONS.AWS.PATH} element={<AWSSetupWizard />} />
        <Route path={PREFERENCES.INTEGRATIONS.NOTIFICATIONS.PATH}>
          <Route path={PREFERENCES.INTEGRATIONS.NOTIFICATIONS.SLACK.PATH} element={<SlackSetupWizard />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.USAGE.PATH}>
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.OKTA.PATH} element={<OktaSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.ENTRA.PATH} element={<EntraSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.ONELOGIN.PATH} element={<OneLoginSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.JUMPCLOUD.PATH} element={<JumpCloudSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.GOOGLE.PATH} element={<GoogleSetupWizard />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.WORKFLOWS.PATH}>
          <Route path={PREFERENCES.INTEGRATIONS.WORKFLOWS.JIRA.PATH} element={<JiraSetup />} />
          <Route path={PREFERENCES.INTEGRATIONS.WORKFLOWS.VISO_TRUST.PATH} element={<VisoTrustSetup />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.CUSTOM.PATH} element={<BaseCustomIntegration />}>
          <Route path={PREFERENCES.INTEGRATIONS.CUSTOM.EDIT.PATH} element={<EditIntegration />} />
          <Route path={PREFERENCES.INTEGRATIONS.CUSTOM.CREATE.PATH} element={<CreateIntegration />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default PreferencesRouter;
