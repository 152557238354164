import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { EditableDataGridContextProvider } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import {
  ValidateRowResult,
  useEditableDataGrid,
} from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';
import {
  GRID_REORDER_COL_DEF,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import NoRowsOverlay from '../../../NoRowsOverlay';
import { DataGrid } from '@verticeone/design-system';
import { renderRowReorderCell } from '@mui/x-data-grid-pro/components/GridRowReorderCell';
import { useTranslation } from 'react-i18next';
import ToggleSwitchCell from '../cells/ToggleSwitchCell';
import { enhanceRowsWithReorderProperty, normalizeValueLabel } from './utils';
import { moveArrayItem } from '@verticeone/utils/arrays';
import { Box } from '@mui/material';

interface DropdownValueListProps {
  values: CustomContractFieldDropdownOption[];
  onValuesChange: (values: CustomContractFieldDropdownOption[]) => void;
  setError: Dispatch<SetStateAction<string | undefined>>;
}

const LABEL_COL_NAME = 'label';

export const DropdownValuesList: FC<DropdownValueListProps> = ({ values, onValuesChange, setError }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT_CUSTOMIZATION.DROPDOWN_VALUE_LIST' });
  const apiRef = useGridApiRef();

  const handleAddRow = useCallback(
    (newValue: CustomContractFieldDropdownOption) => onValuesChange([...values, newValue]),
    [onValuesChange, values]
  );

  const handleUpdateRow = useCallback(
    (updatedValue: CustomContractFieldDropdownOption) => {
      onValuesChange(values.map((item) => (item.id === updatedValue.id ? updatedValue : item)));
    },
    [onValuesChange, values]
  );

  const handleValidateRow = useCallback(
    (row: CustomContractFieldDropdownOption): ValidateRowResult<CustomContractFieldDropdownOption> => {
      const label = normalizeValueLabel(row.label);
      if (!label) {
        return { isValid: false, message: t('LABEL_MISSING') };
      }

      const labelExists = values.some((item) => item.label === label && item.id !== row.id);
      if (labelExists) {
        return { isValid: false, message: t('LABEL_DUPLICATED', { name: label }) };
      }

      return { isValid: true, row: { ...row, label } };
    },
    [t, values]
  );

  const handleRowOrderChange = useCallback(
    ({ oldIndex, targetIndex }: GridRowOrderChangeParams) =>
      onValuesChange(moveArrayItem(values, oldIndex, targetIndex).map((item, index) => ({ ...item, order: index }))),
    [onValuesChange, values]
  );

  const editableDataGrid = useEditableDataGrid<CustomContractFieldDropdownOption>({
    apiRef,
    defaultMode: 'confirm',
    validateRow: handleValidateRow,
    onAddRow: handleAddRow,
    onUpdateRow: handleUpdateRow,
    isRowDeletable: () => false,
    onErrorsChange: (errors) => setError(errors.length > 0 ? errors[0] : undefined),
    fieldToFocus: LABEL_COL_NAME,

    // Add-button-specific
    withAddButton: true,
    createTmpAddRow: (id: string): CustomContractFieldDropdownOption => ({
      id,
      label: '',
      enabled: true,
      order: values.length,
    }),
    addItemButtonLabel: t('ADD_NEW_VALUE'),
  });

  const staticColumns = useMemo(
    (): GridColDef[] => [
      {
        ...GRID_REORDER_COL_DEF,
        // This helps to show __reorder__ property in the drag&drop placeholder also for the last added row
        editable: true,
        cellClassName: gridClasses.rowReorderCellContainer,
        renderEditCell: renderRowReorderCell,
      },
      {
        field: LABEL_COL_NAME,
        headerName: t('LABEL'),
        preProcessEditCellProps: (params) => ({
          ...params.props,
          error: !handleValidateRow({ ...params.row, label: params.props.value }).isValid,
        }),
        valueParser: (value: string) => value.trimStart(),
        disableColumnMenu: true,
        editable: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'enabled',
        headerName: t('IS_SHOWN'),
        valueParser: (value?: boolean) => !!value,
        disableColumnMenu: true,
        sortable: false,
        editable: true,
        renderCell: (params: GridRenderCellParams) => <ToggleSwitchCell disabled={true} {...params} />,
        renderEditCell: (params: GridRenderCellParams) => <ToggleSwitchCell {...params} />,
        width: 120,
      },
    ],
    [t, handleValidateRow]
  );

  const columns = useMemo(
    () => [...staticColumns, ...(editableDataGrid.actionsColumn ? [editableDataGrid.actionsColumn] : [])],
    [staticColumns, editableDataGrid.actionsColumn]
  );

  const valueRowsWithReorderProperty = useMemo(() => enhanceRowsWithReorderProperty(values, 'label') || [], [values]);
  const rows = useMemo(
    () => [...valueRowsWithReorderProperty, ...(editableDataGrid.tmpAddRow ? [editableDataGrid.tmpAddRow] : [])],
    [valueRowsWithReorderProperty, editableDataGrid.tmpAddRow]
  );

  const needsScrollbar = rows.length > 6;

  return (
    <EditableDataGridContextProvider value={editableDataGrid.context}>
      <Box height={needsScrollbar ? 400 : undefined /* dynamic height for low number of rows */}>
        <DataGrid
          {...editableDataGrid.dataGridProps}
          apiRef={apiRef}
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns}
          slots={{
            ...editableDataGrid.dataGridProps.slots,
            noRowsOverlay: () => <NoRowsOverlay text={t('NO_VALUES')} />,
          }}
          sortingMode="client"
          disableRowSelectionOnClick
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          columnHeaderHeight={48}
          autoHeight={!needsScrollbar}
          // A non-ideal solution to highlight invalid cells
          sx={({ palette }) => ({
            '.Mui-error': { border: `1px solid ${palette.error.color1}`, height: '100%' },
          })}
        />
      </Box>
    </EditableDataGridContextProvider>
  );
};
