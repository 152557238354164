import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, Outlet } from 'react-router-dom';
import { BreadcrumbsV2 } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/src';

const BaseCustomIntegration = () => {
  const { t } = useTranslation();
  const routes = useRoutes();

  return (
    <Stack direction="column">
      <Stack p={6} borderBottom="1px solid" borderColor="core.color3">
        <BreadcrumbsV2
          items={[
            {
              label: t('PREFERENCES.TITLE'),
              component: Link,
              to: generatePath(routes.PREFERENCES.ABSOLUTE_PATH),
            },
            {
              label: t('PREFERENCES.INTEGRATIONS.CUSTOM.TITLE'),
            },
          ]}
        />
      </Stack>
      <Stack p={6}>
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default BaseCustomIntegration;
