import { integrationsJiraApi as api } from '../../api/integrationsJiraAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listJiraProjects: build.query<ListJiraProjectsApiResponse, ListJiraProjectsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/projects` }),
    }),
    listJiraIssueTypes: build.query<ListJiraIssueTypesApiResponse, ListJiraIssueTypesApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types` }),
    }),
    listCreateJiraIssueTypeFields: build.query<
      ListCreateJiraIssueTypeFieldsApiResponse,
      ListCreateJiraIssueTypeFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types/${queryArg.issueTypeId}/create-fields`,
      }),
    }),
    listEditJiraIssueTypeFields: build.query<ListEditJiraIssueTypeFieldsApiResponse, ListEditJiraIssueTypeFieldsApiArg>(
      {
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types/${queryArg.issueTypeId}/edit-fields`,
        }),
      }
    ),
    listJiraFieldOptions: build.query<ListJiraFieldOptionsApiResponse, ListJiraFieldOptionsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types/${queryArg.issueTypeId}/fields/${queryArg.fieldName}/options`,
      }),
    }),
    getJiraWebhookConfig: build.query<GetJiraWebhookConfigApiResponse, GetJiraWebhookConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhook` }),
    }),
    setJiraWebhookConfig: build.mutation<SetJiraWebhookConfigApiResponse, SetJiraWebhookConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhook`, method: 'POST' }),
    }),
    removeJiraWebhookConfig: build.mutation<RemoveJiraWebhookConfigApiResponse, RemoveJiraWebhookConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhook`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as integrationsJiraAPICodegen };
export type ListJiraProjectsApiResponse = /** status 200 List of Jira projects. */ {
  projects?: {
    /** ID of the project */
    id: string;
    /** Name of the project */
    name: string;
    /** Key of the project */
    key: string;
  }[];
};
export type ListJiraProjectsApiArg = {
  accountId: string;
};
export type ListJiraIssueTypesApiResponse = /** status 200 List of Jira issue types for a given projects. */ {
  issueTypes?: {
    /** ID of the issue type */
    id: string;
    /** Name of the issue type */
    name: string;
    /** URL of the issue type icon */
    iconUrl?: string;
  }[];
};
export type ListJiraIssueTypesApiArg = {
  accountId: string;
  projectId: Schema;
};
export type ListCreateJiraIssueTypeFieldsApiResponse =
  /** status 200 List of fields for a Jira issue types in a given projects. */ {
    fields?: {
      /** ID of the field */
      id: string;
      /** Name of the field */
      name: string;
      /** Key of the field */
      key: string;
      /** JSON Schema type of the field used by Workflows engine.
       */
      type: string;
      /** XType of the field used by Workflows engine.
       */
      xType?: string;
      /** Whether the field is required */
      required: boolean;
      /** Whether the field has a default value. Optional. */
      hasDefaultValue?: boolean;
    }[];
  };
export type ListCreateJiraIssueTypeFieldsApiArg = {
  accountId: string;
  projectId: Schema;
  issueTypeId: Schema;
};
export type ListEditJiraIssueTypeFieldsApiResponse =
  /** status 200 List of fields for a Jira issue types in a given projects. */ {
    fields?: {
      /** ID of the field */
      id: string;
      /** Name of the field */
      name: string;
      /** Key of the field */
      key: string;
      /** JSON Schema type of the field used by Workflows engine.
       */
      type: string;
      /** XType of the field used by Workflows engine.
       */
      xType?: string;
      /** Whether the field is required */
      required: boolean;
      /** Whether the field has a default value. Optional. */
      hasDefaultValue?: boolean;
    }[];
  };
export type ListEditJiraIssueTypeFieldsApiArg = {
  accountId: string;
  projectId: Schema;
  issueTypeId: Schema;
};
export type ListJiraFieldOptionsApiResponse =
  /** status 200 List of key-value options for a field of a Jira issue types in a given projects. */ {
    items?: {
      /** ID of the option */
      id: string;
      /** Value of the option */
      label?: string;
    }[];
  };
export type ListJiraFieldOptionsApiArg = {
  accountId: string;
  projectId: Schema;
  issueTypeId: Schema;
  fieldName: string;
};
export type GetJiraWebhookConfigApiResponse = /** status 200 Returns the Jira webhook and it's validity. */ {
  webhook?: {
    /** ID of the webhook */
    id?: number;
    /** Name of the webhook */
    name: string;
    /** Url the webhook should use */
    url: string;
    /** Filter for the jira webhook */
    filters?: object;
    /** Events the webhook should listen to */
    events: string[];
    /** Whether the webhook is enabled */
    enabled: boolean;
    /** Whether the webhook is using signature */
    isSigned: boolean;
  };
  /** Whether the webhook in Jira seems to be valid */
  isValid?: boolean;
};
export type GetJiraWebhookConfigApiArg = {
  accountId: string;
};
export type SetJiraWebhookConfigApiResponse = /** status 200 Returns the Jira webhook and it's validity. */ {
  webhook?: {
    /** ID of the webhook */
    id?: number;
    /** Name of the webhook */
    name: string;
    /** Url the webhook should use */
    url: string;
    /** Filter for the jira webhook */
    filters?: object;
    /** Events the webhook should listen to */
    events: string[];
    /** Whether the webhook is enabled */
    enabled: boolean;
    /** Whether the webhook is using signature */
    isSigned: boolean;
  };
  /** Whether the webhook in Jira seems to be valid */
  isValid?: boolean;
};
export type SetJiraWebhookConfigApiArg = {
  accountId: string;
};
export type RemoveJiraWebhookConfigApiResponse = /** status 200 Returns the Jira webhook and it's validity. */ {
  webhook?: {
    /** ID of the webhook */
    id?: number;
    /** Name of the webhook */
    name: string;
    /** Url the webhook should use */
    url: string;
    /** Filter for the jira webhook */
    filters?: object;
    /** Events the webhook should listen to */
    events: string[];
    /** Whether the webhook is enabled */
    enabled: boolean;
    /** Whether the webhook is using signature */
    isSigned: boolean;
  };
  /** Whether the webhook in Jira seems to be valid */
  isValid?: boolean;
};
export type RemoveJiraWebhookConfigApiArg = {
  accountId: string;
};
export type Schema = string;
export const {
  useListJiraProjectsQuery,
  useLazyListJiraProjectsQuery,
  useListJiraIssueTypesQuery,
  useLazyListJiraIssueTypesQuery,
  useListCreateJiraIssueTypeFieldsQuery,
  useLazyListCreateJiraIssueTypeFieldsQuery,
  useListEditJiraIssueTypeFieldsQuery,
  useLazyListEditJiraIssueTypeFieldsQuery,
  useListJiraFieldOptionsQuery,
  useLazyListJiraFieldOptionsQuery,
  useGetJiraWebhookConfigQuery,
  useLazyGetJiraWebhookConfigQuery,
  useSetJiraWebhookConfigMutation,
  useRemoveJiraWebhookConfigMutation,
} = injectedRtkApi;
