import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { useController, Validate } from 'react-hook-form';
import { Typography, useTheme, InputAdornment, SxProps } from '@mui/material';
import { DesignSystemColor, TextField } from '@verticeone/design-system';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { isNil } from 'lodash';
import { Theme } from '@mui/material/styles';

const pattern = /^[0-9]*(\.?[0-9]?[0-9]?)?$/;

export type FormDecimalNumberFieldType<FormDataType extends FieldValues> = (
  props: FormDecimalNumberFieldProps<FormDataType>
) => JSX.Element;

type FormDecimalNumberFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  validate?: Record<any, Validate<number, FormDataType>>;
  unit?: string;
  unitPosition?: 'start' | 'end';
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  emptyValue?: number | null;
  color?: DesignSystemColor;
  sx?: SxProps<Theme>;
};

const FormDecimalNumberField = <FormDataType extends FieldValues = never>({
  id,
  validate,
  required,
  disabled,
  name,
  unit,
  unitPosition = 'end',
  placeholder,
  error,
  helperText,
  emptyValue = null,
  color,
  sx,
}: FormDecimalNumberFieldProps<FormDataType>) => {
  const { palette } = useTheme();
  const [inputText, setInputText] = useState('');

  const unitAdornment = unit ? (
    <InputAdornment position={unitPosition}>
      <Typography variant="body-bold-m" sx={{ color: palette.text.color3 }}>
        {unit}
      </Typography>
    </InputAdornment>
  ) : null;

  const {
    field: { onChange, onBlur, value, ref, ...field },
    fieldState: { invalid },
  } = useController({
    name,
    rules: {
      required,
      validate: {
        ...validate,
      },
    },
  });

  useEffect(() => {
    if (isNil(value) || isNaN(value)) {
      setInputText('');
    } else {
      setInputText(parseFloat(value).toFixed(2));
    }
  }, [value]);

  const onDecimalNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (pattern.test(e.target.value)) {
      setInputText(e.target.value);
    }
  };
  const onDecimalNumberBlur = (e: FocusEvent<HTMLInputElement>) => {
    // Set formatted input value
    const inputValue = e.target.value ? parseFloat(e.target.value).toFixed(2) : '';
    setInputText(inputValue);

    // Save value to the form
    const valueParsed = parseFloat(inputValue);
    onChange(isNaN(valueParsed) ? emptyValue : valueParsed);
    onBlur();
  };

  return (
    <TextField
      id={id}
      type="text" // text because: https://mui.com/material-ui/react-text-field/#type-quot-number-quot
      value={inputText}
      variant="outlined"
      inputRef={ref}
      hiddenLabel
      disabled={disabled}
      placeholder={placeholder}
      helperText={helperText}
      onChange={onDecimalNumberChange}
      onBlur={onDecimalNumberBlur}
      error={error || invalid}
      color={color}
      fullWidth
      {...field}
      InputProps={{
        inputProps: {
          inputMode: 'numeric',
        },
        [`${unitPosition}Adornment`]: unitAdornment,
      }}
      sx={sx}
    />
  );
};

export default FormDecimalNumberField;
