import { z, ZodObject, ZodRawShape } from 'zod';
import { DateFieldMetadata, DateFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';

export function getDatePresenterSchema(field: DateFieldType): ZodObject<ZodRawShape> {
  let schema = z.string().date();

  return z.object({
    [field.name]: field.metadata.required ? schema : schema.nullish(),
  });
}

export function getDatePresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<DateFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getDateJsonSchemaFromMetadata(metadata: DateFieldMetadata): JsonSchema {
  return { type: metadata.required ? ['string'] : ['string', 'null'], format: 'date', title: metadata.label };
}

export function getDateJsonSchema(field: DateFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getDateJsonSchemaFromMetadata(field.metadata) },
  };
}
