import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { createRequiredContext } from '@verticeone/utils/contexts';
import { NodeId, Variable } from '../../../types';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { ProcessDefinition } from '../../../../../definitionsTypes';

export type CustomFormEditContextType = {
  variables: Variable[];
  nodeId: NodeId;
  createVariable: (variable: Variable) => void;
};

const customFormContext = createRequiredContext<CustomFormEditContextType, 'CustomFormEdit'>('CustomFormEdit');
const { CustomFormEditContextProvider: ContextProvider, useCustomFormEditContext } = customFormContext;

type CustomFormEditContextProps = {
  nodeId: NodeId;
  processDefinition: ProcessDefinition;
  children?: ReactNode | ReactNode[];
};

const CustomFormEditContextProvider: FC<CustomFormEditContextProps> = (props) => {
  const { nodeId, processDefinition, children } = props;

  const [createdVariables, setCreatedVariables] = useState<Variable[]>([]);
  const { requestVariables } = useVariablesAvailableInNode({ nodeId, processDefinition, includeNodeVariables: true });

  const variables = useMemo<Variable[]>(
    () => [...requestVariables, ...createdVariables],
    [requestVariables, createdVariables]
  );

  const createVariable = useCallback(
    (variable: Variable) => {
      setCreatedVariables((v) => [...v, variable]);
    },
    [setCreatedVariables]
  );

  return <ContextProvider value={{ variables, createVariable, nodeId }}>{children}</ContextProvider>;
};

export { useCustomFormEditContext, CustomFormEditContextProvider };
