import { DropdownValueListItem } from './types';
import { GridRowId } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useSimpleDialogContext } from '@verticeone/design-system';
import { useCallback } from 'react';
import { useDropdownValuesListModel } from './useDropdownValuesListModel';
import { normalizeValue } from './utils';
import { ValidateRowResult } from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';

export type UseDropdownValuesListActionsParams = {
  valueRows: DropdownValueListItem[];
  setValueRows: (rows: DropdownValueListItem[]) => void;
};

export type UseDropdownValuesListActionsReturn = {
  validateRow?: (row: DropdownValueListItem) => ValidateRowResult<DropdownValueListItem>;
  onErrorsChange?: (errors: string[]) => void;
  onAddRow?: (row: DropdownValueListItem) => void;
  onUpdateRow?: (row: DropdownValueListItem) => void;
  onBeforeDeleteRow?: (row: DropdownValueListItem) => Promise<boolean>;
  onDeleteRow?: (id: GridRowId) => void;
};

const getNormalizedRow = (row: DropdownValueListItem): DropdownValueListItem => {
  return { ...row, value: normalizeValue(row.value) };
};

const useDropdownValuesListActions = ({
  valueRows,
  setValueRows,
}: UseDropdownValuesListActionsParams): UseDropdownValuesListActionsReturn => {
  const { t } = useTranslation(undefined, { keyPrefix: 'DYNAMIC_FORM_BUILDER.DIALOG.DROPDOWN_LIST' });
  const { addValue, updateValue, removeValue } = useDropdownValuesListModel({ valueRows, setValueRows });

  const { getConfirmation } = useSimpleDialogContext();

  const validateRow = useCallback(
    (rawRow: DropdownValueListItem): { isValid: true } | { isValid: false; message: string } => {
      const { id, value: valueName } = getNormalizedRow(rawRow);
      if (!valueName) {
        return { isValid: false, message: t('ERROR_DIALOG.MISSING_VALUE_MESSAGE') };
      }

      const valueExists = valueRows.some((rawItem: DropdownValueListItem) => {
        // Defensive precaution: Assume there could be existing not-normalized items.
        const item = getNormalizedRow(rawItem);
        return item.value === valueName && item.id !== id;
      });
      if (valueExists) {
        return { isValid: false, message: t('ERROR_DIALOG.EXISTING_VALUE_MESSAGE') };
      }

      return { isValid: true };
    },
    [t, valueRows]
  );

  const onAddRow = useCallback((row: DropdownValueListItem) => addValue(getNormalizedRow(row)), [addValue]);

  const onUpdateRow = useCallback((row: DropdownValueListItem) => updateValue(getNormalizedRow(row)), [updateValue]);

  const onBeforeDeleteRow = useCallback(async () => {
    const confirmation = await getConfirmation({
      title: t('REMOVE_DIALOG.TITLE'),
      description: t('REMOVE_DIALOG.MESSAGE'),
      okButton: {
        label: t('REMOVE_DIALOG.YES'),
      },
    });

    return confirmation.status === 'ok';
  }, [getConfirmation, t]);

  const onDeleteRow = useCallback((id: GridRowId) => removeValue(id as string), [removeValue]);

  const onErrorsChange = useCallback(
    (errors: string[]) => {
      if (errors.length) {
        void getConfirmation({
          title: t('ERROR_DIALOG.TITLE'),
          description: errors.join(', '),
          cancelButton: {
            hidden: true,
          },
        });
      }
    },
    [getConfirmation, t]
  );

  return {
    validateRow,
    onAddRow,
    onUpdateRow,
    onBeforeDeleteRow,
    onDeleteRow,
    onErrorsChange,
  };
};

export default useDropdownValuesListActions;
