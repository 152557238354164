import { createContext, PropsWithChildren, RefObject, useCallback, useContext, useRef, useState } from 'react';

const useScrollToChild = (parentRef: RefObject<HTMLDivElement>) => {
  const scrollToChild = useCallback(
    (childRef: RefObject<HTMLDivElement>) => {
      if (parentRef.current && childRef.current) {
        const parent = parentRef.current;
        const child = childRef.current;

        const parentRect = parent.getBoundingClientRect();
        const childRect = child.getBoundingClientRect();

        const offset = childRect.top - parentRect.top + parent.scrollTop;

        parent.scrollTo({
          top: offset,
          behavior: 'instant',
        });
      }
    },
    [parentRef]
  );

  return scrollToChild;
};

type DialogScrollContextType = {
  containerRef: RefObject<HTMLDivElement>;
  scrollToChild: (childRef: RefObject<HTMLDivElement>) => void;
};

const DialogScrollContext = createContext<DialogScrollContextType>({} as DialogScrollContextType);

export const DialogScrollProvider = ({ children }: PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  const scrollToChild = useScrollToChild(containerRef);
  const scrollToChildWithDisabledScroll = useCallback(
    (childRef: RefObject<HTMLDivElement>) => {
      if (!isScrollDisabled) {
        scrollToChild(childRef);
        setIsScrollDisabled(true);
      }
    },
    [isScrollDisabled, scrollToChild]
  );

  return (
    <DialogScrollContext.Provider value={{ containerRef, scrollToChild: scrollToChildWithDisabledScroll }}>
      {children}
    </DialogScrollContext.Provider>
  );
};

export const useDialogScroll = () => useContext(DialogScrollContext);
