import { Grid, Stack, styled, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import {
  UsageBox,
  UsageBoxBadge,
  UsageBoxContent,
  UsageBoxTitle,
} from '@vertice/slices/src/slices/UsageAnalytics/components/UsageBox';
import { prepareDataForApplication } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import type { Severity } from '@vertice/slices/src/types/Severity';
import { Card, LoadableComponent, Text } from '@verticeone/design-system';

type UsersTileProps = {
  isLoading: boolean;
  application?: ApplicationWithMeasureReports;
};

type StyledLastDaysProps = {
  severity: Severity;
  theme: Theme;
};

const StyledLastDays = styled((props) => <Text {...props} variant="caption" size="M" />)<StyledLastDaysProps>(
  ({ severity, theme }) => ({
    color: theme.palette[severity].color1,
  })
);

const StyledUsageBoxContent = styled(UsageBoxContent)({
  margin: '0',
});

const StyledUsageBoxBadge = styled(UsageBoxBadge)({
  '&, .MuiTypography-root': {
    fontSize: '1.25rem',
  },
});

const StyledUsersTooltip = styled(Text)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
});

export const UsersTile = ({ isLoading, application }: UsersTileProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const applicationData = prepareDataForApplication(application);

  return (
    <Card sx={{ marginBottom: theme.spacing(4) }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <UsageBox>
            <LoadableComponent isLoading={isLoading}>
              <Grid container>
                <Grid item xs={12} md={7}>
                  <UsageBoxTitle>{t('PREFERENCES.INTEGRATIONS.USAGE.ACTIVE_USERS')}</UsageBoxTitle>
                  <StyledUsageBoxContent text={applicationData.activeUsers?.users ?? 0} dataTestId="activeUsers" />
                </Grid>
                <Grid item xs={12} md={5}>
                  <StyledUsersTooltip variant="body-regular" size="S" color="text2">
                    {t('APPLICATIONS.TOOLTIPS.ACTIVE_USERS.TOOLTIP')}
                  </StyledUsersTooltip>
                </Grid>
              </Grid>
            </LoadableComponent>
          </UsageBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <UsageBox>
            <LoadableComponent isLoading={isLoading}>
              <Stack gap={2} direction="row" alignItems="center" height="100%">
                <StyledUsageBoxBadge
                  text={applicationData.activeUsers?.delta.value ?? 0}
                  severity={applicationData.activeUsers?.delta.severity ?? 'info'}
                  append="%"
                />
                <StyledLastDays severity={applicationData.activeUsers?.delta.severity ?? 'info'}>
                  {t('APPLICATIONS.TOOLTIPS.ACTIVE_USERS.BADGE_DESCRIPTION')}
                </StyledLastDays>
              </Stack>
            </LoadableComponent>
          </UsageBox>
        </Grid>
      </Grid>
    </Card>
  );
};
