import { Control, FieldPath, FieldPathByValue, FieldPathValue, FieldValues, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { OFFER_FREQUENCY_OPTIONS } from './formFields/OfferFrequencyFormEntry';
import { PAYMENT_TERMS_OPTIONS } from './formFields/PaymentTermsFormEntry';
import { ConcessionStatus } from '../../../../../../saas/contract/components/ConcessionsList/constants';
import { SecurityQuestionAnswer } from '../SecurityQuestionnaireForm/formFields/SecurityQuestion';
import { SecurityScore } from './constants';

export const licenseType = z.object({
  id: z.string(),
  label: z.string().nullable().optional(),
});
export type LicenseType = z.infer<typeof licenseType>;

type ProductRollingFrequency = 'monthly' | 'annually' | 'quarterly' | 'bi-annually';
const PRODUCT_ROLLING_FREQUENCY_OPTIONS: [ProductRollingFrequency, ...ProductRollingFrequency[]] = [
  'monthly',
  'annually',
  'quarterly',
  'bi-annually',
];

export const formDataProductItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  productId: z.string(),
  annualCost: z.number().nullable().optional(),
  numberOfLicences: z.number().nullable().optional(),
  licenseType: licenseType.nullable().optional(),
});
export type FormDataProductItem = z.infer<typeof formDataProductItemSchema>;

export const vendorSchema = z.object({
  type: z.string(),
  id: z.string(),
  name: z.string(),
  label: z.string().optional(),
});
export type Vendor = z.infer<typeof vendorSchema>;

export const productSchema = formDataProductItemSchema.and(
  z.object({
    licenseType: licenseType.nullish().optional(),

    licenseCost: z.number().nullish().optional(),
    allocationSpan: z
      .object({
        start_date: z.string(),
        end_date: z.string().nullish().optional(),
        rolling_frequency: z.enum(PRODUCT_ROLLING_FREQUENCY_OPTIONS).optional(),
      })
      .nullish()
      .optional(),
  })
);

export type OfferProduct = z.infer<typeof productSchema>;

export const productsSchema = z.array(productSchema);

export const offerSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1),
    rollingFrequency: z.enum(OFFER_FREQUENCY_OPTIONS),
    baseCurrency: z.string(),
    startDate: z.string(),
    endDate: z.string().nullish().optional(),
    paymentTerms: z.enum(PAYMENT_TERMS_OPTIONS).nullish(),
    paymentTermsOther: z.string().nullish().optional(),
    purchasedFromReseller: z.boolean(),
    resellerName: z.string().nullish().optional(),
    vendor: vendorSchema,
    products: productsSchema,
    concessions: z.array(
      z.object({
        id: z.string(),
        status: z.enum([ConcessionStatus.InProgress, ConcessionStatus.Realised]),
        type: z.string(),
        amount: z.number(),
        description: z.string().nullish().optional(),
      })
    ),
    computedCosts: z.number().optional(),
    effectiveCosts: z
      .number()
      .nullish()
      .transform((value) => value ?? undefined),
    overrideEffectiveCost: z.boolean(),
    additionalNotes: z.string().nullish().optional(),
    totalCosts: z.number().nullish(),
  })
  .refine(({ rollingFrequency, endDate }) => (rollingFrequency === 'NO' ? !!endDate : true), { path: ['endDate'] })
  .refine(({ paymentTerms, paymentTermsOther }) => (paymentTerms === 'OTHER' ? !!paymentTermsOther : true), {
    path: ['paymentTermsOther'],
  })
  .refine(({ overrideEffectiveCost, effectiveCosts }) => !overrideEffectiveCost || effectiveCosts !== undefined, {
    path: ['effectiveCosts'],
  });

export const MoneySchema = z.object({
  value: z.number(),
  currency: z.string(),
});

export type Money = z.infer<typeof MoneySchema>;

export type Offer = z.infer<typeof offerSchema>;

export const joinFormPath = <
  V extends FieldValues,
  P extends FieldPathByValue<V, Offer> = FieldPathByValue<V, Offer>,
  K extends FieldPath<Offer> = FieldPath<Offer>
>(
  path: P,
  field: K
) => `${path}.${field}` as FieldPath<V>;

export const useWatchNested = <
  V extends FieldValues,
  P extends FieldPathByValue<V, Offer> = FieldPathByValue<V, Offer>,
  K extends FieldPath<Offer> = FieldPath<Offer>
>({
  offerName,
  fieldName,
  ...props
}: {
  offerName: P;
  fieldName: K;
  defaultValue?: FieldPathValue<V, P>;
  control?: Control<V>;
  disabled?: boolean;
  exact?: boolean;
}) => {
  const name = joinFormPath<V>(offerName, fieldName);
  return useWatch<V, typeof name>({ name, ...props }) as FieldPathValue<Offer, typeof fieldName>;
};

export const SecurityQuestionAnswerSchema = z.nativeEnum(SecurityQuestionAnswer);

export const SecurityScoreSchema = z.nativeEnum(SecurityScore);
