import { DataGrid, DesignSystemColor, Text, useSimpleDialogContext } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import {
  useEditableDataGrid,
  ValidateRowResult,
} from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';
import { normalizeValue } from './utils';
import { EditableDataGridContextProvider } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import NoRowsOverlay from '../../../../../../saas/contract/components/NoRowsOverlay';

import { Box, Stack } from '@mui/material';
import { LineItem } from '../../types';
import FormControlContext from '@mui/material/FormControl/FormControlContext';
import { EditableLineItemTypeCell, LineItemTypeCell } from './LineItemTypeCell';
import { EditableQuantityCell } from './EditableQuantityCell';
import { useFormatNumber } from '@verticeone/utils/formatting';
import { isNil } from 'lodash';

export type LineItemsTableProps = {
  values: LineItem[];
  onValuesChange: (values: LineItem[]) => void;
  color: DesignSystemColor;
  editMode: boolean;
  errorText?: string;
};

export const LineItemsTable = ({ color, values = [], onValuesChange, errorText, editMode }: LineItemsTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'DYNAMIC_FORM_BUILDER.FIELD.LINE_ITEMS' });
  const apiRef = useGridApiRef();
  const { getConfirmation } = useSimpleDialogContext();
  const formatNumber = useFormatNumber();

  const handleAddRow = (newValue: LineItem) => onValuesChange([...values, newValue]);

  const handleUpdateRow = (updatedValue: LineItem) =>
    onValuesChange(values.map((item) => (item.id === updatedValue.id ? updatedValue : item)));

  const handleDeleteRow = (id: GridRowId) => onValuesChange(values.filter((item) => item.id !== id));

  const handleValidateRow = useCallback(
    (lineItem: LineItem): ValidateRowResult<LineItem> => {
      const description = normalizeValue(lineItem.description ?? '');

      if (!lineItem.lineItemType || !description) {
        return { isValid: false, message: t('ERROR_DIALOG.MISSING_VALUE_MESSAGE') };
      }

      return { isValid: true, row: { ...lineItem, description } };
    },
    [t]
  );

  const handleBeforeDeleteRow = useCallback(async () => {
    const confirmation = await getConfirmation({
      title: t('REMOVE_DIALOG.TITLE'),
      description: t('REMOVE_DIALOG.MESSAGE'),
      okButton: { label: t('REMOVE_DIALOG.YES') },
    });
    return confirmation.status === 'ok';
  }, [getConfirmation, t]);

  const handleErrorsChange = useCallback(
    (errors: string[]) => {
      if (errors.length) {
        void getConfirmation({
          title: t('ERROR_DIALOG.TITLE'),
          description: errors.join(', '),
          cancelButton: { hidden: true },
        });
      }
    },
    [getConfirmation, t]
  );

  const editableDataGrid = useEditableDataGrid<LineItem>({
    apiRef,
    defaultMode: 'confirm',
    validateRow: handleValidateRow,
    editMode,
    onAddRow: handleAddRow,
    onUpdateRow: handleUpdateRow,
    onBeforeDeleteRow: handleBeforeDeleteRow,
    onDeleteRow: handleDeleteRow,
    onErrorsChange: handleErrorsChange,

    // Add-button-specific
    withAddButton: true,
    createTmpAddRow: (id: string) => ({ id, lineItemType: undefined, description: '', quantity: null }),
    addItemButtonLabel: t('ADD_NEW_LINE_ITEM'),
    color,
  });

  const columns = useMemo(
    (): GridColDef<LineItem>[] => [
      {
        field: 'lineItemType',
        headerName: t('LINE_TYPE'),
        disableColumnMenu: true,
        renderCell: (params) => <LineItemTypeCell {...params} />,
        renderEditCell: (params) => <EditableLineItemTypeCell color={color} {...params} />,
        editable: true,
        sortable: false,
        minWidth: 130,
      },
      {
        field: 'description',
        headerName: t('DESCRIPTION'),
        disableColumnMenu: true,
        editable: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'quantity',
        headerName: t('QUANTITY'),
        renderCell: (params) => (isNil(params.value) ? '' : formatNumber(params.value)),
        renderEditCell: (params) => <EditableQuantityCell color={color} {...params} />,
        disableColumnMenu: true,
        editable: true,
        sortable: false,
        minWidth: 110,
      },
      ...(editableDataGrid.actionsColumn ? [editableDataGrid.actionsColumn] : []),
    ],
    [color, editableDataGrid.actionsColumn, formatNumber, t]
  );

  const rows = useMemo(
    () => [...values, ...(editableDataGrid.tmpAddRow ? [editableDataGrid.tmpAddRow] : [])],
    [values, editableDataGrid.tmpAddRow]
  );

  return (
    // Workaround: Destroy the FormControl context so that we're able to have multiple inputs inside a FormControl.
    <FormControlContext.Provider value={undefined}>
      <Stack gap={1}>
        <EditableDataGridContextProvider value={editableDataGrid.context}>
          <DataGrid
            {...editableDataGrid.dataGridProps}
            apiRef={apiRef}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            slots={{
              ...editableDataGrid.dataGridProps.slots,
              noRowsOverlay: () => <NoRowsOverlay text={t('NO_ROWS')} />,
            }}
            sortingMode="client"
            autoHeight={true}
            color={color}
          />
        </EditableDataGridContextProvider>
        {errorText && (
          <Box px={4}>
            <Text color="error2" size="XS" variant="body-regular">
              {errorText}
            </Text>
          </Box>
        )}
      </Stack>
    </FormControlContext.Provider>
  );
};
