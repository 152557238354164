import React, { FC, useEffect, useMemo, useState } from 'react';

import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { isProcessDefinition } from '../../pocWorkflowSchema';
import { processDefinitionToModel } from '../model/processDefinitionToModel';
import { useRequestState } from './useRequestState';
import { useServicesThumbnails } from './useServicesThumbnails';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { useLoggedUser } from '@verticeone/auth/src';
import { WorkflowRendererContextProvider } from '../WorkflowRenderer/WorkflowRendererContext';
import { useResolveUsersInfo } from '../../../../hooks/useResolveUsersInfo';
import { parseUserRef } from '../../../../hooks/workflows/refUtils';
import { useRequestTasks } from '../../request/RequestDetailPage/useRequestTasks';
import { TaskDrawer } from '../../task/TaskDrawer/TaskDrawer';
import { useSearchParams } from 'react-router-dom';
import { WorkflowRenderer } from '../WorkflowRenderer/WorkflowRenderer';
import { withWorkflowRenderer } from '../WorkflowRenderer/useWorkflowRendererState';

type WorkflowViewerProps = {
  definitions?: Definitions;
  requestId?: string;
  requestRoute?: string;
  allowVerticeServiceNavigation?: boolean;
  isEditor?: boolean;
};

const BaseWorkflowViewer: FC<WorkflowViewerProps> = (props) => {
  const { definitions, requestRoute, requestId, allowVerticeServiceNavigation } = props;
  const { accountId } = useAccountContext();
  const { userId: loggedUserId } = useLoggedUser();

  const requestWorkflowState = useRequestState(requestId, requestRoute);

  const servicesThumbnails = useServicesThumbnails();

  const model = useMemo(() => {
    const processDefinition = definitions?.definitions.find(isProcessDefinition);
    if (processDefinition) {
      return processDefinitionToModel({ processDefinition, workflowState: requestWorkflowState, servicesThumbnails });
    }
  }, [definitions, requestWorkflowState, servicesThumbnails]);

  // users
  const { usersById: accountUsersById } = useRelevantUsersById({ includeLoggedVerticeUser: true });
  const { allTasks } = useRequestTasks(requestId, true);
  const tasksAssignees = useMemo(() => {
    return allTasks.flatMap((task) => task.assignment.map((ref) => parseUserRef(ref).userId));
  }, [allTasks]);
  const { usersById } = useResolveUsersInfo(tasksAssignees, accountUsersById);

  //  drawer
  const [drawerTaskId, setDrawerTaskId] = useState<string | undefined>(undefined);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const taskId = searchParams.get('taskId');
    if (taskId) {
      setDrawerTaskId(taskId);
      setSearchParams({}, { replace: true });
    }
  }, [searchParams, setDrawerTaskId, setSearchParams]);

  const onOpenTask = (taskId: string) => {
    setDrawerTaskId(taskId);
  };
  const drawerTask = useMemo(() => {
    return allTasks.find((row) => drawerTaskId && row.id.includes(drawerTaskId));
  }, [allTasks, drawerTaskId]);

  if (!model) {
    return null;
  }

  return (
    <WorkflowRendererContextProvider
      value={{
        model,
        usersById,
        isEditor: false,
        loggedUserId,
        accountId,
        onOpenTask,
        allowVerticeServiceNavigation,
        workflowDefinitions: definitions,
        drawerTaskId,
      }}
    >
      <WorkflowRenderer key={requestId} />
      {drawerTask ? <TaskDrawer onClose={() => setDrawerTaskId(undefined)} taskRow={drawerTask} /> : null}
    </WorkflowRendererContextProvider>
  );
};

// eslint-disable-next-line testing-library/render-result-naming-convention
export const WorkflowViewer = withWorkflowRenderer(BaseWorkflowViewer);
