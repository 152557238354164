import type { Variable, VariableId } from '../types';
import type { OriginsWithVariables, VariablesGroup, SectionId } from './types';

export const hasObjectKeys = (obj: Record<string, unknown>) => Object.keys(obj).length > 0;

export const normalize = (value?: string) => {
  if (!value) {
    return '';
  }

  return value
    .trim()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
};

const variableMatches = (variable: Variable, what: string) => {
  const { type, label } = variable;

  const isVariableVisibleInTheList = variable.isSelectable || (!variable.isSelectable && variable.variables.length > 1);

  return (
    isVariableVisibleInTheList && (normalize(label).includes(what) || normalize(type.labels?.[0] ?? '').includes(what))
  );
};

const recursiveSearch = ({ in: variables, what }: { in: Variable[]; what: string }) => {
  const matchedVariables: Variable[] = [];
  const expandedVariablesIds: VariableId[] = [];

  for (const variable of variables) {
    let isMatch = variableMatches(variable, what);
    let matchedChildVariables: Variable[] = [];

    if (variable.variables.length > 0) {
      const searchResult = recursiveSearch({
        in: variable.variables,
        what,
      });

      matchedChildVariables = searchResult.matchedVariables;
      expandedVariablesIds.push(...searchResult.expandedVariablesIds);

      if (matchedChildVariables.length > 0) {
        expandedVariablesIds.push(variable.id);
        isMatch = true;
      }
    }

    if (isMatch) {
      const newVariable = {
        ...variable,
        ...(matchedChildVariables.length > 0 ? { variables: matchedChildVariables } : {}),
      };
      matchedVariables.push(newVariable);
    }
  }

  return {
    matchedVariables,
    expandedVariablesIds,
  };
};

export const filterVariablesGroupsBySearchValue = ({
  variablesGroups,
  searchValue,
}: {
  variablesGroups: VariablesGroup[];
  searchValue?: string;
}) => {
  const normalizedSearchValue = normalize(searchValue);

  if (!normalizedSearchValue) {
    return variablesGroups;
  }

  const filteredVariablesGroups: VariablesGroup[] = [];

  for (const variableGroup of variablesGroups) {
    const filteredOriginsWithVariables: OriginsWithVariables = {};
    const expandedSectionsIds: SectionId[] = [];

    for (const originWithVariables of Object.values(variableGroup.originsWithVariables)) {
      const searchResult = recursiveSearch({ in: originWithVariables.variables, what: normalizedSearchValue });

      if (searchResult.matchedVariables.length > 0) {
        expandedSectionsIds.push(...searchResult.expandedVariablesIds, originWithVariables.origin.id);
        filteredOriginsWithVariables[originWithVariables.origin.id] = {
          ...originWithVariables,
          variables: searchResult.matchedVariables,
        };
      }
    }

    if (hasObjectKeys(filteredOriginsWithVariables)) {
      filteredVariablesGroups.push({
        ...variableGroup,
        originsWithVariables: filteredOriginsWithVariables,
        expandedSectionsIds,
        count: countSelectableVariables(
          Object.values(filteredOriginsWithVariables)
            .map(({ variables }) => variables)
            .flat()
        ),
      });
    }
  }

  return filteredVariablesGroups;
};

export const filterVariablesGroupsByGroupId = ({
  variablesGroups,
  groupId,
}: {
  variablesGroups: VariablesGroup[];
  groupId: string;
}) => {
  if (groupId === 'all') {
    return variablesGroups;
  }

  return variablesGroups.filter(({ id }) => id === groupId);
};

export const getVariablePath = (variables: Variable[], variableId: VariableId): VariableId[] => {
  for (const variable of variables) {
    if (variable.id === variableId) {
      return [variable.id];
    }

    if (variable.variables.length > 0) {
      const variablePath = getVariablePath(variable.variables, variableId);
      if (variablePath.length > 0) {
        return [variable.id, ...variablePath];
      }
    }
  }

  return [];
};

export const setVariablesGroupsByVariableId = ({
  variablesGroups,
  variableId,
}: {
  variablesGroups: VariablesGroup[];
  variableId?: VariableId;
}) => {
  if (!variableId) {
    return variablesGroups;
  }

  const setVariablesGroups: VariablesGroup[] = [];

  for (const variableGroup of variablesGroups) {
    const expandedSectionsIds: SectionId[] = [];

    for (const originWithVariables of Object.values(variableGroup.originsWithVariables)) {
      const variablePath = getVariablePath(originWithVariables.variables, variableId);

      if (variablePath.length > 0) {
        const variablePathWithoutLastElement = variablePath.slice(0, -1);
        expandedSectionsIds.push(...variablePathWithoutLastElement, originWithVariables.origin.id);
      }
    }

    setVariablesGroups.push({
      ...variableGroup,
      expandedSectionsIds: [...(variableGroup.expandedSectionsIds || []), ...expandedSectionsIds],
    });
  }

  return setVariablesGroups;
};

export const countSelectableVariables = (variables: Variable[]) => {
  let count = 0;
  for (const variable of variables) {
    if (variable.isSelectable) {
      count += 1;
    }
    if (variable.variables.length > 0) {
      count += countSelectableVariables(variable.variables);
    }
  }
  return count;
};

export const groupVariablesByOrigin = ({
  variables,
  hideOrigin = false,
}: {
  variables: Variable[];
  hideOrigin?: boolean;
}) => {
  return variables.reduce<OriginsWithVariables>((originWithVariables, variable) => {
    const originId = variable.origin.id;

    if (!originWithVariables[originId]) {
      originWithVariables[originId] = {
        origin: variable.origin,
        variables: [variable],
        hideOrigin,
      };

      return originWithVariables;
    }

    originWithVariables[originId].variables.push(variable);

    return originWithVariables;
  }, {});
};

export const findVariableById = (variables: Variable[], variableId: VariableId): Variable | null => {
  for (const variable of variables) {
    const finalVariableId = variable.idOverride ?? variable.id;

    if (finalVariableId === variableId) {
      return variable;
    }

    if (variable.variables?.length > 0) {
      const foundVariable = findVariableById(variable.variables, variableId);
      if (foundVariable) {
        return foundVariable;
      }
    }
  }

  return null;
};

export const getVariablesIds = (variables: Variable[]): VariableId[] => {
  const ids: VariableId[] = [];

  for (const variable of variables) {
    ids.push(variable.id);
    if (variable.variables.length > 0) {
      ids.push(...getVariablesIds(variable.variables));
    }
  }

  return ids;
};

export const getAllSectionsIds = (originsWithVariables: OriginsWithVariables): SectionId[] => {
  const sectionsIds: SectionId[] = [];

  for (const originId of Object.keys(originsWithVariables)) {
    sectionsIds.push(originId);
    sectionsIds.push(...getVariablesIds(originsWithVariables[originId].variables));
  }

  return sectionsIds;
};

export const getSectionLabel = (labelBase: string, variablesCount: number) => {
  return `${labelBase} (${variablesCount})`;
};

export const getFirstLevelSectionsIds = (originsWithVariables: OriginsWithVariables): SectionId[] => {
  const sectionsIds: SectionId[] = [];

  for (const [originId, originWithVariables] of Object.entries(originsWithVariables)) {
    if (originWithVariables.hideOrigin) {
      sectionsIds.push(originId);
    }
  }

  return sectionsIds;
};
