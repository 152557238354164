import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { SlackSetupCard } from './components/Slack/SlackSetupCard';
import { SLACK_PROVIDER_ID } from './components/Slack/common';
import { CardWrapper } from '../components/CardWrapper';
import { CardsList } from '../components/CardsList';

export const Notifications = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={6}>
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.NOTIFICATIONS')}
      </Text>
      <CardsList>
        <CardWrapper>
          <SlackSetupCard providerId={SLACK_PROVIDER_ID} />
        </CardWrapper>
      </CardsList>
    </Stack>
  );
};
