import { Text } from '@verticeone/design-system';
import { Stack, useTheme } from '@mui/material';
import React from 'react';

export type NoRowsOverlayProps = {
  text: string;
};

const NoRowsOverlay = ({ text }: NoRowsOverlayProps) => {
  const { palette } = useTheme();

  return (
    <Stack height="100%" alignItems="center" justifyContent="center" bgcolor={palette.core.color2}>
      <Text variant="body-regular" size="M" color="text2" textAlign="center">
        {text}
      </Text>
    </Stack>
  );
};

export default NoRowsOverlay;
