import { AnyZodObject, z } from 'zod';
import { FileUploadFieldMetadata, FileUploadFieldType, fileUploadSchema } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';
import { t } from 'i18next';

export function getFileUploadPresenterSchema(field: FileUploadFieldType): AnyZodObject {
  const arraySchema = z.array(fileUploadSchema);
  const requiredArraySchema = arraySchema.min(1, {
    message: t('DYNAMIC_FORM_BUILDER.FIELD.FILE_UPLOAD.ADD_AT_LEAST_ONE_FILE'),
  });

  return z.object({
    [field.name]: field.metadata.required ? requiredArraySchema : z.union([arraySchema, z.null()]),
  });
}

export function getFileUploadPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<FileUploadFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getFileUploadJsonSchemaFromMetadata(metadata: FileUploadFieldMetadata): JsonSchema {
  return {
    type: metadata.required ? 'array' : ['array', 'null'],
    title: metadata.label,
  };
}

export function getFileUploadJsonSchema(field: FileUploadFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getFileUploadJsonSchemaFromMetadata(field.metadata) },
  };
}
