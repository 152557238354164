import { Stack, styled, useTheme } from '@mui/material';
import { Node, NodeProps, useReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { FC, PropsWithChildren, useState } from 'react';

import { testProps, Text } from '@verticeone/design-system';
import { TaskIcon, useTaskStyle } from '../../../../sharedVisualStyle/taskStyle';
import { WorkflowTaskNode } from '../../../model/types';
import { checkServiceTaskHasDetail } from '../../../WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/utils';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';
import { getNodeDimensions } from '../getNodeDimensions';
import { NodeControls } from '../NodeControls';
import { ValidationCheck } from '../ValidationCheck';
import { GradientWrapper } from './GradientWrapper';
import { StyledTaskNode } from './StyledTaskNode';
import { TaskAssignees } from './TaskAssignees';
import { TaskDate } from './TaskDate';
import { TaskNavigateAction } from './TaskNavigateAction';
import { TaskVerticeAssignment } from './TaskVerticeAssignment';
import { TaskViewButton } from './TaskViewButton';
import { TaskColorVariant } from './types';

const TaskHeader = styled(Stack)<{ $colorVariant: TaskColorVariant; $invertedColors?: boolean }>(
  ({ theme, $colorVariant, $invertedColors }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    width: '100%',
    backgroundColor: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette.background.default,
  })
);

const TaskBody = styled(Stack)<{ $colorVariant: TaskColorVariant; $invertedColors?: boolean }>(
  ({ theme, $colorVariant, $invertedColors }) => ({
    borderTop: `1px solid ${$invertedColors ? theme.palette[$colorVariant].color3 : theme.palette.core.color4}`,
    padding: theme.spacing(4),
    width: '100%',
    backgroundColor: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette.background.default,
    '&:empty': {
      display: 'none',
    },
  })
);

/*
  Task node may vary in height depending on the content,
  so here we use a wrapper with constant width and height which are used for the auto-layouting
  and the task node itself is centered inside the wrapper.
 */
export const VerticalAlignWrapper = styled(Stack)<{ $node: WorkflowTaskNode }>(({ $node }) => ({
  ...getNodeDimensions($node),
  flexDirection: 'row',
  alignItems: 'center',
}));

type ValidationMessageVariant = 'error' | 'warning' | null;

export const TaskNodeComponent: FC<NodeProps<Node<WorkflowTaskNode>>> = (props) => {
  const { palette } = useTheme();
  const [validationMessageVariant, setValidationMessageVariant] = useState<ValidationMessageVariant>(null);
  const { loggedUserId: userId, isEditor } = useWorkflowRendererContext();
  const taskState = props.data.state;
  const { updateNode } = useReactFlow();

  const taskNodeType = props.data.type;
  const { colorVariant, withShadow, withColoredBorder, invertedColors, invertedHeaderColors } = useTaskStyle(
    userId,
    taskState?.status,
    taskState?.assigneeIds,
    taskNodeType,
    props.data.thumbnail
  );

  const textColor = invertedColors ? palette.text.color5 : palette.text.color1;
  // Header text color has an edge case for success and primary color variant
  const variantColor = !Array<TaskColorVariant>('neutral', 'transparent').includes(colorVariant)
    ? colorVariant
    : palette.text.color1;
  const headerTextColor = invertedHeaderColors ? palette.text.color5 : variantColor;

  const isActive = taskState?.status === 'ACTIVE';
  const wrapperHasShadow = isActive && withShadow;
  const WrapperComponent: FC<PropsWithChildren> = ({ children }) => {
    return isActive ? (
      <GradientWrapper $colorVariant={colorVariant} $withShadow={wrapperHasShadow}>
        {children}
      </GradientWrapper>
    ) : (
      children
    );
  };

  const validationTaskNodePropsOverride = {
    $colorVariant: validationMessageVariant ?? ('neutral' as TaskColorVariant),
    $withShadow: true,
    $withColoredBorder: true,
  };
  // Fallback color variant to satisfy typescript
  const validationTaskHeaderPropsOverride = {
    $colorVariant: validationMessageVariant ?? ('neutral' as TaskColorVariant),
    $invertedColors: false,
  };

  const getEditorOrDefaultColorVariant = <T extends unknown, S extends unknown>(editorValue: T, defaultValue: S) =>
    isEditor && validationMessageVariant ? editorValue ?? defaultValue : defaultValue;

  const handleTaskNodeClick = () => {
    updateNode(props.id, { selected: true });
  };

  return (
    <NodeControls
      {...props}
      content={
        <VerticalAlignWrapper $node={props.data}>
          <WrapperComponent>
            <StyledTaskNode
              // Use 'error' or 'warning' if there is validation message present
              {...getEditorOrDefaultColorVariant(validationTaskNodePropsOverride, {
                $colorVariant: colorVariant,
                $withShadow: withShadow && !wrapperHasShadow,
                $withColoredBorder: withColoredBorder,
              })}
              $selected={props.selected}
              $active={isActive}
              onClick={handleTaskNodeClick}
              {...testProps(props.data.name)}
            >
              <TaskHeader
                {...getEditorOrDefaultColorVariant(validationTaskHeaderPropsOverride, {
                  $colorVariant: colorVariant,
                  $invertedColors: invertedHeaderColors,
                })}
              >
                <TaskIcon
                  userId={userId}
                  status={taskState?.status}
                  taskType={taskNodeType}
                  type={props.data.thumbnail?.type}
                  id={props.data.thumbnail?.id}
                  assigneeIds={taskState?.assigneeIds}
                  size="XS"
                  overrideColorVariant={getEditorOrDefaultColorVariant(validationMessageVariant, undefined)}
                />
                <Text
                  variant="heading"
                  size="XS"
                  color={getEditorOrDefaultColorVariant(validationMessageVariant, headerTextColor)}
                >
                  {props.data.name}
                </Text>
              </TaskHeader>
              <TaskBody $colorVariant={colorVariant} $invertedColors={invertedColors} gap={1}>
                <TaskAssignees
                  isEditIconDisplayed={!!taskState?.canUserReassign && isActive}
                  taskId={props.data.id}
                  taskType={taskNodeType}
                  textColor={textColor}
                  taskState={taskState}
                />
                <TaskVerticeAssignment status={taskState?.status} textColor={textColor} taskNodeType={taskNodeType} />
                <TaskDate
                  state={taskState}
                  textColor={
                    Array<TaskColorVariant>('success', 'error').includes(colorVariant) ? variantColor : textColor
                  }
                />
                <TaskNavigateAction state={taskState} route={props.data.requestRoute} taskNodeType={taskNodeType} />
                <TaskViewButton
                  assigneeIds={taskState?.assigneeIds}
                  isHidden={!taskState?.status || taskState.status === 'PENDING' || !!taskState.requestRef}
                  hasServiceTaskDetail={checkServiceTaskHasDetail(taskState)}
                  taskId={props.data.id}
                  colorVariant={colorVariant}
                  nodeId={props.id}
                />
              </TaskBody>
            </StyledTaskNode>
          </WrapperComponent>
          {isEditor && <ValidationCheck setValidationResult={setValidationMessageVariant} id={props.data.id} />}
        </VerticalAlignWrapper>
      }
    />
  );
};
