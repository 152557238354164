import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Stack, styled } from '@mui/material';
import { IconButton } from '@verticeone/design-system';
import { forwardRef } from 'react';

const RichSelectorWrapper = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.core.color3}`,
  '&:hover, &:focus-within': {
    border: `1px solid ${theme.palette.info.hover.color1}`,
  },
  borderRadius: theme.spacing(1),
  minHeight: '80px',
  outline: 'none',
  padding: '8px 24px 8px 12px',
  position: 'relative',
  fontSize: '0.875rem',
}));

const OpenDialogButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
});

type RichSelectorProps = {
  value: string;
  onChange?: (value: string) => void;
  onOpenInNewClick?: () => void;
};

export const RichSelector = forwardRef<HTMLDivElement, RichSelectorProps>(
  ({ value, onChange, onOpenInNewClick }, ref) => {
    return (
      <RichSelectorWrapper>
        {onOpenInNewClick && (
          <OpenDialogButton icon={OpenInNewIcon} variant="plain" size="S" onClick={onOpenInNewClick} />
        )}
        <Box
          ref={ref}
          contentEditable
          dangerouslySetInnerHTML={{ __html: value }}
          onBlur={(e) => onChange?.(e.currentTarget.innerHTML)}
          sx={{ outline: 0, whiteSpace: 'pre-line' }}
        />
      </RichSelectorWrapper>
    );
  }
);
