import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { CardWrapper } from '../../components/CardWrapper';
import { useCustomIntegrationsData } from '../dataSource';
import { CardsList } from '../../components/CardsList';
import IntegrationCard from './components/IntegrationCard';
import NewIntegrationCard from './components/NewIntegrationCard';

const StyledCardWrapper = styled(CardWrapper)({
  minHeight: '180px',
});

const CustomSection = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();
  const { data } = useCustomIntegrationsData();

  return (
    <Stack gap={6}>
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.NOTIFICATIONS')}
      </Text>
      {isEnabled(FEATURES.INTEGRATION_GENERIC_OUTBOUND) && (
        <CardsList>
          {data.map(({ id, enabled, name, auth: { type } }) => (
            <StyledCardWrapper key={id}>
              <IntegrationCard id={id} authType={type} enabled={enabled} name={name} />
            </StyledCardWrapper>
          ))}
          <StyledCardWrapper>
            <NewIntegrationCard />
          </StyledCardWrapper>
        </CardsList>
      )}
    </Stack>
  );
};

export default CustomSection;
