import React, { useId } from 'react';
import { StatsBody, StatsContainer, Title, Value } from '@vertice/core/src/components/StatsBar';
import { useTranslation } from 'react-i18next';
import SolidStatsTile from '@vertice/core/src/components/StatsBar/StatsTile/SolidStatsTile';
import { useOverviewStatsData } from '../useOverviewStatsData';

const OverdueRequestsStatsContainer = () => {
  const id = useId();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.OVERVIEW.STATS' });
  const { data, isLoading } = useOverviewStatsData();

  return (
    <StatsContainer flex={1}>
      <SolidStatsTile color="error">
        <StatsBody
          isLoading={isLoading}
          title={<Title id={id}>{t('OVERDUE_REQUESTS')}</Title>}
          value={<Value aria-labelledby={id}>{data.overdueRequests}</Value>}
        />
      </SolidStatsTile>
    </StatsContainer>
  );
};

export default OverdueRequestsStatsContainer;
