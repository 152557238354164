import type { FlowEdge, FlowEdgeDefinition } from '../definitionsTypes';

export const updateEdgeDefinition = ({
  edgeDefinition,
  edge,
}: {
  edgeDefinition: FlowEdgeDefinition;
  edge: {
    name?: string;
    configurations?: FlowEdge['configurations'];
  };
}) => {
  const originalConfigurations = edge.configurations
    ? (edgeDefinition.edge.configurations ?? []).filter(
        (config) => !edge.configurations?.some((updatedConfig) => updatedConfig.kind === config.kind)
      )
    : edgeDefinition.edge.configurations;

  return {
    ...edgeDefinition,
    edge: {
      ...edgeDefinition.edge,
      ...(edge.name !== undefined ? { name: edge.name } : {}),
      configurations: edge.configurations
        ? [...(originalConfigurations || []), ...edge.configurations]
        : edgeDefinition.edge.configurations,
    },
  };
};
