import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, styled } from '@mui/material';
import { FC, useId } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, IconWrapper, Text, Tooltip } from '@verticeone/design-system';
import { AddFieldButton } from '../../../../../../forms/dynamicForms/DynamicFormBuilder/components/AddFieldButton';
import type { Variable } from '../../../types';
import { QueryBuilder } from '../../../QueryBuilder/QueryBuilder';
import { EditUserTaskFormData, MIN_ASSIGNEES } from '../schema';
import { TaskAssigneeSelect } from './TaskAssigneeSelect';
import { StyledTooltipContent } from '../../../components/StyledTooltipContent';

const ConditionalAssignmentContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.input.color1,
  padding: theme.spacing(4),
  borderRadius: 12,
}));

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.neutral.color2,
  opacity: 0.4,
}));

type ConditionalAssignmentProps = {
  requestVariables: Variable[];
  udfVariables: Variable[];
  onAddUser: () => void;
  allowContractOwnerAssignment?: boolean;
};

export const ConditionalAssignment: FC<ConditionalAssignmentProps> = ({
  requestVariables,
  udfVariables,
  onAddUser,
  allowContractOwnerAssignment,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.CONDITIONAL_ASSIGNEES',
  });
  const id = useId();

  const { setValue, getValues } = useFormContext<EditUserTaskFormData>();
  const { fields, append, remove } = useFieldArray<EditUserTaskFormData, 'conditionalAssignees'>({
    name: 'conditionalAssignees',
  });

  return (
    <Stack gap={2}>
      {fields.map((field, index) => (
        <ConditionalAssignmentContainer data-testid="conditional-assignment" gap={6} key={field.id}>
          <Stack alignItems="center" justifyContent="space-between" direction="row">
            <Stack alignItems="center" direction="row" gap={1}>
              <Text variant="label" size="XXS" color="text3">
                {t('TITLE')}
              </Text>
              <Tooltip content={<StyledTooltipContent>{t('HELP.CONDITIONAL')}</StyledTooltipContent>} size="S">
                <StyledIconWrapper icon={InfoOutlinedIcon} size="XS" />
              </Tooltip>
            </Stack>
            <Button
              testId="delete-condition"
              sx={{ padding: '0px !important' }}
              onClick={() => remove(index)}
              size="XXS"
              variant="plain"
            >
              {t('ACTIONS.DELETE')}
            </Button>
          </Stack>
          <Stack gap={3}>
            <QueryBuilder
              initialCondition={getValues(`conditionalAssignees.${index}.condition`)}
              onConditionChange={(newCondition) => {
                setValue(`conditionalAssignees.${index}.condition`, newCondition, { shouldDirty: true });
              }}
              requestVariables={requestVariables}
              udfVariables={udfVariables}
            />
            <Stack direction="row" alignItems="center" gap={2}>
              <Text variant="caption" size="S" color="text2">
                {t('THEN_ASSIGN')}
              </Text>
              <TaskAssigneeSelect
                id={`${id}-${index}`}
                onAddUser={onAddUser}
                allowContractOwnerAssignment={allowContractOwnerAssignment}
                name={`conditionalAssignees.${index}.assignees`}
                testId="select-assignee"
                minAssignees={MIN_ASSIGNEES}
              />
            </Stack>
          </Stack>
        </ConditionalAssignmentContainer>
      ))}
      <AddFieldButton
        label={t('ACTIONS.ADD')}
        onClick={() =>
          append({
            condition: '',
            assignees: [],
          })
        }
      />
    </Stack>
  );
};
