import { AnyZodObject, z } from 'zod';
import { ShortTextFieldMetadata, ShortTextFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';

export function getShortTextPresenterSchema(field: ShortTextFieldType): AnyZodObject {
  let schema = z.string();

  return z.object({
    [field.name]: field.metadata.required ? schema.min(1) : schema.optional(),
  });
}

export function getShortTextPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<ShortTextFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: '' };
}

export function getShortTextJsonSchemaFromMetadata(metadata: ShortTextFieldMetadata): JsonSchema {
  return { type: metadata.required ? 'string' : ['string', 'null'], title: metadata.label };
}

export function getShortTextJsonSchema(field: ShortTextFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getShortTextJsonSchemaFromMetadata(field.metadata) },
  };
}
