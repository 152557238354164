import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingCartOutlined } from '@mui/icons-material';

import { Button } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';

import { useShoppingCart } from '../../../../providers/ShoppingCartProvider';
import { OfferingData } from '../../../../../types';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useTempData } from '../../CustomizeTable/TempDataContext';

type AddToCartButtonProps = GridRenderCellParams<OfferingData> & {
  tKey: string;
};

const AddToCartButton = ({ row, tKey, api }: AddToCartButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const { addToCart, cartItems } = useShoppingCart();
  const { setTempData } = useTempData();

  const cartItem = cartItems.find((item) => item.offeringId === row.offeringId);

  const handleClick = () => {
    setTempData(Array.from(api.getRowModels().values()) as Array<OfferingData>);

    addToCart(row);
  };

  return (
    <Button disabled={!!cartItem} variant="ghost" color="neutral" fullWidth onClick={handleClick}>
      {t('ACTIONS.ADD')}
      <IconWrapper icon={ShoppingCartOutlined} />
    </Button>
  );
};

export default AddToCartButton;
