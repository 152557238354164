import { parse } from './urn';
import { NotifMessage } from '../../contexts/NotificationWebSocketContext/types';

export const parseWorkflowVersionRef = (workflowVersionRef: string) => {
  const urn = parse(workflowVersionRef);

  if (urn.resourceName !== 'workflow' || !urn.resourceId) {
    throw new Error('Invalid workflow version ref');
  }

  const idParts = urn.resourceId.split('/');

  return {
    workflowId: idParts[0],
    workflowVersion: idParts[idParts.length - 1],
  };
};

/**
 * Safely parse workflow version ref
 * Expected format is: urn:verticeone:vertice:{accountId}:workflows:workflow/{workflowId}/version/{workflowVersion}
 * Version part will be ignored
 * @param workflowRef
 */
export const parseWorkflowRef = (workflowRef?: string) => {
  if (!workflowRef) {
    return { workflowId: undefined };
  }

  const workflowUrn = parse(workflowRef);
  if (workflowUrn.resourceName !== 'workflow' || !workflowUrn.resourceId) {
    return { workflowId: undefined };
  }

  const idParts = workflowUrn.resourceId.split('/');

  return {
    workflowId: idParts[0],
  };
};

export const parseWorkflowInstanceRef = (workflowVersionRef: string) => {
  const instanceUrn = parse(workflowVersionRef);
  if (instanceUrn.resourceName !== 'workflow-instance' || !instanceUrn.resourceId) {
    throw new Error('Invalid workflow instance ref');
  }
  return {
    workflowInstanceId: instanceUrn.resourceId,
  };
};

export const parseUserTaskRef = (taskRef: string) => {
  const taskUrn = parse(taskRef);
  if (taskUrn.resourceName !== 'task' || !taskUrn.resourceId) {
    throw new Error('Invalid task ref');
  }

  return {
    taskId: taskUrn.resourceId,
    accountId: taskUrn.accountId,
  };
};

export const parseRequestRef = (requestRef: string) => {
  const requestUrn = parse(requestRef);
  if (requestUrn.resourceName !== 'request' || !requestUrn.resourceId) {
    throw new Error(`Failed to parse request id from ref "${requestRef}"`);
  }

  return {
    accountId: requestUrn.accountId,
    requestId: requestUrn.resourceId,
  };
};

export const parseRequestRefSafe = (requestRef: string) => {
  try {
    return parseRequestRef(requestRef);
  } catch {
    return { requestId: undefined };
  }
};

export const parseResourceRef = (resourceRef: string) => {
  const resourceUrn = parse(resourceRef);
  if (!resourceUrn.resourceId) {
    throw new Error(`Failed to parse resource id from ref "${resourceRef}"`);
  }
  return {
    accountId: resourceUrn.accountId,
    resourceId: resourceUrn.resourceId,
    resourceName: resourceUrn.resourceName,
  };
};

export const isUserRef = (ref: string) => {
  const urn = parse(ref);
  return urn.resourceName === 'user' && urn.service === 'iam';
};

export const formatUserRef = (userId: string) => {
  return userId.startsWith('urn:verticeone:vertice::iam:user') ? userId : `urn:verticeone:vertice::iam:user/${userId}`;
};

export const parseUserRef = (userRef: string) => {
  const userUrn = parse(userRef);
  if (userUrn.resourceName !== 'user' || !userUrn.resourceId) {
    throw new Error(`Failed to parse user id from ref "${userRef}"`);
  }
  return {
    userId: userUrn.resourceId,
  };
};

type ParsedGenericHandlerRef = {
  handlerType?: string;
};
type ParsedWorkflowHandlerRef = {
  handlerType: 'workflow';
  workflowId: string;
};

type ParsedHandlerRef = ParsedGenericHandlerRef | ParsedWorkflowHandlerRef;

export const parseServiceHandlerRef = (handlerRef?: string): ParsedHandlerRef => {
  if (!handlerRef) {
    return { handlerType: undefined };
  }

  const handlerUrn = parse(handlerRef || '');

  if (handlerUrn.service === 'vertice-workflows' && handlerUrn.resourceName === 'workflow') {
    const workflowId = handlerUrn.resourceId?.split('/')[0];

    return {
      handlerType: handlerUrn.resourceName,
      workflowId,
    };
  }

  return { handlerType: undefined };
};

export const isVerticeWorkflowHandler = (
  parsedHandler: ParsedHandlerRef
): parsedHandler is ParsedWorkflowHandlerRef => {
  return parsedHandler.handlerType === 'workflow';
};

const AWS_RESOURCE_REF_PREFIX = 'arn:';
export const isAwsResourceRef = (ref: string) => ref.startsWith(AWS_RESOURCE_REF_PREFIX);
export const isWorkflowInstanceRef = (ref: string) => parse(ref).resourceName === 'workflow-instance';

export const isSaasRenewalServiceRef = (ref: string) => parse(ref).resourceId?.includes('saas/renewal') ?? false;
export const isSaasNewPurchaseServiceRef = (ref: string) => parse(ref).resourceId?.includes('saas/purchase') ?? false;
export const isGenericNewPurchaseServiceRef = (ref: string) =>
  parse(ref).resourceId?.includes('generic/purchase') ?? false;

// create virtual service identifier that can be used as a unique key in pages URL
export const createServiceIdentifier = (serviceRef: string) => {
  if (serviceRef.startsWith('urn:')) {
    const serviceUrn = parse(serviceRef);
    return serviceUrn.resourceId ? serviceUrn.resourceId.replaceAll('/', '-') : '';
  } else {
    return serviceRef.replaceAll('/', '-');
  }
};

export const isTaskRef = (message: NotifMessage) => parse(message.message_attributes.ref).resourceName === 'task';
export const isRequestRef = (message: NotifMessage) => parse(message.message_attributes.ref).resourceName === 'request';
export const isWorkflowRef = (message: NotifMessage) =>
  parse(message.message_attributes.ref).resourceName === 'workflow';
export const isConversationRef = (message: NotifMessage) =>
  parse(message.message_attributes.ref).resourceName === 'conversation';
export const isCurrentRequest = (requestId: string) => (message: NotifMessage) => {
  return isRequestRef(message) && parse(message.message_attributes.ref).resourceId === requestId;
};
export const isCurrentWorkflowVersion = (workflowId: string) => (message: NotifMessage) => {
  return (
    isWorkflowRef(message) && !!parse(message.message_attributes.ref).resourceId?.startsWith(`${workflowId}/version/`)
  );
};
export const isCurrentConversation =
  (conversationId: string) =>
  (message: NotifMessage): boolean => {
    return isConversationRef(message) && parse(message.message_attributes.ref).resourceId === conversationId;
  };

// parses request id from child request ref
// expected format is: urn:verticeone:vertice:{accountId}:requests:request/{requestId}_child
export const parseRequestIdFromChildRequestRef = (ref: string) => {
  const idPart = parseRequestRefSafe(ref).requestId;

  if (!idPart) return undefined;

  const parts = idPart.split('_');

  if (parts.length < 2) return undefined;

  return parts[0];
};
