import { useCallback, useEffect } from 'react';
import { Control, FieldValues, useFormState, useWatch } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

const DEBOUNCE_TIME = 500;
const MAX_WAIT = 8000;

type UseCustomFormDraftProps<T extends FieldValues> = {
  control: Control<T>;
  onDraftSubmit: (values: T) => Promise<void>;
};

export const useCustomFormDraft = <T extends FieldValues>(props: UseCustomFormDraftProps<T>) => {
  const { control, onDraftSubmit } = props;

  const formValues = useWatch({ control });
  const { isSubmitting, isSubmitted } = useFormState({ control });

  const saveDraft = useCallback(
    (values: T) => {
      if (isSubmitting || isSubmitted) return;
      void onDraftSubmit(values);
    },
    [onDraftSubmit, isSubmitted, isSubmitting]
  );

  const saveDebounced = useDebouncedCallback(saveDraft, DEBOUNCE_TIME, { maxWait: MAX_WAIT });

  useEffect(() => {
    saveDebounced(formValues as T);
  }, [formValues, saveDebounced]);
};
