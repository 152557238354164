import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { GridColDef } from '@mui/x-data-grid-pro';

import { TaskAssignees, TaskRequestName, TaskName, TaskActiveDays, TaskStatus, TaskDateCompleted } from './Columns';
import type { TaskRow } from '../../dataSource';
import { getDaysActive } from '../../dataSource/utils';
import { useFilterParam } from '../../dataSource/queryParams';

export const useTaskColumns = (): Array<GridColDef<TaskRow>> => {
  const { t } = useTranslation();
  const [filter] = useFilterParam();

  return useMemo(() => {
    const columns: Array<GridColDef<TaskRow> & { isVisible: boolean }> = [
      {
        field: 'name',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.TASK_NAME_COLUMN'),
        flex: 3,
        renderCell: ({ row }) => <TaskName taskRow={row} />,
        isVisible: true,
      },
      {
        field: 'status',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.TASK_STATUS_COLUMN'),
        flex: 1,
        renderCell: ({ row }) => <TaskStatus taskRow={row} />,
        isVisible: true,
      },
      {
        field: 'assignees',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.ASSIGNEE_COLUMN'),
        renderCell: ({ row }) => <TaskAssignees taskRow={row} />,
        isVisible: true,
        sortable: false,
        flex: 2,
      },
      {
        field: 'requestName',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.REQUEST_COLUMN'),
        flex: 3,
        renderCell: ({ row }) => <TaskRequestName taskRow={row} />,
        isVisible: true,
      },
      {
        field: 'activeDays',
        valueGetter: ({ row }) => getDaysActive(row.createdAt, row.closedAt),
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.DAYS_ACTIVE_COLUMN'),
        renderCell: ({ row }) => <TaskActiveDays taskRow={row} />,
        isVisible: true,
        flex: 1.5,
      },
      {
        field: 'dateCompleted',
        valueGetter: ({ row }) => row.closedAt,
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.CLOSED_AT_COLUMN'),
        renderCell: ({ row }) => <TaskDateCompleted taskRow={row} />,
        isVisible: filter !== 'IN_PROGRESS',
        flex: 1.5,
      },
    ] as const;

    return columns.filter((item) => item.isVisible);
  }, [filter, t]);
};
