import { Link, Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconWrapper, Text } from '@verticeone/design-system';
import { JiraIcon } from '../../../../../../integrations/Jira/icons';
import { EditorDrawerGroup } from '../../../EditorDrawer';

const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.core.color1,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
  textDecoration: 'none',
}));

type JiraTicketLinkProps = {
  url?: string;
  title?: string;
};

export const JiraTicketLink: FC<JiraTicketLinkProps> = ({ url, title }) => {
  const { t } = useTranslation();

  if (!url || !title) return null;

  return (
    <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.LINK_GROUP_TITLE')}>
      <Stack component={StyledLink} href={url} direction="row" gap={2}>
        <IconWrapper icon={JiraIcon} />
        <Text variant="body-bold" size="S" color="text1">
          {title}
        </Text>
      </Stack>
    </EditorDrawerGroup>
  );
};
