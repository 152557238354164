import React from 'react';
import { Controller, FieldPath, Path, PathValue, useFormContext, FieldValues } from 'react-hook-form';
import { Select, SelectProps, PropsValue } from '@verticeone/design-system';
import { GroupBase } from '@verticeone/design-system';
import { OnChangeValue } from 'react-select';
import { identity, isNil } from 'lodash';

type FormSelectFieldProps<
  FormDataType extends FieldValues,
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = SelectProps<Option, IsMulti, Group> & {
  id: string;
  name: FieldPath<FormDataType>;
  disabled?: boolean;
  unboxValue?: (
    selectValue: OnChangeValue<Option, IsMulti>
  ) => PathValue<FormDataType, (string | string[] | undefined) & Path<FormDataType>>;
  boxValue?: (
    value: PathValue<FormDataType, (string | string[] | undefined) & Path<FormDataType>>
  ) => PropsValue<Option> | undefined;
  hideHelperText?: boolean;
};

export type FormSelectFieldComponentType<
  FormDataType extends FieldValues,
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = (props: FormSelectFieldProps<FormDataType, Option, IsMulti, Group>) => JSX.Element;

const FormSelectField2 = <
  FormDataType extends FieldValues = never,
  Option extends unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  id,
  name,
  required,
  disabled,
  boxValue = identity,
  unboxValue = identity,
  hideHelperText,
  ...selectProps
}: FormSelectFieldProps<FormDataType, Option, IsMulti, Group>) => {
  const { control } = useFormContext<FormDataType>();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref, disabled: formDisabled }, fieldState: { invalid, error } }) => (
        <Select
          // Renderer props
          onBlur={onBlur}
          onChange={(newValue) => {
            onChange(!isNil(newValue) ? unboxValue(newValue) : newValue);
          }}
          value={boxValue(value) || undefined}
          ref={ref}
          name={name}
          // Explicit props
          id={id}
          isDisabled={disabled || formDisabled}
          // Sane defaults (consider moving directly to Select component)
          menuPlacement="auto"
          menuPosition="absolute"
          error={invalid}
          helperText={invalid && !hideHelperText && error?.message}
          isClearable
          // Everything else (e.g. options, getOptionLabel, styling...)
          {...selectProps}
        />
      )}
    />
  );
};

export default FormSelectField2;
