import { Loadable } from '@verticeone/utils/async';
import { DynamicFormPresenterConfig, DynamicFormPresenterMode } from '../../types';
import { useAccountSettings } from '../../../../../hooks/useAccountSettings';
import { useMemo } from 'react';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

/**
 * Prepares config for the dynamic form presenter
 */
export const useDynamicFormPresenterConfig = (mode: DynamicFormPresenterMode, filePathPrefix = '') => {
  const { data: accountSettings, isLoading: isAccountSettingLoading } = useAccountSettings();
  return useMemo<Loadable<DynamicFormPresenterConfig>>(() => {
    if (isAccountSettingLoading) return { isLoading: true };
    return {
      isLoading: false,
      data: {
        mode,
        defaultCurrency: accountSettings?.preferredCurrency || DEFAULT_CURRENCY,
        filePathPrefix,
      },
    };
  }, [accountSettings?.preferredCurrency, isAccountSettingLoading, mode, filePathPrefix]);
};
