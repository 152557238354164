import { useConversations } from '../../request/RequestDetailPage/useConversations';
import { getNewCommentsCount } from '@verticeone/design-system';

export const useGetTaskUnreadCount = ({
  accountId,
  requestId,
  taskRef,
  skip,
}: {
  accountId: string;
  requestId?: string;
  taskRef: string;
  skip?: boolean;
}) => {
  const { cursor, messages } = useConversations({ accountId, requestId, requestOwner: null, sourceRef: taskRef });

  if (!cursor || !messages || skip) return undefined;

  const unreadCount = cursor ? getNewCommentsCount(messages, cursor) : 0;

  return unreadCount;
};
