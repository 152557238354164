import { z, ZodObject, ZodRawShape } from 'zod';
import { CheckboxFieldMetadata, CheckboxFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';

export function getCheckboxPresenterSchema(field: CheckboxFieldType): ZodObject<ZodRawShape> {
  return z.object({
    [field.name]: field.metadata.required ? z.literal(true) : z.boolean(),
  });
}

export function getCheckboxPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<CheckboxFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: false };
}

export function getCheckboxJsonSchemaFromMetadata(metadata: CheckboxFieldMetadata): JsonSchema {
  return metadata.required
    ? { type: ['boolean'], enum: [true], title: metadata.label }
    : { type: ['boolean'], title: metadata.label };
}

export function getCheckboxJsonSchema(field: CheckboxFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getCheckboxJsonSchemaFromMetadata(field.metadata) },
  };
}
