import { useUsersById } from './useAccountUsersById';
import { useVerticeUsersById } from './useVerticeUsersById';
import { useMemo } from 'react';
import { useGetAccountQuery, Account, useGetUserPublicInformationQuery } from '@vertice/slices';
import { MentionUser } from '@verticeone/design-system';

export type UsersByIdReturn = Record<string, MentionUser>;

export const useConversationUsers = (accountId?: string, options?: { skip: boolean }) => {
  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useUsersById(accountId!, {
    skip: options?.skip || !accountId || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION',
  });
  const { usersById: verticeUsersById, isLoadingUsers: isLoadingVerticeUsers } = useVerticeUsersById({
    skip: options?.skip || !accountId || accountId !== 'VERTICE_WORKFLOW_ADMINISTRATION',
  });
  const { usersById: accountManagerIds, isLoadingUsers: isLoadingManagerIds } = useAccountManagementUsers(
    accountId!,
    options?.skip || !accountId || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION'
  );

  const usersById = useMemo(() => {
    return { ...accountUsersById, ...asManaged(verticeUsersById), ...asManaged(accountManagerIds) };
  }, [accountUsersById, verticeUsersById, accountManagerIds]);

  const isLoadingUsers = useMemo(
    () => isLoadingAccountUsers || isLoadingVerticeUsers || isLoadingManagerIds,
    [isLoadingAccountUsers, isLoadingVerticeUsers, isLoadingManagerIds]
  );

  return {
    usersById,
    isLoadingUsers,
  };
};

const useAccountManagementUsers = (accountId: string, skip?: boolean) => {
  const { data: accountDetails, isLoading } = useGetAccountQuery(
    { accountId },
    {
      skip: skip || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION',
    }
  );

  const { data: pumaProfile, isLoading: isPumaProfileLoading } = useGetUserPublicInformationQuery(
    { userId: (accountDetails as Account)?.associatedManager! },
    { skip: !accountDetails || !(accountDetails as Account)?.associatedManager }
  );
  const { data: managerProfile, isLoading: isManagerProfileLoading } = useGetUserPublicInformationQuery(
    { userId: (accountDetails as Account)?.associatedSuccessManager! },
    { skip: !accountDetails || !(accountDetails as Account)?.associatedSuccessManager }
  );

  const accountManagementUsers: UsersByIdReturn = useMemo(() => {
    const users: UsersByIdReturn = {};
    if (pumaProfile?.userId) {
      users[pumaProfile.userId] = {
        ...pumaProfile,
        userId: pumaProfile.userId,
        email: pumaProfile.email ?? undefined,
      };
    }
    if (managerProfile?.userId) {
      users[managerProfile.userId] = {
        ...managerProfile,
        userId: managerProfile.userId,
        email: managerProfile.email ?? undefined,
      };
    }
    return users;
  }, [pumaProfile, managerProfile]);

  return {
    isLoadingUsers: isLoading || isPumaProfileLoading || isManagerProfileLoading,
    usersById: accountManagementUsers,
  };
};

const asManaged = (users: UsersByIdReturn) => {
  return Object.entries(users).reduce((acc: UsersByIdReturn, [key, value]) => {
    acc[key] = { ...value, verticeUser: true };
    return acc;
  }, {});
};
