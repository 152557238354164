import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { Text } from '@verticeone/design-system';
import { CardWrapper } from '../components/CardWrapper';
import { JiraSetupCard } from './Jira';
import { JIRA_PROVIDER_ID } from './Jira/constants';
import { VisoTrustSetupCard } from './VisoTrust';
import { VISOTRUST_PROVIDER_ID } from './VisoTrust/constants';
import { CardsList } from '../components/CardsList';

export const Workflows = () => {
  const { t } = useTranslation();

  const { isEnabled } = useFeatures();
  const jiraFeatureEnabled = isEnabled(FEATURES.INTEGRATION_JIRA);
  const visoTrustFeatureEnabled = isEnabled(FEATURES.INTEGRATION_VISOTRUST);

  return (
    <Stack gap={6}>
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.WORKFLOWS')}
      </Text>
      <CardsList>
        {jiraFeatureEnabled && (
          <CardWrapper>
            <JiraSetupCard providerId={JIRA_PROVIDER_ID} />
          </CardWrapper>
        )}
        {visoTrustFeatureEnabled && (
          <CardWrapper>
            <VisoTrustSetupCard providerId={VISOTRUST_PROVIDER_ID} />
          </CardWrapper>
        )}
      </CardsList>
    </Stack>
  );
};
