import { Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Text } from '@verticeone/design-system';
import { EditorDrawerGroup } from '../../../EditorDrawer';
import { findValueInTaskObj } from '../JiraCommon/utils';
import { INPUT_RESOLUTION_KEY, INPUT_STATUS_KEY } from './constants';

const ResolutionColumn = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flexBasis: '50%',
}));

type ResolutionSectionProps = {
  taskOverview?: TaskOverview;
};

export const ResolutionSection: FC<ResolutionSectionProps> = ({ taskOverview }) => {
  const { t } = useTranslation();

  const statusKey = findValueInTaskObj(INPUT_STATUS_KEY, taskOverview?.task.input);
  const resolutionKey = findValueInTaskObj(INPUT_RESOLUTION_KEY, taskOverview?.task.input);
  const status = taskOverview?.task.result?.[statusKey]?.title;
  const resolution = taskOverview?.task.result?.[resolutionKey]?.title;

  if (!status || !resolution) return null;

  return (
    <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.RESOLUTION_GROUP_TITLE')}>
      <Stack direction="row" gap={2}>
        <ResolutionColumn>
          <Text variant="caption" size="XS" color="text2">
            {t('INTELLIGENT_WORKFLOWS.JIRA.STATUS_COLUMN_TITLE')}
          </Text>
          <Text variant="body-regular" size="S" color="text1" sx={{ alignSelf: 'flex-start' }}>
            {status}
          </Text>
        </ResolutionColumn>
        <ResolutionColumn>
          <Text variant="caption" size="XS" color="text2">
            {t('INTELLIGENT_WORKFLOWS.JIRA.RESOLUTION_COLUMN_TITLE')}
          </Text>
          <Text variant="body-regular" size="S" color="text1" sx={{ alignSelf: 'flex-start' }}>
            {resolution}
          </Text>
        </ResolutionColumn>
      </Stack>
    </EditorDrawerGroup>
  );
};
