import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export type Task = {
  id: string;
  title: string;
  vendorId: string;
  date: string;
};

type MyRequestsData = {
  tasks: Task[];
  openRequests: number;
  spend: number;
};

export const useMyRequestsData = () => {
  const tempData = {
    name: 'my_requests',
    stats: {
      openRequests: 4,
      spend: 1200000,
    },
    details: {
      columns: ['id', 'title', 'vendor_id', 'date'],
      types: ['string', 'string', 'string', 'isotime'],
      data: [
        ['1', 'Microsoft Office 365 New Purchase', '40c6b64f-780c-569a-b8ed-e716d36dc6a3', '2025-01-29'],
        ['2', 'Adobe New Purchase', '61a63d7c-fb29-5829-be2e-e9dadebedade', '2025-01-31'],
        ['3', 'Gainsight Review', 'c1931324-8cc3-55a8-8581-9e2b52dfb76a', '2025-02-09'],
        ['4', 'LinkedIn Review', '65235894-7167-53f0-8d2f-b16e1b4fcb20', '2025-02-19'],
      ],
    },
  };

  const data: MyRequestsData = {
    tasks: getTableData(tempData.details, {
      vendor_id: 'vendorId',
      title: 'title',
      date: 'date',
    }) as Task[],
    openRequests: tempData.stats.openRequests,
    spend: tempData.stats.spend,
  };

  return {
    data,
    isLoading: false,
  };
};
