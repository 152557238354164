import { z } from 'zod';

export const checkboxFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const checkboxFieldSchema = z.object({
  name: z.string(),
  type: z.literal('CHECKBOX'),
  metadata: checkboxFieldMetadataSchema,
});

export type CheckboxFieldType = z.infer<typeof checkboxFieldSchema>;

export type CheckboxFieldMetadata = z.infer<typeof checkboxFieldMetadataSchema>;
