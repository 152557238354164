import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Wizard } from '@vertice/core/src/components/Wizard';
import StartingStep from './Steps/StartingStep';
import EnterEmailStep from './Steps/EnterEmailStep';
import EnterCodeStep from './Steps/EnterCodeStep';
import { useLoggedUser } from '@verticeone/auth/src';
import { SlackIntegrationData } from './types';

const defaultValues: SlackIntegrationData = {
  slackEmail: '',
  accessCode: null,
};

const SlackIntegrationWizard = () => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const formMethods = useForm<SlackIntegrationData>({ defaultValues, mode: 'onChange' });

  const { email: userEmail } = useLoggedUser();
  useEffect(() => {
    formMethods.setValue('slackEmail', userEmail);
  }, [formMethods, userEmail]);

  const handleOnClose = () => navigate(routes.PREFERENCES.USER);

  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={(e) => e.preventDefault()} p={10}>
        <Wizard
          initialStep="start"
          onClose={handleOnClose}
          closeButtonText={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.CANCEL_BUTTON_TEXT')}
        >
          <StartingStep stepId="start" />
          <EnterEmailStep stepId="enter_email" />
          <EnterCodeStep stepId="enter_code" />
        </Wizard>
      </Box>
    </FormProvider>
  );
};

export default SlackIntegrationWizard;
