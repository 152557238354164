import { FieldMapping } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';

export type ExpressionAlias = {
  assignmentItem: string;
  expressionType: FieldMapping['mappingType'];
  code: string;
  disabled?: boolean;
};

export const EXPRESSION_ALIASES: Array<ExpressionAlias> = [
  {
    assignmentItem:
      'udf(`urn:verticeone:vertice::services:udf/saas/account/workflow/approvers`, {offer: finalOffer, owner: udf(`urn:verticeone:vertice::services:udf/core/request/owner`, {requestId: requestId})})',
    expressionType: 'JMESPathExpression',
    code: 'WORKFLOWS_CONFIG',
    disabled: true,
  },
  {
    assignmentItem: 'udf(`urn:verticeone:vertice::services:udf/core/request/owner`, {requestId: requestId})',
    expressionType: 'JMESPathExpression',
    code: 'REQUEST_OWNER',
  },
  {
    assignmentItem:
      'udf(`urn:verticeone:vertice::services:udf/saas/account/contract/owners`, {contractId: contractId})',
    expressionType: 'JMESPathExpression',
    code: 'CONTRACT_OWNER',
  },
  {
    assignmentItem:
      'udf(`urn:verticeone:vertice::services:udf/saas/department/owner`, {department: department, contractId: contractId})',
    expressionType: 'JMESPathExpression',
    code: 'DEPARTMENT_OWNER',
  },
  {
    assignmentItem:
      'udf(`urn:verticeone:vertice::services:udf/core/account/users`, {accountId: accountId, role: `user:power`})',
    expressionType: 'JMESPathExpression',
    code: 'SAAS_POWER_USER',
  },
  {
    assignmentItem:
      'udf(`urn:verticeone:vertice::services:udf/core/account/users`, {accountId: accountId, role: `user:admin`})',
    expressionType: 'JMESPathExpression',
    code: 'ADMIN',
  },
];
