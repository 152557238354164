import { Edge, Node, useReactFlow, useStoreApi } from '@xyflow/react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSimpleDialogContext } from '@verticeone/design-system';
import { AppTypeContext } from '../../../../contexts/AppTypeContext';
import { FEATURES } from '../../../features/constants';
import { useFeatures } from '../../../features/useFeatures';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';
import { isGatewayDefinition } from '../../definitions/processDefinition';
import { GatewayDefinition, Process, ProcessDefinition, TaskDefinition } from '../../definitionsTypes';
import { isNodeGatewayNode, isNodeLabelNode, isNodeTaskNode } from '../WorkflowRenderer/types';
import {
  SelectionAllowed,
  SelectionChanged,
  useWorkflowRendererState,
} from '../WorkflowRenderer/useWorkflowRendererState';
import { checkServiceTaskIsEditable } from './EditNodeDrawers/EditServiceTaskDrawer/utils';

import isEqual from 'lodash/isEqual';

type UseWorkflowEditingProps = {
  processDefinition?: ProcessDefinition;
};

type Selection = Partial<{ node: TaskDefinition | GatewayDefinition; edge: string }>;

const getSelection = (
  process: Process,
  node?: Node,
  edge?: Edge,
  isAdvancedWorkflowDefinitionEditModeEnabled?: boolean
): Selection => {
  const { tasks = [], gateways = [], flow } = process;
  const selection: Selection = {};

  if (isNodeTaskNode(node)) {
    const taskId = node.data.id;
    const definitionTaskNode = tasks.find(({ task }) => task.id === taskId);

    if (
      definitionTaskNode?.task.taskType === 'User' ||
      (definitionTaskNode?.task.taskType === 'Service' && checkServiceTaskIsEditable(definitionTaskNode.task))
    ) {
      selection['node'] = definitionTaskNode;
    } else {
      selection['node'] = undefined;
    }
    selection['edge'] = undefined;
  }

  if (isNodeGatewayNode(node) || isNodeLabelNode(node)) {
    if (!isAdvancedWorkflowDefinitionEditModeEnabled) {
      return {};
    }
    const gatewayId = isNodeGatewayNode(node) ? node.data.id : node.data.from;
    const definitionGatewayNode = gateways.find(({ gateway }) => gateway.id === gatewayId);

    if (definitionGatewayNode) {
      selection['node'] = definitionGatewayNode;
      if (isNodeLabelNode(node)) {
        selection['edge'] = node.data.originalEdgeId;
      } else {
        selection['edge'] = undefined;
      }
    } else {
      selection['node'] = undefined;
      selection['edge'] = undefined;
    }
  }

  if (edge) {
    const definitionEdge = flow?.flow.edges?.find(
      (e) => e.edge.id === edge?.data?.id || e.edge.id === edge?.data?.originalEdgeId
    );

    const definitionGatewayNode = gateways.find(({ gateway }) => gateway.id === definitionEdge?.edge.from);
    if (definitionGatewayNode) {
      selection['node'] = definitionGatewayNode;
      selection['edge'] = definitionEdge?.edge.id;
    } else {
      selection['node'] = undefined;
      selection['edge'] = undefined;
    }
  }

  return selection;
};

const isNodeSelectionEqual = (
  a: { node?: Node; edge?: Edge },
  b: { node?: Node; edge?: Edge },
  process?: Process,
  isAdvancedWorkflowDefinitionEditModeEnabled?: boolean
): boolean => {
  if (!process) {
    return false;
  }

  return isEqual(
    getSelection(process, a.node, a.edge, isAdvancedWorkflowDefinitionEditModeEnabled).node,
    getSelection(process, b.node, b.edge, isAdvancedWorkflowDefinitionEditModeEnabled).node
  );
};

export const useWorkflowEditing = ({ processDefinition }: UseWorkflowEditingProps) => {
  const flow = useReactFlow();
  const flowApi = useStoreApi();

  const { t } = useTranslation();
  const { getFeature } = useFeatures();
  const { isIAT } = useContext(AppTypeContext);

  const { getConfirmation } = useSimpleDialogContext();
  const [hasChange, setHasChange] = useState(false);
  const isAdvancedWorkflowDefinitionEditModeEnabled =
    !getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.hideAdvancedEditMode || isIAT;
  const [selectedNodeDefinition, setSelectedNodeDefinition] = useState<GatewayDefinition | TaskDefinition | undefined>(
    undefined
  );
  const [selectedEdgeId, setSelectedEdgeId] = useState<string>();

  const onSelectionChanged: SelectionChanged = useCallback(
    (node, edge, resetDirty) => {
      if (!processDefinition) {
        return;
      }

      const selection = getSelection(
        processDefinition.process,
        node,
        edge,
        isAdvancedWorkflowDefinitionEditModeEnabled
      );

      if ('edge' in selection) {
        setSelectedEdgeId(selection.edge);
      }
      if ('node' in selection) {
        setSelectedNodeDefinition(selection.node);
      }

      if (resetDirty) {
        setHasChange(false);
      }
    },
    [processDefinition, isAdvancedWorkflowDefinitionEditModeEnabled]
  );

  const activeGatewayLeavingEdges = useMemo(
    () =>
      isGatewayDefinition(selectedNodeDefinition) && processDefinition
        ? (processDefinition.process.flow?.flow.edges ?? []).filter(
            ({ edge }) => edge.from === selectedNodeDefinition.gateway.id
          )
        : [],
    [selectedNodeDefinition, processDefinition]
  );

  const confirmation = useCallback(
    () =>
      getConfirmation({
        title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DISCARD_TASK_CHANGES_DIALOG.HEADER'),
        okButton: {
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        },
        cancelButton: {
          hidden: true,
        },
      }),
    [t, getConfirmation]
  );

  const checkChangeSelectionAllowed: SelectionAllowed = useCallback(
    async (oldSelection, nextElements) => {
      if (!selectedNodeDefinition || !hasChange) {
        return { allowed: true, reset: true };
      }

      // ReactFlow sometimes batches multiple elements together when clicking on nodes/edges, happens mainly withint gateways
      const multipleElementsSelected = (nextElements.edges?.length ?? 0) + (nextElements.nodes?.length ?? 0) > 1;
      if (multipleElementsSelected) {
        await confirmation();
        return { allowed: false, reset: false };
      }

      // form is dirty, but new node/edge is in scope of the previous thus tansition is fine (gateway edges/labes...)
      const nextSelection = { edge: nextElements.edges?.at(0), node: nextElements.nodes?.at(0) };
      if (
        isNodeSelectionEqual(
          oldSelection,
          nextSelection,
          processDefinition?.process,
          isAdvancedWorkflowDefinitionEditModeEnabled
        )
      ) {
        return { allowed: true, reset: false };
      }

      await confirmation();

      return { allowed: false, reset: false };
    },
    [isAdvancedWorkflowDefinitionEditModeEnabled, processDefinition, selectedNodeDefinition, confirmation, hasChange]
  );

  const { clearRendererSelection } = useWorkflowRendererState({
    onSelectionChanged,
    checkChangeSelectionAllowed,
  });

  const clearSelection = useCallback(() => {
    setSelectedNodeDefinition(undefined);
    setSelectedEdgeId(undefined);
    clearRendererSelection();
  }, [clearRendererSelection]);

  const onNodeChanged = useCallback(() => setHasChange(true), []);

  const selectEdge = useCallback(
    (id: string) =>
      // update edge in ReactFlow state so that reselection via editor is possible
      flowApi.setState((s) => {
        // edge may be split due to label thus start/end variants needs to be used also...
        const edgeId = [id, `${id}-start`, `${id}-end`].find((idVariant) => s.edgeLookup.has(idVariant));

        if (edgeId) {
          flow.setNodes((n) => n.map((node) => ({ ...node, selected: false })));
          flow.setEdges((e) => e.map((edge) => ({ ...edge, selected: false })));
          s.addSelectedEdges([edgeId]);
        }
        return s;
      }),
    [flowApi, flow]
  );

  return useMemo(
    () => ({
      selectNode: onSelectionChanged,
      selectedNodeDefinition,
      selectEdge,
      selectedEdgeId,
      clearSelection,
      activeGatewayLeavingEdges,
      isAdvancedWorkflowDefinitionEditModeEnabled,
      onNodeChanged,
    }),
    [
      activeGatewayLeavingEdges,
      selectedNodeDefinition,
      isAdvancedWorkflowDefinitionEditModeEnabled,
      onNodeChanged,
      onSelectionChanged,
      selectedEdgeId,
      clearSelection,
      selectEdge,
    ]
  );
};
