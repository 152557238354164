import { Stack, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system';
import FormSelectField2 from '../../../../../../forms/fields/FormSelectField2';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { GroupOption, Option, useAssignmentOptions } from '../assignmentOptions';
import { EditUserTaskFormData } from '../schema';

type AllowedFieldPaths = 'defaultAssignees' | `conditionalAssignees.${number}.assignees`;

type TaskAssigneeSelectProps = {
  id: string;
  name: AllowedFieldPaths;
  allowContractOwnerAssignment?: boolean;
  onAddUser: () => void;
  testId?: string;
  minAssignees?: number;
};

export const TaskAssigneeSelect: FC<TaskAssigneeSelectProps> = ({
  id,
  name,
  allowContractOwnerAssignment,
  onAddUser,
  testId,
  minAssignees,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });
  const { palette } = useTheme();

  const [sortAssignees, setSortAssignees] = useState(true);
  const currentAssignees = useWatch<EditUserTaskFormData, AllowedFieldPaths>({ name });
  const assignmentOptions = useAssignmentOptions(currentAssignees, sortAssignees, allowContractOwnerAssignment);

  return (
    <FormSelectField2<EditUserTaskFormData, Option, true, GroupOption>
      sx={{ flexGrow: 1 }}
      isMulti
      id={id}
      name={name}
      variant="outlined"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      placeholder={t('EDIT_USER_TASK.CONDITIONAL_ASSIGNEES.SELECT_ASSIGNEE')}
      size="S"
      maxMultiChips={1}
      options={assignmentOptions}
      filterOption={({ data }) => !data.disabled}
      onMenuOpen={() => setSortAssignees(false)}
      onMenuClose={() => setSortAssignees(true)}
      testId={testId}
      footer={
        <Stack paddingBottom={0.5} alignItems="flex-start" bgcolor={palette.background.default}>
          <Button variant="plain" size="S" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} onClick={onAddUser}>
            {t('EDIT_USER_TASK.ADD_USER')}
          </Button>
        </Stack>
      }
      unboxValue={(value) => value.map((val) => val.value)}
      boxValue={(value) =>
        assignmentOptions
          .map((v) => v.options)
          .flat()
          .filter((val) => (value as string[]).includes(val.value))
      }
      hideHelperText
      error={minAssignees ? currentAssignees.length < minAssignees : undefined}
    />
  );
};
