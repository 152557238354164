import { FieldDef } from '../types';
import {
  getDropdownJsonSchema,
  getDropdownJsonSchemaFromMetadata,
  getDropdownPresenterDefault,
  getDropdownPresenterSchema,
} from './utils';
import { DropdownFieldType } from './types';
import { DropdownFieldBuilderCard } from './DropdownFieldBuilderCard';
import { DropdownFieldPresenter } from './DropdownFieldPresenter';
import { DropdownFieldBuilderDialog } from './DropdownFieldBuilderDialog';

export type { DropdownFieldType, DropdownFieldMetadata } from './types';
export { dropdownFieldSchema, dropdownFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<DropdownFieldType> = {
  type: 'DROPDOWN',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.DROPDOWN',
  builderCard: DropdownFieldBuilderCard,
  builderDialog: DropdownFieldBuilderDialog,
  presenter: DropdownFieldPresenter,
  getPresenterDefault: getDropdownPresenterDefault,
  getPresenterSchema: getDropdownPresenterSchema,
  getJsonSchemaFromMetadata: getDropdownJsonSchemaFromMetadata,
  getJsonSchema: getDropdownJsonSchema,
};
