import React from 'react';
import { Stack } from '@mui/material';
import { EllipsisText } from '@verticeone/design-system';

import { GenericRequestIcon } from '../../../../components/icons/Icons';
import type { TaskRow } from '../../../dataSource';
import VendorLogo from '../../../../../../components/VendorLogo';

type TaskRequestNameProps = {
  taskRow: TaskRow;
};

export const TaskRequestName = ({ taskRow }: TaskRequestNameProps) => (
  <Stack direction="row" alignItems="center" gap={2} minWidth={0}>
    <Stack width={30} alignItems="center">
      {taskRow.input?.vendor?.type === 'PREDEFINED' ? (
        <VendorLogo vendorId={taskRow.input?.vendor?.id} />
      ) : (
        <GenericRequestIcon size="L" />
      )}
    </Stack>
    <EllipsisText variant="body-regular" size="S" title={taskRow.requestName}>
      {taskRow.requestName}
    </EllipsisText>
  </Stack>
);
