import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeepPartial, FieldPath, FieldPathByValue, FieldValues, useFormContext } from 'react-hook-form';
import {
  useOfferCostModelMutation,
  useOfferTotalCostMutation,
} from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { Offer, joinFormPath, offerSchema } from './predefinedForms/shared/schemas';
import { useTaskFormContext } from './predefinedForms/shared/TaskFormContext';

// Fields that trigger total cost computation
const TOTAL_COST_RELATED_FIELDS: Set<string | number> = new Set<FieldPath<Offer>>([
  'effectiveCosts',
  'overrideEffectiveCost',
]);

// Fields that trigger cost computation
const COST_MODEL_RELATED_FIELDS: Set<string | number> = new Set<FieldPath<Offer>>([
  'endDate',
  'startDate',
  'rollingFrequency',
  'products',
  'vendor',
  ...(TOTAL_COST_RELATED_FIELDS as Set<FieldPath<Offer>>),
]);

export const useOfferFormComputeCosts = <
  T extends FieldValues,
  P extends FieldPathByValue<T, Offer> = FieldPathByValue<T, Offer>
>(
  offerName: P
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isProcessing, setProcessing } = useTaskFormContext();
  const { setValue, watch } = useFormContext<T>();

  const [calculateOfferAnnualCost, { isLoading: isLoadingOfferAnnualCost }] = useOfferCostModelMutation();
  const [calculateTotalOfferCost, { isLoading: isLoadingTotalOfferCost }] = useOfferTotalCostMutation();
  const validateOffer = useIsOfferValid();

  const computeAllCosts = useCallback(
    async (form: DeepPartial<T>, fieldName: string) => {
      setProcessing?.(true);
      try {
        const offer = get(form, offerName);

        // Validate offer
        const { success, offers } = validateOffer(offer);
        if (!success) return;

        // Compute offer costs only if the changed field is not in TOTAL_COST_RELATED_FIELDS
        if (!TOTAL_COST_RELATED_FIELDS.has(fieldName)) {
          const annualCostModels = await calculateOfferAnnualCost({ body: offers });
          if ('data' in annualCostModels && annualCostModels.data.length > 0) {
            setValue(joinFormPath<T>(offerName, 'computedCosts'), annualCostModels.data[0].model.annual_cost as any, {
              shouldValidate: true,
            });
          }
        }

        // Compute total costs
        const totalCostModels = await calculateTotalOfferCost({ body: offers });
        if ('data' in totalCostModels && totalCostModels.data.length > 0) {
          setValue(joinFormPath<T>(offerName, 'totalCosts'), totalCostModels.data[0] as any, { shouldValidate: true });
        }
      } catch (error) {
        enqueueSnackbar(t('SNACKBAR.ERRORS.FAILED_TO_COMPUTE_COST'), {
          variant: 'error',
        });
      } finally {
        setProcessing?.(false);
      }
    },
    [
      offerName,
      setValue,
      calculateOfferAnnualCost,
      calculateTotalOfferCost,
      setProcessing,
      validateOffer,
      enqueueSnackbar,
      t,
    ]
  );

  const debouncedComputeAllCosts = useDebouncedCallback(computeAllCosts, 500, { maxWait: 1000 });

  useEffect(() => {
    const subscription = watch((form, { name }) => {
      const fieldName = name?.split('.').pop();
      if (!fieldName) return;

      if (COST_MODEL_RELATED_FIELDS.has(fieldName)) {
        void debouncedComputeAllCosts(form, fieldName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, debouncedComputeAllCosts]);

  return {
    isProcessing: isProcessing || isLoadingOfferAnnualCost || isLoadingTotalOfferCost,
  };
};

const useIsOfferValid = () => {
  return useCallback((offer: Offer | undefined) => {
    if (!offer) {
      return { success: false, offers: [] };
    }
    const { success, error } = offerSchema.safeParse(offer);

    return {
      success: success || !error?.errors.find(({ path }) => path.find((v) => COST_MODEL_RELATED_FIELDS.has(v))),
      offers: [offer],
    };
  }, []);
};
