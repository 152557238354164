import { FieldValues, useController } from 'react-hook-form';
import { CommonFormFieldProps } from '../../../../types';
import { DropdownValuesList } from './DropdownValuesList';
import { DesignSystemColor } from '@verticeone/design-system';

type DropdownValuesListFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  color: DesignSystemColor;
};

export type DropdownValuesListFieldComponentType<FormDataType extends FieldValues> = (
  props: DropdownValuesListFieldProps<FormDataType>
) => JSX.Element;

export const DropdownValuesListField = <FormDataType extends FieldValues = never>({
  name,
  required,
  color,
}: DropdownValuesListFieldProps<FormDataType>) => {
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController<FormDataType>({
    name,
    rules: { required },
  });

  return (
    <DropdownValuesList
      color={color}
      values={value}
      onValuesChange={onChange}
      errorText={invalid ? error?.message : undefined}
    />
  );
};
