import React, { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, IconWrapper, Text } from '@verticeone/design-system';
import OverviewStatBar from './components/OverviewStatBar/OverviewStatBar';
import { Refresh } from '@mui/icons-material';
import MyTasksCard from './components/Card/MyTasksCard/MyTasksCard';
import MyRequestsCard from './components/Card/MyRequestsCard/MyRequestsCard';
import OverdueRequestsCard from './components/Card/OverdueRequestsCard/OverdueRequestsCard';
import CompletedRequestsCard from './components/Card/CompletedRequestsCard/CompletedRequestsCard';

const Content = () => {
  const { t } = useTranslation();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    setIsRefreshing(true);
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  return (
    <Stack gap={8} p={10}>
      <Stack gap={1} direction="row" justifyContent="space-between">
        <Text variant="heading" size="M" tag="h1">
          {t('INTELLIGENT_WORKFLOWS.NAVBAR.GROUP_TITLE')}
        </Text>
        <Button
          disabled={isRefreshing}
          variant="outline"
          color="secondary"
          size="S"
          testId="refresh-data"
          onClick={handleRefresh}
        >
          <Stack
            sx={{
              animation: isRefreshing ? 'spin 0.75s linear infinite' : 'none',
              transformOrigin: 'center',
              '@keyframes spin': {
                from: {
                  transform: 'rotate(0deg)',
                },
                to: {
                  transform: 'rotate(360deg)',
                },
              },
            }}
          >
            <IconWrapper icon={Refresh} size="S" />
          </Stack>
          {t('INTELLIGENT_WORKFLOWS.DASHBOARD.ACTIONS.REFRESH_DATA')}
        </Button>
      </Stack>
      <Stack gap={4}>
        <Grid container rowSpacing={4} columnSpacing={4} alignItems="stretch">
          <Grid item sm={12}>
            <OverviewStatBar />
          </Grid>
          <Grid item sm={8}>
            <Stack gap={4}>
              <MyRequestsCard />
              <OverdueRequestsCard />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <MyTasksCard />
          </Grid>
          <Grid item sm={12}>
            <CompletedRequestsCard />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

const Dashboard = () => (
  // todo add wrapper
  // <LoadableAWSPageWrapper>
  <Content />
  // </LoadableAWSPageWrapper>
);

export default Dashboard;
