import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Wizard } from '@vertice/core/src/components/Wizard';
import SetUp from './steps/SetUp';
import IntegrationDetails from './steps/IntegrationDetails';
import { providers } from '../../utils/providers';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

export const AccountingSetupWizard = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation();
  const { providerId } = useParams();
  const backToIntegration = () => navigate(routes.PREFERENCES.INTEGRATIONS);
  useEffect(() => {
    if (providerId === undefined || !providers[providerId as keyof typeof providers]) {
      navigate(routes.PAGE_NOT_FOUND, undefined, { replace: true });
    }
  }, [navigate, providerId, routes]);

  const {
    mergeId,
    providerCode,
    translationId,
    icon: { big: icon },
  } = providers[providerId as keyof typeof providers];

  return (
    <Wizard
      initialStep="setup"
      onClose={backToIntegration}
      closeButtonText={t('PREFERENCES.INTEGRATIONS.CANCEL_SETUP')}
    >
      <SetUp stepId="setup" icon={icon} translationId={translationId} />
      <IntegrationDetails
        stepId="integration_details"
        translationId={translationId}
        mergeIntegrationId={mergeId}
        providerCode={providerCode}
        onSuccess={backToIntegration}
      />
    </Wizard>
  );
};
