import { z, ZodObject, ZodRawShape } from 'zod';
import { DropdownFieldMetadata, DropdownFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/types';

export function getDropdownPresenterSchema(field: DropdownFieldType): ZodObject<ZodRawShape> {
  const enumValue = z.custom<string>((val) => field.metadata.values?.includes(val) ?? false);

  return z.object({
    [field.name]: field.metadata.required ? enumValue : enumValue.nullish(),
  });
}

export function getDropdownPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<DropdownFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getDropdownJsonSchemaFromMetadata(metadata: DropdownFieldMetadata): JsonSchema {
  return metadata.required
    ? { type: ['string'], enum: metadata.values ?? [], title: metadata.label }
    : { type: ['string', 'null'], enum: [...(metadata.values ?? []), null], title: metadata.label };
}

export function getDropdownJsonSchema(field: DropdownFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getDropdownJsonSchemaFromMetadata(field.metadata) },
  };
}
