import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { AWSSetupCard } from './components';
import { CardsList } from '../components/CardsList';
import { CardWrapper } from '../components/CardWrapper';

export const CloudCostOptimization = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap="24px">
      <Text variant="heading" size="S">
        {t('PREFERENCES.INTEGRATIONS.CLOUD_COST_OPTIMIZATION')}
      </Text>
      <CardsList>
        <CardWrapper>
          <AWSSetupCard />
        </CardWrapper>
      </CardsList>
    </Stack>
  );
};
