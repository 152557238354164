import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SlackIconBW } from '@vertice/assets';
import { Button, Card, CardHeader, CardHeaderDescription, CardHeaderTitle, Text } from '@verticeone/design-system';
import { Step, WizardV2StepProps } from '../../../common';
import { useActivateSlackIntegration } from '../../hooks/useActivateSlackIntegration';
import { StepContainer } from '../StepContainer';

type InitialStepProps = {
  setStep: (step: Step) => void;
};

export const InitialStep: FC<InitialStepProps & WizardV2StepProps> = ({ setStep }) => {
  const { t } = useTranslation();

  const { activateSlackIntegration, isLoadingActivateSlackIntegration, isConnectionInitiated } =
    useActivateSlackIntegration(setStep);

  return (
    <StepContainer>
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle
            icon={SlackIconBW}
            text={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_TITLE')}
          />
          <CardHeaderDescription>
            <Text variant="body-regular" size="S" color="text2">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_HEADER_DESCRIPTION')}
            </Text>
          </CardHeaderDescription>
        </CardHeader>
        <Stack gap={4} p={6}>
          <Stack gap={1}>
            <Text variant="caption" size="XS" color="text2">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TITLE')}
            </Text>
            <Text variant="body-regular" size="M" color="text2">
              {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TEXT')}
            </Text>
          </Stack>
          <Button
            variant="solid"
            size="M"
            color="primary"
            onClick={activateSlackIntegration}
            isLoading={isLoadingActivateSlackIntegration || isConnectionInitiated}
            sx={{ alignSelf: 'flex-start' }}
          >
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CONNECT_BUTTON')}
          </Button>
        </Stack>
      </Card>
    </StepContainer>
  );
};
