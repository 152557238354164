import React, { useMemo } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Text, TextField, TextFieldCaption } from '@verticeone/design-system';
import FormControl from './FormControl';
import { Control, UseFormRegister } from 'react-hook-form';

type TestConnectionFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  protocolPath: FieldPath<T>;
  hostnamePath: FieldPath<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
};

const TestConnectionField = <T extends FieldValues>({
  name,
  protocolPath,
  hostnamePath,
  register,
  control,
}: TestConnectionFieldProps<T>) => {
  const [protocol, _hostname, path] = useWatch({ control, name: [protocolPath, hostnamePath, name] });
  const hostname = useMemo(
    () =>
      [
        _hostname,
        !!_hostname.length && !_hostname.endsWith('/') && !!path.length && !path.startsWith('?') ? '/' : '',
      ].join(''),
    [_hostname, path]
  );

  return (
    <FormControl>
      <Stack>
        <TextFieldCaption label={'Test Connection URL'} htmlFor="test-connection" size="XS" />
        <Text variant="body-regular" size="XS" color="text3">
          {'The base URL for all requests sent to this integration'}
        </Text>
      </Stack>
      <Stack gap={2} direction="row" alignItems="center">
        <TextField
          size="S"
          id="test-connection"
          fullWidth
          {...register(name)}
          startAdornment={
            <Stack pr={2} direction="row">
              <Stack py={1.5} borderRight="1px solid" borderColor="core.color3">
                <Text variant="body-regular" size="S" color="inactive1" height="100%" pr={3.5}>
                  {protocol}://
                </Text>
              </Stack>
              <Stack
                py={1.5}
                px={3.5}
                borderRight="1px solid"
                borderColor="core.color3"
                bgcolor="inactive.color4"
                minWidth={150}
              >
                <Text variant="body-regular" size="S" color="inactive1" height="100%" pr={3.5}>
                  {hostname}
                </Text>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </FormControl>
  );
};

export default TestConnectionField;
