import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, testProps } from '@verticeone/design-system';
import { useFilterParam, filterItems, type FilterStatus } from '../dataSource/queryParams';

type FilterProps = Record<FilterStatus, number>;

const Filter = (props: FilterProps) => {
  const { t } = useTranslation();
  const [statusValue, setStatusValue] = useFilterParam();

  return (
    <Stack direction="row" gap={2}>
      {filterItems.map((item) => (
        <Button
          key={item}
          variant={statusValue === item ? 'solid' : 'ghost'}
          size="S"
          color="secondary"
          onClick={() => setStatusValue(item)}
          {...testProps(item, 'filter_button')}
        >
          <Stack data-testid="value">{t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.FILTER.${item}`)}</Stack>
          <Stack data-testid="count" sx={{ opacity: 0.4 }}>
            {props[item]}
          </Stack>
        </Button>
      ))}
    </Stack>
  );
};

export default Filter;
