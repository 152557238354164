import { z } from 'zod';

export const longTextFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const longTextFieldSchema = z.object({
  name: z.string(),
  type: z.literal('LONG_TEXT'),
  metadata: longTextFieldMetadataSchema,
});

export type LongTextFieldType = z.infer<typeof longTextFieldSchema>;

export type LongTextFieldMetadata = z.infer<typeof longTextFieldMetadataSchema>;
