import { WorkflowNode } from '../../model/types';
import { assertExhausted } from '@verticeone/utils/logic';

/*
 Node dimensions used for the auto-layouting,
 They don't need to match the real dimensions of the node components
 */
export const getNodeDimensions = (node: WorkflowNode): { width: number; height: number } => {
  const { kind } = node;
  switch (kind) {
    case 'start':
    case 'end':
      return { width: 80, height: 24 };
    case 'task':
      return { width: 256, height: 160 };
    case 'label':
      return {
        width: node.width,
        height: 24,
      };
    case 'gateway':
      return { width: 40, height: 40 };
    default:
      assertExhausted(kind);
      throw new Error('Unknown node kind');
  }
};
