import { useEffect } from 'react';
import { useWorkflowValidationContext } from '../../WorkflowEditor/Validation/WorkflowValidationContext';

export const ValidationCheck = ({
  setValidationResult,
  id,
}: {
  setValidationResult: (message: 'error' | 'warning' | null) => void;
  id: string;
}) => {
  const { errors, warnings } = useWorkflowValidationContext();

  useEffect(() => {
    if (!errors) return;

    const error = errors.find((err) => err.detail?.activityIds?.includes(id));
    setValidationResult(error ? 'error' : null);
  }, [errors, id, setValidationResult]);

  useEffect(() => {
    if (!warnings) return;

    const warning = warnings.find((warn) => warn.detail?.activityIds?.includes(id));
    setValidationResult(warning ? 'warning' : null);
  }, [warnings, id, setValidationResult]);

  return null;
};
