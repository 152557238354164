import React from 'react';
import { DesignSystemColor, DesignSystemSize, FormControlLabel, RadioSwitch } from '@verticeone/design-system';
import RadioSwitchGroup from '@mui/material/RadioGroup';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Controller, useFormContext } from 'react-hook-form';
import { identity } from 'lodash';

export type FormRadioGroupFieldType<FormDataType extends FieldValues, Option extends unknown = unknown> = (
  props: FormRadioGroupFieldProps<FormDataType, Option>
) => JSX.Element;

type FormRadioGroupFieldProps<
  FormDataType extends FieldValues,
  Option extends unknown = unknown
> = CommonFormFieldProps<FormDataType> & {
  color?: DesignSystemColor;
  size?: DesignSystemSize;
  options: Option[];
  getLabel?: (option: Option) => string;
  getValue?: (option: Option) => string;
};

export const FormRadioGroupField = <FormDataType extends FieldValues = never, Option extends unknown = unknown>({
  id,
  name,
  required,
  disabled,
  color,
  size,
  options,
  getLabel = identity,
  getValue = identity,
}: FormRadioGroupFieldProps<FormDataType, Option>) => {
  const { control } = useFormContext<FormDataType>();
  const radioSwitchProps = {
    color: color,
    size: size,
    disabled: disabled,
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value, ref } }) => (
        <RadioSwitchGroup id={id} ref={ref} value={value} onChange={onChange}>
          {options.map((option: Option) => (
            <FormControlLabel
              // The maxWidth changes the clickable area of the radiobutton to be
              // only the radiobutton and label instead of the entire row.
              sx={{ maxWidth: 'max-content', gap: 0 }}
              key={getValue(option)}
              label={getLabel(option)}
              value={getValue(option)}
              control={<RadioSwitch {...radioSwitchProps} />}
            />
          ))}
        </RadioSwitchGroup>
      )}
    />
  );
};
