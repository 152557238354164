import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Divider, Grid, IconWrapper, Text } from '@verticeone/design-system';
import { ArrowCircleDownOutlined } from '@mui/icons-material';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { differenceInDays } from 'date-fns';
import { useMyRequestsData, type Task as TypeType } from './useMyRequestsData';

const Task = ({ title, vendorId, date }: TypeType) => {
  const daysDifference = differenceInDays(new Date(date), new Date());
  const absDaysDifference = Math.abs(daysDifference);
  const isOverdue = daysDifference < 0;
  const daysText = isOverdue ? `${absDaysDifference} days overdue` : `${daysDifference} days left`;

  return (
    <Stack gap={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={1} alignItems="center">
          <VendorLogo vendorId={vendorId ?? undefined} size={28} />
          <Text variant="body-bold" size="S">
            {title}
          </Text>
        </Stack>
        <Text variant="body-regular" size="S" color={absDaysDifference <= 3 || isOverdue ? 'error1' : 'text1'}>
          {daysText}
        </Text>
      </Stack>
    </Stack>
  );
};

const MyRequestsCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.MY_REQUESTS' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const { data } = useMyRequestsData();
  const formatNumber = (value: number) =>
    new Intl.NumberFormat(locale, {
      maximumFractionDigits: 0,
      maximumSignificantDigits: 2,
      currency: 'USD',
      style: 'currency',
      notation: 'compact',
    }).format(value);

  return (
    <Card testId="my-tasks-card">
      <Stack p={6} gap={4}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item sm={4}>
            <Stack gap={4}>
              <Text variant="heading" size="S">
                {t('TITLE')}
              </Text>
              <Stack direction="row" gap={4} alignItems="center">
                <Text variant="display" size="S" color="success1">
                  {data.openRequests}
                </Text>
                <IconWrapper icon={ArrowCircleDownOutlined} size="XL" htmlColor={palette.success.color1} />
              </Stack>
              <Text variant="body-regular">
                <Trans
                  i18nKey="INTELLIGENT_WORKFLOWS.DASHBOARD.MY_REQUESTS.DESCRIPTION"
                  components={{
                    highlight: <Text variant="body-regular" color="error1" />,
                  }}
                  values={{
                    spend: formatNumber(data.spend),
                  }}
                />
              </Text>
            </Stack>
          </Grid>
          <Grid item sm={8}>
            <Stack gap={4}>
              <Text variant="heading" size="S">
                {t('REVIEW_OPEN_REQUESTS')}
              </Text>
              <Stack gap={2}>
                {data.tasks.map((task, index) => (
                  <Stack gap={2}>
                    <Task key={task.id} {...task} />
                    {index < data.tasks.length - 1 && <Divider />}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

export default MyRequestsCard;
