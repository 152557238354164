import { TagAssignment, useLazyListTagAssignmentsPaginatedQuery } from '@vertice/slices';
import { getUserRef } from '../modules/saas/contract/utils/refUtils';
import {
  AllPowerUsersApprovalConfig,
  SingleApproverApprovalConfig,
  ThresholdBasedApprovalConfig,
  useLazyGetAccountConfigQuery,
} from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useAccountContext } from '../contexts/AccountContext';
import { isContractOwnerTagAssignment, isOwnersDepartmentTagAssignment } from '../modules/saas/contract/utils/tagUtils';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useContracts } from '../modules/saas/contract/components/ContractList/useContracts';

type UserAssignments = {
  departmentOwner: boolean;
  contractOwner: boolean;
  contractApprover: boolean;
};

export type UseUserAssignmentsReturn = {
  getUserAssignments: (userId: string) => Promise<UserAssignments>;
};

type ApprovalConfiguration = AllPowerUsersApprovalConfig | SingleApproverApprovalConfig | ThresholdBasedApprovalConfig;

const isUserApprover = (userId: string, approvalConfiguration?: ApprovalConfiguration) => {
  if (!approvalConfiguration) {
    return false;
  }

  if (approvalConfiguration.type === 'SingleApproverApprovalConfig') {
    return approvalConfiguration.approver === userId;
  }

  if (approvalConfiguration.type === 'ThresholdBasedApprovalConfig') {
    return approvalConfiguration.approvers.some((approver) => approver.userId === userId);
  }

  return false;
};

const isUserContractOwner = (tagAssignments?: TagAssignment[], contracts?: ViewOnContract[]) => {
  if (!tagAssignments) {
    return false;
  }

  const contractAssignments = tagAssignments.filter((tagAssignment) => isContractOwnerTagAssignment(tagAssignment));

  if (contractAssignments.length > 0) {
    return contractAssignments.some((assignment) =>
      contracts?.some(
        (contract) => contract.contract.record.contractId === assignment.tagId.split('iam_asoc_contract_')[1]
      )
    );
  } else {
    return false;
  }
};

const isUserDepartmentOwner = (tagAssignments?: TagAssignment[]) => {
  if (!tagAssignments) {
    return false;
  }

  return tagAssignments.some((tagAssignment) => {
    return isOwnersDepartmentTagAssignment(tagAssignment);
  });
};

const useUserAssignments = (): UseUserAssignmentsReturn => {
  const { accountId } = useAccountContext();
  const [getAccountConfig] = useLazyGetAccountConfigQuery();
  const [listTagAssignments] = useLazyListTagAssignmentsPaginatedQuery();
  const { allViewsOnContracts: contracts } = useContracts({});

  const getUserAssignments = async (userId: string) => {
    const accountConfigQuery = getAccountConfig({ accountId });
    const userTagAssignmentsQuery = listTagAssignments({
      accountId: accountId,
      ref: getUserRef(userId),
    });

    const [accountConfig, userTagAssignments] = await Promise.all([accountConfigQuery, userTagAssignmentsQuery]);

    return {
      contractOwner: isUserContractOwner(userTagAssignments.data?.tagAssignments, contracts),
      departmentOwner: isUserDepartmentOwner(userTagAssignments.data?.tagAssignments),
      contractApprover: isUserApprover(userId, accountConfig.data?.config?.approvalConfiguration),
    };
  };

  return {
    getUserAssignments,
  };
};

export default useUserAssignments;
