import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { currencies } from '@verticeone/utils/currency';
import { Select, SimpleOption } from '@verticeone/design-system';

import { NumberField } from '../NumberField';
import { ConditionalTooltip } from '../ConditionalTooltip';
import { parseMoneyExchangeUdfCall, createMoneyExchangeUdfCall } from './utils';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { useTranslation } from 'react-i18next';

type MoneyFieldProps = {
  onChange: (value: string) => void;
  value: string;
  testId?: string;
  defaultTargetCurrency?: string;
  isDisabled?: boolean;
  hasError?: boolean;
};

export const MoneyField: FC<MoneyFieldProps> = ({
  onChange,
  testId,
  value,
  defaultTargetCurrency = 'USD',
  hasError,
  isDisabled,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES',
  });
  const {
    amount,
    sourceCurrency: defaultSourceCurrency,
    targetCurrency,
  } = parseMoneyExchangeUdfCall({
    targetCurrency: defaultTargetCurrency,
    udfCall: value,
  });

  const [sourceCurrency, setSourceCurrency] = useState(defaultSourceCurrency);

  const currencyOptions = currencies.map<SimpleOption>((currencyCode) => ({
    value: currencyCode,
    label: currencyCode,
  }));
  const selectedCurrencyOption = currencyOptions.find((option) => option.value === sourceCurrency);

  const handleCurrencyChange = (newCurrency: string) => {
    setSourceCurrency(newCurrency);
    onChange(createMoneyExchangeUdfCall({ amount, sourceCurrency: newCurrency, targetCurrency }));
  };
  const handleAmountChange = (newAmount: string | number) => {
    if (typeof newAmount === 'string' && newAmount === '') {
      onChange('');
      return;
    }

    onChange(createMoneyExchangeUdfCall({ amount: newAmount.toString(), sourceCurrency, targetCurrency }));
  };

  return (
    <Grid container columnGap={2}>
      <Grid item xs>
        <NumberField
          disabled={isDisabled}
          error={hasError}
          testId={`${testId}-amount`}
          value={amount}
          onChange={handleAmountChange}
          placeholder={t('INPUT_VALUE')}
        />
      </Grid>
      <Grid item xs={4}>
        <ConditionalTooltip value={selectedCurrencyOption?.label ?? ''} offset={48}>
          <Select<SimpleOption, false>
            variant="outlined"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            value={selectedCurrencyOption ?? null}
            onChange={(newValue) => handleCurrencyChange(newValue?.value ?? '')}
            options={currencyOptions}
            testId={`${testId}-select`}
            placeholder={t('CHOOSE_VALUE')}
            isDisabled={isDisabled}
          />
        </ConditionalTooltip>
      </Grid>
    </Grid>
  );
};
