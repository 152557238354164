import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, Tooltip } from '@verticeone/design-system';

import type { TaskRow } from '../../../dataSource';

type TaskAssigneesProps = {
  taskRow: TaskRow;
};

type AssignedAvatarProps = {
  assignees: Array<string>;
};

export const AssignedUsers = ({ assignees }: AssignedAvatarProps) => {
  const { t } = useTranslation();
  const [firstUser, ...otherUsers] = assignees.sort((a, b) => a.localeCompare(b));

  return (
    <Stack direction="row" gap={1.5}>
      {firstUser}
      {!!otherUsers.length && (
        <Tooltip
          title={`${assignees.length} ${t('INTELLIGENT_WORKFLOWS.TASKS_LIST.ASSIGNEE_COLUMN')}`}
          content={assignees.map((user) => (
            <Stack key={user}>{user}</Stack>
          ))}
          size="S"
        >
          <Text variant="body-regular" size="S" color="text3" sx={{ cursor: 'pointer' }}>
            +{otherUsers.length}
          </Text>
        </Tooltip>
      )}
    </Stack>
  );
};

export const TaskAssignees = ({ taskRow }: TaskAssigneesProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.WORKFLOW_TASK.LABELS' });
  const validAssignees = taskRow.assignees.filter((assignee) => assignee.valid);
  const hasOnlyInvalidAssignees = validAssignees.length === 0 && taskRow.assignees.length > 0;

  return (
    <Text variant="body-regular" size="S" color={!!validAssignees.length ? 'text1' : 'error'}>
      {!!validAssignees.length ? (
        <AssignedUsers assignees={validAssignees.map(({ fullName }) => fullName)} />
      ) : (
        t(hasOnlyInvalidAssignees ? 'INVALID_ASSIGNEE' : 'NO_ASSIGNEE')
      )}
    </Text>
  );
};
