import { useDescribeCatalogResourceCamelQuery } from '@vertice/slices/src/api/enhancedCatalogAPI';
import { FC, useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { Loader } from '@verticeone/design-system';
import { TaskCustomForm } from './TaskCustomForm';
import { useDynamicFormPresenterConfig } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/hooks/useDynamicFormPresenterConfig';
import { parseFormUrn } from '../../../workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm';
import { isFormCatalogResource } from '../../../utils/catalogResourceUtils';
import { useFilePathPrefix } from './useFilePathPrefix';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useDraftUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useAccountContext } from '../../../../../contexts/AccountContext';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { TaskCustomFormDraft } from './types';
import { isTaskCustomFormDraft } from './utils';

const emptyFormSchema = { fields: [] };

export type TaskCustomFormSectionProps = {
  formUrn: string;
  taskOverview: TaskOverview;
  onSubmit: (values: FieldValues) => Promise<void>;
};

export const TaskCustomFormSection: FC<TaskCustomFormSectionProps> = (props) => {
  const { formUrn, onSubmit, taskOverview } = props;

  const { t } = useTranslation();
  const filePathPrefix = useFilePathPrefix();
  const { accountId } = useAccountContext();
  const { accountId: formUrnAccountId, formPath } = parseFormUrn(formUrn);
  const [updateDraftUserTask] = useDraftUserTaskMutation();
  const formConfigQuery = useDynamicFormPresenterConfig('readWrite', filePathPrefix);

  const { data: catalogResource, isLoading: isCatalogResourceLoading } = useDescribeCatalogResourceCamelQuery({
    accountId: formUrnAccountId ?? 'GLOBAL',
    resourceId: encodeURIComponent(formPath),
  });

  const draftData = useMemo<TaskCustomFormDraft | undefined>(() => {
    const draft = taskOverview?.task?.draft;
    if (!draft || !isTaskCustomFormDraft(draft)) return undefined;
    return draft;
  }, [taskOverview]);

  const formSchema = useMemo(() => {
    if (isFormCatalogResource(catalogResource?.resource?.definition)) {
      return catalogResource?.resource?.definition?.form?.formUiModel?.layout ?? emptyFormSchema;
    }
    return null;
  }, [catalogResource]);

  const handleDraftSubmit = useCallback(
    async (formValues: FieldValues) => {
      if (!taskOverview.task.taskId) return;

      try {
        const body: TaskCustomFormDraft = { formValues };
        await updateDraftUserTask({ accountId, taskId: taskOverview.task.taskId, body });
      } catch (e) {
        enqueueSnackbar(t('PREFERENCES.USER.SNACKBAR.ERROR'), { variant: 'error' });
      }
    },
    [accountId, t, taskOverview, updateDraftUserTask]
  );

  if (isCatalogResourceLoading || formConfigQuery.isLoading) return <Loader />;

  if (!formSchema) return <></>;

  return (
    <TaskCustomForm
      formSchema={formSchema}
      defaultValues={draftData?.formValues}
      config={formConfigQuery.data}
      onSubmit={onSubmit}
      onDraftSubmit={handleDraftSubmit}
    />
  );
};
