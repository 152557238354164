import { z } from 'zod';

export const moneyFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const moneyFieldSchema = z.object({
  name: z.string(),
  type: z.literal('MONEY'),
  metadata: moneyFieldMetadataSchema,
});

export type MoneyFieldType = z.infer<typeof moneyFieldSchema>;

export type MoneyFieldMetadata = z.infer<typeof moneyFieldMetadataSchema>;
