import { DataGrid } from '@verticeone/design-system';
import { useCallback, useMemo } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowOrderChangeParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useEditableDataGrid } from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';
import { EditableDataGridContextProvider } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import { ColumnSelectCell } from './ColumnSelectCell';
import { useContractColumns } from '../../../columns';
import { useTranslation } from 'react-i18next';
import { ContractListColumn } from '../../../../../types';
import { keyBy } from 'lodash';
import { moveArrayItem } from '@verticeone/utils/arrays';

export type ContractColumnsListProps = { value: string[]; onChange: (value: string[]) => void };

type ColumnsListRow = {
  id: string;
  columnName: string;
  __reorder__: string;
};

const MAX_COLUMNS_COUNT = 10;

export const ContractColumnsList = ({ value, onChange }: ContractColumnsListProps) => {
  const apiRef = useGridApiRef();

  const handleUpdateRow = useCallback(
    (row: ColumnsListRow) => onChange(value.map((r) => (r === row.id ? row.columnName : r))),
    [onChange, value]
  );
  const handleAddRow = useCallback((row: ColumnsListRow) => onChange([...value, row.columnName]), [onChange, value]);
  const handleDeleteRow = useCallback(
    (id: GridRowId) => onChange([...value.filter((v) => v !== id)]),
    [onChange, value]
  );
  const handleRowOrderChange = useCallback(
    ({ oldIndex, targetIndex }: GridRowOrderChangeParams) => onChange(moveArrayItem(value, oldIndex, targetIndex)),
    [onChange, value]
  );

  const { t } = useTranslation();

  const contractColumns = useContractColumns();
  const contractColumnsById = useMemo(() => keyBy(contractColumns, 'field'), [contractColumns]);

  const editableDataGrid = useEditableDataGrid<ColumnsListRow>({
    apiRef,
    defaultMode: 'confirm',
    isRowEditable: (row) => row.id !== ContractListColumn.VENDOR,
    onAddRow: handleAddRow,
    onUpdateRow: handleUpdateRow,
    onDeleteRow: handleDeleteRow,

    // Add-button-specific
    withAddButton: value.length < MAX_COLUMNS_COUNT,
    createTmpAddRow: (id) => ({ id, columnName: undefined }),
    addItemButtonLabel: t('ENTITIES.CONTRACT_CUSTOM_VIEW.ACTIONS.ADD_COLUMN'),
  });

  const rows = useMemo(
    () => [
      ...value.map(
        (v): ColumnsListRow => ({
          id: v,
          columnName: v,
          // Text displayed when dragging
          __reorder__: contractColumnsById[v]?.headerName ?? v,
        })
      ),
      ...(editableDataGrid.tmpAddRow ? [editableDataGrid.tmpAddRow] : []),
    ],
    [contractColumnsById, editableDataGrid.tmpAddRow, value]
  );
  const columns: GridColDef[] = [
    {
      field: 'columnName',
      headerName: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.COLUMN_NAME'),
      editable: true,
      renderCell: ({ value: v }) => contractColumnsById[v]?.headerName,
      renderEditCell: (params: GridRenderCellParams) => <ColumnSelectCell {...params} allSelectedColumns={value} />,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    ...(editableDataGrid.actionsColumn ? [editableDataGrid.actionsColumn] : []),
  ];

  return (
    <EditableDataGridContextProvider value={editableDataGrid.context}>
      <DataGrid
        {...editableDataGrid.dataGridProps}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        slots={editableDataGrid.dataGridProps.slots}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        disableColumnReorder={true}
      />
    </EditableDataGridContextProvider>
  );
};
