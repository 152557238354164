import { FC } from 'react';
import { DynamicFormPresenterConfig, DynamicFormSchema } from '@vertice/core/src/modules/forms/dynamicForms';
import { FieldValues, SubmitHandler, FormProvider } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Divider, Button } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { mapFieldToComponent } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/DynamicFormPresenter';
import { Tooltip } from '@verticeone/design-system';
import { useDynamicFormPresenterForm } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/hooks/useDynamicFormPresenterForm';
import { useCustomFormDraft } from './hooks/useCustomFormDraft';

export type TaskCustomFormProps = {
  formSchema: DynamicFormSchema;
  config: DynamicFormPresenterConfig;
  defaultValues?: FieldValues;
  onSubmit: (values: FieldValues) => Promise<void>;
  onDraftSubmit: (values: FieldValues) => Promise<void>;
};

export const TaskCustomForm: FC<TaskCustomFormProps> = (props) => {
  const { formSchema, config, defaultValues, onSubmit, onDraftSubmit } = props;
  const { t } = useTranslation();

  const form = useDynamicFormPresenterForm({ formSchema, config, defaultValues });
  const { formState, handleSubmit, control } = form;

  useCustomFormDraft({ control, onDraftSubmit });

  const submitHandler: SubmitHandler<FieldValues> = async (values) => {
    await onSubmit(values);
  };

  return (
    <FormProvider {...form}>
      <Stack component="form" onSubmit={handleSubmit(submitHandler)} width="100%" height="100%">
        <Stack padding={6} overflow="auto" minHeight={0} height="100%">
          {formSchema.fields.map(mapFieldToComponent(config))}
        </Stack>
        <Divider />

        <Stack direction="row" padding={6}>
          <Tooltip
            title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TOOLTIP')}
            size="S"
            disableHoverListener={formState.isSubmitting || formState.isValid}
          >
            <Stack width="100%">
              <Button
                type="submit"
                variant="solid"
                size="S"
                color="secondary"
                disabled={formState.isSubmitting || !formState.isValid}
                isLoading={formState.isSubmitting}
              >
                {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TASK')}
              </Button>
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
