import {
  Definitions,
  ProcessDefinition,
  TaskDefinition,
  GatewayDefinition,
  FlowEdgeDefinition,
} from '../definitionsTypes';
import { isProcessDefinition } from '../pocWorkflowSchema';

type NodeDefinition = TaskDefinition | GatewayDefinition;

export const isTaskDefinition = (node?: NodeDefinition): node is TaskDefinition => node?.kind === 'ProcessEngine:Task';
export const isGatewayDefinition = (node?: NodeDefinition): node is GatewayDefinition =>
  node?.kind === 'ProcessEngine:Gateway';

export const updateNodeInProcessDefinition = (definitions: Definitions, node: NodeDefinition): Definitions => {
  const processDefinition = definitions.definitions.find(isProcessDefinition);

  if (!processDefinition) {
    throw new Error('No process definition found');
  }

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...processDefinition.process,
      ...(isTaskDefinition(node)
        ? {
            tasks: (processDefinition.process.tasks ?? []).map((t) => {
              if (t.task.id === node.task.id) {
                return node;
              }

              return t;
            }),
          }
        : {}),
      ...(isGatewayDefinition(node)
        ? {
            gateways: (processDefinition.process.gateways ?? []).map((g) => {
              if (g.gateway.id === node.gateway.id) {
                return node;
              }

              return g;
            }),
          }
        : {}),
    },
  };

  return {
    ...definitions,
    definitions: [...definitions.definitions.filter((d) => !isProcessDefinition(d)), newProcessDefinition],
  };
};

export const updateEdgesInProcessDefinition = (definitions: Definitions, edges: FlowEdgeDefinition[]): Definitions => {
  const processDefinition = definitions.definitions.find(isProcessDefinition);

  if (!processDefinition) {
    throw new Error('No process definition found');
  }

  const originalEdges = processDefinition.process.flow?.flow.edges ?? [];
  const updatedEdges = originalEdges.map((originalEdge) => {
    const updatedEdge = edges.find((e) => e.edge.id === originalEdge.edge.id);

    return updatedEdge ?? originalEdge;
  });

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...processDefinition.process,
      ...(processDefinition.process.flow
        ? { flow: { ...processDefinition.process.flow, flow: { edges: updatedEdges } } }
        : {}),
    },
  };

  return {
    ...definitions,
    definitions: [...definitions.definitions.filter((d) => !isProcessDefinition(d)), newProcessDefinition],
  };
};
