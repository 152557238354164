import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid, Theme, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { FieldArrayPath, FieldValues, Path, useController } from 'react-hook-form';

import { IconButton, IconWrapper } from '@verticeone/design-system';
import { TaskDefinition } from '../../../../../../definitionsTypes';
import { VariableToken } from '../../../../EditServiceTaskDrawer/JiraCreate/VariableToken';
import { WorkflowRichSelector } from '../../../../forms/EditGatewayForm/WorkflowRichSelector';
import { WorkflowVariableSelector } from '../../../../forms/EditGatewayForm/WorkflowVariableSelector';
import { Variable, VariableOrigin } from '../../../../types';
import { findVariableById } from '../../../../VariableSelector/utils';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../../utils';
import { WorkflowVariables } from '../types';
import { getVariableXType } from '../utils';
import { VariableMapperRowFormData } from './formSchema';
import { JiraVariableSelector } from './variableSelectors/JiraVariableSelector';

type VariableMapperRowProps<T extends FieldValues> = {
  index: number;
  field: VariableMapperRowFormData;
  formFieldPrefix: FieldArrayPath<T>;
  onRemove: (index: number) => void;
  canRemove: boolean;
  fromWorkflow: boolean; // To distinguish the direction of mapping
  workflowVariables: WorkflowVariables;
  setWorkflowVariables?: (variables: WorkflowVariables) => void;
  jiraVariables: Variable[];
  selectedJiraVariables?: string[];
  task?: TaskDefinition;
  allowCreateVariable: boolean;
};

// TODO: Add unit test for this helper function
const parseFromRichSelectorValue = (value: string) => {
  return `\`"${value
    .replace(/<span class="[^"]*?variable-id[^"]*?">(.*?)<\/span>/g, '$1')
    .replace(/<span class="[^"]*?variable-label[^"]*?"(.*?)>(.*?)<\/span><\/span>/g, '')
    .replace(/<\/div>/g, '')
    .replace(/<div>/g, '\n')
    .replace(/<\/div>/g, '')
    .replace(/<br>/g, '\n')
    .replace(/&nbsp;/g, ' ')
    .trim()}"\``;
};

// TODO: Add unit test for this helper function
const parseToRichSelectorValue = (variables: Variable[], value: string, theme: Theme) => {
  return value
    .replaceAll(/{{(.*?)}}/g, function (_$0: string, $1: string) {
      const variable = findVariableById(variables, $1);

      return variable ? renderToString(<VariableToken theme={theme} variable={variable} />) : '';
    })
    .replace(/`"/g, '')
    .replace(/"`/g, '')
    .replace(/\n/g, '<br>');
};

export const VariableMapperRow = <T extends FieldValues>({
  formFieldPrefix,
  index,
  onRemove,
  canRemove,
  fromWorkflow,
  workflowVariables,
  setWorkflowVariables,
  jiraVariables,
  selectedJiraVariables,
  task,
  allowCreateVariable,
}: VariableMapperRowProps<T>) => {
  const theme = useTheme();
  const { field: workflowField } = useController({
    name: `${formFieldPrefix}.${index}.${fromWorkflow ? 'from' : 'to'}` as Path<T>,
  });
  const { field: jiraField } = useController({
    name: `${formFieldPrefix}.${index}.${fromWorkflow ? 'to' : 'from'}` as Path<T>,
  });

  const [selectedWFVariable, setSelectedWFVariable] = useState<Variable | undefined>(
    findVariableById(
      [...workflowVariables.request, ...(fromWorkflow ? workflowVariables.udfs : [])],
      workflowField.value
    ) ?? undefined
  );
  const [richSelectorValue, setRichSelectorValue] = useState<string>(workflowField.value ?? '');
  const [selectedJiraVariable, setSelectedJiraVariable] = useState<Variable | undefined>(
    findVariableById(jiraVariables, jiraField.value) ?? undefined
  );

  useEffect(() => {
    setRichSelectorValue(
      parseToRichSelectorValue(
        [...workflowVariables.request, ...(fromWorkflow ? workflowVariables.udfs : [])],
        workflowField.value,
        theme
      )
    );
  }, [fromWorkflow, theme, workflowField.value, workflowVariables.request, workflowVariables.udfs]);

  const handleWFVariableChange = (variable: Variable) => {
    setSelectedWFVariable(variable);
    workflowField.onChange(variable.id);
  };

  const handleRichSelectorValueChange = (newValue: string) => {
    setRichSelectorValue(newValue);
    workflowField.onChange(parseFromRichSelectorValue(newValue));
  };

  const handleJiraVariableChange = (variable?: Variable) => {
    setSelectedJiraVariable(variable);
    jiraField.onChange(variable?.id);
  };

  const allowCreateVariableProps = allowCreateVariable
    ? {
        addVariableDefaults: {
          type: {
            baseType: selectedJiraVariable?.type.baseType,
            labels: selectedJiraVariable?.type.labels,
            xType: getVariableXType(jiraVariables, jiraField.value),
          },
          origin: {
            kind: 'vertice-task-output-mapping',
            id: task?.task.id ?? '',
            label: task?.task.name,
          } satisfies VariableOrigin,
        },
        onAddLocalVariable: (variable: Variable) => {
          setWorkflowVariables?.({ udfs: workflowVariables.udfs, request: [...workflowVariables.request, variable] });
        },
      }
    : {};
  const isRichSelector =
    fromWorkflow && selectedJiraVariable && REQUIRED_JIRA_CREATE_FIELDS.includes(selectedJiraVariable.id);

  const workflowSelector = isRichSelector ? (
    <WorkflowRichSelector
      udfVariables={fromWorkflow ? workflowVariables.udfs : []}
      allRequestVariables={workflowVariables.request}
      selectedVariable={selectedWFVariable}
      richSelectorValue={richSelectorValue}
      onRichSelectorValueChange={handleRichSelectorValueChange}
      typeFilter={selectedJiraVariable?.type.baseType?.[0]}
      {...allowCreateVariableProps}
    />
  ) : (
    <WorkflowVariableSelector
      selectedVariable={selectedWFVariable}
      onSelectedVariableChange={handleWFVariableChange}
      udfVariables={fromWorkflow ? workflowVariables.udfs : []}
      allRequestVariables={workflowVariables.request}
      isDisabled={!fromWorkflow && selectedJiraVariable === undefined}
      typeFilter={selectedJiraVariable?.type.baseType?.[0]}
      {...allowCreateVariableProps}
    />
  );

  const jiraSelector = (
    <JiraVariableSelector
      selectedVariable={selectedJiraVariable}
      onSelectedVariableChange={handleJiraVariableChange}
      variables={jiraVariables}
      isVariableTypeDisplayed={true}
      isDisabled={(fromWorkflow && selectedWFVariable === undefined) || !canRemove}
      typeFilter={selectedWFVariable?.type.baseType?.[0]}
      selectedJiraVariables={selectedJiraVariables}
    />
  );

  const fromSelector = fromWorkflow ? workflowSelector : jiraSelector;
  const toSelector = fromWorkflow ? jiraSelector : workflowSelector;

  return (
    <Grid container sx={{ zIndex: 1 }} alignItems="center">
      <Grid item xs={fromWorkflow ? 6 : 4} display="grid">
        {fromSelector}
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IconWrapper icon={ArrowForwardIcon} />
      </Grid>
      <Grid item xs={fromWorkflow ? 4 : 6} display="grid">
        {toSelector}
      </Grid>

      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {canRemove && (
          <IconButton icon={DeleteOutlineIcon} variant="plain" color="neutral" onClick={() => onRemove(index)} />
        )}
      </Grid>
    </Grid>
  );
};
