import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { Button as MuiButton, Stack, styled } from '@mui/material';
import { Button, IconWrapper, Text } from '@verticeone/design-system';

import { capitalizeFirstLetter } from '../../WorkflowViewer/utils';
import { Variable, VariableId } from '../types';
import { useDialogScroll } from './DialogScrollProvider';
import { highlightText } from './HighlightedText';
import { VariableChip } from './VariableChip';
import type { OriginsWithVariables, SectionId } from './types';
import {
  countSelectableVariables,
  getAllSectionsIds,
  getFirstLevelSectionsIds,
  getSectionLabel,
  getVariablesIds,
} from './utils';

type ExpandIconWrapperProps = {
  $isExpanded: boolean;
};

const ExpandIconWrapper = styled(IconWrapper)<ExpandIconWrapperProps>(({ $isExpanded, theme }) => ({
  transform: $isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
  transition: 'transform 0.2s',
  color: theme.palette.core.color6,
}));

type VariableBaseContainerProps = {
  $withBackground?: boolean;
  $withPaddingRight?: boolean;
};

const VariableBaseContainer = styled(Stack)<VariableBaseContainerProps>(
  ({ theme, $withBackground = false, $withPaddingRight = false }) => ({
    position: 'relative',
    zIndex: 0,
    width: 'fit-content',
    paddingRight: $withPaddingRight ? theme.spacing(1.5) : 0,
    ...($withBackground && {
      '&::before': {
        borderRadius: 8,
        content: '""',
        position: 'absolute',
        width: `100%`,
        height: '100%',
        backgroundColor: theme.palette.secondary.color4,
        zIndex: -1,
        opacity: 0.5,
      },
    }),
  })
);

type VariableBaseProps = {
  variable: Variable;
  typeFilter?: string;
  disabledVariables?: VariableId[];
  highlightedLabel?: string;
  onClick: () => void;
  isSelected?: boolean;
};

const VariableBase: FC<VariableBaseProps> = ({
  variable,
  typeFilter,
  disabledVariables,
  onClick,
  highlightedLabel,
  isSelected,
}) => {
  const { label, type, isSelectable } = variable;

  const finalTypeLabel = isSelectable ? type.labels?.[0] : undefined;
  const isTypeFiltered = typeFilter !== undefined && !type.baseType?.some((t) => t === typeFilter);
  const isVariableDisabled = disabledVariables?.some((disabledVariable) => variable.id === disabledVariable);

  return (
    <VariableBaseContainer $withBackground={isSelected} direction="row" alignItems="center">
      <VariableChip
        isDisabled={!isSelectable || isTypeFiltered || isVariableDisabled}
        label={label}
        typeLabel={finalTypeLabel}
        isActive={false}
        onClick={onClick}
        highlightedLabel={highlightedLabel}
      />
    </VariableBaseContainer>
  );
};

const StyledSectionButton = styled(MuiButton)(({ theme }) => ({
  display: 'flex',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: theme.palette.inactive.color2,
}));

type SectionButtonProps = {
  label: string;
  onClick: () => void;
  isExpanded: boolean;
  highlightedLabel?: string;
};

const SectionButton: FC<SectionButtonProps> = ({ label, onClick, isExpanded, highlightedLabel }) => {
  const finalLabel = highlightedLabel ? highlightText(label, highlightedLabel) : label;

  return (
    <Stack display="block">
      <StyledSectionButton onClick={onClick}>
        <ExpandIconWrapper $isExpanded={isExpanded} icon={ExpandMoreOutlined} size="S" />
        <Text sx={{ padding: 1.5 }} variant="button-regular" size="S">
          {finalLabel}
        </Text>
      </StyledSectionButton>
    </Stack>
  );
};

type VariableRowContainerProps = {
  $nestingLevel?: number;
};

const VariableRowContainer = styled(Stack)<VariableRowContainerProps>(({ theme, $nestingLevel = 0 }) => ({
  paddingLeft: $nestingLevel ? theme.spacing(4) : 0,
  marginTop: -1,
  marginBottom: -1,
}));

type VariableRowProps = {
  variable: Variable;
  onPrimitiveVariableClick: (variable: Variable) => void;
  onVariableWithChildVariablesClick: ({
    sectionId,
    childrenSectionsIds,
  }: {
    sectionId: SectionId;
    childrenSectionsIds: SectionId[];
  }) => void;
  expandedVariables: VariableId[];
  highlightedLabel?: string;
  typeFilter?: string;
  disabledVariables?: VariableId[];
  selectedVariableId?: VariableId;
  nestingLevel?: number;
};

const VariableRow: FC<VariableRowProps> = ({
  variable,
  onPrimitiveVariableClick,
  onVariableWithChildVariablesClick,
  expandedVariables,
  highlightedLabel,
  typeFilter,
  disabledVariables,
  selectedVariableId,
  nestingLevel = 0,
}) => {
  const containerRef = useRef(null);
  const { scrollToChild } = useDialogScroll();

  useEffect(() => {
    if (variable.id === selectedVariableId && containerRef) {
      scrollToChild?.(containerRef);
    }
  }, [containerRef, variable.id, selectedVariableId, scrollToChild]);

  if (variable.variables.length === 0 || variable.isSelectable) {
    return (
      <VariableRowContainer ref={containerRef} $nestingLevel={nestingLevel}>
        <VariableBase
          highlightedLabel={highlightedLabel}
          isSelected={variable.id === selectedVariableId}
          typeFilter={typeFilter}
          disabledVariables={disabledVariables}
          onClick={() => onPrimitiveVariableClick(variable)}
          variable={variable}
        />
      </VariableRowContainer>
    );
  }

  const isVariableExpanded = expandedVariables.includes(variable.id);

  return (
    <VariableRowContainer $nestingLevel={nestingLevel}>
      <SectionButton
        label={getSectionLabel(variable.label, countSelectableVariables(variable.variables))}
        onClick={() =>
          onVariableWithChildVariablesClick({
            sectionId: variable.id,
            childrenSectionsIds: getVariablesIds(variable.variables),
          })
        }
        isExpanded={isVariableExpanded}
        highlightedLabel={highlightedLabel}
      />
      {isVariableExpanded && (
        <>
          {variable.variables.map((childVariable) => (
            <VariableRow
              highlightedLabel={highlightedLabel}
              expandedVariables={expandedVariables}
              onPrimitiveVariableClick={onPrimitiveVariableClick}
              onVariableWithChildVariablesClick={onVariableWithChildVariablesClick}
              key={`${childVariable.origin.id}-${childVariable.id}`}
              variable={childVariable}
              typeFilter={typeFilter}
              disabledVariables={disabledVariables}
              selectedVariableId={selectedVariableId}
              nestingLevel={nestingLevel + 1}
            />
          ))}
        </>
      )}
    </VariableRowContainer>
  );
};

type VariablesListProps = {
  title: string;
  originsWithVariables: OriginsWithVariables;
  typeFilter?: string;
  disabledVariables?: VariableId[];
  onVariableClick: (variable: Variable) => void;
  highlightedLabel?: string;
  selectedVariableId?: VariableId;
  expandedSectionsIds?: SectionId[];
  variablesCount: number;
};

export const VariablesList: FC<VariablesListProps> = ({
  originsWithVariables,
  typeFilter,
  disabledVariables,
  title,
  onVariableClick,
  highlightedLabel,
  selectedVariableId,
  expandedSectionsIds,
  variablesCount,
}) => {
  const { t } = useTranslation();

  const [expandedSections, setExpandedSections] = useState<SectionId[]>(expandedSectionsIds || []);

  const { firstLevelSectionsIds, allSectionsIds } = useMemo(() => {
    return {
      firstLevelSectionsIds: getFirstLevelSectionsIds(originsWithVariables),
      allSectionsIds: getAllSectionsIds(originsWithVariables),
    };
  }, [originsWithVariables]);

  const handleSectionClick = ({
    sectionId,
    childrenSectionsIds,
  }: {
    sectionId: SectionId;
    childrenSectionsIds: SectionId[];
  }) => {
    if (expandedSections.includes(sectionId)) {
      setExpandedSections((prevState) =>
        prevState.filter((id) => id !== sectionId && !childrenSectionsIds.includes(id))
      );
    } else {
      setExpandedSections((prevState) => [...prevState, sectionId]);
    }
  };

  const handleButtonClick = () => {
    setExpandedSections((prevState) =>
      isEqual(firstLevelSectionsIds, prevState) ? allSectionsIds : firstLevelSectionsIds
    );
  };

  const finalTitle = `${title} (${variablesCount})`;

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Text size="XS" color="text2" variant="caption">
          {finalTitle}
        </Text>
        <Button variant="plain" size="XS" onClick={handleButtonClick}>
          {t(
            `INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.ACTIONS.${
              isEqual(expandedSections, firstLevelSectionsIds) ? 'EXPAND_ALL' : 'COLLAPSE_ALL'
            }`
          )}
        </Button>
      </Stack>
      <Stack>
        {Object.values(originsWithVariables).map(({ origin, variables, hideOrigin }) => (
          <Fragment key={origin.id}>
            {!hideOrigin && (
              <SectionButton
                highlightedLabel={highlightedLabel}
                label={getSectionLabel(
                  origin.label || capitalizeFirstLetter(origin.kind),
                  countSelectableVariables(variables)
                )}
                onClick={() =>
                  handleSectionClick({
                    sectionId: origin.id,
                    childrenSectionsIds: getVariablesIds(variables),
                  })
                }
                isExpanded={expandedSections.includes(origin.id)}
              />
            )}
            {expandedSections.includes(origin.id) && (
              <>
                {variables.map((variable) => (
                  <VariableRow
                    highlightedLabel={highlightedLabel}
                    typeFilter={typeFilter}
                    disabledVariables={disabledVariables}
                    key={`${variable.origin.id}-${variable.id}`}
                    expandedVariables={expandedSections}
                    onVariableWithChildVariablesClick={handleSectionClick}
                    onPrimitiveVariableClick={onVariableClick}
                    variable={variable}
                    selectedVariableId={selectedVariableId}
                    nestingLevel={!hideOrigin ? 1 : 0}
                  />
                ))}
              </>
            )}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
