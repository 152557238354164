import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import {
  cssObjectToString,
  monthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import {
  AWS_DEFAULT_CURRENCY,
  AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
} from '@vertice/dashboard/src/modules/cloud/constants';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { ifExpression } from '@verticeone/utils/logic';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import extractTooltipContent, {
  TooltipItem,
} from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';
import { Button, Menu, MenuItem, Text } from '@verticeone/design-system';
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@mui/icons-material';
import { useCompleteRequestsContext } from './CompleteRequestsContext';
import dayjs from 'dayjs';

type CompletedRequestsGraphProps = {
  data: any;
};

type DropDownMenuItem = {
  id: number;
  label: string;
};

type DropDownMenuProps = {
  prefix: string;
  label: string;
  onChange?: (id: number) => void;
  items: DropDownMenuItem[];
};

const DropDownMenu = ({ prefix, label, onChange, items }: DropDownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => items.length > 0 && setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (id: number) => {
    setAnchorEl(null);
    await onChange?.(id);
  };

  return (
    <>
      <Button
        id="status-button"
        onClick={handleClick}
        variant="outline"
        disabled={items.length === 0}
        color="neutral"
        size="S"
        sx={{ background: 'white' }}
      >
        <Text variant="body-regular" color="text3" size="XS">
          {prefix}
        </Text>
        <Text variant="body-bold" color="text2" size="XS">
          {label}
        </Text>
        {open ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
      </Button>
      <Menu
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoFocus={false}
        testId={'status-menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem onClick={() => handleChange(item.id)}>
            <Text variant="body-bold" color="text2" size="XS">
              {item.label}
            </Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const CompletedRequestsGraph = ({ data }: CompletedRequestsGraphProps) => {
  const [chart, setChartRef] = useChartRef();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const { range, setRange } = useCompleteRequestsContext();

  const coloredValues = data.values as unknown as SeriesOptionsWithData[];

  const series = useMemo(() => {
    const values = coloredValues.map((item) => {
      return {
        ...item,
        ...ifExpression(item.id === 'onTime', {
          name: 'Completed on-time',
          color: {
            linearGradient: [0, 0, 0, 400],
            stops: [
              [0, '#33D970'],
              [1, '#90E94B'],
            ],
          },
          states: {
            hover: {
              color: '#90E94B',
            },
            inactive: {
              color: '#90E94B',
            },
          },
        }),
        ...ifExpression(item.id === 'late', {
          name: 'Completed late',
          color: '#0B9C9F',
          states: {
            hover: {
              color: '#0B9C9F',
            },
            inactive: {
              color: '#0B9C9F',
            },
          },
        }),
      };
    });

    return getSeriesWithBorderRadius(values, 8) as SeriesOptionsType[];
  }, [coloredValues]);

  const options = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStyledHighcharts,
        mergeOptions({
          chart: { type: 'column', height: 300, marginTop: 30 },
          plotOptions: {
            column: {
              borderWidth: 0,
              borderRadius: 3,
              // pointRange: 1,
            },
          },
          yAxis: {
            labels: {
              formatter: ({ value }: { value: number | string }) =>
                `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`,
            },
          },
          xAxis: {
            tickLength: 0,
            categories: data.months,
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true },
          series,
        }),
      ]),
    [applyXAxisOffset, applyStyledHighcharts, palette, data.months, series]
  );

  const tooltipSort = sortTooltipPointsFunction(data.usedCategories, true);
  const tooltipExtractor = extractTooltipContent(tooltipSort, (v) =>
    currencyFormatter(locale, AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES)(v)
  );

  return (
    <Box bgcolor={palette.core.color1} py={6} borderRadius={2}>
      <Stack gap={1.5}>
        <Stack direction="row" justifyContent="space-between" px={6} spacing={4}>
          <DropDownMenu items={[]} prefix="By" label="On-time vs. late" />
          <DropDownMenu
            prefix="Range"
            label={t('LAST_X_MONTHS', { monthCount: dayjs(range.endDate).diff(dayjs(range.startDate), 'month') })}
            items={[
              { id: 6, label: t('LAST_X_MONTHS', { monthCount: 6 }) },
              { id: 12, label: t('LAST_X_MONTHS', { monthCount: 12 }) },
            ]}
            onChange={(months) => {
              setRange(
                dayjs().subtract(months, 'months').startOf('month').format('YYYY-MM-DD'),
                dayjs().startOf('month').format('YYYY-MM-DD')
              );
            }}
          />
        </Stack>
        <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
        <HighchartTooltip chart={chart}>
          {(context) => {
            const tooltipContent = tooltipExtractor(context);

            if (!tooltipContent.items?.length) return <></>;

            return (
              <TooltipWrapper>
                {tooltipContent.items?.map((item: TooltipItem, index: number) => (
                  <TooltipSeriesValuePair
                    seriesColor={item.color}
                    seriesName={item.name}
                    value={item.value}
                    key={item.name || index}
                  />
                ))}
                <TooltipTotal value={tooltipContent.total} />
              </TooltipWrapper>
            );
          }}
        </HighchartTooltip>
      </Stack>
    </Box>
  );
};

export default CompletedRequestsGraph;
