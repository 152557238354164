import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';
import {
  servicesAPICodegen,
  ListRequestsApiResponse,
  ListRequestsApiArg,
  GetServiceCatalogResourceApiArg,
  GetServiceCatalogResourceApiResponse,
  ServiceCatalogResource,
  XTypeDatasourceWithParamsApiResponse,
  XTypeDatasourceWithParamsApiArg,
} from '../openapi/codegen/servicesAPI';
import { camelCase } from 'lodash';

import { SERVICES_TAG_PREFIX } from './servicesAPI';
export const SERVICES_REQUEST = `${SERVICES_TAG_PREFIX}Request`;
export const SERVICES_RESOURCE = `${SERVICES_TAG_PREFIX}CatalogResource`;

export type ServicesTagType = typeof SERVICES_REQUEST | typeof SERVICES_RESOURCE;

function transformKeysToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(transformKeysToCamelCase);
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = camelCase(key);
      acc[camelCaseKey] = transformKeysToCamelCase(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}

type PascalToCamelCase<S extends string> = S extends `${infer T}${infer U}` ? `${Lowercase<T>}${U}` : S;

type CamelCaseKeys<T> = {
  [K in keyof T as PascalToCamelCase<string & K>]: T[K];
};

export type GetServiceCatalogResourceApiResponseCamelCase = CamelCaseKeys<GetServiceCatalogResourceApiResponse>;
export type ServiceCatalogResourceCamelCase = CamelCaseKeys<ServiceCatalogResource>;

export const enhancedServicesAPI = servicesAPICodegen
  .enhanceEndpoints<ServicesTagType>({
    addTagTypes: [SERVICES_REQUEST, SERVICES_RESOURCE],
    endpoints: {
      createRequest: {
        invalidatesTags: [SERVICES_REQUEST],
      },
      getRequest: {
        providesTags: [SERVICES_REQUEST],
      },
      listRequests: {
        providesTags: [SERVICES_REQUEST],
      },
      listServiceCatalog: {
        providesTags: [SERVICES_RESOURCE],
      },
      updateServiceCatalogServiceResource: {
        invalidatesTags: [SERVICES_RESOURCE],
      },
      updateRequestsOwner: {
        invalidatesTags: [SERVICES_REQUEST],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      listServiceRequestsPaginated: build.query<{ items: ListRequestsApiResponse['items'] }, ListRequestsApiArg>({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListRequestsApiResponse,
            ListRequestsApiArg,
            ListRequestsApiResponse['items'][0]
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/requests`,
                params: { nextToken: args.nextToken, maxResults: args.maxResults },
              });
              return { data: queryResponse.data as ListRequestsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response.items,
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [SERVICES_REQUEST],
      }),
      getServiceCatalogResourceCamel: build.query<
        GetServiceCatalogResourceApiResponseCamelCase,
        GetServiceCatalogResourceApiArg
      >({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const queryResponse = await baseQuery({
            url: `/accounts/${arg.accountId}/serviceCatalog/resource`,
            params: {
              resourceUrn: arg.resourceUrn,
            },
          });
          const data = queryResponse.data as GetServiceCatalogResourceApiResponse;
          const camelCaseData: GetServiceCatalogResourceApiResponseCamelCase = transformKeysToCamelCase(data);
          return { data: camelCaseData };
        },
        providesTags: [SERVICES_RESOURCE],
      }),
      getXTypeDatasourceWithParams: build.query<XTypeDatasourceWithParamsApiResponse, XTypeDatasourceWithParamsApiArg>({
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/serviceCatalog/xtype/datasource`,
          method: 'POST',
          body: queryArg.body,
          params: {
            resourceUrn: queryArg.resourceUrn,
            nextToken: queryArg.nextToken,
            maxResults: queryArg.maxResults,
          },
        }),
      }),
    }),
  });

export const {
  useListServiceRequestsPaginatedQuery,
  useGetServiceCatalogResourceCamelQuery,
  useGetXTypeDatasourceWithParamsQuery,
} = enhancedServicesAPI;
