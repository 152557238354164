import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from '@vertice/core/src/components/SearchInput';
import { useFilterParam, useSearchParam } from '../dataSource/queryParams';

type SearchBarProps = {
  rowCount: number;
};

export const SearchBar = ({ rowCount }: SearchBarProps) => {
  const { t } = useTranslation();
  const [statusValue] = useFilterParam();
  const [searchValue, setSearchValue] = useSearchParam();

  return (
    <SearchInput
      size="S"
      value={searchValue}
      onSearch={setSearchValue}
      debounce={500}
      sx={{ width: '300px' }}
      placeholder={t(`INTELLIGENT_WORKFLOWS.TASKS_LIST.SEARCH_N_${statusValue}_TASKS`, { count: rowCount })}
    />
  );
};

export default SearchBar;
