import { Card } from '@verticeone/design-system';
import { Placeholder } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import React from 'react';

export const WorkflowVisualizerSkeleton = () => {
  return (
    <Card>
      <Placeholder height={'min(calc(100vh - 300px), 700px)'} />
    </Card>
  );
};

export const RequestDetailSkeleton = () => {
  return (
    <Stack gap={4}>
      <WorkflowVisualizerSkeleton />
      <Card>
        <Stack spacing={6} padding={6}>
          {Array.from('abcd').map((i) => (
            <Placeholder width={300} height={52} key={i} />
          ))}
        </Stack>
      </Card>
    </Stack>
  );
};
