import { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ChipButton,
  CommentMetadata,
  DesignSystemSize,
  Tab,
  Tabs,
  TabsBottomLineWrapper,
  Text,
  VerticeVIcon,
} from '@verticeone/design-system';
import { getNewCommentsCount } from '@verticeone/design-system';
import { useConversationContext } from './ConversationContext';

export type ConversationTab = 'internal' | 'customer';

type ConversationTabsProps = {
  value: ConversationTab;
  onChange: (v: ConversationTab) => void;
};

export const ConversationTabs: FC<ConversationTabsProps> = ({ value, onChange }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const { current, parent } = useConversationContext();

  return (
    <TabsBottomLineWrapper sx={{ paddingTop: 4 }}>
      <Tabs color="primary" scrollButtons value={value} onChange={(_, newValue) => onChange(newValue)}>
        <Tab
          value="customer"
          label={
            <Stack direction="row" gap={2} alignItems="center">
              <Text variant="body-bold" size="S">
                {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.TABS.CUSTOMER')}
              </Text>
              <UnreadMessageChip {...parent} size="XXS" />
            </Stack>
          }
        />
        <Tab
          value="internal"
          sx={{
            backgroundColor: palette.grey[200],
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          label={
            <Stack direction="row" gap={2} alignItems="center">
              <VerticeVIcon height={16} />
              <Text variant="body-bold" size="S">
                {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.TABS.INTERNAL')}
              </Text>
              <UnreadMessageChip {...current} size="XXS" />
            </Stack>
          }
        />
      </Tabs>
    </TabsBottomLineWrapper>
  );
};

type UnreadMessageChipProps = {
  messages: CommentMetadata[];
  cursor: string | null;
  size?: DesignSystemSize;
  showNew?: boolean;
};

export const UnreadMessageChip: FC<UnreadMessageChipProps> = ({ cursor, messages, showNew, size = 'S' }) => {
  const { t } = useTranslation();

  const newCommentsCount = getNewCommentsCount(messages, cursor!);

  if (!newCommentsCount) {
    return null;
  }

  return (
    <ChipButton variant="solid" size={size} color="secondary">
      {showNew ? t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.NEW', { count: newCommentsCount }) : newCommentsCount}
    </ChipButton>
  );
};
