import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { allLineItemTypes } from '../../../types';

export const useLineItemTypeOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      allLineItemTypes.map((lineType) => ({
        value: lineType,
        label: t(`DYNAMIC_FORM_BUILDER.FIELD.LINE_ITEMS.LINE_TYPES.${lineType}`),
      })),
    [t]
  );
};
