import { z } from 'zod';
import { nonEmptyString } from '@verticeone/utils/validation';

export const lineItemsFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const lineItemsFieldSchema = z.object({
  name: z.string(),
  type: z.literal('LINE_ITEMS'),
  metadata: lineItemsFieldMetadataSchema,
});

export type LineItemsFieldMetadata = z.infer<typeof lineItemsFieldMetadataSchema>;

export type LineItemsFieldType = z.infer<typeof lineItemsFieldSchema>;

export type LineItemType = 'ITEM' | 'SERVICE';
export const allLineItemTypes: LineItemType[] = ['ITEM', 'SERVICE'];

export const lineItemSchema = z.object({
  id: nonEmptyString,
  lineItemType: z.custom<LineItemType>((val) => allLineItemTypes.includes(val) ?? false),
  description: z.string().nullable(),
  quantity: z.number().nullable(),
});

export type LineItem = z.infer<typeof lineItemSchema>;

export type LineItemsValue = LineItem[];
