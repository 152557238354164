import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export type EditorDrawerScrollContainerProps = PropsWithChildren<{
  noPadding?: boolean;
}>;

export const EditorDrawerScrollContainer: FC<EditorDrawerScrollContainerProps> = ({ children, noPadding = false }) => {
  return (
    <Stack flexGrow={1} width="100%" minHeight={0}>
      <Stack
        direction="column"
        padding={noPadding ? undefined : 6}
        overflow="auto"
        height="100%"
        maxHeight="100%"
        width="100%"
        gap={12}
      >
        {children}
      </Stack>
    </Stack>
  );
};
