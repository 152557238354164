import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { LineItem, LineItemType } from '../../types';
import { DesignSystemColor, TextField } from '@verticeone/design-system';
import { Box } from '@mui/material';

export const EditableQuantityCell = ({
  id,
  value,
  field,
  color,
}: GridCellParams<LineItem, LineItemType> & { color: DesignSystemColor }) => {
  const apiRef = useGridApiContext();
  return (
    <Box px={4}>
      <TextField
        variant="ghost"
        type="number"
        hiddenLabel
        value={value ?? ''}
        onChange={(e) => {
          const parseResult = parseFloat(e.target.value);
          void apiRef.current.setEditCellValue({ id, field, value: isNaN(parseResult) ? null : parseResult });
        }}
        color={color}
      />
    </Box>
  );
};
