import { createRequiredContext } from '@verticeone/utils/contexts';
import { useConversations } from '../../useConversations';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';
import { PropsWithChildren } from 'react';

type ConversationContextProps = {
  current: ReturnType<typeof useConversations>;
  parent: ReturnType<typeof useConversations>;
  currentUsers: ReturnType<typeof useConversationUsers>;
  parentUsers: ReturnType<typeof useConversationUsers>;
};

const { ConversationContextProvider, useConversationContext } = createRequiredContext<
  ConversationContextProps,
  'Conversation'
>('Conversation');

export type ConversationData = {
  accountId: string;
  requestId: string;
  owner?: string | null;
};

type ConversationProviderProps = {
  request: ConversationData;
  parentRequest?: ConversationData;
  sourceRef?: string;
};

const ConversationProvider = ({
  children,
  request,
  parentRequest,
  sourceRef,
}: PropsWithChildren<ConversationProviderProps>) => {
  const current = useConversations({
    requestId: request.requestId,
    accountId: request.accountId,
    requestOwner: request.owner,
    sourceRef,
  });
  const parent = useConversations({
    requestId: parentRequest?.requestId,
    accountId: parentRequest?.accountId,
    requestOwner: parentRequest?.owner,
    sourceRef,
  });

  const currentUsers = useConversationUsers(request.accountId);
  const parentUsers = useConversationUsers(parentRequest?.accountId);

  return (
    <ConversationContextProvider value={{ current, parent, currentUsers, parentUsers }}>
      {children}
    </ConversationContextProvider>
  );
};

export { ConversationContextProvider, ConversationProvider, useConversationContext };
