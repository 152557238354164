import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { IconButton, Text } from '@verticeone/design-system';
import { CloseOutlined } from '@mui/icons-material';

type EditorDrawerHeaderProps = {
  title: string;
  subtitle?: string;
  gap?: number;
  onClose?: VoidFunction;
  actions?: ReactNode;
};

export const EditorDrawerHeader: FC<EditorDrawerHeaderProps> = ({ title, subtitle, onClose, gap, actions }) => (
  <Stack
    direction="row"
    alignItems="center"
    padding={6}
    spacing={2}
    borderBottom={({ palette }) => `1px solid ${palette.core.color3}`}
  >
    <Stack direction="column" gap={gap} flex={1}>
      <Text variant="heading" size="M" color="text1">
        {title}
      </Text>
      {subtitle && (
        <Text variant="body-regular" size="S" color="text4">
          {subtitle}
        </Text>
      )}
    </Stack>
    {actions}
    {onClose && <IconButton variant="outline" size="M" color="neutral" icon={CloseOutlined} onClick={onClose} />}
  </Stack>
);
