import { FieldValues, useController } from 'react-hook-form';
import { CommonFormFieldProps } from '../../../../types';
import { RadioGroupValuesList } from './RadioGroupValuesList';
import { DesignSystemColor } from '@verticeone/design-system';

type RadioGroupValuesListFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  color: DesignSystemColor;
};

export type RadioGroupValuesListFieldComponentType<FormDataType extends FieldValues> = (
  props: RadioGroupValuesListFieldProps<FormDataType>
) => JSX.Element;

export const RadioGroupValuesListField = <FormDataType extends FieldValues = never>({
  name,
  required,
  color,
}: RadioGroupValuesListFieldProps<FormDataType>) => {
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController<FormDataType>({
    name,
    rules: { required },
  });

  return (
    <RadioGroupValuesList
      color={color}
      values={value}
      onValuesChange={onChange}
      errorText={invalid ? error?.message : undefined}
    />
  );
};
