import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';

import { DEFAULT_OFFER_LIMIT, FormData } from './schema';
import { Button } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../shared/TaskFormContext';
import { Accordion, AccordionDetails, AccordionSummary } from '@verticeone/design-system';
import { Divider } from '@vertice/core/src/components/Dialog/Components';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/CopyAll';
import { IconButton } from '@verticeone/design-system';
import { OfferFormEntry } from '../shared/formFields/OfferFormEntry';
import { BaseSyntheticEvent } from 'react';

const getUniqueOfferId = (ids: number[]) => {
  return `${Math.max(...ids) + 1}`;
};

export const NegotiationOfferForm = () => {
  const { readOnly } = useTaskFormContext();
  const { getValues } = useFormContext<FormData>();
  const { t } = useTranslation();

  const maxOffers = useWatch<FormData, 'maxOffers'>({ name: 'maxOffers' });
  const baselineOffer = useWatch<FormData, 'baselineOffer'>({ name: 'baselineOffer' });
  const { fields, append, remove } = useFieldArray<FormData, 'negotiationOffers.instances'>({
    name: 'negotiationOffers.instances',
  });

  const addOffer = () =>
    append({
      products: [],
      concessions: [],
      overrideEffectiveCost: false,
      purchasedFromReseller: false,
      baseCurrency: baselineOffer.baseCurrency,
      vendor: baselineOffer.vendor,
    } as any);

  const duplicate = (e: BaseSyntheticEvent, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    // fetch offer values from context as fields have stale values
    const offerInstances = getValues(`negotiationOffers.instances`);
    const offerValues = offerInstances[index];

    if (!offerValues) {
      return;
    }

    const offerInstancesIds = offerInstances.map((offer) => Number(offer.id || 0));

    append({ ...offerValues, name: '', id: getUniqueOfferId(offerInstancesIds) });
  };

  const maxOffersExceeded = fields.length >= (maxOffers || DEFAULT_OFFER_LIMIT);

  return (
    <Stack gap={4}>
      {fields.map(({ id }, i) => (
        <Accordion key={id} defaultExpanded variant="label">
          <AccordionSummary
            title={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.OFFER_SUMMARY', { count: i + 1 })}
            adornment={
              !readOnly && (
                <Stack flexDirection="row" gap={2}>
                  <IconButton icon={DeleteIcon} variant="ghost" onClick={() => remove(i)} />
                  <IconButton
                    icon={CopyIcon}
                    variant="ghost"
                    onClick={(e: BaseSyntheticEvent) => duplicate(e, i)}
                    disabled={maxOffersExceeded}
                  />
                </Stack>
              )
            }
          />
          <AccordionDetails>
            <OfferFormEntry<FormData> name={`negotiationOffers.instances.${i}`} editName />
          </AccordionDetails>
        </Accordion>
      ))}

      {!readOnly && (
        <>
          <Divider />
          <Button
            variant="ghost"
            onClick={addOffer}
            disabled={maxOffersExceeded}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.ACTIONS.ADD_OFFER')}
          </Button>
        </>
      )}
    </Stack>
  );
};
