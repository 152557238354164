import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export type Task = {
  id: string;
  title: string;
  vendorId: string;
  date: string;
};

type MyRequestsData = {
  tasks: Task[];
  overdueRequests: number;
  spend: number;
};

export const useOverdueRequestsData = () => {
  const tempData = {
    name: 'overdue_requests',
    stats: {
      overdueRequests: 42,
      spend: 1200000,
    },
    details: {
      columns: ['id', 'title', 'vendor_id', 'date'],
      types: ['string', 'string', 'string', 'isotime'],
      data: [
        ['1', 'NetSuit New Purchase', 'c8cfa123-2c7c-56d5-9086-25d94246d3ec', '2024-12-21'],
        ['2', 'Hubspot New Purchase', '0079f31a-400f-5c0b-8b12-fc86c5177e46', '2024-12-24'],
        ['3', 'Adobe Renewal', '61a63d7c-fb29-5829-be2e-e9dadebedade', '2024-12-26'],
        ['4', 'Postmark Renewal', '6d15aedf-8393-5462-97b7-8b0c176804ed', '2024-12-29'],
      ],
    },
  };

  const data: MyRequestsData = {
    tasks: getTableData(tempData.details, {
      vendor_id: 'vendorId',
      title: 'title',
      date: 'date',
    }) as Task[],
    overdueRequests: tempData.stats.overdueRequests,
    spend: tempData.stats.spend,
  };

  return {
    data,
    isLoading: false,
  };
};
