import { Stack, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { DRAWER_WIDTH } from '../../WorkflowViewer/constants';

const StyledDrawer = styled(Stack)<{ $open: boolean; $width: number }>(({ theme, $open, $width }) => ({
  position: 'fixed',
  backgroundColor: theme.palette.background.default,
  height: '100%',
  top: 0,
  right: 0,
  width: $width,
  transition: 'transform ease-in-out 300ms',
  transform: `translate(${$open ? 0 : $width}px, 0)`,
  boxShadow: $open
    ? theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '80' })
    : 'none',

  '@media (max-width: 1280px)': {
    width: `${$width - $width / 6}px`,
  },

  // 968px is max width where variable picker displays properly
  '@media (max-width: 968px)': {
    width: 'calc(100% - 88px)', // 88px is the width of the sidebar
  },
}));

type EditorDrawerProps = PropsWithChildren & {
  open: boolean;
  width?: number;
};

export const EditorDrawer: FC<EditorDrawerProps> = ({ children, open, width = DRAWER_WIDTH }) => {
  return (
    <StyledDrawer $open={open} $width={width}>
      {children}
    </StyledDrawer>
  );
};
