import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { ValidationItem, useValidateWorkflowMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useCallback, useMemo, useState } from 'react';

export const useValidateWorkflow = () => {
  const [validationItems, setValidationItems] = useState<ValidationItem[]>([]);

  const [validateDefinition, { isLoading: isValidatingWorkflow }] = useValidateWorkflowMutation();
  const { accountId } = useAccountContext();

  const validate = useCallback(
    async (definitions?: Definitions) => {
      if (!definitions) {
        setValidationItems([]);
        return;
      }

      const validationResponse = await validateDefinition({
        accountId,
        validateWorkflow: { processDefinition: JSON.stringify(definitions) },
      }).unwrap();

      const validations = validationResponse.validations || [];
      setValidationItems(validations);
    },
    [validateDefinition, accountId]
  );

  return useMemo(() => {
    const errors = validationItems.filter((validation) => validation.severity === 'ERROR');
    const warnings = validationItems.filter((validation) => validation.severity === 'WARNING');
    const infos = validationItems.filter((validation) => validation.severity === 'INFO');

    return { validate, errors, warnings, infos, isValidatingWorkflow };
  }, [validationItems, validate, isValidatingWorkflow]);
};
