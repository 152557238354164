import React, { MouseEvent, useEffect, useRef } from 'react';
import { useTheme, styled, Tooltip, Stack } from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import type { StyledComponent } from 'styled-components';
import type { SvgIconComponent } from '@mui/icons-material';
import { useMainLayout } from '@vertice/dashboard/src/layouts/Main/LayoutWrapper';
import { Text } from '@verticeone/design-system';
import { Button, ButtonProps } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';

export type NavigationItemProps = {
  label: string;
  to?: string;
  icon?: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
  chip?: React.ReactNode;
  onClick?: () => void;
  isVisible?: boolean;
  match?: string[];
  endPart?: React.ReactNode;
} & Partial<ButtonProps>;

const DEFAULT_COLOR = 'primary';

const BaseNavigationItem = ({
  isVisible = true,
  icon,
  chip,
  label,
  onClick,
  to,
  match,
  color = DEFAULT_COLOR,
  ...otherProps
}: NavigationItemProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isOpen } = useMainLayout();
  const isActive = Boolean(to === pathname || match?.some((path) => !!matchPath(path, pathname)));

  const onButtonClick = (evt: MouseEvent) => {
    if (to) {
      if (evt.ctrlKey || evt.metaKey) {
        // Open in new tab
        window.open(to, '_blank');
      } else {
        navigate(to);
      }
    }

    onClick?.();
  };

  useEffect(() => {
    if (isOpen && isActive) {
      // Scroll to active item
      ref.current?.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [isOpen, isActive]);

  if (!isVisible) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Text variant="body-bold" size="M">
          {label}
        </Text>
      }
      placement="right-end"
      disableFocusListener={isOpen}
      disableHoverListener={isOpen}
      disableInteractive={isOpen}
      disableTouchListener={isOpen}
      key={`tooltip-${label}-${isOpen ? 'open' : 'closed'}`}
    >
      <Stack>
        <Button
          ref={ref}
          size="M"
          variant="plain"
          color="neutral"
          onClick={onButtonClick}
          sx={{
            justifyContent: isOpen ? 'flex-start' : 'center',
            color: isActive ? theme.palette[color].color2 : theme.palette.text.color2,
          }}
          {...otherProps}
        >
          {icon && (
            <IconWrapper
              className="menu-icon"
              sx={{ color: isActive ? theme.palette[color].color2 : theme.palette.core.color5 }}
              icon={icon}
            />
          )}
          {isOpen && (
            <Text variant="button-bold" size="S" flex={1}>
              {label}
            </Text>
          )}
          {isOpen && chip}
        </Button>
      </Stack>
    </Tooltip>
  );
};

const NavigationItem = styled(BaseNavigationItem)(({ color = DEFAULT_COLOR, theme }) => ({
  justifyContent: 'flex-start',
  minHeight: '40px',
  padding: '8px',
  '&:hover': {
    padding: '8px',
    color: theme.palette[color].color2,
    backgroundColor: theme.palette[color].color4,
  },
  '.menu-icon': {
    opacity: 0.6,
    width: '25px',
    height: '25px',
  },
})) as StyledComponent<typeof BaseNavigationItem, NavigationItemProps>;

export default NavigationItem;
