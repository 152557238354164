import { currencies } from '@verticeone/utils/currency';

type MoneyExchangeArguments = {
  amount: string;
  sourceCurrency: string;
  targetCurrency: string;
};

const MoneyExchangeUdfRegex =
  /udf\(`urn:verticeone:vertice::services:udf\/core\/exchange-currency\/v0`, {amount: `?(-?\d+(?:\.\d+)?|)`?, sourceCurrency: `?([^`,]+)`?, targetCurrency: `?(.+?)`?}\)$/;

export const parseMoneyExchangeUdfCall = ({
  targetCurrency,
  udfCall,
}: {
  targetCurrency: string;
  udfCall: string;
}): MoneyExchangeArguments => {
  const match = MoneyExchangeUdfRegex.exec(udfCall);

  if (!match) {
    return {
      amount: '',
      sourceCurrency: 'USD',
      targetCurrency,
    };
  }

  const [, amount, sourceCurrency, targetCurrencyFromUdfCall] = match;

  return {
    amount,
    sourceCurrency,
    targetCurrency: targetCurrencyFromUdfCall,
  };
};

export const createMoneyExchangeUdfCall = ({
  amount,
  sourceCurrency,
  targetCurrency,
}: MoneyExchangeArguments): string => {
  const formattedTargetCurrency = currencies.includes(targetCurrency) ? `\`${targetCurrency}\`` : targetCurrency;
  return `udf(\`urn:verticeone:vertice::services:udf/core/exchange-currency/v0\`, {amount: \`${amount}\`, sourceCurrency: \`${sourceCurrency}\`, targetCurrency: ${formattedTargetCurrency}})`;
};
